<h5 mat-dialog-title>Notification ({{ dateError }})</h5>
<mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<p class="h6">Page</p>
			<div class="alert alert-secondary text-break" role="alert">{{ page }}</div>
		</div>
		<div class="col-md-12">
			<p class="h6">Method</p>
			<div class="alert alert-secondary text-break" role="alert">{{ method }}</div>
		</div>
		<div class="col-md-12">
			<p class="h6">Service</p>
			<div class="alert alert-secondary text-break" role="alert">{{ services }}</div>
		</div>
		<div class="col-md-12" *ngIf="params !== ''">
			<p class="h6">Params</p>
			<div class="alert alert-secondary text-break" role="alert">
				<p *ngFor="let param of paramsList" style="margin-bottom: 0">{{ param }}</p>
			</div>
		</div>
		<div class="col-md-12" *ngIf="body !== null">
			<p class="h6">Body</p>
			<div class="alert alert-secondary" role="alert" [innerText]="body"></div>
		</div>
		<div class="col-md-12">
			<p class="h6">Message</p>
			<div class="alert alert-secondary text-break" role="alert">{{ message }}</div>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="btn-close-modal">
	<button mat-raised-button color="primary" [mat-dialog-close]="false">Close</button>
</mat-dialog-actions>
