<ng-container>
	<mat-card appearance="outlined" class="card-container">
		<mat-card-content [ngStyle]="styleContent">
			<ng-content select="[content]"></ng-content>
		</mat-card-content>
		<mat-card-actions *ngIf="actionsView" class="matactions-propio">
			<ng-content select="[actions]"></ng-content>
		</mat-card-actions>
	</mat-card>
</ng-container>
