<div class="plots-grid">
	<div class="plot-container border-graf">
		<ng-content select="[contentGraf]"></ng-content>
	</div>
	<div class="plot-container border-text">
		<h5>How to choose the k number of clusters:</h5>
		<h5>Using the elbow curve:</h5>
		<p>
			- Consider the “elbow” curve as an arm. <br />
			- Find the inflection point, which is equivalent to the “elbow” of the arm. <br />
			- This point represents a good estimation of the number of clusters. <br />
			- Choosing a higher number of clusters gives diminishing returns.
		</p>
		<h5>Using the silhouette curve:</h5>
		<p>
			-The average silhouette method computes the average silhouette of observations for different values of k. The
			optimal number of clusters k is the one that maximizes the average silhouette over a range of possible values for
			k.
		</p>
	</div>
</div>
