import { Injectable } from '@angular/core';
import { Color } from '../../models/calendars/Color';

@Injectable({
	providedIn: 'root'
})
export class CalendarColorService {
	constructor() {}

	/*
	 * para no romper la pagina de seasson que usa por DI este servicio angular
	 */
	calcColor(cell_value: number, min_value: number, max_value: number, is_ascending: boolean): Color {
		return fcalcColor(cell_value, min_value, max_value, is_ascending);
	}
}

/*
 * para las paginas que no usan DI y llaman la function directamente
 */
export function fcalcColor(cell_value: number, min_value: number, max_value: number, is_ascending: boolean): Color {
	// Si is_ascending es True, colores menores son rojos y mayores verdes, si is_ascending es False el orden se invierte
	// Position va del 0 al 100. Si position es 0, color es más Rojo.
	let position = (100 * (cell_value - min_value)) / (max_value - min_value);
	if (is_ascending) {
		position = 100 - position;
	}
	const a = 0;

	if (position < 10) {
		return new Color(225, 123, 116, a);
	} else if (position < 20) {
		return new Color(231, 144, 114, a);
	} else if (position < 30) {
		return new Color(238, 163, 112, a);
	} else if (position < 40) {
		return new Color(243, 184, 110, a);
	} else if (position < 50) {
		return new Color(250, 203, 109, a);
	} else if (position < 60) {
		return new Color(234, 210, 112, a);
	} else if (position < 70) {
		return new Color(199, 204, 119, a);
	} else if (position < 80) {
		return new Color(164, 199, 126, a);
	} else if (position < 90) {
		return new Color(130, 193, 133, a);
	} else {
		return new Color(98, 187, 140, a);
	} // Verde
}
