import { Component, Input } from '@angular/core';
import Handsontable from 'handsontable';
import { GridBucketSummary } from 'src/app/models/buckets/GridBucketSummary';
import { ConfigService } from '../../../../config/config.service';

interface HTColumn {
	title: string;
	readOnly: boolean;
	numericFormat?: { pattern: string };
	renderer?: (
		hotInstance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties
	) => void;
}

const column_empty: HTColumn = {
	title: '',
	readOnly: true
};

const column_total: HTColumn = {
	title: 'Total',
	readOnly: true
};

let nextId = 0;

const GridBucketSummaryInputs = {
	data_grid: 'data_grid'
};

@Component({
	selector: 'app-grid-bucket-summary',
	templateUrl: './grid-bucket-summary.component.html',
	styleUrls: ['./grid-bucket-summary.component.scss']
})
export class GridBucketSummaryComponent {
	@Input() title = '';
	@Input() readOnly = true;
	@Input() show_bucket_col = true;

	columns_width: Array<number> = [];

	@Input() rendererGrid: (
		instance: Handsontable,
		TD: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridBucketSummary,
		editable: boolean
	) => void;

	@Input(GridBucketSummaryInputs.data_grid) set setDataGrid(data: GridBucketSummary) {
		if (typeof data !== 'undefined' && data !== undefined) {
			this.data_grid = {
				...data,
				rows: data.rows.map((row) => this.formatRow(row))
			} as GridBucketSummary;

			this.ready = true;

			this.columns = [];
			this.columns = [column_empty];

			for (const cluster of this.data_grid.clusters) {
				this.columns = [
					...this.columns,
					{
						title: 'C' + cluster,
						readOnly: true,
						numericFormat: { pattern: '%0.0' },
						renderer: this.callRendererGrid
					}
				];
			}

			this.columns = [...this.columns, column_total];
			const widths = witdhCols(this.data_grid.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];
			this.columns_width = [];
			// ap
			this.columns_width.push(width_ap);
			// clusters
			for (const cluster of this.data_grid.clusters) {
				this.columns_width.push(width_cv);
			}
			this.columns_width.push(width_cv);
		}
	}

	formatRow(row) {
		return row.includes('Rem elasticity')
			? row.map((rem) => (typeof rem === 'number' ? new String(rem.toFixed(2)) : rem))
			: row;
	}

	private ready: boolean;
	columns: Array<HTColumn> = [];
	data_grid: GridBucketSummary;

	id = 'hot-id-buckets-summm-' + nextId++;

	tableSettings(): Handsontable.GridSettings {
		return {
			licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
			contextMenu: false,
			dropdownMenu: false,
			language: 'es-MX',
			className: 'htCenter',
			filters: false,
			columnSorting: false,
			allowInsertRow: false,
			viewportColumnRenderingOffset: 1000,
			colWidths: this.columns_width,
			afterChange: (changes, source) => {}
		};
	}

	constructor(private configService: ConfigService) {}

	isReady(): boolean {
		return this.ready;
	}

	stretchH(): string {
		if (this.show_bucket_col) {
			return 'none';
		} else {
			return 'all';
		}
	}

	callRendererGrid = (
		hotInstance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties
	) => {
		if (typeof this.rendererGrid !== 'undefined') {
			let value_num = 0;
			if (col !== 1) {
				value_num = parseFloat(value);
			} else {
				value_num = parseInt(value, 10);
			}
			this.rendererGrid(hotInstance, td, row, col, prop, value_num, cellProperties, this.data_grid, !this.readOnly);
		} else {
			Handsontable.renderers.NumericRenderer.apply(this, [hotInstance, td, row, col, prop, value, cellProperties]);
		}
	};
}

function witdhCols(num_clusters: number): [number, number] {
	if (num_clusters === 1) {
		return [210, 100];
	} else if (num_clusters === 2) {
		return [180, 75];
	} else if (num_clusters === 3) {
		return [150, 70];
	} else if (num_clusters === 4) {
		return [140, 60];
	} else if (num_clusters === 5) {
		return [130, 50];
	} else if (num_clusters <= 7) {
		return [120, 42];
	} else {
		return [104, 32]; // [49, 26]
	}
}
