export function createRowTod<T>(start: string, end: string, emptyT: T): RowTod<T> {
	const row = new RowTod<T>(start, end, emptyT, emptyT, emptyT, emptyT, emptyT, emptyT, emptyT);

	return row;
}

export class RowTod<T> {
	constructor(
		public tod_start: string,
		public tod_end: string,
		public mon_data: T,
		public tue_data: T,
		public wed_data: T,
		public thu_data: T,
		public fri_data: T,
		public sat_data: T,
		public sun_data: T
	) {}

	// dow debe ser el nombre del atributo (mon_data, tue_data, ..., sun_data)
	updateValue(dow: string, value: T) {
		this[dow] = value;
	}

	getValue(dow_attr: string): T {
		return this[dow_attr];
	}
}
