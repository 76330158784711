<ng-container>
	<mat-card appearance="outlined" class="mat-card">
		<mat-card-content class="mat-card-content">
			<div class="row">
				<div class="col-md-7">
					<!-- Graficos -->
					<div class="container linea-container">
						<app-target-curve-scatterplot [data]="data"></app-target-curve-scatterplot>
					</div>
				</div>
				<div class="col-md-5 container-model-ask">
					<div class="container-tablas">
						<!-- TABLA -->
						<app-model-settings [grid_model_settings]="grid_model_settings"></app-model-settings>
						<!-- desplegar tabla aca -->
						<br />
						<app-ask-indicators [data]="data_ask_indicators"></app-ask-indicators>
					</div>
				</div>
			</div>
		</mat-card-content>
		<mat-card-actions class="select-rem">
			<div class="contenedor-btn">
				<div class="btn-select">
					<mat-form-field appearance="outline" class="ml-22">
						<mat-select multiple [formControl]="selectCurves" placeholder="Select Curves">
							<mat-option [value]="indicator" *ngFor="let indicator of optionCurves">{{ indicator }}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="outline" class="ml-22">
						<mat-select multiple [formControl]="selectClusters" placeholder="Select Clusters">
							<mat-option [value]="indicator" *ngFor="let indicator of optionClusters">{{ indicator }}</mat-option>
						</mat-select>
					</mat-form-field>
					<div class="select-white">
						<mat-form-field class="ml-22">
							<mat-select [formControl]="selectSmooth" placeholder="Smooth Curves">
								<mat-option [value]="indicator" *ngFor="let indicator of optionSmooth">{{ indicator }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div class="btn-trio">
					<button class="btn-refresh" (click)="refresh()">Refresh</button>
					<button class="btn-save" [disabled]="disableSave" (click)="save()">Save</button>
				</div>
			</div>
		</mat-card-actions>
	</mat-card>
</ng-container>
