import { Component, Input } from '@angular/core';
import { fcalcColor } from '@services/calendars/calendar-color.service';
import Handsontable from 'handsontable';
import { GridBucketApClusters } from 'src/app/models/buckets/GridBucketApClusters';
import { Color } from 'src/app/models/calendars/Color';

const CardInformativeTablesInputs = {
	dataGridInfoTable: 'data_grid_info_table'
};
@Component({
	selector: 'app-card-informative-tables',
	templateUrl: './card-informative-tables.component.html',
	styleUrls: ['./card-informative-tables.component.scss']
})
export class CardInformativeTablesComponent {
	// lista de clusters de la ruta
	clusters: Array<string> = [];

	data_grid_info_table: GridBucketApClusters<number> | undefined = undefined;
	data_grid_info_table_grid_average_bp: GridBucketApClusters<number> | undefined = undefined;
	data_grid_info_table_grid_lf_composition: GridBucketApClusters<number> | undefined = undefined;
	data_grid_info_table_av_bp_bsln: GridBucketApClusters<number> | undefined = undefined;
	data_grid_info_table_pax_bsln: GridBucketApClusters<number> | undefined = undefined;
	data_grid_info_table_raw: GridBucketApClusters<number> | undefined = undefined;
	data_grid_info_table_ready = false;

	@Input(CardInformativeTablesInputs.dataGridInfoTable)
	set setDataGridInfoTable(data) {
		if (typeof data !== 'undefined') {
			this.data_grid_info_table_ready = true;

			this.data_grid_info_table_grid_average_bp = data.grid_average_bp;
			this.data_grid_info_table_grid_lf_composition = data.grid_lf_composition;
			this.data_grid_info_table_av_bp_bsln = data.grid_average_bp_vs_baseline;
			this.data_grid_info_table_pax_bsln = data.grid_pax_vs_baseline;

			this.data_grid_info_table_grid_average_bp.title = 'Average Bid price by anticipation';
			this.data_grid_info_table_grid_lf_composition.title = 'LF Composition by AP';
			this.data_grid_info_table_av_bp_bsln.title = 'Δ Average Bid-Price vs Baseline';
			this.data_grid_info_table_pax_bsln.title = 'Δ PAX vs Baseline';
		} else {
			this.data_grid_info_table_ready = false;
			this.data_grid_info_table_grid_average_bp = undefined;
			this.data_grid_info_table_grid_lf_composition = undefined;
			this.data_grid_info_table_av_bp_bsln = undefined;
			this.data_grid_info_table_pax_bsln = undefined;
		}
	}

	constructor() {}

	formatInfoTable = (value: number) => {
		return value.toFixed(0);
	};

	rendererGridAverageBp = (
		instance,
		td,
		row,
		col,
		prop,
		value,
		cellProperties,
		data_grid_info_table_grid_average_bp,
		editable
	) => {
		this.rendererGrid(
			instance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.data_grid_info_table_grid_average_bp,
			this.formatCell,
			true
		);
	};

	rendererGridLfComposition = (
		instance,
		td,
		row,
		col,
		prop,
		value,
		cellProperties,
		data_grid_info_table_grid_lf_composition,
		editable
	) => {
		this.rendererGrid(
			instance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.data_grid_info_table_grid_lf_composition,
			this.formatCell,
			true
		);
	};

	rendererGridAverageBpVsBaseline = (
		instance,
		td,
		row,
		col,
		prop,
		value,
		cellProperties,
		data_grid_info_table_av_bp_bsln,
		editable
	) => {
		this.rendererGrid(
			instance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.data_grid_info_table_av_bp_bsln,
			this.formatCell,
			true
		);
	};

	rendererGridPaxVsBaseline = (
		instance,
		td,
		row,
		col,
		prop,
		value,
		cellProperties,
		data_grid_info_table_pax_bsln,
		editable
	) => {
		this.rendererGrid(
			instance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.data_grid_info_table_pax_bsln,
			this.formatCell,
			true
		);
	};

	formatCell = (value: number) => {
		return value.toFixed();
	};

	rendererGrid = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridBucketApClusters<number>,
		formatNumber: (n: number) => string,
		editable: boolean
	) => {
		const emptyCellColor = '#F3F2F';
		if (typeof value === 'number') {
			if (Number.isNaN(value)) {
				td.style.backgroundColor = emptyCellColor;
				td.innerHTML = '';
			} else {
				const szValue = formatNumber(value);
				td.innerHTML = szValue;
				const totalRowDataNumber = data_grid.clusters.length;

				if (col >= 1 && col < data_grid.clusters.length && value !== null) {
					const data = this.getDataSource(instance, totalRowDataNumber);
					const minValue = Math.min.apply(Math, data);
					const maxValue = Math.max.apply(Math, data);
					let valueColor = null;

					if (row < totalRowDataNumber) {
						if (value > maxValue) {
							valueColor = fcalcColor(value, minValue, value, false);
						} else if (value < minValue) {
							valueColor = fcalcColor(value, value, maxValue, false);
						} else {
							valueColor = fcalcColor(value, minValue, maxValue, false);
						}
					} else {
						valueColor = new Color(243, 242, 240, 1);
					}

					const hexColor = valueColor.toHex();

					if (hexColor.length === 7) {
						td.style.backgroundColor = hexColor;
					}
				}
			}
		}
	};

	getDataSource(instance: Handsontable, totalRow: number): Array<any> {
		const dataData = instance.getSourceDataArray().slice(0, totalRow);
		return dataData
			.filter((cellData) => cellData.length > 1)
			.map((cellValue) => cellValue.slice(1, cellValue.length - 1))
			.reduce((acc, cell) => {
				return [...acc, ...cell];
			}, []);
	}
}
