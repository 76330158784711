import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ParameterServices } from '@services/parameter.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-parameters',
	templateUrl: './parameters.component.html',
	styleUrls: ['./parameters.component.scss'],
	providers: []
})
export class ParametersComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	displayedColumnsservicio: string[] = ['select'];
	comboListCategory;
	comboListBusiness;
	comboListProduct;
	loadtable: any;
	category: number;
	product: any;
	productId;
	business;
	activeBlock1 = false;
	selection = new SelectionModel<null>(true, []);
	dataSource: MatTableDataSource<null>;
	selectedRow;
	idSelectedRow: number = null;
	displayEdit = false;
	displayNew = false;
	sendSnackbarActive;

	displayWelcome = true;
	displayLoad = false;
	rowDelete = false;
	valueRadius;
	idSelect;

	constructor(
		private ListService: ParameterServices,
		private router: Router,
		private translate: TranslateService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.getCategoryList();
		this.getBusiness();
		this.activeBlock1 = false;
	}

	radioButton(event, row) {
		this.valueRadius = event.source._checked;
		this.idSelect = event.source._changeDetector._view.parent.context.index;
	}

	getCategoryList() {
		this.ListService.getCategoryList().subscribe(
			(res) => {
				this.comboListCategory = res;
			},
			(err) => {
				console.log(err);
			}
		);
	}

	getBusiness() {
		this.ListService.getBusinessList().subscribe(
			(res) => {
				this.comboListBusiness = res;
			},
			(err) => {
				console.log(err);
			}
		);
	}

	locateProduct(id) {
		this.ListService.getProductList(id).subscribe(
			(res) => {
				this.comboListProduct = res;
			},
			(err) => {
				console.log(err);
			}
		);
	}

	public function(business, product, res) {
		this.loadtable = [];
		this.displayEdit = false;
		this.displayNew = false;
		this.displayLoad = false;
		this.displayWelcome = false;
		this.idSelectedRow = null;
		this.activeBlock1 = true;
		this.displayWelcome = false;
		this.displayLoad = true;

		this.ListService.getLoadTable(business, product).subscribe(
			(res) => {
				this.dataSource = new MatTableDataSource(null);
				this.loadtable = res;
				setTimeout(() => {
					this.dataSource = new MatTableDataSource(this.loadtable);
					this.dataSource.paginator = this.paginator;
					this.dataSource.sort = this.sort;
				});
			},
			(err) => {
				console.log(err);
			}
		);
	}

	public refreshTableEdit(resUpdate) {
		for (const preValueSave of this.loadtable.values()) {
			this.loadtable.forEach(() => {
				if (preValueSave.parameterId === resUpdate.parameterId) {
					preValueSave.parameterName = resUpdate.parameterName;
					preValueSave.lastUpdateUser = resUpdate.lastUpdateUser;
					preValueSave.lastUpdateDate = resUpdate.lastUpdateDate;
				}
			});
		}
	}

	public refreshTableNew(resUpdate) {
		this.displayEdit = false;
		this.displayNew = false;
		this.displayLoad = false;
		this.idSelectedRow = null;
		this.ListService.getLoadTable(resUpdate.businessUnitId, resUpdate.productId).subscribe(
			(res) => {
				this.dataSource = new MatTableDataSource(null);
				this.loadtable = res;
				setTimeout(() => {
					this.loadtable.sort((a, b) => Number(b.parameterId) - Number(a.parameterId));
					this.dataSource = new MatTableDataSource(this.loadtable);
					this.getRecord(resUpdate);
				});
			},
			(err) => {
				console.log(err);
			}
		);
	}

	changeParameters() {
		this.activeBlock1 = false;
		this.displayEdit = false;
		this.displayNew = false;
		this.displayWelcome = true;
	}

	applyFilter1(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	getRecord(row) {
		this.selectedRow = row;
		this.idSelectedRow = row.parameterId;
		this.displayNew = false;
		this.displayLoad = false;
		this.displayWelcome = false;
		this.displayEdit = true;
	}

	buttonNew() {
		this.idSelectedRow = null;
		this.displayEdit = false;
		this.displayLoad = false;
		this.displayWelcome = false;
		this.displayNew = true;
	}

	deleteParameter(element) {
		const idParameterDelete: string = element.parameterId.toString();
		Swal.fire({
			text: this.translate.instant('question') + ': ' + element.parameterName,
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('accept'),
			reverseButtons: true,
			customClass: {
				confirmButton: 'primary',
				cancelButton: 'secondary',
				icon: 'icon-class'
			},
			buttonsStyling: false
		}).then((result) => {
			if (result.value) {
				this.deleteParameterId(idParameterDelete);
			}
		});
	}

	deleteParameterId(idParameterDelete: string) {
		this.ListService.deleteParameter(idParameterDelete).subscribe(
			(res) => {
				this.function(this.business, this.product.id, null);
				this.openSnackBarCorrect('Parameter has been succesfully deleted');
			},
			(err) => {
				console.log(err);
				this.openSnackBarIncorrect('Error! Unable to delete parameter');
			}
		);
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackIncorrect']
		});
	}

	ngOnDestroy() {
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}
}
