import { Component } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpSpinnerService } from './http-spinner.service';

@Component({
	selector: 'app-http-spinner',
	templateUrl: './http-spinner.component.html',
	styleUrls: ['./http-spinner.component.scss']
})
export class HttpSpinnerComponent {
	// Decorator wires up blockUI instance
	@BlockUI() blockUI: NgBlockUI;

	constructor(private httpStateService: HttpSpinnerService) {
		this.httpStateService.state$.subscribe((obs: boolean) => {
			if (obs) {
				this.blockUI.start(); // Start blocking
			} else {
				this.blockUI.stop(); // Stop blocking
			}
		});
	}
}
