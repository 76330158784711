<div>
	br
	<form [formGroup]="baselineForm">
		<div class="area-menu">
			<div class="title-menu">
				<span> Baseline </span>
			</div>
			<div class="menu-fecha">
				<mat-form-field>
					<mat-label>Level</mat-label>
					<mat-select [formControlName]="baselineControl.level">
						<mat-option [value]="optionLevel" *ngFor="let optionLevel of levelOptions">
							{{ optionLevel.level }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field>
					<mat-label>Element</mat-label>
					<input
						type="text"
						aria-label="Number"
						matInput
						[formControlName]="baselineControl.elementCode"
						[matAutocomplete]="auto"
						style="color: black"
					/>
					<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="changeElement">
						<mat-option *ngFor="let option of filteredElementOptions | async" [value]="option">
							{{ option.elementCode }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				<mat-form-field>
					<mat-label>Cabin</mat-label>
					<mat-select [formControlName]="baselineControl.cabinCode">
						<mat-option [value]="optionCabin" *ngFor="let optionCabin of cabinOptions">
							{{ optionCabin.description }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field>
					<mat-label>Start date</mat-label>
					<input
						matInput
						[matDatepicker]="dpStart"
						[formControlName]="baselineControl.startDate"
						style="color: black"
					/>
					<mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
					<mat-datepicker startView="multi-year" #dpStart></mat-datepicker>
				</mat-form-field>
				<mat-form-field>
					<mat-label>End date</mat-label>
					<input matInput [matDatepicker]="dpEnd" [formControlName]="baselineControl.endDate" style="color: black" />
					<mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
					<mat-datepicker startView="multi-year" #dpEnd></mat-datepicker>
				</mat-form-field>
				<mat-form-field>
					<mat-label>Seasons</mat-label>
					<mat-select #selectSeasons multiple [formControlName]="baselineControl.seasons">
						<div class="select-all">
							<mat-checkbox
								[(ngModel)]="allSelectedSeasons"
								[ngModelOptions]="{ standalone: true }"
								(change)="toggleAllSelectionSeasons()"
								>Select All</mat-checkbox
							>
						</div>
						<mat-option
							[value]="optionSeason"
							*ngFor="let optionSeason of seasonsOptions"
							(click)="optionSeasonsClick()"
						>
							{{ optionSeason }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field>
					<mat-label>Short AP</mat-label>
					<mat-select [formControlName]="baselineControl.shortAP">
						<mat-option *ngFor="let short of shortOptions" [value]="short">{{ short }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<button type="button" class="btn-menu" (click)="updateBaseline()">Update Baseline</button>
		</div>
	</form>
	<div class="linea"></div>
	<form [formGroup]="modelForm">
		<div class="area-menu">
			<div class="title-menu">
				<span> Model </span>
			</div>
			<div class="menu-fecha">
				<mat-form-field>
					<mat-label>Number of clusters</mat-label>
					<mat-select [formControlName]="modelControl.numberClusters">
						<mat-option *ngFor="let ncluster of numberClustersOptions" [value]="ncluster">{{ ncluster }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field>
					<mat-label>Model Inputs</mat-label>
					<mat-select #selectModel multiple [formControlName]="modelControl.modelInputs">
						<div class="select-all">
							<mat-checkbox
								[(ngModel)]="allSelectedModel"
								[ngModelOptions]="{ standalone: true }"
								(change)="toggleAllSelectionModel()"
								>Select All</mat-checkbox
							>
						</div>
						<mat-option *ngFor="let model of modelOptions" [value]="model.value" (click)="optionModelClick()">
							{{ model.display }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<button type="button" class="btn-menu" (click)="calculate()">Calculate</button>
		</div>
	</form>
</div>
