<div class="login">
	<img src="assets/images/logo-login.svg" alt="REM" /><br />
	<asl-google-signin-button
		type="standard"
		locale="es"
		theme="filled_blue"
		text="signin_with"
		size="large"
		shape="pill"></asl-google-signin-button>
	<p class="error-message" *ngIf="isForbidden">{{ errorMessage | translate }}</p>
</div>
