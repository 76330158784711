import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HotTableRegisterer } from '@handsontable/angular';
import { LinkServices } from '@services/link.service';
import 'handsontable/languages/es-MX';
import moment from 'moment';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SessionStorage } from 'src/app/core/storages/session.storage';
import { HttpSpinnerService } from 'src/app/http-spinner/http-spinner.service';
import { Links } from 'src/app/models/Links';
import { LinksDetail } from 'src/app/models/LinskDetail';
import Swal from 'sweetalert2';
import { LinkComponent } from '../link/link.component';
import { SnackbarComponent } from '../SnackbarLink/Snackbar.component';
import { ConfigService } from '../../../config/config.service';
export class Rutas {
	businnessUnitId: number;
	name: string;
}

@Component({
	selector: 'app-new-links',
	templateUrl: './new-links.component.html',
	styleUrls: ['./new-links.component.scss'],
	providers: [DatePipe]
})
export class NewLinksComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() business: any;
	@Input() category: any;
	@Input() cabins: any;
	@Input() links: [];

	newDataSource;
	nameLinkSave: string;
	RUTAS: Rutas[] = [];
	dataNew;
	linksDataSave: Links;
	linksdatadetailSave: LinksDetail;
	sendSnackbarActive;

	@Input() cabinCode: any;

	private hotRegisterer = new HotTableRegisterer();
	id = 'hotInstance';
	isDisableSave: boolean;
	comboLinkrutas;
	linksrutas;
	date: moment.Moment = moment();
	tableNewData = [];
	newDataSourceClusters = [];
	newDataSourceSeasons = [];
	removeDuplicados = [];
	removeAux = [];
	loadDataActive;
	userEmail;
	addressForm = this.formControl.group({});

	tableSettings: any = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		colHeaders: [
			'Month Start',
			'Month End',
			'Season',
			'Cluster',
			'Cockpit',
			'Competition<br>Match',
			'Competition<br>Position',
			'Competition<br>Gap',
			'Decision',
			'Limits'
		],
		colWidths: [60, 60, 60, 60, 50, 60, 60, 60, 50, 50],
		stretchH: 'all',
		columns: [
			{
				data: 'monthStart',
				type: 'date',
				dateFormat: 'YYYY-MM',
				correctFormat: true,
				allowEmpty: false
			},
			{
				data: 'monthEnd',
				type: 'date',
				dateFormat: 'YYYY-MM',
				correctFormat: true,
				allowEmpty: false
			},
			{
				data: 'season',
				type: 'dropdown',
				strict: true,
				allowEmpty: false
			},
			{
				data: 'cluster',
				type: 'dropdown',
				strict: true,
				allowEmpty: false
			},
			{
				data: 'cockpitName',
				type: 'dropdown',
				strict: true,
				allowEmpty: false
			},
			{
				data: 'competitionMatchName',
				type: 'dropdown',
				strict: true,
				allowEmpty: true
			},
			{
				data: 'competitionPositionName',
				type: 'dropdown',
				strict: true,
				allowEmpty: true
			},
			{
				data: 'competitionGapName',
				type: 'dropdown',
				strict: true,
				allowEmpty: true
			},
			{
				data: 'decisionName',
				type: 'dropdown',
				strict: true,
				allowEmpty: false
			},
			{
				data: 'limitsName',
				type: 'dropdown',
				strict: true,
				allowEmpty: true
			}
		],
		manualColumnMove: false,
		contextMenu: true,
		filters: false,
		dropdownMenu: false,
		autoInsertRow: true,
		language: 'es-MX',
		className: 'htCenter',
		rowHeaders: false,
		allowInsertRow: true,
		minSpareRows: 1,
		multiSelect: true,

		afterGetColHeader: (column, th) => {
			console.log('COLUMNA', column);
			th.style.color = 'black';
			if (column < 4) {
				th.style.backgroundColor = '#FAA0A0';
			}
		},

		afterRemoveRow: (index, amount, rows, source) => {
			const rowCount = this.hotRegisterer.getInstance(this.id).countRows();
			if (rowCount === 1) {
				Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'warning');
				return;
			}

			if (rowCount > 1) {
				var rowValids = [];
				for (var i = 0; i < rowCount - 1; i++) {
					rowValids.push(i);
				}
			}

			this.hotRegisterer.getInstance(this.id).validateRows(rowValids, (valid) => {
				if (valid) {
					console.log('Bien sin Errores de validacion');
				} else {
					console.log('Hay Errores en la validacion');
				}
			});
		},

		afterInit: () => {
			this.isDisableSave = false;
		},

		afterChange: (changes, row) => {
			if (changes !== null) {
				this.isDisableSave = true;
			}
		}
	};

	constructor(
		private ListService: LinkServices,
		private snackBar: MatSnackBar,
		private formControl: UntypedFormBuilder,
		private linkcomp: LinkComponent,
		private httpSpinnerService: HttpSpinnerService,
		private datepipe: DatePipe,
		private sessionData: SessionStorage,
		private configService: ConfigService
	) {}

	protected rutas: Rutas[] = this.RUTAS;
	public rutasCtrl: UntypedFormControl = new UntypedFormControl();
	public rutasFilterCtrl: UntypedFormControl = new UntypedFormControl();
	public filteredRutas: ReplaySubject<Rutas[]> = new ReplaySubject<Rutas[]>(1);
	public filteredRutasObs = this.filteredRutas.asObservable();
	@ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
	// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
	protected _onDestroy = new Subject<void>();

	ngOnInit() {
		this.loadDataActive = false;
		this.isDisableSave = false;
		this.userEmail = this.sessionData.userEmail;
		this.getRutasSource();
		this.rutasCtrl.setValue(this.rutas[10]);
		this.filteredRutas.next(this.rutas.slice());
		this.rutasFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
			this.filterBanks();
		});
	}

	getRutasSource() {
		this.ListService.getEditLinks(this.business, this.category, this.cabins).subscribe(
			(getDatos) => {
				this.removeAux = getDatos;
				for (const resData of this.removeAux) {
					this.RUTAS.push(resData);
				}
				this.filterBanks();
			},
			(err) => {
				console.log(err);
			}
		);
	}

	changeRutaClusters(rutasCtrl) {
		this.newDataSourceClusters = [];
		this.ListService.getDataSourceClusters(rutasCtrl.name, this.cabinCode).subscribe(
			(res) => {
				this.newDataSourceClusters = Object.assign(res);
				this.changeRutaSeasons(rutasCtrl);
			},
			(err) => {
				console.log(err);
			}
		);
	}

	changeRutaSeasons(rutasCtrl) {
		this.newDataSourceSeasons = [];
		this.ListService.getDataSourceSeasons(rutasCtrl.name, this.cabinCode).subscribe(
			(res) => {
				this.newDataSourceSeasons = Object.assign(res);
				this.getNewLinks();
			},
			(err) => {
				console.log(err);
			}
		);
	}
	validateCellSave() {
		this.httpSpinnerService.nextState$(true);
		this.dataNew = this.hotRegisterer.getInstance(this.id).getSourceData();
		const rowCount = this.hotRegisterer.getInstance(this.id).countRows();
		if (rowCount === 1) {
			Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'question');
			this.httpSpinnerService.nextState$(false);
			return;
		}
		if (rowCount > 1) {
			var rowValids = [];
			for (var i = 0; i < rowCount - 1; i++) {
				rowValids.push(i);
			}
		}
		this.hotRegisterer.getInstance(this.id).validateRows(rowValids, (valid) => {
			if (valid) {
				for (const value of this.dataNew.values()) {
					if (value.monthEnd) {
						value.monthEnd = value.monthEnd + '-20';
						value.monthStart = value.monthStart + '-01';
					}
				}

				for (const preValueSave of this.dataNew.values()) {
					this.newDataSource.Cockpit.forEach((element) => {
						if (element.parameterName === preValueSave.cockpitName) {
							preValueSave.cockpitId = element.parameterId;
						} else if (preValueSave.cockpitName === '') {
							preValueSave.cockpitId = null;
						}
					});

					this.newDataSource['Competition Match'].forEach((element) => {
						if (element.parameterName === preValueSave.competitionMatchName) {
							preValueSave.competitionMatchId = element.parameterId;
						} else if (preValueSave.competitionMatchName === '') {
							preValueSave.competitionMatchId = null;
						}
					});

					this.newDataSource['Competition Position'].forEach((element) => {
						if (element.parameterName === preValueSave.competitionPositionName) {
							preValueSave.competitionPositionId = element.parameterId;
						} else if (preValueSave.competitionPositionName === '') {
							preValueSave.competitionPositionId = null;
						}
					});

					this.newDataSource['Competition Gap'].forEach((element) => {
						if (element.parameterName === preValueSave.competitionGapName) {
							preValueSave.competitionGapId = element.parameterId;
						} else if (preValueSave.competitionGapName === '') {
							preValueSave.competitionGapId = null;
						}
					});

					this.newDataSource.Decision.forEach((element) => {
						if (element.parameterName === preValueSave.decisionName) {
							preValueSave.decisionId = element.parameterId;
						} else if (preValueSave.decisionName === '') {
							preValueSave.decisionId = null;
						}
					});

					this.newDataSource.Limits.forEach((element) => {
						if (element.parameterName === preValueSave.limitsName) {
							preValueSave.limitsId = element.parameterId;
						} else if (preValueSave.limitsName === '') {
							preValueSave.limitsId = null;
						}
					});
				}

				this.dataNew.length = this.dataNew.length - 1;
				this.linksDataSave = Object.assign(new Links());
				this.linksDataSave.routeId = this.rutasCtrl.value.name;
				this.linksDataSave.routeName = this.rutasCtrl.value.name;
				this.linksDataSave.id = null;
				this.linksDataSave.lastUpdateDate = this.date.toDate();
				this.linksDataSave.lastUpdateUser = this.userEmail;
				this.linksDataSave.productCategoryId = this.category;
				this.linksDataSave.cabinId = this.cabins;

				this.linksDataSave.detail = this.dataNew;
				const saveData = Object.assign(this.linksDataSave);

				this.ListService.postNewLinks(saveData).subscribe(
					(res) => {
						this.httpSpinnerService.nextState$(false);
						this.openSnackBarCorrect('Link has been successfully saved!');
						this.nameLinkSave = this.rutasCtrl.value.name;
						this.refreshTableData(res, this.nameLinkSave);
					},
					(err) => {
						if (err.status === 400) {
							console.error(err);
							this.sendSnackbar(err);
							this.httpSpinnerService.nextState$(false);
						} else {
							this.openSnackBarIncorrect('Error! Unable to save Links');
							this.httpSpinnerService.nextState$(false);
							console.error(err);
						}
					}
				);
			} else {
				this.httpSpinnerService.nextState$(false);
				Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'warning');
				return;
			}
		});
	}

	refreshTableData(linksDataSave, nameLinks) {
		this.linkcomp.refresTableLinks(this.category, this.business, this.cabins, linksDataSave, nameLinks);
	}

	sendSnackbar(errMensaje) {
		this.sendSnackbarActive = true;
		this.snackBar.openFromComponent(SnackbarComponent, {
			data: errMensaje,
			panelClass: ['snackValidation']
		});
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackIncorrect']
		});
	}

	ngAfterViewInit() {
		this.setInitialValue();
	}

	protected setInitialValue() {
		this.filteredRutas.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
			this.singleSelect.compareWith = (a: Rutas, b: Rutas) => a && b && a.name === b.name;
		});
	}

	protected filterBanks() {
		if (!this.rutas) {
			return;
		}
		let search = this.rutasFilterCtrl.value;
		if (!search) {
			this.filteredRutas.next(this.rutas.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredRutas.next(this.rutas.filter((rutas) => rutas.name.toLowerCase().indexOf(search) > -1));
	}

	getNewLinks() {
		this.loadDataActive = true;
		this.ListService.getDataSource(this.business, this.category).subscribe(
			(res) => {
				this.newDataSource = Object.assign(res);
				for (const value of this.tableSettings.columns.values()) {
					if (value.data === 'cockpitName') {
						if (this.newDataSource.Cockpit) {
							value.source = this.newDataSource.Cockpit.map((source) => source.parameterName);
						} else {
							value.source = [];
						}
					}

					if (value.data === 'competitionMatchName') {
						if (this.newDataSource['Competition Match']) {
							value.source = this.newDataSource['Competition Match'].map((source) => source.parameterName);
						} else {
							value.source = [];
						}
					}

					if (value.data === 'competitionPositionName') {
						if (this.newDataSource['Competition Position']) {
							value.source = this.newDataSource['Competition Position'].map((source) => source.parameterName);
						} else {
							value.source = [];
						}
					}

					if (value.data === 'competitionGapName') {
						if (this.newDataSource['Competition Gap']) {
							value.source = this.newDataSource['Competition Gap'].map((source) => source.parameterName);
						} else {
							value.source = [];
						}
					}
					if (value.data === 'decisionName') {
						if (this.newDataSource.Decision) {
							value.source = this.newDataSource.Decision.map((source) => source.parameterName);
						} else {
							value.source = [];
						}
					}

					if (value.data === 'limitsName') {
						if (this.newDataSource.Limits) {
							value.source = this.newDataSource.Limits.map((source) => source.parameterName);
						} else {
							value.source = [];
						}
					}

					if (value.data === 'cluster') {
						value.source = this.newDataSourceClusters.map((source) => source.name);
					}

					if (value.data === 'season') {
						value.source = this.newDataSourceSeasons.map((source) => source.name);
					}
				}
				this.hotRegisterer.getInstance(this.id).loadData(Object.assign(this.tableNewData));
				this.hotRegisterer.getInstance(this.id).updateSettings({ columns: this.tableSettings.columns });
			},
			(err) => {
				console.log(err);
			}
		);
	}

	selectAllCells() {
		const hotInstance = this.hotRegisterer.getInstance(this.id);
		hotInstance.selectCells([[0, 0, hotInstance.countRows() - 1, hotInstance.countCols() - 1]], true, true);
	}

	downloadCsv() {
		const hot = this.hotRegisterer.getInstance(this.id).getPlugin('exportFile');
		hot.downloadFile('csv', {
			bom: true,
			columnDelimiter: ',',
			columnHeaders: true,
			exportHiddenColumns: true,
			exportHiddenRows: true,
			fileExtension: 'csv',
			filename: 'Links[YYYY]-[MM]-[DD]',
			mimeType: 'text/csv',
			rowDelimiter: '\r\n',
			rowHeaders: true
		});
	}

	ngOnDestroy() {
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}
}
