import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { copyDataFromGrid, GridApClusters } from 'src/app/models/target-curves/GridApClusters';
import { ConfigService } from '../../../../../config/config.service';

interface HTColumn {
	title: string;
	readOnly: boolean;
	renderer?: (
		hotInstance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties
	) => void;
}

const column_ap: HTColumn = {
	title: 'AP',
	readOnly: true
};

const column_total: HTColumn = {
	title: 'Total',
	readOnly: true
};

// para que cada grilla hot tenga un id unico
let id_target_curves = 0;

const GridApClustersInputs = {
	columnsWidth: 'columns_width',
	dataGrid: 'data_grid'
};

@Component({
	selector: 'app-grid-ap-clusters',
	templateUrl: './grid-ap-clusters.component.html',
	styleUrls: ['./grid-ap-clusters.component.scss']
})
export class GridApClustersComponent {
	id = 'hot-instance.target-curves-' + id_target_curves++;
	private hotRegisterer = new HotTableRegisterer();
	private hotInstance: Handsontable;

	@Input() title = '';
	@Input() readOnly = true;
	@Input() show_ap = true;

	columns_width: Array<number> = [];
	tableSettings: Handsontable.GridSettings | undefined = undefined;
	@Input(GridApClustersInputs.columnsWidth) set setColumnsWidth(data: Array<number>) {
		this.columns_width = data;
		this.tableSettings = this.calcTableSettings();
	}

	@Output() target_curves_edited = new EventEmitter<[string, Handsontable]>();

	data_grid: GridApClusters;
	columns: Array<HTColumn> = [];
	@Input(GridApClustersInputs.dataGrid) set setDataGrid(data: GridApClusters) {
		this.data_grid = data;

		if (typeof data !== 'undefined') {
			//
			this.columns = [];

			this.columns.push(column_ap);

			for (const cluster of data.clusters) {
				this.columns.push({
					title: 'C' + cluster,
					readOnly: this.readOnly,
					renderer: this.callRendererGrid
				});
			}

			this.columns.push({
				title: column_total.title,
				readOnly: column_total.readOnly,
				renderer: this.callRendererGrid
			});
		} else {
			this.columns = [];
		}
	}

	@Input() data_grid_raw: GridApClusters | undefined;

	@Input() rendererGrid: (
		instance: Handsontable,
		TD: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridApClusters,
		data_grid_raw: GridApClusters | undefined,
		editable: boolean
	) => void;

	// Configuraciones Handsontable
	calcTableSettings(): Handsontable.GridSettings {
		if (this.show_ap) {
			return {
				licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
				contextMenu: false,
				dropdownMenu: false,
				language: 'es-MX',
				className: 'htCenter',
				filters: false,
				columnSorting: false,
				allowInsertRow: false,
				viewportColumnRenderingOffset: 1000,
				colWidths: this.columns_width,

				cells: this.getCellProperties,

				afterChange: (changes, source) => {
					if (source === 'edit') {
						changes?.forEach(([row, prop, oldValue, newValue]) => {
							prop = this.hotInstance.propToCol(prop as string);
							try {
								const newValueNumeric = parseFloat(newValue);
								if (!isNaN(newValueNumeric)) {
									this.data_grid.rows[row][prop] = newValueNumeric;
									this.changeDetectorRef.detectChanges();
								} else {
									this.data_grid.rows[row][prop] = oldValue;
								}
							} catch (e) {
								this.data_grid.rows[row][prop] = oldValue;
							}
						});
					}
					if (source !== 'loadData' && source !== 'updateData') {
						let columnIndexes: Array<number> = [];
						// Obtener columnas de cambios detectados
						for (const change of changes) {
							const col = Number(change[1]);
							columnIndexes.push(col);
						}
						// Eliminar duplicados
						var columns = columnIndexes.filter(function (elem, index, self) {
							return index === self.indexOf(elem);
						});
						const totalRowNumber = this.data_grid.rows.length;

						//Recalcular columnas
						for (const col of columns) {
							this.calculateColumnSum(col, totalRowNumber);
						}

						this.target_curves_edited.emit([this.id, this.hotInstance]);
					}
				},

				beforeKeyDown: (event: KeyboardEvent) => {
					if (event.key === 'Delete' && typeof this.data_grid_raw !== 'undefined') {
						Handsontable.dom.stopImmediatePropagation(event);
						event.stopImmediatePropagation();
						this.back2Raw();
						this.target_curves_edited.emit([this.id, this.hotInstance]);
					}
				}
			};
		} else {
			return {
				licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
				contextMenu: false,
				dropdownMenu: false,
				language: 'es-MX',
				className: 'htCenter',
				filters: false,
				columnSorting: false,
				allowInsertRow: false,
				viewportColumnRenderingOffset: 1000,
				colWidths: this.columns_width,
				hiddenColumns: {
					columns: [0]
				}
			};
		}
	}

	getCellProperties = (row: number, col: number, prop: string): Handsontable.CellMeta => {
		const cellProperties: Handsontable.CellMeta = {};

		if (col === 0 || col === this.columns.length - 1 || row === this.data_grid.rows.length - 1) {
			cellProperties.readOnly = true;
		} else {
			cellProperties.readOnly = this.readOnly;
		}

		return cellProperties;
	};

	// ojo: callback de hot, no confundir con AfterViewInit de Angular
	public onAfterInit = (): void => {
		this.hotInstance = this.hotRegisterer.getInstance(this.id);
	};

	calculateColumnSum(colNumber: number, totalRows: number) {
		let sum = 0;
		for (let i = 0; i < totalRows - 1; i++) {
			sum = sum + Number(this.data_grid.rows[i][colNumber]);
		}
		//TODO: Refrescar celda total
		this.data_grid.rows[totalRows - 1][colNumber] = sum;
		this.hotRegisterer.getInstance(this.id).loadData(this.data_grid.rows);
		this.hotRegisterer.getInstance(this.id).render();
		this.changeDetectorRef.detectChanges();
	}

	back2Raw(): void {
		const totalRowNumber = this.data_grid.rows.length;
		const rangeSelected: Handsontable.CellRange[] = this.hotInstance.getSelectedRange();

		rangeSelected.forEach((element) => {
			let rowStart = 0;
			let rowEnd = 0;

			if (element.from.row <= element.to.row) {
				rowStart = element.from.row;
				rowEnd = element.to.row;
			} else {
				rowStart = element.to.row;
				rowEnd = element.from.row;
			}

			let colStart = 0;
			let colEnd = 0;

			if (element.from.col <= element.to.col) {
				colStart = element.from.col;
				colEnd = element.to.col;
			} else {
				colStart = element.to.col;
				colEnd = element.from.col;
			}

			copyDataFromGrid(this.data_grid, this.data_grid_raw, rowStart, rowEnd, colStart, colEnd);
			for (let column = element.from.col; column <= element.to.col; column++) {
				this.calculateColumnSum(column, totalRowNumber);
			}
		});

		this.hotInstance.render();
	}

	stretchH(): string {
		/*if (this.show_ap) return 'none';
		else return 'all';*/
		return 'none';
	}

	isReady(): boolean {
		return typeof this.data_grid !== 'undefined' && typeof this.tableSettings !== 'undefined';
	}

	callRendererGrid = (
		hotInstance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties
	) => {
		if (typeof this.rendererGrid !== 'undefined') {
			const value_num = parseFloat(value);
			this.rendererGrid(
				hotInstance,
				td,
				row,
				col,
				prop,
				value_num,
				cellProperties,
				this.data_grid,
				this.data_grid_raw,
				!this.readOnly
			);
		} else {
			Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, col, prop, value, cellProperties]);
		}
	};

	constructor(private changeDetectorRef: ChangeDetectorRef, private configService: ConfigService) {}
}
