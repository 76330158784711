import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Notification, NotificationsService } from '@services/notifications.service';
import { ModalErrorNotifyComponent } from '../modal-error-notify/modal-error-notify.component';

@Component({
	selector: 'app-menu-notify',
	templateUrl: './menu-notify.component.html',
	styleUrls: ['./menu-notify.component.scss']
})
export class MenuNotifyComponent implements OnInit {
	@ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

	stickyMenu: boolean = false;
	menuHeight: number = 600;
	opened: boolean = false;
	openedModal: boolean = false;
	listNotify: Notification[] = [];

	@Output() changeOpened = new EventEmitter<boolean>();

	constructor(public notify: NotificationsService, public dialog: MatDialog, private eRef: ElementRef) {
		this.getScreenSize();
	}

	ngOnInit(): void {
		this.notify.data.subscribe((notifications) => {
			this.listNotify = notifications;
		});
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event?: Event) {
		this.menuHeight = window.innerHeight - 200;
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(e: Event) {
		const scrollTop = window.scrollY;
		if (scrollTop > 80) {
			this.stickyMenu = true;
		} else {
			this.stickyMenu = false;
		}
	}

	closedStart() {
		this.notify.updateNotify(0);
		this.opened = false;
	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this.opened) {
			if (!this.eRef.nativeElement.contains(event.target)) {
				this.drawer.close();
			} else {
				this.opened = true;
			}
		} else {
			if (!this.openedModal) {
				this.opened = this.drawer.opened;
			}
		}
	}

	openModalNotify(item: Notification) {
		this.opened = false;
		const modal = this.dialog.open(ModalErrorNotifyComponent, {
			width: '810px',
			disableClose: true,
			data: { ...item }
		});

		modal.afterOpened().subscribe(() => {
			this.openedModal = true;
			this.opened = false;
		});

		modal.beforeClosed().subscribe(() => {
			this.openedModal = false;
		});
	}
}
