<div class="old-container">
	<table mat-table [dataSource]="dataSource">
		<!-- Position Column -->
		<ng-container matColumnDef="id">
			<mat-header-cell *matHeaderCellDef> Session ID </mat-header-cell>
			<mat-cell *matCellDef="let element"> # {{ element.id }} </mat-cell>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="level">
			<mat-header-cell *matHeaderCellDef> Level </mat-header-cell>
			<mat-cell *matCellDef="let element"> {{ element.level }} </mat-cell>
		</ng-container>

		<!-- Weight Column -->
		<ng-container matColumnDef="elementId">
			<mat-header-cell *matHeaderCellDef> Element </mat-header-cell>
			<mat-cell *matCellDef="let element"> {{ element.elementId }} </mat-cell>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="cabinCode">
			<mat-header-cell *matHeaderCellDef> Cabin </mat-header-cell>
			<mat-cell *matCellDef="let element"> {{ normCabin(element.cabinCode) }} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="creationDateTime">
			<mat-header-cell *matHeaderCellDef> Creation </mat-header-cell>
			<mat-cell *matCellDef="let element"> {{ element.creationDateTime | date: 'dd/MM/yyyy' }} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="lastUpdateDateTime">
			<mat-header-cell *matHeaderCellDef> Last Update </mat-header-cell>
			<mat-cell *matCellDef="let element"> {{ element.lastUpdateDateTime | date: 'dd/MM/yyyy' }} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="delete">
			<mat-header-cell *matHeaderCellDef> </mat-header-cell>
			<mat-cell *matCellDef="let element">
				<button mat-button (click)="deleteSession(element)">
					<span class="material-icons">delete_outline</span>
				</button>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="load">
			<mat-header-cell *matHeaderCellDef> </mat-header-cell>
			<mat-cell *matCellDef="let element">
				<button mat-button (click)="chargeSession(element)">
					<span class="material-icons">play_arrow</span>
				</button>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="view">
			<mat-header-cell *matHeaderCellDef> </mat-header-cell>
			<mat-cell *matCellDef="let element">
				<button mat-button (click)="chargeSession(element)" *ngIf="element.id !== sessionId">
					<span class="material-icons">visibility</span>
				</button>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			[ngClass]="{ 'make-gold': row.id === sessionId }"
		></mat-row>
	</table>
</div>
