import { Component } from '@angular/core';

@Component({
	selector: 'app-welcome-links',
	templateUrl: './welcome-links.component.html',
	styleUrls: ['./welcome-links.component.scss']
})
export class WelcomeLinksComponent {
	constructor() {}
}
