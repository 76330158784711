<div class="principal">
	<div class="contengo">
		<mat-form-field class="combo-links">
			<mat-select
				[formControl]="rutasCtrl"
				placeholder="Nondirectional Leg"
				#singleSelect
				(ngModelChange)="changeRutaClusters($event)"
			>
				<mat-option>
					<ngx-mat-select-search
						[formControl]="rutasFilterCtrl"
						placeholderLabel="Search"
						noEntriesFoundLabel="No matching routes found"
					></ngx-mat-select-search>
				</mat-option>
				<mat-option *ngFor="let option of filteredRutasObs | async" [value]="option">{{ option.name }}</mat-option>
			</mat-select>
		</mat-form-field>

		<div class="icon-buton">
			<button type="button" class="btn-select-all" (click)="selectAllCells()" [disabled]="!loadDataActive">
				<img src="../../../../assets/icons/LATAM Icons/vector-cuadrilla.svg" alt="" />
			</button>
			<button type="button" class="btn-download" (click)="downloadCsv()" [disabled]="!loadDataActive">
				<img src="../../../../assets/icons/LATAM Icons/vector.svg" alt="" />
			</button>
			<button type="button" (click)="validateCellSave()" class="btn-search" [disabled]="!isDisableSave">Save</button>
		</div>
	</div>

	<div class="headersLinks">
		<hot-table *ngIf="loadDataActive" [hotId]="id" [settings]="tableSettings" [data]="tableNewData" class="handsontable-custom"> </hot-table>
	</div>
</div>
