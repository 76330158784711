import * as d3 from 'd3';

// TODO: compartir este mapa
const colors: Map<number, string> = new Map([
	[1, '#FF3D00'],
	[2, '#99CC33'],
	[3, '#008BFF'],
	[4, '#D236BB'],
	[5, '#FFC700'],
	[6, '#401279'],
	[7, '#24C2B9'],
	[8, '#F20B0B'],
	[9, '#11837C'],
	[10, '#E55CB4'],
	[11, '#5C7A1F'],
	[12, '#DA9901'],
	[13, '#DD5252'],
	[14, '#4658DF'],
	[15, '#9452FF'],
	[16, '#BC6C15'],
	[17, '#FA7950'],
	[18, '#99E1DE'],
	[19, '#7200FF'],
	[20, '#5C5C5C']
]);

export module D3ClusterInd1Ind2Renderer {
	export class ClusterInd1Ind2Point {
		constructor(
			public leg: string,
			public tod: string,
			public dow: number,
			public cluster: number,
			public indicator1: number,
			public indicator2: number
		) {}
	}

	class Margin {
		constructor(public top: number, public right: number, public bottom: number, public left: number) {}
	}

	// TODO: transformar en una rutina utilitaria para todos los scatterplot
	function minMax(values: Array<number>): [number, number] {
		let min = Number.MAX_VALUE;
		let max = Number.MIN_VALUE;

		values.forEach((value) => {
			if (value < min) {
				min = value;
			}
			if (value > max) {
				max = value;
			}
		});

		const diff = max - min;

		const tick = diff / 10.0;

		return [min - tick, max + tick];
	}

	/*
	 *
	 */
	export function plot(
		points: Array<ClusterInd1Ind2Point>,
		placeholder: string,
		divWidth: number,
		divHeight: number,
		titleX: string,
		titleY: string
	) {
		const div = d3.select('#' + placeholder);

		const margin = new Margin(20, 20, 60, 60);
		const width = divWidth - margin.left - margin.right;
		const height = divHeight - margin.top - margin.bottom;

		div.select('svg').remove();

		const svgtop = div
			.append('svg')
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom);

		svgtop
			.append('rect')
			.attr('x', 0)
			.attr('width', divWidth)
			.attr('y', 0)
			.attr('height', divHeight)
			.attr('fill', 'none')
			.attr('stroke', 'none');

		const svg = svgtop.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		// eje X
		const valuesX = points.map(function (point) {
			return point.indicator1;
		});
		const domainX = minMax(valuesX);

		const x = d3.scaleLinear().domain(domainX).range([0, width]);

		svg
			.append('g')
			.attr('transform', 'translate(0,' + height + ')')
			.call(d3.axisBottom(x));

		// eje Y
		const valuesY = points.map(function (point) {
			return point.indicator2;
		});
		const domainY = minMax(valuesY);

		const y = d3.scaleLinear().domain(domainY).range([height, 0]);

		svg.append('g').call(d3.axisLeft(y));

		//
		const radius = 3;

		const fx = (d: ClusterInd1Ind2Point) => x(d.indicator1);
		const fy = (d: ClusterInd1Ind2Point) => y(d.indicator2);
		const fcolor = (d: ClusterInd1Ind2Point) => colors.get(d.cluster);

		// tooltip functions

		const tooltip = div
			.append('div')
			.style('opacity', '0')
			.attr('class', 'tooltip')
			.style('background-color', 'white')
			.style('border', 'solid')
			.style('border-width', '2px')
			.style('border-radius', '5px')
			.style('padding', '5px');

		var fmouseover = (d: ClusterInd1Ind2Point) => {
			tooltip.style('opacity', '1').style('visibility', 'visible');
		};
		var fmouseleave = (d: ClusterInd1Ind2Point) => {
			tooltip.style('opacity', '0').style('visibility', 'hidden');
		};
		var fmousemove = (d: ClusterInd1Ind2Point) => {
			tooltip
				.html(
					d.leg +
						'<br>DOW ' +
						d.dow +
						', ' +
						d.tod +
						'<br>Cluster ' +
						d.cluster +
						'<br>' +
						titleX +
						': ' +
						d.indicator1.toFixed(3) +
						'<br>' +
						titleY +
						': ' +
						d.indicator2.toFixed(3)
				)
				.style('left', x(d.indicator1) + 80 + 'px')
				.style('top', y(d.indicator2) + 'px');
		};

		// punto de cluster
		svg
			.append('g')
			.selectAll('dot')
			.data(points)
			.enter()
			.append('circle')
			.attr('class', 'my_point')
			.attr('cx', fx)
			.attr('cy', fy)
			.attr('r', radius) //
			.attr('fill', fcolor) // fcolor
			.on('mouseover', fmouseover)
			.on('mouseleave', fmouseleave)
			.on('mousemove', fmousemove);

		// Add X axis label:
		svg
			.append('text')
			.attr('text-anchor', 'end')
			.attr('x', width - 30)
			.attr('y', height + 40)
			.text(titleX);

		// Y axis label:
		svg
			.append('text')
			.attr('text-anchor', 'end')
			.attr('transform', 'rotate(-90)')
			.attr('y', -margin.left + 15)
			.attr('x', -margin.top)
			.text(titleY);
	}
}
