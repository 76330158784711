import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ClustersService, SeasonByParams } from '@services/clusters/clusters.service';
import { DialogService } from '@services/dialog.service';
import { OneTimeServices } from '@services/one-time.service';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { RESTRICTED_YEARS } from '../../components/one-time/one-time.constants';
import { BaselineInput, DATE_FORMAT_MENU, FormMenu, ModelInput } from './menu-lateral-inputs.constant';
import { BaselineValues, ModelValues, ViewComponentMenu } from './menu-lateral-inputs.interface';

const MenuLateralInputs = {
	selectedSeason: 'selectedSeason'
};

@Component({
	selector: 'app-menu-lateral-inputs',
	templateUrl: './menu-lateral-inputs.component.html',
	styleUrls: ['./menu-lateral-inputs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT_MENU }
	]
})
export class MenuLateralInputsComponent implements OnInit {
	// View Inputs
	@Input() viewForm: ViewComponentMenu[] = [
		{ name: FormMenu.baseline, view: true },
		{ name: FormMenu.model, view: true },
		{ name: FormMenu.switchView, view: true }
	];
	@Input() viewInputs: ViewComponentMenu[] = [
		{ name: BaselineInput.cabin, view: true },
		{ name: BaselineInput.element, view: true },
		{ name: BaselineInput.level, view: true },
		{ name: BaselineInput.endDate, view: true },
		{ name: BaselineInput.startDate, view: true },
		{ name: BaselineInput.seasons, view: true },
		{ name: BaselineInput.monthly, view: true },
		{ name: BaselineInput.endDateAsk, view: true },
		{ name: BaselineInput.startDateAsk, view: true },
		{ name: ModelInput.activate, view: true },
		{ name: ModelInput.model, view: true },
		{ name: ModelInput.endDateAct, view: true },
		{ name: ModelInput.startDateAct, view: true },
		{ name: ModelInput.minimumFare, view: true },
		{ name: ModelInput.numberOfBuckets, view: true }
	];

	@Input() switchView: 'AU' | 'BP' = 'AU';
	@Input() viewPage: 'buckets' | 'target';

	menues: any = null;
	// FormControlName
	formMenu = FormMenu;
	baselineControl = BaselineInput;
	modelControl = ModelInput;

	// Formularios
	baselineForm: UntypedFormGroup;
	modelForm: UntypedFormGroup;

	// Opciones de Select
	levelOptions = [];
	elementOptions = [];
	cabinOptions = [];
	seasonsOptions = [];

	modelOptions = [
		{ display: 'Base model', value: 'Base model' },
		{ display: 'LSTM', value: 'LSTM' }
	];
	numberOfBucketsOptions = Array.from(Array(14)).map((value, index) => index + 1);

	// Autocomplete
	filteredElementOptions: Observable<any[]>;

	// Multi selection
	allSelectedModel = false;

	// View
	view;
	inBuckets = false;

	selectedSeason = '';
	@Input(MenuLateralInputs.selectedSeason)
	set setSelectedSeason(season: string) {
		this.selectedSeason = season;
		const value = this.baselineForm === undefined ? undefined : this.baselineForm.get([BaselineInput.seasons]).value;
		if (this.baselineForm && !value) {
			const selectedOption =
				(this.seasonsOptions && this.seasonsOptions.find((option) => option === this.selectedSeason)) || season;
			const selected = selectedOption ? [selectedOption] : this.seasonsOptions.filter((_, index) => index === 0);

			this.baselineForm.patchValue({
				id: 0,
				[BaselineInput.seasons]: [...selected]
			});
		}
	}

	// @ViewChild('selectModel', { static: true }) modelSel: MatSelect;

	allSelectedSeasons = false;
	@ViewChild('selectSeasons', { static: true }) seasonsSel: MatSelect;

	// Event
	@Output() baselineClick = new EventEmitter<BaselineValues>();
	@Output() baselineLoad = new EventEmitter<BaselineValues>();
	@Output() baselineChange = new EventEmitter<BaselineValues>();

	@Output() calculateClick = new EventEmitter<[BaselineValues, ModelValues]>();
	@Output() switchClick = new EventEmitter<[BaselineValues, ModelValues]>();

	@Output() modelLoad = new EventEmitter<ModelValues>();
	@Output() modelChange = new EventEmitter<ModelValues>();

	viewTrend = true;

	wrongDataYears = [];
	startRangeYear: number;

	constructor(
		private fb: UntypedFormBuilder,
		private clustersService: ClustersService,
		private dialog: DialogService,
		private oneTimeService: OneTimeServices,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.builderForms();
		this.getMenu();
		this.eventForm();
		this.getLevelList();
		this.getCabinList();
		this.getView();
	}

	builderForms() {
		this.wrongDataYears = RESTRICTED_YEARS;
		this.startRangeYear = this.getFirstValidYearRange();

		const startDate = moment().startOf('month').year(this.startRangeYear).format('YYYY-MM-DD');
		const endDate = moment()
			.endOf('month')
			.year(this.startRangeYear + 1)
			.add(-1, 'month')
			.format('YYYY-MM-DD');

		const defaultStart = moment().add(-3, 'month').format('YYYY-MM-DD');
		const now = moment().format('YYYY-MM-DD');

		const startDateAsk = moment().format('YYYY-MM-DD');
		const endDateAsk = moment().add(330, 'days').format('YYYY-MM-DD');

		// Form Baseline
		this.baselineForm = this.fb.group({
			id: [null],
			[BaselineInput.level]: [''],
			[BaselineInput.element]: [''],
			[BaselineInput.cabin]: [''],
			[BaselineInput.startDate]: [startDate],
			[BaselineInput.endDate]: [endDate],
			[BaselineInput.seasons]: [''],
			[BaselineInput.monthly]: [['basis']],
			[BaselineInput.startDateAsk]: [startDateAsk],
			[BaselineInput.endDateAsk]: [endDateAsk]
		});

		// Form Model
		this.modelForm = this.fb.group({
			[ModelInput.model]: ['Base model'],
			[ModelInput.activate]: [['activate']],
			[ModelInput.startDateAct]: [defaultStart],
			[ModelInput.endDateAct]: [now],
			[ModelInput.minimumFare]: [0],
			[ModelInput.numberOfBuckets]: [7]
		});
	}

	eventForm() {
		this.baselineForm.get(BaselineInput.element).valueChanges.subscribe((value) => {
			if (typeof value !== 'string') {
				OneTimeContext.setBaselineElement(value.elementCode);
				this.getSeasonsMenu();
			}
		});

		this.baselineForm.get(BaselineInput.cabin).valueChanges.subscribe((value) => {
			OneTimeContext.setBaselineCabinCode(value.code);
			this.getSeasonsMenu();
		});

		this.baselineForm.get(BaselineInput.cabin).valueChanges.subscribe((value) => {
			OneTimeContext.setBaselineCabinCode(value.code);
			this.getSeasonsMenu();
		});

		//TODO: Revisar ngx-localstorage
		this.baselineForm.get(BaselineInput.seasons).valueChanges.subscribe((value) => {
			OneTimeContext.setBaselineSeason(JSON.stringify(value));
		});

		// Calling on first load
		const baselineValueLoad = this.baselineForm.valueChanges.subscribe((values: BaselineValues) => {
			const valid =
				values &&
				values.cabin &&
				values.element &&
				values.level &&
				values.seasons &&
				values.startDate &&
				values.endDate;
			if (valid !== '') {
				this.baselineLoad.emit(values);
				// this.modelLoad.emit(this.modelForm.getRawValue()); // ?????

				baselineValueLoad.unsubscribe();
			}
		});

		const modelFormValueLoad = this.modelForm.valueChanges.subscribe((values: ModelValues) => {
			const valid = values && values.activate && values.startDateAct && values.endDateAct && values.model;

			if (valid) {
				this.modelLoad.emit(values);
				modelFormValueLoad.unsubscribe();
			}
		});

		// Calling on form changes
		this.baselineForm.valueChanges.subscribe((values: BaselineValues) => {
			const valid =
				values &&
				values.cabin &&
				values.element &&
				values.level &&
				values.seasons &&
				values.startDate &&
				values.endDate;

			if (valid !== '') {
				this.baselineChange.emit(values);
			}
		});

		this.modelForm.valueChanges.subscribe((values: ModelValues) => {
			const valid = values && values.activate && values.startDateAct && values.endDateAct && values.model;

			if (valid) {
				this.modelChange.emit(values);
			}
		});

		//
		this.modelForm.get(ModelInput.minimumFare).valueChanges.subscribe((value) => {
			if (typeof value === 'number' && value < 0) {
				this.modelForm.patchValue({
					[ModelInput.minimumFare]: 0
				});
			}
		});

		this.modelForm.get(ModelInput.activate).valueChanges.subscribe((value: string[]) => {
			this.viewTrend = value.length === 1;
		});
	}

	// data combobox
	getLevelList() {
		this.oneTimeService.getLevelsAndElements().subscribe(
			(data) => {
				this.levelOptions = data;
				if (this.levelOptions) {
					this.elementOptions = this.levelOptions.find((dato) => dato.level === OneTimeContext.getLevel()).elements;
					this.optionFilter();

					const level = this.levelOptions.find((option) => option.level === OneTimeContext.getLevel());
					const element = this.elementOptions.find((option) => option.elementCode === OneTimeContext.getElementCode());

					OneTimeContext.setBaselineLevel(level.level);
					OneTimeContext.setBaselineElement(element.elementCode);

					let menuLevel = null;
					let menuElement = null;
					if (this.menues) {
						const menuLevelSave = this.menues && this.menues.menu_baseline.level;
						menuLevel = this.levelOptions.find((option) => option.levelId === menuLevelSave.levelId);

						const menuElementSave = this.menues && this.menues.menu_baseline.element;
						menuElement = this.elementOptions.find((option) => option.elementCode === menuElementSave.elementCode);

						OneTimeContext.setBaselineLevel(menuLevel);
						OneTimeContext.setBaselineElement(menuElement);
					}

					this.baselineForm.patchValue({
						[BaselineInput.level]: menuLevel || level,
						[BaselineInput.element]: menuElement || element
					});
				}
			},
			(err) => {
				console.error(err);
			}
		);
	}

	getCabinList() {
		this.oneTimeService.getCabins().subscribe(
			(data) => {
				this.cabinOptions = data;
				if (this.cabinOptions) {
					const cabin = this.cabinOptions.find((option) => option.code === OneTimeContext.getCabinCode());
					OneTimeContext.setBaselineCabinCode(cabin.cabinCode);

					let menuCabin = null;
					if (this.menues) {
						const menuCabinSave = this.menues && this.menues.menu_baseline.cabin;
						menuCabin = this.cabinOptions.find((option) => option.code === menuCabinSave.code);
						OneTimeContext.setBaselineCabinCode(menuCabin);
					}

					this.baselineForm.patchValue({
						[BaselineInput.cabin]: menuCabin || cabin
					});
				}
			},
			(err) => {
				console.error(err);
			}
		);
	}

	getFirstValidYearRange() {
		let startYear: number = parseInt(moment().subtract(1, 'years').format('YYYY'), 10);
		let endYear: number = parseInt(moment().format('YYYY'), 10);

		this.wrongDataYears.sort((a, b) => a - b);
		let isValidYear = false;
		while (!isValidYear) {
			if (this.wrongDataYears.includes(startYear) || this.wrongDataYears.includes(endYear)) {
				startYear = startYear - 1;
				endYear = endYear - 1;
			} else {
				isValidYear = true;
			}
		}
		return startYear;
	}

	// Seasons
	getSeasonsMenu() {
		const formValue: BaselineValues = this.baselineForm.getRawValue();
		const validForm = formValue.element && formValue.cabin && formValue.startDate && formValue.endDate;
		if (validForm) {
			const params: SeasonByParams = {
				elementCode: formValue.element.elementCode,
				cabinCode: formValue.cabin.code,
				initDate: moment('2000-01-01').format('YYYY-MM-DD'),
				endDate: moment('3000-01-01').format('YYYY-MM-DD'),
				sessionId: OneTimeContext.getSessionID()
			};
			this.clustersService.getSeasonsByParams(params).subscribe((response) => {
				this.seasonsOptions = [...response];
				const selectedOption = this.seasonsOptions.find((v, i) => v === this.selectedSeason);

				const selected =
					(selectedOption && [selectedOption]) ||
					(this.seasonsOptions.find((_, i) => i === 0) && [this.seasonsOptions.find((_, i) => i === 0)]) ||
					[];

				let menuSeasons = null;
				if (this.menues) {
					const menuSeasonsSave = this.menues && this.menues.menu_baseline.seasons;
					menuSeasons = this.seasonsOptions.filter((season) => menuSeasonsSave.some((s) => s === season));
				}

				this.baselineForm.patchValue({
					id: 1,
					[BaselineInput.seasons]: menuSeasons || selected
				});
			});
		}
	}

	// Display autocomplete
	changeElement(value) {
		const display = value ? value.elementCode : '';
		if (display) {
			OneTimeContext.setBaselineElement(display);
		}

		return display;
	}

	// autocomplete
	optionFilter() {
		this.filteredElementOptions = this.baselineForm.get(BaselineInput.element).valueChanges.pipe(
			startWith(''),
			map((value) => (typeof value === 'string' ? value : value.elementCode)),
			map((elementCode) => (elementCode ? this._filter(elementCode) : this.elementOptions.slice()))
		);
	}

	private _filter(nombre: string) {
		const filterValue = this._normalizeValue(nombre);
		return this.elementOptions.filter((option) => this._normalizeValue(option.elementCode).includes(filterValue));
	}

	private _normalizeValue(value: string): string {
		return value.toLowerCase().replace(/\s/g, '');
	}

	// Multi selection
	toggleAllSelectionSeasons() {
		if (this.allSelectedSeasons) {
			this.seasonsSel.options.forEach((item: MatOption) => item.select());
		} else {
			this.seasonsSel.options.forEach((item: MatOption) => {
				item.deselect();
			});
		}
	}

	optionSeasonsClick() {
		let newStatus = true;
		this.seasonsSel.options.forEach((itemSeason: MatOption) => {
			if (!itemSeason.selected) {
				newStatus = false;
			}
		});
		this.allSelectedSeasons = newStatus;
	}

	// Emmit
	updateBaseline() {
		const valuesBaseline: BaselineValues = this.baselineForm.getRawValue();

		const startYear = moment(valuesBaseline.startDate).year();
		const endYear = moment(valuesBaseline.endDate).year();

		if (this.wrongDataYears.includes(startYear) || this.wrongDataYears.includes(endYear)) {
			this.dialog
				.confirmDialog({
					title: 'You are selecting ' + startYear + ' and ' + endYear + ' as your baseline range',
					message: 'Do you want to continue?',
					confirmCaption: 'Continue',
					cancelCaption: 'Go Back',
					typeDialog: 'warning'
				})
				.subscribe((confirm) => {
					if (confirm) {
						this.baselineClick.emit(valuesBaseline);
					}
				});
		} else {
			this.baselineClick.emit(valuesBaseline);
		}
		this.changeDetectorRef.detectChanges();
	}

	// callback boton calculate
	calculate() {
		const valuesBaseline = this.baselineForm.getRawValue();
		const valuesModel = this.modelForm.getRawValue();
		this.calculateClick.emit([valuesBaseline as BaselineValues, valuesModel as ModelValues]);
	}

	switchViewEvent() {
		const valuesBaseline = this.baselineForm.getRawValue();
		const valuesModel = this.modelForm.getRawValue();
		OneTimeContext.setView(this.switchView === 'AU' ? 'BP' : 'AU');
		this.getView();
		this.switchClick.emit([valuesBaseline as BaselineValues, valuesModel as ModelValues]);
	}

	// Format Date
	formatDate(event, input: string) {
		const typeValue = event.value && typeof event.value._i;
		let newDate = null;
		if (typeValue === 'object') {
			newDate = moment(event.value).format('YYYY-MM-DD');
		}

		if (typeValue === 'string') {
			const value: string = (event.value && event.value._i.replace(/-/g, '').replace(/\//g, '')) || '';
			if (value.length >= 5) {
				newDate = moment(event.value).format('YYYY-MM-DD');
			}
		}

		this.baselineForm.patchValue({
			[input]: newDate
		});
	}

	formatDateModel(event, input: string) {
		const typeValue = event.value && typeof event.value._i;
		let newDate = null;
		if (typeValue === 'object') {
			newDate = moment(event.value).format('YYYY-MM-DD');
		}

		if (typeValue === 'string') {
			const value: string = (event.value && event.value._i.replace(/-/g, '').replace(/\//g, '')) || '';
			if (value.length >= 5) {
				newDate = moment(event.value).format('YYYY-MM-DD');
			}
		}

		this.modelForm.patchValue({
			[input]: newDate
		});
	}

	viewComponent(name: string): boolean {
		const valid = this.viewInputs.find((f) => f.name === name);
		return valid && valid.view;
	}

	viewFormHtml(name: string): boolean {
		const valid = this.viewForm.find((f) => f.name === name);
		return valid && valid.view;
	}

	getView() {
		this.inBuckets = JSON.parse(OneTimeContext.getCurrentlyInBuckets());
		if (OneTimeContext.getView() === 'AU') {
			this.view = 'Switch to BP view';
		} else if (OneTimeContext.getView() === 'BP') {
			this.view = 'Switch to AU view';
		}
	}

	getMenu() {
		const idSession = OneTimeContext.getSessionID();

		this.oneTimeService.getLastMenu(this.viewPage, idSession, this.selectedSeason).subscribe(
			(res: any) => {
				if (res !== null) {
					const menuesSave: any = res;
					this.menues = menuesSave;

					if (res.menu_baseline) {
						this.baselineForm.get(BaselineInput.startDate).setValue(this.menues.menu_baseline.startDate);
						this.baselineForm.get(BaselineInput.endDate).setValue(this.menues.menu_baseline.endDate);
						this.baselineForm.get(BaselineInput.monthly).setValue(this.menues.menu_baseline.monthly);
						this.baselineForm.get(BaselineInput.startDateAsk).setValue(this.menues.menu_baseline.startDateAsk);
						this.baselineForm.get(BaselineInput.endDateAsk).setValue(this.menues.menu_baseline.endDateAsk);
					}

					if (res.menu_model) {
						const modelValue = this.modelOptions.find(
							(option) => option.display === this.menues.menu_model.model
						)?.value;

						this.modelForm.get(ModelInput.model).setValue(modelValue);
						this.modelForm.get(ModelInput.activate).setValue(this.menues.menu_model.activate);
						this.modelForm.get(ModelInput.startDateAct).setValue(this.menues.menu_model.startDateAct);
						this.modelForm.get(ModelInput.endDateAct).setValue(this.menues.menu_model.endDateAct);
						this.modelForm.get(ModelInput.minimumFare).setValue(this.menues.menu_model.minimumFare);
						this.modelForm.get(ModelInput.numberOfBuckets).setValue(this.menues.menu_model.numberOfBuckets);
					}
				}
			},
			(err) => {
				console.log(err);
			}
		);
	}
}
