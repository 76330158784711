import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorage } from '../core/storages/session.storage';

@Injectable({
	providedIn: 'root'
})
export class SessionAuthLatamGuard {
	currentUser: any;
	constructor(private readonly router: Router, private readonly sessionData: SessionStorage) {}

	canActivate() {
		if (this.sessionData.userName && this.sessionData.idToken) {
			return true;
		}

		this.router.navigate(['']);
		return false;
	}
}
