import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OneTimeServices } from '@services/one-time.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataFilter, DataFilterCabin, DataFilterElement } from 'src/app/models/one-time-home/DataFilter';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { ElementSessionList, SessionElement } from 'src/app/shared/table-old-sessions/table-old-sessions.component';

interface DataListLevelCabin {
	code: string; //  "Y"
	description: string; // "Economy"
	id: number; // 1
}

interface DataListLevelElement {
	elementCode: string;
	inbound: string;
	level: number;
	outbound: string;
}

interface DataListLevel {
	elements: Array<DataListLevelElement>;
	level: string;
	levelId: number;
}

@Component({
	selector: 'app-filtro-target',
	templateUrl: './filtro-target.component.html',
	styleUrls: ['./filtro-target.component.scss']
})
export class FiltroTargetComponent implements OnInit {
	@Output() emite = new EventEmitter<DataFilter>();
	@Output() newSession = new EventEmitter<boolean>();
	@Input() dataSource: ElementSessionList[];
	@Input()
	set restSession(value: SessionElement) {
		this.getCabinList();
		this.getLevelList();
	}

	level: DataListLevel;
	element: DataListLevelElement; // no es un string (comprobado en el debugger)
	cabin: DataListLevelCabin;
	// eventoElement;
	fechaStart = new UntypedFormControl();
	fechaEnd = new UntypedFormControl();
	comboListLevel: Array<DataListLevel>;
	comboListElement: Array<DataListLevelElement>;
	comboListCabin: Array<DataListLevelCabin>;
	myControl = new UntypedFormControl();
	filteredOptions: Observable<any[]>;
	sessionId = OneTimeContext.getSessionID();
	//Flag de snack bar activo
	sendSnackbarActive;

	constructor(
		private oneTimeService: OneTimeServices,
		private router: Router,
		private translate: TranslateService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.getCabinList();
		this.getLevelList();
	}

	displayFn(user): string {
		return user && user.elementCode ? user.elementCode : '';
	}

	private _filter(nombre: string) {
		const filterValue = this._normalizeValue(nombre);
		return this.comboListElement.filter((option) => this._normalizeValue(option.elementCode).includes(filterValue));
	}

	private _normalizeValue(value: string): string {
		return value.toLowerCase().replace(/\s/g, '');
	}

	getLevelList() {
		this.oneTimeService.getLevelsAndElements().subscribe(
			(res) => {
				this.comboListLevel = res;
				this.setSession();
			},
			(err) => {
				console.error(err);
			}
		);
	}

	getCabinList() {
		this.oneTimeService.getCabins().subscribe(
			(res) => {
				this.comboListCabin = res;
			},
			(err) => {
				console.error(err);
			}
		);
	}

	changeParameters(evento: DataListLevel) {
		this.comboListElement = evento.elements;

		this.optionFilter();
		//variables para controlar que aparece y desaparece
	}

	changeElement(evento) {
		if (evento != null) {
			return evento.elementCode;
		} else {
			return '';
		}
	}

	optionFilter() {
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map((value) => (typeof value === 'string' ? value : value.elementCode)),
			map((elementCode) => (elementCode ? this._filter(elementCode) : this.comboListElement.slice()))
		);
	}

	closedSelected() {
		if (typeof this.myControl.value === 'string') {
			const filterValue = this._normalizeValue(this.myControl.value);
			const value = this.comboListElement.find((option) => this._normalizeValue(option.elementCode) === filterValue);

			if (!!value) {
				this.myControl.setValue(value);
			}
		}
	}

	LoadSerch(level: string, levelId: number, element: DataFilterElement, cabin: DataFilterCabin, btn: boolean) {
		const target: DataFilter = {
			level: level,
			element: element,
			cabin: cabin,
			levelId: levelId
		};
		const resultado = this.dataSource.find(
			(d) => d.level === target.level && d.cabinCode == target.cabin.code && d.elementId === target.element.elementCode
		);

		if (resultado && btn) {
			this.openSnackBarIncorrect(
				'You already have an active session for this element. You can´t load an element for which you have an active session'
			);
		} else {
			if (btn) {
				OneTimeContext.setSessionID(NaN);
				this.newSession.emit(true);
			}
			this.emite.emit(target);
		}
	}

	setSession() {
		if (OneTimeContext.getSessionID()) {
			const levelS = OneTimeContext.getLevel();
			const elementS = OneTimeContext.getElementCode();
			const cabinS = OneTimeContext.getCabinCode();

			this.level = this.comboListLevel.find((c) => c.level == levelS);
			this.changeParameters(this.level);
			this.element = this.level.elements.find((c) => c.elementCode == elementS);
			this.myControl.setValue(this.element);
			this.cabin = this.comboListCabin.find((c) => c.code == cabinS);

			this.LoadSerch(this.level.level, this.level.levelId, this.myControl.value, this.cabin, false);
		}
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackIncorrect']
		});
	}
}
