import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotTableModule } from '@handsontable/angular';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CardCalendarComponent } from './card-calendar/card-calendar.component';
import { FiltroTargetComponent } from './filtro-target/filtro-target.component';
import { HomeComponent } from './home.component';
import { KpiTableComponent } from './kpi-table/kpi-table.component';

@NgModule({
	declarations: [HomeComponent, FiltroTargetComponent, KpiTableComponent, CardCalendarComponent],
	imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, HotTableModule.forRoot(), MaterialModule],
	exports: [HomeComponent, FiltroTargetComponent, KpiTableComponent, CardCalendarComponent]
})
export class HomeModule {}
