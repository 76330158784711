import { Component } from '@angular/core';

@Component({
	selector: 'app-one-time',
	templateUrl: './one-time.component.html',
	styleUrls: ['./one-time.component.scss']
})
export class OneTimeComponent {
	constructor() {}
}
