<div>
	<br />
	<div class="area-menu">
		<div class="title-menu">
			<span> Editor </span>
		</div>
		<div class="menu-fecha">
			<mat-form-field>
				<mat-label>Year</mat-label>
				<mat-select class="year" [(value)]="fechaEditor">
					<mat-option [value]="option" *ngFor="let option of listYears">{{ option }}</mat-option>
				</mat-select>
				<mat-icon class="icon-date" matSuffix>date_range</mat-icon>
			</mat-form-field>
		</div>
		<button class="btn-menu" (click)="updateEditor()">Update Editor</button>
	</div>

	<div class="linea"></div>

	<div class="area-menu">
		<div class="title-menu">
			<span> Baseline </span>
		</div>
		<div class="menu-fecha">
			<mat-form-field>
				<mat-label>Level</mat-label>
				<mat-select [(value)]="level" (valueChange)="changeParameters($event)">
					<mat-option [value]="option" *ngFor="let option of comboListLevel">{{ option.level }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field>
				<mat-label>Element</mat-label>
				<input type="text" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" />
				<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="changeElement">
					<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
						{{ option.elementCode }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field>
				<mat-label>Cabin</mat-label>
				<mat-select [(value)]="cabin">
					<mat-option [value]="option" *ngFor="let option of comboListCabin">{{ option.description }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field>
				<mat-label>Year</mat-label>
				<mat-select class="year" [(value)]="fechaBaseline">
					<mat-option [value]="option" *ngFor="let option of listYears">{{ option }}</mat-option>
				</mat-select>
				<mat-icon matSuffix>date_range</mat-icon>
			</mat-form-field>
		</div>
		<button class="btn-menu" (click)="updateBaseline()">Update Baseline</button>
	</div>

	<div class="linea"></div>

	<div class="area-menu">
		<div class="title-menu">
			<span> Model </span>
		</div>
		<button class="btn-menu" (click)="calculate()">Calculate</button>
	</div>
</div>
