<ng-container>
	<mat-card appearance="outlined" class="mat-card">
		<mat-card-title class="titulo">{{ title }}</mat-card-title>
		<mat-card-content class="mat-card-content">
			<div class="col-md-4">
				<div class="container linea-container" *ngIf="data_grid_target_curves_ready">
					<app-grid-ap-clusters
						[readOnly]="false"
						title="Overrides"
						[data_grid]="data_grid_target_curves"
						[data_grid_raw]="data_grid_target_curves_raw"
						[show_ap]="true"
						[rendererGrid]="rendererPP"
						[columns_width]="columns_width"
						(target_curves_edited)="onTargetCurvesEdited()"
					>
					</app-grid-ap-clusters>
				</div>
			</div>
			<!-- model output -->
			<div class="col-md-4" *ngIf="data_grid_target_curves_ready && data_grid_model_selected">
				<div class="container">
					<!-- Tabla -->
					<app-grid-ap-clusters
						[readOnly]="true"
						title="{{ title }} Model Output"
						[data_grid]="data_grid_target_curves_raw"
						[show_ap]="false"
						[rendererGrid]="rendererPP"
						[columns_width]="columns_width"
					>
					</app-grid-ap-clusters>
				</div>
			</div>
			<!-- RDA -->
			<div class="col-md-4" *ngIf="data_grid_rda1_selected">
				<div class="container">
					<app-grid-kpi-generic [title]="kpi_1.name" [data]="kpi_1"></app-grid-kpi-generic>
				</div>
			</div>
			<!-- TO-DO ver si hay un kpi 2 y desplegarlo-->
			<div class="col-md-4" *ngIf="data_grid_rda2_selected">
				<div class="container">
					<app-grid-kpi-generic title="KPI Generic" [data]="kpi_1"></app-grid-kpi-generic>
				</div>
			</div>
		</mat-card-content>

		<!-- atm, esta desactivado el select pq el servicio retorna solamente 2 indicadores
      <mat-card-actions class="select-rem">
        <div class="contenedor-btn">
          <div class="btn-select">
            <mat-form-field appearance="outline" class="ml-22">
              <mat-select multiple [formControl]="selectIndicadores" placeholder="Select Indicator">
                <mat-option [value]="indicator" *ngFor="let indicator of optionIndicators"
                [disabled]="maxOptionDisabled(indicator)">
                  {{ indicator }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-actions>
    -->
	</mat-card>
</ng-container>
