<div class="links">
	<div *ngIf="activeBlock1" class="first">
		<h2>Links</h2>
		<div class="search">
			<mat-form-field *ngIf="activeBlock1" class="w-100">
				<mat-icon matPrefix>search</mat-icon>
				<mat-label>Search Links</mat-label>
				<input matInput name="filter" autocomplete="off" (keyup)="applyFilter1($event)" />
			</mat-form-field>
		</div>
		<div class="content-table-side">
			<mat-table [dataSource]="dataSource" matSort>
				<ng-container matColumnDef="select" sticky>
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox
							(click)="$event.stopPropagation()"
							(change)="$event ? selection.toggle(row) : null"
							[checked]="selection.isSelected(row)"
							[aria-label]="checkboxLabel(row)"
						>
						</mat-checkbox>
					</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>
				<ng-container matColumnDef="nombre">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<div class="block-one">
							<p class="pname">{{ element.routeName }}</p>
							<p class="puser">{{ element.lastUpdateUser }}</p>
						</div>
					</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>
				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<div class="block-two">
							<p class="pdate">{{ element.lastUpdateDate | date: 'd MMM, y HH:mm' }}</p>
						</div>
					</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumnsservicio; sticky: true"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumnsservicio"
					[ngClass]="{ 'element-row': true, highlight: idSelectedRow === row.id }"
					(click)="getRecord(row, null)"
				></tr>
				<tr mat-footer-row *matFooterRowDef="displayedColumnsservicio; sticky: true"></tr>
			</mat-table>
		</div>
		<div class="btn-new">
			<button mat-raised-button (click)="buttonNew()">New Link</button>

		</div>
	</div>

	<div class="second w-100">
		<form (ngSubmit)="function(category, business, cabins)" name="loadform" #loadTableP="ngForm">
			<div class="header-principal">
				<div class="row">
					<div class="col-md-2">
						<mat-form-field>
							<mat-label>Product Category</mat-label>
							<mat-select [(value)]="category">
								<mat-option
									[value]="option.id"
									*ngFor="let option of comboListCategory"
									(valueChange)="changeParameters()"
								>
									{{ option.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-md-2">
						<mat-form-field>
							<mat-label>Business Unit</mat-label>
							<mat-select [(value)]="business" (valueChange)="changeParameters()">
								<mat-option [value]="option.id" *ngFor="let option of comboListBusiness">
									{{ option.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field>
							<mat-label>Cabin</mat-label>
							<mat-select [(value)]="cabins" (valueChange)="changeParameters()">
								<mat-option [value]="option.id" *ngFor="let option of comboListCabins">
									{{ option.description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2 align-content-center">
						<button type="submit" class="btn-search" [disabled]="!cabins || !category || !business">Search</button>
					</div>
				</div>
			</div>

			<div *ngIf="displayWelcome">
				<app-welcome-links></app-welcome-links>
			</div>

			<div *ngIf="displayLoad">
				<app-load-main-links></app-load-main-links>
			</div>

			<div class="render-new" *ngIf="displayNew">
				<app-new-links
					[business]="business"
					[category]="category"
					[cabins]="cabins"
					[links]="selectedRow"
					[cabinCode]="cabinCode"
				></app-new-links>
			</div>

			<div class="render-edit" *ngIf="displayEdit">
				<app-edit-links
					[business]="business"
					[idLinks]="idLinks"
					[category]="category"
					[nameLink]="nameLink"
					[links]="selectedRow"
					[newDataSource]="newDataSource"
					[loadtable]="loadtable"
					[cabinCode]="cabinCode"
				></app-edit-links>
			</div>

			<!-- <div class="content-principal">
                <router-outlet></router-outlet>
            </div>-->
		</form>
	</div>
</div>
