import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	BaselineBucketInputParams,
	BaselineBuckets,
	Bucket,
	BucketClustersControl,
	BucketInputParams,
	BucketSessionProgressInput,
	BucketsParams,
	ModelOutputBucketInputParams,
	RefreshPayload,
	ResponseSummary
} from '@services/buckets/buckets.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClusterPax } from 'src/app/models/buckets/ClusterPax';
import { ControlGridBucketCluster } from 'src/app/models/buckets/ControlGridBucketCluster';
import { ControlGridBucketSummary } from 'src/app/models/buckets/ControlGridBucketSummary';
import {
	addFooterBuckets,
	addFooterTotalCalculatedAF,
	GridBucketCluster
} from 'src/app/models/buckets/GridBucketCluster';
import { GridBucketSummary } from 'src/app/models/buckets/GridBucketSummary';
import { DataBucketsSummaryRequest, DataPlotRequest, DataPlotResponse } from 'src/app/models/buckets/PlotBuckets';
import { bff } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class BucketsService {
	private host = `${bff.host}`;
	private ENDPOINT_GET_BUCKETS = `${this.host}${bff.getBuckets}`;
	private ENDPOINT_GET_BUCKET_SUMMARY = `${this.host}${bff.getBucketSummary}`;
	private ENDPOINT_GET_CLUSTER_PAX = `${this.host}${bff.getClusterPax}`;
	private ENDPOINT_GET_BASELINE_BUCKETS = `${this.host}${bff.getBaselineBuckets}`;
	private ENDPOINT_GET_MODEL_OUTPUT_BUCKETS = `${this.host}${bff.getModelOutputBuckets}`;
	private ENDPOINT_GET_GRAF_BUCKETS = `${this.host}${bff.getGraphBuckets}`;
	private ENDPOINT_SAVE_PROGRESS = `${this.host}${bff.saveBucketProgress}`;
	private ENDPOINT_REFRESH = `${this.host}${bff.refreshBuckets}`;
	private ENDPOINT_UPDATE_PB_AND_AF = `${this.host}${bff.updateBPandAF}`;

	headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	});

	constructor(private http: HttpClient) {}

	invokeGetBucketData(bucketParams: BucketsParams): Observable<Array<BucketClustersControl>> {
		let queryParams: HttpParams;

		queryParams = new HttpParams()
			.set('cabinCode', bucketParams.cabinCode)
			.set('elementCode', bucketParams.elementCode)
			.set('level', bucketParams.level.toString())
			.set('season', bucketParams.season)
			.set('sessionId', bucketParams.sessionId.toString());
		const httpOptions = {
			headers: this.headers,
			params: queryParams
		};
		return this.http.get(this.ENDPOINT_GET_BUCKETS, httpOptions) as Observable<Array<BucketClustersControl>>;
	}

	invokeGetBucketSummaryData(bucketBody: any): Observable<Array<any>> {
		const httpOptions = {
			headers: this.headers
		};
		return this.http.post(this.ENDPOINT_GET_BUCKET_SUMMARY, bucketBody, httpOptions) as Observable<Array<any>>;
	}

	getClusterPax(bucketInputParam: BucketInputParams): Observable<Array<ClusterPax>> {
		let queryParams: HttpParams;

		queryParams = new HttpParams()
			.set('elementCode', bucketInputParam.elementCode)
			.set('cabinCode', bucketInputParam.cabinCode)
			.set('level', bucketInputParam.level.toString())
			.set('season', bucketInputParam.season)
			.set('sessionId', bucketInputParam.sessionId.toString())
			.set('levelBaseLine', bucketInputParam.levelBaseLine)
			.set('elementCodeBaseLine', bucketInputParam.elementCodeBaseLine)
			.set('cabinCodeBaseLine', bucketInputParam.cabinCodeBaseLine)
			.set('seasonsBaseLine', JSON.stringify(bucketInputParam.seasonsBaseLine))
			.set('startDateBaseLine', bucketInputParam.startDateBaseLine)
			.set('endDateBaseLine', bucketInputParam.endDateBaseLine);

		const httpOptions = {
			headers: this.headers,
			params: queryParams
		};

		return this.http.get(this.ENDPOINT_GET_CLUSTER_PAX, httpOptions) as Observable<Array<ClusterPax>>;
	}

	getBuckets(bucketParams: BucketsParams): Observable<Array<ControlGridBucketCluster>> {
		return this.invokeGetBucketData(bucketParams).pipe(
			map((res) => {
				let response: BucketClustersControl[] = [];

				res.forEach((bucket) => {
					const bucketPush: any = {};
					bucketPush.control = bucket.control;
					bucketPush.clusters = bucket.clusters.sort((a, b) => {
						return a.cluster.localeCompare(b.cluster, undefined, {
							numeric: true,
							sensitivity: 'base'
						});
					});

					response = [...response, bucketPush];
				});
				return this.createGrids(response, 'buckets');
			})
		);
	}

	invokeGetBucketBaselineData(payload: string): Observable<BaselineBuckets> {
		const httpOptions = {
			headers: this.headers
		};
		return this.http.post(this.ENDPOINT_GET_BASELINE_BUCKETS, payload, httpOptions) as Observable<BaselineBuckets>;
	}

	getBaselineBuckets(bucketParams: BaselineBucketInputParams): Observable<Array<ControlGridBucketCluster>> {
		const payload = JSON.stringify(bucketParams);

		return this.invokeGetBucketBaselineData(payload).pipe(
			map((res) => {
				let response: BucketClustersControl[] = [];

				res.buckets.forEach((bucket) => {
					const bucketPush: any = {};
					bucketPush.control = bucket.control;
					bucketPush.clusters = bucket.clusters.sort((a, b) => {
						return a.cluster.localeCompare(b.cluster, undefined, {
							numeric: true,
							sensitivity: 'base'
						});
					});

					response = [...response, bucketPush];
				});

				const ret = this.createGrids(response, 'baseline');

				/*
        for (const row of res.summary) {
          const row_ui = [];
          row_ui.push(row.name);
          row_ui.push('');

          for (const value of row.clustersValues) {
            row_ui.push(value);
          }

          ret[0].data_grid.rows.push(row_ui);
          ret[1].data_grid.rows.push(row_ui);
        }
        */
				return ret;
			})
		);
	}

	invokeGetBucketModelOutputData(bucketInputParam: ModelOutputBucketInputParams): Observable<BaselineBuckets> {
		let queryParams: HttpParams;

		queryParams = new HttpParams()
			.set('elementCode', bucketInputParam.elementCode)
			.set('cabinCode', bucketInputParam.cabinCode)
			.set('level', bucketInputParam.level.toString())
			.set('season', bucketInputParam.season)
			.set('sessionId', bucketInputParam.sessionId.toString())
			.set('levelBaseLine', bucketInputParam.levelBaseLine)
			.set('elementCodeBaseLine', bucketInputParam.elementCodeBaseLine)
			.set('cabinCodeBaseLine', bucketInputParam.cabinCodeBaseLine)
			.set('seasonsBaseLine', JSON.stringify(bucketInputParam.seasonsBaseLine))
			.set('startDateBaseLine', bucketInputParam.startDateBaseLine)
			.set('endDateBaseLine', bucketInputParam.endDateBaseLine)
			.set('minimunFare', bucketInputParam.minimunFare.toString())
			.set('numberOfBuckets', bucketInputParam.numberOfBuckets.toString());

		const httpOptions = {
			headers: this.headers,
			params: queryParams
		};

		return this.http.get(this.ENDPOINT_GET_MODEL_OUTPUT_BUCKETS, httpOptions) as Observable<BaselineBuckets>;
	}

	getModelOutputBuckets(bucketParams: ModelOutputBucketInputParams): Observable<Array<ControlGridBucketCluster>> {
		return this.invokeGetBucketModelOutputData(bucketParams).pipe(
			map((res) => {
				let response: BucketClustersControl[] = [];

				res.buckets.forEach((bucket) => {
					const bucketPush: any = {};
					bucketPush.control = bucket.control;
					bucketPush.clusters = bucket.clusters.sort((a, b) => {
						return a.cluster.localeCompare(b.cluster, undefined, {
							numeric: true,
							sensitivity: 'base'
						});
					});

					response = [...response, bucketPush];
				});

				const ret = this.createGrids(response, 'model_output');

				const deltaRow0 = [];
				deltaRow0.push('Delta AF MO vs BL');
				ret[0].data_grid.rows.push(deltaRow0);

				const deltaRow1 = [];
				deltaRow1.push('Delta AF MO vs BL');
				ret[1].data_grid.rows.push(deltaRow1);

				return ret;
			})
		);
	}

	getBucketSummary(bucketParams: DataBucketsSummaryRequest): Observable<ControlGridBucketSummary> {
		return this.invokeGetBucketSummaryData(bucketParams).pipe(
			map((res) => {
				return this.createGridSummary(res);
			})
		);
	}

	createGrids(response: Array<BucketClustersControl>, gridName?: string): Array<ControlGridBucketCluster> {
		const inputAU = response.find((x) => x.control === 'AU');
		const inputBP = response.find((x) => x.control === 'BP');

		const clustersAUList = this.getClusterList(inputAU);
		const clustersBPList = this.getClusterList(inputBP);

		const bucketAUList = this.getBucketList(inputAU.clusters[0].table);
		const bucketBPList = this.getBucketList(inputBP.clusters[0].table);

		let bucketAURows: Array<Array<any>> = [];
		let bucketBPRows: Array<Array<any>> = [];
		let bucketAURawRows: Array<Array<any>> = [];
		let bucketBPRawRows: Array<Array<any>> = [];

		switch (gridName) {
			case 'baseline': {
				bucketAURows = this.getTableBaselineBucketRows(inputAU, bucketAUList);
				bucketBPRows = this.getTableBaselineBucketRows(inputBP, bucketBPList);
				break;
			}
			case 'model_output': {
				bucketAURows = this.getTableModelOutputBucketRows(inputAU, bucketAUList);
				bucketBPRows = this.getTableModelOutputBucketRows(inputBP, bucketBPList);
				break;
			}
			default: {
				bucketAURows = this.getTableBucketRows(inputAU, bucketAUList);
				bucketBPRows = this.getTableBucketRows(inputBP, bucketBPList);
				bucketAURawRows = this.getTableBucketRawRows(inputAU, bucketAUList);
				bucketBPRawRows = this.getTableBucketRawRows(inputBP, bucketBPList);
				break;
			}
		}

		return [
			{
				control: 'AU',
				data_grid: new GridBucketCluster('AU', clustersAUList, bucketAUList, bucketAURows),
				raw_data_grid: new GridBucketCluster('AU', clustersAUList, bucketAUList, bucketAURawRows)
			},
			{
				control: 'BP',
				data_grid: new GridBucketCluster('BP', clustersBPList, bucketBPList, bucketBPRows),
				raw_data_grid: new GridBucketCluster('BP', clustersBPList, bucketBPList, bucketBPRawRows)
			}
		];
	}

	createGridSummary(response: Array<ResponseSummary>): ControlGridBucketSummary {
		const buckeRows = response.map((m) => [m.name, ...m.clustersValues]);

		const gridBucketSummany = new GridBucketSummary(
			response[0].clusters.map((m) => Number(m)),
			buckeRows
		);

		return { data_grid: gridBucketSummany };
	}

	private getTableBucketDataRows(controlData: BucketClustersControl, buckets: Array<string>, isRaw: boolean = false) {
		const data: Array<Array<any>> = [];
		for (const bucket of buckets) {
			const newRow = [];
			newRow.push(bucket);
			newRow.push(controlData.clusters[0].table.find((x) => x.bucket === bucket).fare);
			for (const bucketCluster of controlData.clusters) {
				const bucketRow = bucketCluster.table.find((x) => x.bucket === bucket);
				if (isRaw) {
					newRow.push(100.0 * bucketRow.paxShareRaw);
				} else {
					newRow.push(100.0 * bucketRow.paxShare);
				}
			}
			data.push(newRow);
		}
		return data;
	}

	private getTableBucketRows(
		controlData: BucketClustersControl,
		buckets: Array<string>,
		isRaw: boolean = false
	): Array<Array<any>> {
		let data = this.getTableBucketDataRows(controlData, buckets, isRaw);

		data = [...data, ...addFooterBuckets()];

		return data;
	}

	private getTableBaselineBucketRows(controlData: BucketClustersControl, buckets: Array<string>): Array<Array<any>> {
		let data = this.getTableBucketDataRows(controlData, buckets);

		data = [...data, ...addFooterTotalCalculatedAF()];

		return data;
	}

	private getTableModelOutputBucketRows(controlData: BucketClustersControl, buckets: Array<string>): Array<Array<any>> {
		const data = this.getTableBucketDataRows(controlData, buckets, false);

		const totalRow = [];
		totalRow.push('Total');

		const afRow = [];
		afRow.push('Calculated AF');

		data.push(totalRow);
		data.push(afRow);

		return data;
	}

	private getTableBucketRawRows(controlData: BucketClustersControl, buckets: Array<string>): Array<Array<any>> {
		return this.getTableBucketRows(controlData, buckets, true);
	}

	getClusterList(controlResponse: BucketClustersControl): Array<number> {
		const result: Array<number> = [];

		for (const cluster of controlResponse.clusters) {
			result.push(Number(cluster.cluster));
		}

		return this.removeDuplicatedFromArray(result);
	}

	getBucketList(tableBuckets: Array<Bucket>): Array<string> {
		const result: Array<string> = [];

		for (const bucket of tableBuckets) {
			result.push(bucket.bucket);
		}
		return this.removeDuplicatedFromArray(result);
	}

	removeDuplicatedFromArray(array: Array<any>): Array<any> {
		return array.filter((item, pos) => {
			return array.indexOf(item) === pos;
		});
	}

	getDataGrafBuckets(bucketGrafParams: DataPlotRequest): Observable<Array<DataPlotResponse>> {
		const payload = JSON.stringify(bucketGrafParams);

		return this.invokeGetDataGrafBuckets(payload);
	}

	invokeGetDataGrafBuckets(payload: string): Observable<Array<DataPlotResponse>> {
		const httpOptions = {
			headers: this.headers
		};
		return this.http.post(this.ENDPOINT_GET_GRAF_BUCKETS, payload, httpOptions) as Observable<Array<DataPlotResponse>>;
	}

	save(toBeSaved: BucketSessionProgressInput): Observable<BucketSessionProgressInput> {
		const payload = JSON.stringify(toBeSaved);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};

		return this.http.post(this.ENDPOINT_SAVE_PROGRESS, payload, httpOptions) as Observable<BucketSessionProgressInput>;
	}

	refreshBuckets(payload: RefreshPayload): Observable<Array<ControlGridBucketCluster>> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};
		return this.http.post(this.ENDPOINT_REFRESH, payload, httpOptions).pipe(
			map((res: Array<BucketClustersControl>) => {
				return this.createGrids(res, 'buckets');
			})
		);
	}

	updateBPandAF(payload: RefreshPayload): Observable<Array<ControlGridBucketCluster>> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};
		return this.http.post(this.ENDPOINT_UPDATE_PB_AND_AF, payload, httpOptions).pipe(
			map((res: Array<BucketClustersControl>) => {
				return this.createGrids(res, 'buckets');
			})
		);
	}
}
