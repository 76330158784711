import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { SessionInfo } from 'src/app/models/SessionInfo';
import { LoginService } from 'src/app/services/login.service';
import { SessionService } from 'src/app/services/session.service';
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
	loading;
	session: SessionInfo;
	constructor(
		public translate: TranslateService,
		private sessionService: SessionService,
		private loginService: LoginService,
		private authService: SocialAuthService,
		private router: Router
	) {
		translate.addLangs(['es', 'en', 'pt']);
		translate.setDefaultLang('en');
	}

	switchLang(lang: string) {
		this.translate.use(lang);
	}

	closeSession() {
		localStorage.clear();
		sessionStorage.clear();
		this.authService.signOut();
		this.loginService.setCurrentLoginState(false);
		this.router.navigateByUrl('');
	}
}
