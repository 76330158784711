<ng-container class="table-clusters">
	<div *ngIf="isReady()">
		<hot-table
			[settings]="tableSettings()"
			[columns]="columns"
			[data]="data_lf"
			[hotId]="id"
			[stretchH]="stretchH"
			[afterInit]="onHotAfterInit"
			class="hot handsontable htColumnHeaders"
		>
		</hot-table>
	</div>
</ng-container>
