import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDate } from '@js-joda/core';
import { RemCalendarService } from '@services/calendars/rem-calendar.service';
import { SeasonCalendarService } from '@services/calendars/season-calendar.service';
import { OneTimeServices } from '@services/one-time.service';
import { SessionStorage } from 'src/app/core/storages/session.storage';
import { CreacionSession } from 'src/app/models/NewSession';
import { DataFilter } from 'src/app/models/one-time-home/DataFilter';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';

const ContenedorInputs = {
	filter: 'filter'
};

@Component({
	selector: 'app-card-calendar',
	templateUrl: './card-calendar.component.html',
	styleUrls: ['./card-calendar.component.scss']
})
export class CardCalendarComponent {
	@Input() disableView: boolean;
	@Input() stateNewSession: boolean = false;
	dataFilter: DataFilter;
	@Input(ContenedorInputs.filter)
	set setFilter(data: DataFilter) {
		this.dataFilter = data;
		this.generateCalendar();
	}

	datosNewSession = new CreacionSession();
	variableRouter: string;

	columns: any = [];
	calendars: any = [];

	calendarType = 'seasons';

	constructor(
		private oneTimeServices: OneTimeServices,
		private seasonCalendarService: SeasonCalendarService,
		private remCalendarService: RemCalendarService,
		public router: Router,
		private route: ActivatedRoute,
		private sessionData: SessionStorage
	) {}

	createNewSessionAndNavigateTo(to: string) {
		OneTimeContext.deleteSessionStorage_Id();
		OneTimeContext.setOutbound(this.dataFilter.element.outbound);
		OneTimeContext.setInbound(this.dataFilter.element.inbound);
		OneTimeContext.setLevel(this.dataFilter.level);
		OneTimeContext.setLevelId(this.dataFilter.levelId);
		OneTimeContext.setCabinCode(this.dataFilter.cabin.code);
		OneTimeContext.setCabinName(this.dataFilter.cabin.description);
		OneTimeContext.setElementCode(this.dataFilter.element.elementCode);
		OneTimeContext.setBaselineElement(this.dataFilter.element.elementCode);
		OneTimeContext.setBaselineLevel(this.dataFilter.level);
		OneTimeContext.setBaselineCabinCode(this.dataFilter.cabin.code);
		OneTimeContext.setBaselineCabinName(this.dataFilter.cabin.description);

		if (this.stateNewSession) {
			this.datosNewSession.user = this.sessionData.userEmail;
			this.datosNewSession.elementId = this.dataFilter.element.elementCode;
			this.datosNewSession.cabinCode = this.dataFilter.cabin.code;
			this.datosNewSession.tactical = false;
			this.datosNewSession.commited = false;

			this.oneTimeServices.postNewSession(this.datosNewSession).subscribe(
				(res) => {
					console.log('setSessionID', res);

					OneTimeContext.setSessionID(res.id);
					this.router.navigate([to], { relativeTo: this.route });
				},
				(error) => {
					console.error('createNewSessionAndNavigateTo', error);
				}
			);
		} else this.router.navigate([to], { relativeTo: this.route });
	}

	generateCalendar(): void {
		const cabinCode = this.dataFilter && this.dataFilter.cabin.code;
		const elementCode = this.dataFilter && this.dataFilter.element.elementCode;
		const currentDate = LocalDate.now();
		const initDate = LocalDate.of(currentDate.year(), currentDate.monthValue(), 1);
		const monthsNumber = 12;
		const endDate = initDate.plusMonths(monthsNumber);

		const sessionId = OneTimeContext.getSessionID();

		this.seasonCalendarService
			.generateSeasonCalendars(cabinCode, elementCode, initDate, endDate, monthsNumber, sessionId)
			.subscribe(
				(dayBoundSeasons) => {
					var calendars: any = [];

					for (var i = 0; i < monthsNumber; i++) {
						calendars.push({
							month: dayBoundSeasons.inbound.calendars[i].month,
							year: dayBoundSeasons.inbound.calendars[i].year,
							inboundCalendars: dayBoundSeasons.inbound.calendars[i].rows,
							outboundCalendars: dayBoundSeasons.outbound.calendars[i].rows
						});
					}

					this.columns = this.remCalendarService.getCalendarColumns();
					this.calendars = calendars;
				},
				(error) => {
					console.log('generateCalendar', error);
				}
			);
	}
}
