import { Component } from '@angular/core';

@Component({
	selector: 'app-welcome-parameters',
	templateUrl: './welcome-parameters.component.html',
	styleUrls: ['./welcome-parameters.component.scss']
})
export class WelcomeParametersComponent {
	constructor() {}
}
