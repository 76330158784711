import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotTableModule } from '@handsontable/angular';
import { esMX, registerLanguageDictionary } from 'handsontable/i18n';
import { MaterialModule } from '../material.module';
import { CalendarContainerComponent } from './calendar-container/calendar-container.component';
import { CalendarRemComponent } from './calendar-rem/calendar-rem.component';
import { CardContainerComponent } from './card-container/card-container.component';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { CopiaCurvaMetaComponent } from './dialogs/copia-curva-meta/copia-curva-meta.component';
import { GridKpiGenericComponent } from './grid-kpi-generic/grid-kpi-generic.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { MenuLateralInputsComponent } from './menu-lateral-inputs/menu-lateral-inputs.component';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { MenuNotifyComponent } from './menu-notify/menu-notify.component';
import { MenuSuperiorGlobalComponent } from './menu-superior-global/menu-superior-global.component';
import { PlotInfoComponent } from './plot-info/plot-info.component';
import { TableCompletitudComponent } from './table-completitud/table-completitud.component';
import { TableOldSessionsComponent } from './table-old-sessions/table-old-sessions.component';
import { ModalErrorNotifyComponent } from './modal-error-notify/modal-error-notify.component';

// Idioma de handsontable
registerLanguageDictionary(esMX);
@NgModule({
	declarations: [
		CardContainerComponent,
		CalendarContainerComponent,
		CalendarRemComponent,
		MenuLateralComponent,
		MenuBarComponent,
		TableOldSessionsComponent,
		ConfirmComponent,
		PlotInfoComponent,
		TableCompletitudComponent,
		GridKpiGenericComponent,
		MenuSuperiorGlobalComponent,
		MenuLateralInputsComponent,
		CopiaCurvaMetaComponent,
		MenuNotifyComponent,
  ModalErrorNotifyComponent
	],
	imports: [FormsModule, ReactiveFormsModule, CommonModule, MaterialModule, HotTableModule.forRoot()],
	exports: [
		CardContainerComponent,
		CalendarContainerComponent,
		CalendarRemComponent,
		MenuLateralComponent,
		MenuBarComponent,
		TableOldSessionsComponent,
		ConfirmComponent,
		PlotInfoComponent,
		TableCompletitudComponent,
		GridKpiGenericComponent,
		MenuSuperiorGlobalComponent,
		MenuLateralInputsComponent,
		CopiaCurvaMetaComponent,
		MenuNotifyComponent
	]
})
export class SharedModule {}
