<mat-card>
	<mat-card-header class="header-card">
		<table class="container-title">
			<tr>
				<th class="title">{{ getTitle }}</th>
				<th class="month" *ngIf="month">{{ month }}</th>
				<th class="year" *ngIf="year">{{ year }}</th>
			</tr>
		</table>
	</mat-card-header>
	<mat-card-content [ngClass]="{ multicalendar: multi }">
		<ng-content></ng-content>
	</mat-card-content>
</mat-card>
