import { Component, EventEmitter, Input, Output } from '@angular/core';
import Handsontable from 'handsontable';
import { ClusterDescriptiveStatistics } from 'src/app/models/clusters/ClusterDescriptiveStatistics';
import { ConfigService } from '../../../../config/config.service';

const DescriptiveStatisticsInputs = {
	dataInputKpi: 'dataInputKpi'
};

@Component({
	selector: 'app-descriptive-statistics',
	templateUrl: './descriptive-statistics.component.html',
	styleUrls: ['./descriptive-statistics.component.scss']
})
export class DescriptiveStatisticsComponent {
	@Output() renderStatisticsEvent = new EventEmitter<any>();

	@Input() flagChangedSeason: boolean;

	data: Array<ClusterDescriptiveStatistics>;
	@Input(DescriptiveStatisticsInputs.dataInputKpi) set setDataInputKpi(dataInputKpi: any) {
		if (typeof dataInputKpi !== 'undefined') {
			if (this.data !== undefined) {
				dataInputKpi.forEach((d) => {
					const oldData = this.data.filter((r) => r.cluster === d.cluster)[0];

					if (oldData !== undefined && this.flagChangedSeason === false) {
						d.description = oldData.description;
					}
				});
			}

			this.data = dataInputKpi;
		}
	}

	tableSettings: Handsontable.GridSettings = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		contextMenu: false,
		dropdownMenu: false,
		language: 'es-MX',
		className: 'htCenter',
		filters: false,
		columnSorting: false,
		allowInsertRow: false,
		viewportColumnRenderingOffset: 1000,
		colWidths: [64, 108, 88, 87, 87, 87, 91, 97, 87, 88, 87, 87]
	};

	columns = [
		{
			data: 'cluster',
			readOnly: true,
			title: 'Clusters',
			type: 'text'
		},
		{
			data: 'description',
			readOnly: false,
			title: 'Description',
			type: 'text',
			placeholder: 'Description...'
		},
		{
			data: 'rask',
			numericFormat: { pattern: '0.[00]' },
			readOnly: true,
			title: 'RASK',
			type: 'numeric'
		},
		{
			data: 'yield',
			numericFormat: { pattern: '0.[00]' },
			readOnly: true,
			title: 'Yield',
			type: 'numeric'
		},
		{
			data: 'loadFactor',
			numericFormat: { pattern: '%0.0' },
			readOnly: true,
			title: 'Load Factor',
			type: 'numeric'
		},
		{
			data: 'paxShortAP',
			numericFormat: { pattern: '%0.0' },
			readOnly: true,
			title: 'Short AP pax',
			type: 'numeric'
		},
		{
			data: 'internationalConnectingPax',
			numericFormat: { pattern: '%0.0' },
			readOnly: true,
			title: 'International Connections',
			type: 'numeric'
		},
		{
			data: 'domConnectingPax',
			numericFormat: { pattern: '%0.0' },
			readOnly: true,
			title: 'Domestic Connections',
			type: 'numeric'
		},
		{
			data: 'baselineAsk',
			numericFormat: { pattern: {average:true, mantissa:1, lowPrecision:false} },
			readOnly: true,
			title: 'Baseline ASK',
			type: 'numeric'
		},
		{
			data: 'targetAsk',
			numericFormat: { pattern: {average:true, mantissa:1, lowPrecision:false} },
			readOnly: true,
			title: 'Target ASK',
			type: 'numeric'
		},
		{
			data: 'postedFlights',
			numericFormat: { pattern: '%0.0' },
			readOnly: true,
			title: 'Posted Flights',
			type: 'numeric'
		},
		{
			data: 'lowMix',
			numericFormat: { pattern: '%0.0' },
			readOnly: true,
			title: 'Mix Low',
			type: 'numeric'
		}
	];

	constructor(private configService: ConfigService) {}
}
