import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
/*Services*/
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HotTableModule } from '@handsontable/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotificationsService } from '@services/notifications.service';
import {
    GoogleLoginProvider,
    GoogleSigninButtonModule,
    SocialAuthServiceConfig
} from '@abacritt/angularx-social-login';
import { esMX, registerLanguageDictionary } from 'handsontable/i18n';
import { BlockUIModule } from 'ng-block-ui';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EditLinksComponent } from './components/link/edit-links/edit-links.component';
import { LinkComponent } from './components/link/link/link.component';
import { LoadMainLinksComponent } from './components/link/load-main-links/load-main-links.component';
import { NewLinksComponent } from './components/link/new-links/new-links.component';
import { SnackbarComponent } from './components/link/SnackbarLink/Snackbar.component';
import { WelcomeLinksComponent } from './components/link/welcome-links/welcome-links.component';
import { ConfigService } from './config/config.service';

/* Components */
import { DashboardComponent } from './components/main/dashboard/dashboard.component';
import { LoginComponent } from './components/main/login/login.component';
import { NavbarComponent } from './components/main/navbar/navbar.component';
import { BucketsModule } from './components/one-time/buckets/buckets.module';
import { ClustersModule } from './components/one-time/clusters/clusters.module';
import { HomeModule } from './components/one-time/home/home.module';
import { OneTimeComponent } from './components/one-time/one-time.component';
import { DiaSeasonValorScatterplotComponent } from './components/one-time/scatterplots/dia-season-valor-scatterplot/dia-season-valor-scatterplot.component';
import { OutInFromToComponent } from './components/one-time/seasons/FormFilterOutInFromTo/out-in-from-to.component';
import { SeasonsComponent } from './components/one-time/seasons/seasons.component';
import { SeasonsModule } from './components/one-time/seasons/seasons.module';
import { SummaryModule } from './components/one-time/summary/summary.module';
import { TargetCurvesModule } from './components/one-time/target-curves/target-curves.module';
import { EditParameterComponent } from './components/parameter/edit-parameter/edit-parameter.component';
import { LoadMainComponent } from './components/parameter/load-main/load-main.component';
import { NewParameterComponent } from './components/parameter/new-parameter/new-parameter.component';
import { ParametersComponent } from './components/parameter/parameters/parameters.component';
import { SnackbarParameter } from './components/parameter/SnackbarParameter/SnackbarParameter.component';
import { WelcomeParametersComponent } from './components/parameter/welcome-parameters/welcome-parameters.component';
import { BlockUiTemplateComponent } from './http-spinner/block-ui-template.component';
import { HttpSpinnerComponent } from './http-spinner/http-spinner.component';
import { HttpErrorInterceptor } from './interceptor/http-error.interceptor';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { MaterialModule } from './material.module';
import { SessionAuthLatamGuard } from './route-guard/session-auth-latam.guard';
import { LoginService } from './services/login.service';
import { SharedModule } from './shared/shared.module';
import { registerAllModules, registerAllRenderers } from 'handsontable/registry';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";

// register all Handsontable modules
registerAllModules();
// register all renderers at once
registerAllRenderers();

registerLanguageDictionary(esMX);

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

export function initializeApp(configService: ConfigService) {
	return () => configService.load();
}

export function socialConfigFactory(configService: ConfigService) {
	let providers = [];

	providers.push({
		id: GoogleLoginProvider.PROVIDER_ID,
		provider: new GoogleLoginProvider(configService.GSuiteClientID)
	});

	return {
		autoLogin: false,
		providers: providers
	} as SocialAuthServiceConfig;
}

const weekDays = [];
const days: any = localeEs[3][1];
days.forEach((day) => {
	day = day.replace(/^\w/, (c) => c.toUpperCase());
	day = day.replace('.', '');
	weekDays.push(day);
});
localeEs[3][1] = weekDays;

const monthsLocal = [];
const monthsEs = localeEs[5][1];
monthsEs.forEach((month) => {
	month = month.replace(/^\w/, (c) => c.toUpperCase());
	month = month.replace('.', '');
	if (month === 'Sept') {
		month = 'Sep';
	}
	monthsLocal.push(month);
});
localeEs[5][1] = monthsLocal;

registerLocaleData(localeEs, 'es');

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        NavbarComponent,
        LinkComponent,
        SnackbarComponent,
        ParametersComponent,
        WelcomeParametersComponent,
        LoadMainComponent,
        NewParameterComponent,
        EditParameterComponent,
        LoadMainLinksComponent,
        WelcomeLinksComponent,
        HttpSpinnerComponent,
        BlockUiTemplateComponent,
        NewLinksComponent,
        EditLinksComponent,
        SnackbarParameter,
        OneTimeComponent,
        SeasonsComponent,
        OutInFromToComponent,
        DiaSeasonValorScatterplotComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    imports: [FormsModule,
        NgxMatSelectSearchModule,
        AppRoutingModule,
        HotTableModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        CommonModule,
        BlockUIModule.forRoot({
            // template: BlockUiTemplateComponent,
            // delayStop: 500
        }),
        ReactiveFormsModule,
        BrowserAnimationsModule,
        BrowserModule,
        SharedModule,
        MaterialModule,
        HomeModule,
        SeasonsModule,
        ClustersModule,
        SummaryModule,
        TargetCurvesModule,
        BucketsModule,
        NgxLocalStorageModule.forRoot({
            storage: sessionStorage
        }),
        GoogleSigninButtonModule],
    providers: [
        LoginService,
        NotificationsService,
        DatePipe,
        SessionAuthLatamGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [ConfigService],
            multi: true
        },
        {
            provide: 'SocialAuthServiceConfig',
            useFactory: socialConfigFactory,
            deps: [ConfigService]
        },
        {
            provide: LOCALE_ID,
            useValue: 'es-US'
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { subscriptSizing: 'dynamic' }
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
