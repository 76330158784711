<div>
	<br />
	<form [formGroup]="baselineForm" *ngIf="viewFormHtml(formMenu.baseline)">
		<div class="area-menu">
			<div class="title-menu">
				<span> Baseline </span>
			</div>
			<div class="menu-inputs">
				<mat-form-field *ngIf="viewComponent(baselineControl.level)">
					<mat-label>Level</mat-label>
					<mat-select [formControlName]="baselineControl.level">
						<mat-option [value]="optionLevel" *ngFor="let optionLevel of levelOptions">
							{{ optionLevel.level }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field *ngIf="viewComponent(baselineControl.element)">
					<mat-label>Element</mat-label>
					<input
						type="text"
						aria-label="Number"
						matInput
						[formControlName]="baselineControl.element"
						[matAutocomplete]="auto"
						style="color: black"
					/>
					<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="changeElement">
						<mat-option *ngFor="let option of filteredElementOptions | async" [value]="option">
							{{ option.elementCode }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				<mat-form-field *ngIf="viewComponent(baselineControl.cabin)">
					<mat-label>Cabin</mat-label>
					<mat-select [formControlName]="baselineControl.cabin">
						<mat-option [value]="optionCabin" *ngFor="let optionCabin of cabinOptions">
							{{ optionCabin.description }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field *ngIf="viewComponent(baselineControl.startDate)">
					<mat-label>Start date</mat-label>
					<input
						matInput
						[matDatepicker]="dpStart"
						[formControlName]="baselineControl.startDate"
						style="color: black"
						(dateChange)="formatDate($event, baselineControl.startDate)"
					/>
					<mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
					<mat-datepicker startView="multi-year" #dpStart></mat-datepicker>
				</mat-form-field>
				<mat-form-field *ngIf="viewComponent(baselineControl.endDate)">
					<mat-label>End date</mat-label>
					<input
						matInput
						[matDatepicker]="dpEnd"
						[formControlName]="baselineControl.endDate"
						style="color: black"
						(dateChange)="formatDate($event, baselineControl.endDate)"
					/>
					<mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
					<mat-datepicker startView="multi-year" #dpEnd></mat-datepicker>
				</mat-form-field>
				<mat-form-field *ngIf="viewComponent(baselineControl.seasons)">
					<mat-label>Seasons</mat-label>
					<mat-select #selectSeasons multiple [formControlName]="baselineControl.seasons">
						<div class="select-all">
							<mat-checkbox
								[(ngModel)]="allSelectedSeasons"
								[ngModelOptions]="{ standalone: true }"
								(change)="toggleAllSelectionSeasons()"
								>Select All</mat-checkbox
							>
						</div>
						<mat-option
							[value]="optionSeason"
							*ngFor="let optionSeason of seasonsOptions"
							(click)="optionSeasonsClick()"
						>
							{{ optionSeason }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<mat-selection-list
				[disableRipple]="true"
				[formControlName]="baselineControl.monthly"
				*ngIf="viewComponent(baselineControl.monthly)"
			>
				<mat-list-option value="basis" class="option-select"> Monthly basis </mat-list-option>
			</mat-selection-list>
			<div
				class="area-sub-menu"
				*ngIf="viewComponent(baselineControl.startDateAsk) || viewComponent(baselineControl.endDateAsk)"
			>
				<div class="title-menu">
					<span> ASK actual year </span>
				</div>
				<div class="menu-inputs">
					<mat-form-field>
						<mat-label>Start date</mat-label>
						<input
							matInput
							[matDatepicker]="dpStartModel"
							style="color: black"
							[formControlName]="baselineControl.startDateAsk"
							(dateChange)="formatDate($event, baselineControl.startDateAsk)"
						/>
						<mat-datepicker-toggle matSuffix [for]="dpStartModel"></mat-datepicker-toggle>
						<mat-datepicker startView="multi-year" #dpStartModel></mat-datepicker>
					</mat-form-field>
					<mat-form-field>
						<mat-label>End date</mat-label>
						<input
							matInput
							[matDatepicker]="dpEndModel"
							style="color: black"
							[formControlName]="baselineControl.endDateAsk"
							(dateChange)="formatDate($event, baselineControl.endDateAsk)"
						/>
						<mat-datepicker-toggle matSuffix [for]="dpEndModel"></mat-datepicker-toggle>
						<mat-datepicker startView="multi-year" #dpEndModel></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
			<button type="button" class="btn-menu" (click)="updateBaseline()">Update Baseline</button>
		</div>
	</form>
	<div class="linea" *ngIf="viewFormHtml(formMenu.model)"></div>
	<form [formGroup]="modelForm" *ngIf="viewFormHtml(formMenu.model)">
		<div class="area-menu">
			<div class="title-menu">
				<span> Model </span>
			</div>
			<div class="menu-inputs">
				<mat-form-field *ngIf="viewComponent(modelControl.model)">
					<mat-label>Model</mat-label>
					<mat-select #selectModel [formControlName]="modelControl.model">
						<mat-option *ngFor="let model of modelOptions" [value]="model.value">{{ model.display }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field *ngIf="viewComponent(modelControl.minimumFare)">
					<mat-label>Minimum Fare</mat-label>
					<input
						type="number"
						aria-label="Number"
						matInput
						[formControlName]="modelControl.minimumFare"
						style="color: black"
						min="0"
					/>
				</mat-form-field>

				<mat-form-field *ngIf="viewComponent(modelControl.numberOfBuckets)">
					<mat-label>Number of buckets</mat-label>
					<mat-select [formControlName]="modelControl.numberOfBuckets">
						<mat-option *ngFor="let nbuckets of numberOfBucketsOptions" [value]="nbuckets">{{ nbuckets }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="area-sub-menu">
			<mat-selection-list
				[disableRipple]="true"
				[formControlName]="modelControl.activate"
				*ngIf="viewComponent(modelControl.activate)"
			>
				<mat-list-option value="activate" class="option-select-sub-title"> Trend </mat-list-option>
			</mat-selection-list>
			<div class="menu-inputs" *ngIf="viewTrend && viewComponent(modelControl.activate)">
				<mat-form-field>
					<mat-label>Start date</mat-label>
					<input
						matInput
						[matDatepicker]="dpStartModelAct"
						[formControlName]="modelControl.startDateAct"
						style="color: black"
						(dateChange)="formatDateModel($event, modelControl.startDateAct)"
					/>
					<mat-datepicker-toggle matSuffix [for]="dpStartModelAct"></mat-datepicker-toggle>
					<mat-datepicker startView="multi-year" #dpStartModelAct></mat-datepicker>
				</mat-form-field>
				<mat-form-field>
					<mat-label>End date</mat-label>
					<input
						matInput
						[matDatepicker]="dpEndModelAct"
						[formControlName]="modelControl.endDateAct"
						style="color: black"
						(dateChange)="formatDateModel($event, modelControl.endDateAct)"
					/>
					<mat-datepicker-toggle matSuffix [for]="dpEndModelAct"></mat-datepicker-toggle>
					<mat-datepicker startView="multi-year" #dpEndModelAct></mat-datepicker>
				</mat-form-field>
			</div>
			<button type="button" class="btn-menu" (click)="calculate()">Calculate</button>
		</div>
	</form>
	<div class="linea" *ngIf="viewFormHtml(formMenu.switchView)"></div>
	<div class="area-menu" *ngIf="viewFormHtml(formMenu.switchView)">
		<button type="button" class="btn-menu btn-menu-switch" (click)="switchViewEvent()">
			Switch to {{ switchView === 'AU' ? 'BP' : 'AU' }} view
		</button>
	</div>
</div>
