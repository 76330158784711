import { Injectable } from '@angular/core';
import { LocalDate, LocalDateTime, ChronoUnit, DateTimeFormatter } from '@js-joda/core';
import { Season } from '../../models/calendars/Season';
import { MonthCalendar } from '../../models/calendars/MonthCalendar';
import { RowCalendar } from '../../models/calendars/RowCalendar';
import { PeriodCalendarStructure } from '../../models/calendars/PeriodCalendarStructure';

@Injectable({
	providedIn: 'root'
})
export class RemCalendarService {
	constructor() {}

	getCalendarColumns() {
		return [
			{
				data: 'days',
				title: 'Days',
				readOnly: true
			},
			{
				data: 'week',
				title: 'Week',
				readOnly: true
			},
			{
				data: 'mon_data',
				title: '1'
			},
			{
				data: 'tue_data',
				title: '2'
			},
			{
				data: 'wed_data',
				title: '3'
			},
			{
				data: 'thu_data',
				title: '4'
			},
			{
				data: 'fri_data',
				title: '5'
			},
			{
				data: 'sat_data',
				title: '6'
			},
			{
				data: 'sun_data',
				title: '7'
			}
		];
	}
}
