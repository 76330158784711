import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from '@services/dialog.service';
import { OneTimeServices } from '@services/one-time.service';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { RESTRICTED_YEARS } from '../../one-time.constants';
import { SeasonsComponent } from '../seasons.component';

export interface filterBaseline {
	level: string;
	element: string;
	cabin: string;
	year: string;
}

export interface editorOutput {
	level: string;
	element: string;
	cabin: string;
	baselineYear: string;
	editorYear: string;
}

@Component({
	selector: 'app-menu-seasons',
	templateUrl: './menu-seasons.component.html',
	styleUrls: ['./menu-seasons.component.scss']
})
export class MenuSeasonsComponent implements OnInit {
	@Output() infoMenu = new EventEmitter<editorOutput>();
	@Output() clickUpdateBaseline = new EventEmitter<filterBaseline>();

	fechaEditor;
	fechaBaseline;

	level;
	element: string;
	cabin;
	tupla;

	comboListLevel;
	comboListElement;
	comboListCabin;

	myControl = new UntypedFormControl();
	filteredOptions: Observable<any[]>;

	respaldoMenu: editorOutput;
	oldMenu: editorOutput;

	listYears = [];

	wrongDataYears = [];

	constructor(
		private oneTimeService: OneTimeServices,
		private router: Router,
		private dialog: DialogService,
		private seasonsComponent: SeasonsComponent
	) {}

	ngOnInit() {
		this.getMenu();
		/* this.gListYears(); */
		/* this.getCabinList(); */
		/* this.getLevelList(); */
	}

	// Filtro
	displayFn(user): string {
		return user && user.elementCode ? user.elementCode : '';
	}

	private _filter(nombre: string) {
		const filterValue = this._normalizeValue(nombre);

		return this.comboListElement.filter((option) => this._normalizeValue(option.elementCode).includes(filterValue));
	}

	private _normalizeValue(value: string): string {
		return value.toLowerCase().replace(/\s/g, '');
	}

	optionFilter() {
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map((value) => (typeof value === 'string' ? value : value.elementCode)),
			map((elementCode) => (elementCode ? this._filter(elementCode) : this.comboListElement.slice()))
		);
	}

	changeParameters(evento) {
		this.comboListElement = evento.elements;
		this.optionFilter();
		// variables para controlar que aparece y desaparece
	}

	changeElement(evento) {
		if (evento != null) {
			this.element = evento.elementCode;
			OneTimeContext.setBaselineElement(this.element);
			return evento.elementCode;
		} else {
			return '';
		}
	}

	// LLamada Servicios
	getLevelList() {
		this.oneTimeService.getLevelsAndElements().subscribe(
			(res) => {
				this.comboListLevel = res;
				if (this.comboListLevel) {
					const levelMenu = this.oldMenu ? this.oldMenu.level : OneTimeContext.getLevel();
					this.tupla = this.comboListLevel.filter((dato) => dato.level === levelMenu);
					this.level = this.tupla[0];
					OneTimeContext.setBaselineLevel(this.level.level);
					const elementMenu = this.oldMenu ? this.oldMenu.element : OneTimeContext.getElementCode();
					const tupla3 = this.level.elements.filter((dato) => dato.elementCode === elementMenu);
					this.changeParameters(this.tupla[0]);
					this.element = tupla3[0]['elementCode'];
					OneTimeContext.setBaselineElement(this.element);
					this.myControl.setValue(tupla3[0]);
				}
				this.getCabinList();
			},
			(err) => {
				console.log(err);
			}
		);
	}

	getCabinList() {
		this.oneTimeService.getCabins().subscribe(
			(res) => {
				this.comboListCabin = res;
				if (this.comboListCabin) {
					const cabinCode = this.oldMenu ? this.oldMenu.cabin : OneTimeContext.getCabinCode();
					let tupla2 = this.comboListCabin.filter((dato) => dato.code == cabinCode);
					this.cabin = tupla2[0];
					OneTimeContext.setBaselineCabinCode(this.cabin.code);
				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	getMenu() {
		const idSession = OneTimeContext.getSessionID();
		this.wrongDataYears = RESTRICTED_YEARS;
		this.oneTimeService.getLastMenu('season', idSession).subscribe(
			(res) => {
				if (res !== null) {
					this.oldMenu = res;
				}
				this.gListYears();
			},
			(err) => {
				console.log(err);
			}
		);
	}

	gListYears() {
		const dateActual: number = +moment(new Date()).format('YYYY');
		for (let index = dateActual - 4; index < dateActual + 5; index++) {
			this.listYears.push(index.toString());
		}
		const fixedDate = this.getFirstValidYear();
		this.fechaEditor = this.oldMenu ? this.oldMenu.editorYear : dateActual.toString();
		this.fechaBaseline = this.oldMenu ? this.oldMenu.baselineYear : fixedDate.toString();
		OneTimeContext.setEditorYear(this.fechaEditor);
		OneTimeContext.setBaselineYear(this.fechaBaseline);
		this.getLevelList();
	}

	getFirstValidYear() {
		let startYear: number = parseInt(moment().subtract(1, 'years').format('YYYY'));
		let endYear: number = parseInt(moment().format('YYYY'));
		this.wrongDataYears.sort((a, b) => a - b);
		let isValidYear = false;
		while (!isValidYear) {
			if (this.wrongDataYears.includes(startYear) || this.wrongDataYears.includes(endYear)) {
				startYear = startYear - 1;
				endYear = endYear - 1;
			} else {
				isValidYear = true;
			}
		}
		return startYear;
	}

	updateBaseline() {
		const baseline: filterBaseline = {
			level: this.level.level,
			element: this.element,
			cabin: this.cabin.code,
			year: this.fechaBaseline
		};
		this.respaldoMenu = {
			level: this.level.level,
			element: OneTimeContext.getBaselineElement(),
			cabin: this.cabin.code,
			baselineYear: this.fechaBaseline,
			editorYear: this.fechaEditor
		};
		if (
			this.wrongDataYears.includes(parseInt(this.fechaBaseline, 10)) ||
			this.wrongDataYears.includes(parseInt(this.fechaBaseline, 10) + 1)
		) {
			this.dialog
				.confirmDialog({
					title:
						'You are selecting ' +
						parseInt(this.fechaBaseline, 10) +
						' and ' +
						(parseInt(this.fechaBaseline, 10) + 1) +
						' as your baseline range',
					message: 'Do you want to continue?',
					confirmCaption: 'Continue',
					cancelCaption: 'Go Back',
					typeDialog: 'warning'
				})
				.subscribe((confirm) => {
					if (confirm) {
						this.infoMenu.emit(this.respaldoMenu);
						this.clickUpdateBaseline.emit(baseline);
					}
				});
		} else {
			this.infoMenu.emit(this.respaldoMenu);
			this.clickUpdateBaseline.emit(baseline);
		}
	}

	calculate() {
		this.respaldoMenu = {
			level: this.level.level,
			element: OneTimeContext.getBaselineElement(),
			cabin: this.cabin.code,
			baselineYear: this.fechaBaseline,
			editorYear: this.fechaEditor
		};
		this.infoMenu.emit(this.respaldoMenu);
		this.seasonsComponent.calculateModelOutputs(
			this.cabin.code,
			this.element,
			this.level.level,
			this.fechaEditor,
			this.fechaBaseline
		);
	}

	updateEditor() {
		this.respaldoMenu = {
			level: this.level.level,
			element: OneTimeContext.getBaselineElement(),
			cabin: this.cabin.code,
			baselineYear: this.fechaBaseline,
			editorYear: this.fechaEditor
		};
		// const tmpElement = OneTimeContext.getBaselineElement ();
		const output: editorOutput = {
			level: this.level.level,
			element: OneTimeContext.getBaselineElement(),
			cabin: this.cabin.code,
			baselineYear: this.fechaBaseline,
			editorYear: this.fechaEditor
		};
		this.infoMenu.emit(this.respaldoMenu);
		this.seasonsComponent.updateEditor(output);
	}
}
