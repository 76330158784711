import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'app-SnackbarParameter',
	templateUrl: './SnackbarParameter.component.html',
	styleUrls: ['./SnackbarParameter.component.scss'],
	providers: []
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SnackbarParameter implements OnInit {
	titleText = 'Errors were detected while saving! Correct the following errors and try again';
	dataMessageErr: any;

	constructor(public sbRef: MatSnackBarRef<SnackbarParameter>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
		this.dataMessageErr = data.error;
	}

	ngOnInit() {
		this.data = 'Errors were detected while saving! Correct the following errors and try again';
	}

	cerrarDialogo(): void {
		this.sbRef.dismiss();
	}
}
