export const FormMenu = {
	baseline: 'baseline',
	model: 'model',
	switchView: 'switchView'
};

export const BaselineInput = {
	level: 'level',
	element: 'element',
	cabin: 'cabin',
	startDate: 'startDate',
	endDate: 'endDate',
	seasons: 'seasons',
	monthly: 'monthly',
	startDateAsk: 'startDateAsk',
	endDateAsk: 'endDateAsk'
};

export const ModelInput = {
	model: 'model',
	activate: 'activate',
	startDateAct: 'startDateAct',
	endDateAct: 'endDateAct',
	minimumFare: 'minimumFare',
	bPb1Pivot: 'bPb1Pivot',
	numberOfBuckets: 'numberOfBuckets'
};

export const DATE_FORMAT_MENU = {
	parse: {
		dateInput: ['YYYY-MM-DD', 'DD-MM-YYYY']
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	}
};
