export class RowCalendar<T> {
	constructor(
		public days: string,
		public week: number,
		public mon_data: T,
		public tue_data: T,
		public wed_data: T,
		public thu_data: T,
		public fri_data: T,
		public sat_data: T,
		public sun_data: T
	) {}
}
