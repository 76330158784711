import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DataPlotResponse, GraphPoints, InfoGraf } from 'src/app/models/buckets/PlotBuckets';

interface ClusterColor {
	cluster: number;
	color: string;
}

const colors = [
	'#FF3D00',
	'#99CC33',
	'#008BFF',
	'#D236BB',
	'#FFC700',
	'#401279',
	'#24C2B9',
	'#F20B0B',
	'#11837C',
	'#E55CB4',
	'#5C7A1F',
	'#DA9901',
	'#DD5252',
	'#4658DF',
	'#9452FF',
	'#BC6C15',
	'#FA7950',
	'#99E1DE',
	'#7200FF',
	'#5C5C5C'
];

const PlotsBucketsInputs = {
	buckets_data_plots: 'buckets_data_plots'
};

@Component({
	selector: 'app-plots-buckets',
	templateUrl: './plots-buckets.component.html',
	styleUrls: ['./plots-buckets.component.scss']
})
export class PlotsBucketsComponent implements OnInit {
	bucketsDataGraph: Array<Array<GraphPoints>> = [];

	bucketsData: Array<DataPlotResponse> = [];

	@Input(PlotsBucketsInputs.buckets_data_plots) set setDataPLots(data: Array<DataPlotResponse>) {
		this.bucketsData = data;

		this.bucketsDataGraph = data.map((plot) => this.dataGraph(plot));
	}

	clusters_colors: Array<ClusterColor> = [];
	clustersUnicos: Array<string> = [];
	titlePlot: Array<InfoGraf> = [];

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit() {
		this.calcClusters();
		this.changeDetectorRef.detectChanges();
	}

	calcClusters() {
		for (var i = 0; i < this.bucketsData.length; i++) {
			this.titlePlot = [
				...this.titlePlot,
				{ name: this.bucketsData[i].name, ylabel: this.bucketsData[i].ylabel, xlabel: this.bucketsData[i].xlabel }
			];
		}

		for (var i = 0; i < this.bucketsData[0].plots.length; i++) {
			let elemento = this.bucketsData[0].plots[i].cluster;
			if (!this.clustersUnicos.includes(elemento)) {
				this.clustersUnicos = [...this.clustersUnicos, elemento];
			}
		}

		for (var i = 0; i < this.clustersUnicos.length; i++) {
			this.clusters_colors = [...this.clusters_colors, { cluster: parseInt(this.clustersUnicos[i]), color: colors[i] }];
		}
	}

	dataGraph(value: DataPlotResponse): Array<GraphPoints> {
		let punto: GraphPoints;
		let graf: Array<GraphPoints> = [];

		for (let i = 0; i < value.plots.length; i++) {
			for (let j = 0; j < value.plots[i].data.x.length; j++) {
				punto = {
					nameLine: value.plots[i].cluster,
					ap: this.getValue(value.xlabel, value.plots[i].data.x[j]),
					porc: this.getValue(value.ylabel, value.plots[i].data.y[j])
				};
				graf = [...graf, punto];
			}
		}
		return graf;
	}

	getValue(label: string, value: number): number {
		if (label == 'Load Factor') {
			const tmp = Math.round(value * 1000);
			return tmp / 10.0;
		} else {
			return Math.round(value);
		}
	}
}
