import { Injectable } from '@angular/core';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { RemCalendarService } from '@services/calendars/rem-calendar.service';
import { OneTimeServices } from '@services/one-time.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Bounds } from 'src/app/models/Bounds';
import { createCalendars, PeriodCalendarStructure } from '../../models/calendars/PeriodCalendarStructure';

@Injectable({
	providedIn: 'root'
})
export class OutputModelCalendarService {
	constructor(private oneTimeService: OneTimeServices, private remCalendarService: RemCalendarService) {}

	loadInCalendar(calendar: PeriodCalendarStructure<string>, dateRangeCellData: any[]) {
		const formater = DateTimeFormatter.ofPattern('yyyy-MM-dd');

		for (var dailyCellData of dateRangeCellData) {
			const dt = LocalDate.parse(dailyCellData.flight_date, formater);

			const value = dailyCellData.season;

			calendar.updateOneCell(dt, value);
		}
	}

	generateIndicatorCalendars(
		initDate: LocalDate,
		endDate: LocalDate,
		cabinCode: string,
		elementCode: string,
		year: number,
		level: string,
		baseLineLevel: string,
		baseLineElementCode: string,
		baseLineCabinCode: string,
		baseLineYear: number,
		monthsNumber: number
	): Observable<Bounds<PeriodCalendarStructure<string>>> {
		return this.oneTimeService
			.getModelOutputs(
				cabinCode,
				elementCode,
				year,
				level,
				baseLineLevel,
				baseLineElementCode,
				baseLineCabinCode,
				baseLineYear
			)
			.pipe(
				map((response: any) => {
					console.log('getModelOutputs');
					console.log(response);

					const inboundModelOutputCalendars = createCalendars(initDate.year(), initDate.monthValue(), monthsNumber, '');
					const outboundModelOutputCalendars = createCalendars(
						initDate.year(),
						initDate.monthValue(),
						monthsNumber,
						''
					);

					const inboundData = response.filter((s) => s.bound === 'INBOUND');
					const outboundData = response.filter((s) => s.bound === 'OUTBOUND');

					this.loadInCalendar(inboundModelOutputCalendars, inboundData);
					this.loadInCalendar(outboundModelOutputCalendars, outboundData);

					return {
						outbound: outboundModelOutputCalendars,
						inbound: inboundModelOutputCalendars
					};
				})
			);
	}
}
