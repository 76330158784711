import { ParameterDetail } from './ParameterDetail';

export class Parameter {
	parameterId: number;
	businessUnitId: number;
	productId: number;
	parameterName: string;
	productName: string;
	parameterDetail: ParameterDetail;
	lastUpdateUser: string;
	lastUpdateDate: Date;
}
