<div class="parameters">
	<div class="second">
		<form
			(ngSubmit)="LoadSerch(level.level, level.levelId, myControl.value, cabin, true)"
			name="loadform"
			#loadTableP="ngForm"
		>
			<div class="header-principal source">
				<div class="row rowPropio">
					<div class="titulo">
						<mat-icon aria-hidden="false" class="icono">arrow_right</mat-icon>
						Editor
					</div>
				</div>
				<div class="row rowPropio">
					<div class="elementoFiltro">
						<mat-form-field>
							<mat-label>Level</mat-label>
							<mat-select [(value)]="level" (valueChange)="changeParameters($event)">
								<mat-option [value]="option" *ngFor="let option of comboListLevel">{{ option.level }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="elementoFiltro">
						<mat-form-field>
							<mat-label>Element</mat-label>
							<input type="text" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" />
							<mat-autocomplete
								autoActiveFirstOption
								#auto="matAutocomplete"
								[displayWith]="changeElement"
								(closed)="closedSelected()"
							>
								<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
									{{ option.elementCode }}
								</mat-option>
							</mat-autocomplete>
						</mat-form-field>
					</div>
					<div class="elementoFiltro">
						<mat-form-field>
							<mat-label>Cabin</mat-label>
							<mat-select [(value)]="cabin">
								<mat-option [value]="option" *ngFor="let option of comboListCabin">
									{{ option.description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<button type="submit" class="btn-search" [disabled]="!level || !myControl.value || !cabin">Load</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
