<app-card-container [actionsView]="false">
	<ng-container content>
		<div class="content-descriptive">
			<h3 class="title-descriptive">Descriptive statistics</h3>
			<hot-table
				[settings]="tableSettings"
				[columns]="columns"
				[data]="data"
				stretchH="all"
				class="hot handsontable htColumnHeaders"
			>
			</hot-table>
		</div>
	</ng-container>
</app-card-container>
