import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClustersSaveBffIn, DataClusters, DataIndicators } from '@services/clusters/indicator-cluster.service';
import { BehaviorSubject } from 'rxjs';
import { Bounds } from 'src/app/models/Bounds';
import { copyGridTod, createGridTod, GridTod, zip2GridTod } from 'src/app/models/clusters/GridTod';

export interface AllGrids {
	clusters: Bounds<GridTod<number>>;
	rask: Bounds<GridTod<number>>;
	yield: Bounds<GridTod<number>>;
	load_factor: Bounds<GridTod<number>>;
	short_ap_pax: Bounds<GridTod<number>>;
	international_connections: Bounds<GridTod<number>>;
	domestic_connections: Bounds<GridTod<number>>;
	// model_output: Bounds <GridTod<number>>,
	// baseline_clusters: Bounds <GridTod<number>>
}

const arr_dow_attr = Array('', 'mon_data', 'tue_data', 'wed_data', 'thu_data', 'fri_data', 'sat_data', 'sun_data');

/*
 * El componente padre de todas las grillas que se desplegan en la pagina de "clusters"
 *
 * Procesa los eventos de los botones, emite eventos al componente padre si es necesario
 */

const CardTodDowInputs = {
	data_clusters: 'data_clusters',
	data_baseline_clusters: 'data_baseline_clusters',
	data_indicators: 'data_indicators',
	data_grid_model_output: 'data_grid_model_output'
};

@Component({
	selector: 'app-card-tod-dow',
	templateUrl: './card-tod-dow.component.html',
	styleUrls: ['./card-tod-dow.component.scss']
})
export class CardTodDowComponent implements OnInit {
	@Output() seasonImportClusterChangeEvent = new EventEmitter<string>();

	@Output() saveEvent = new EventEmitter<[string, Array<ClustersSaveBffIn>]>();

	// disparado cada vez que se actualiza la grilla de clusters
	@Output() updateGridClustersEvent = new EventEmitter<Bounds<GridTod<number>>>();
	// disparado cada vez que se actualizan las grillas de indicadores
	@Output() updateAllGridsEvent = new EventEmitter<AllGrids>();

	@Input() seasons: string[] = [];
	@Input() disableBtnKpi = true;

	@Input() resetStatus: BehaviorSubject<string>;

	source_type = '';

	data_clusters: DataClusters;
	sendSnackbarActive: boolean;

	@Input(CardTodDowInputs.data_clusters) set setDataClusters(data: DataClusters) {
		this.data_clusters = data;

		if (typeof data !== 'undefined') {
			this.calcDataGridsClusters(data);

			this.updateGridClustersEvent.emit(this.data_grid_clusters);
		}
	}

	@Input() baseline_clusters_visible = false;
	data_baseline_clusters: DataClusters;
	@Input(CardTodDowInputs.data_baseline_clusters) set setDataBaselineClusters(data: DataClusters) {
		this.data_baseline_clusters = data;

		if (typeof data !== 'undefined') {
			this.calcDataGridsBaselineClusters(data);
		}
	}

	data_indicators: DataIndicators;
	@Input(CardTodDowInputs.data_indicators) set setDataIndicators(data: DataIndicators) {
		this.data_indicators = data;

		if (typeof data !== 'undefined') {
			this.calcDataGridsIndicators(data);

			this.emitDataGridLoadedOrChanged();
		} else {
			// usado cuando se manda a recalcular los indicadores
			this.data_grid_rask = undefined;
			this.data_grid_yield = undefined;
			this.data_grid_load_factor = undefined;
			this.data_grid_short_ap_pax = undefined;
			this.data_grid_international_connecting = undefined;
			this.data_grid_domestic_connecting = undefined;
			this.data_grid_baseline_ask = undefined;
			this.data_grid_low_mix = undefined;
			this.data_grid_posted_flights = undefined;
			this.data_grid_target_ask = undefined;
		}
	}

	data_grid_model_output: Bounds<GridTod<number>>;
	@Input(CardTodDowInputs.data_grid_model_output) set setDataGridModelOutput(data: Bounds<GridTod<number>>) {
		this.data_grid_model_output = data;

		if (typeof data !== 'undefined') {
			// selecciona la grilla de model output
			const values_selected = this.selectIndicadores.value as Array<string>;

			if (values_selected === null || !values_selected.includes(this.optionIndicators[11])) {
				const other_selected =
					values_selected === null || values_selected.length === 0 ? this.optionIndicators[0] : values_selected[0];

				this.selectIndicadores.setValue([this.optionIndicators[11], other_selected]);
			}
		}
	}

	/*
	 * una vez que todas las grillas fueron recalculadas, se avisa al componente padre para que refresque los graficos
	 *
	 * se usa tb cuando el usuario edita la grilla de cluster y apreta el boton UpdateKPIs
	 */
	emitDataGridLoadedOrChanged() {
		if (typeof this.data_clusters !== 'undefined' && typeof this.data_indicators !== 'undefined') {
			const allGrids: AllGrids = {
				clusters: this.data_grid_clusters,
				rask: this.data_grid_rask,
				yield: this.data_grid_yield,
				load_factor: this.data_grid_load_factor,
				short_ap_pax: this.data_grid_short_ap_pax,
				international_connections: this.data_grid_international_connecting,
				domestic_connections: this.data_grid_domestic_connecting
				// atm la data de las otras grillas no entran en los graficos
			};

			this.updateAllGridsEvent.emit(allGrids);
		}
	}

	data_grid_rask_selected = true;
	data_grid_yield_selected = false;
	data_grid_load_factor_selected = true;
	data_grid_short_ap_pax_selected = false;
	data_grid_international_connecting_selected = false;
	data_grid_domestic_connecting_selected = false;
	data_grid_baseline_ask_selected = false;
	data_grid_low_mix_selected = false;
	data_grid_posted_flights_selected = false;
	data_grid_target_ask_selected = false;
	data_grid_baseline_clusters_selected = false;
	data_grid_model_output_selected = false;

	// modelo que se despliega
	element_code: Bounds<string> = { outbound: '', inbound: '' };

	data_grid_clusters: Bounds<GridTod<number>>;
	data_grid_clusters_raw: Bounds<GridTod<number>>;
	data_grid_baseline_clusters: Bounds<GridTod<number>>;

	selectIndicadores = new UntypedFormControl();
	// ojo, si se cambia el orden, hay que cambiar logica en ngOnInit
	optionIndicators: string[] = [
		'RASK',
		'Yield',
		'Load Factor',
		'Short AP Pax',
		'International Connections',
		// 5
		'Domestic Connections',
		'Mix Low',
		'Posted Flights',
		'Target ASK',
		'Baseline  ASK',
		// 10
		'Baseline Clusters',
		'Model Output'
	];

	selectSeasonImportCluster = new UntypedFormControl();

	//
	data_grid_rask: Bounds<GridTod<number>> | undefined;
	data_grid_yield: Bounds<GridTod<number>> | undefined;
	data_grid_load_factor: Bounds<GridTod<number>> | undefined;
	data_grid_short_ap_pax: Bounds<GridTod<number>> | undefined;
	data_grid_international_connecting: Bounds<GridTod<number>> | undefined;
	data_grid_domestic_connecting: Bounds<GridTod<number>> | undefined;
	data_grid_baseline_ask: Bounds<GridTod<number>> | undefined;
	data_grid_low_mix: Bounds<GridTod<number>> | undefined;
	data_grid_posted_flights: Bounds<GridTod<number>> | undefined;
	data_grid_target_ask: Bounds<GridTod<number>> | undefined;

	constructor(private snackBar: MatSnackBar) {}

	ngOnInit() {
		this.resetStatus.asObservable().subscribe((value) => {
			if (value === 'reset') {
				this.source_type = '';
			}
		});

		this.selectIndicadores.valueChanges.subscribe(this.onIndicadoresValueChanged);

		this.selectSeasonImportCluster.valueChanges.subscribe(this.onSeasonImportClusterValueChanged);
	}

	onIndicadoresValueChanged = (value: any): void => {
		console.log('Cambio indicadores ', value);

		const arr = value as Array<string>;

		if (arr.length === 2) {
			this.data_grid_rask_selected = arr.includes(this.optionIndicators[0]);
			this.data_grid_yield_selected = arr.includes(this.optionIndicators[1]);
			this.data_grid_load_factor_selected = arr.includes(this.optionIndicators[2]);
			this.data_grid_short_ap_pax_selected = arr.includes(this.optionIndicators[3]);
			this.data_grid_international_connecting_selected = arr.includes(this.optionIndicators[4]);

			this.data_grid_domestic_connecting_selected = arr.includes(this.optionIndicators[5]);
			this.data_grid_low_mix_selected = arr.includes(this.optionIndicators[6]);
			this.data_grid_posted_flights_selected = arr.includes(this.optionIndicators[7]);
			this.data_grid_target_ask_selected = arr.includes(this.optionIndicators[8]);
			this.data_grid_baseline_ask_selected = arr.includes(this.optionIndicators[9]);

			this.data_grid_baseline_clusters_selected = arr.includes(this.optionIndicators[10]);
			this.data_grid_model_output_selected = arr.includes(this.optionIndicators[11]);
		}
	};

	onSeasonImportClusterValueChanged = (value: any): void => {
		const season = value as string;
		this.source_type = 'other season';
		this.seasonImportClusterChangeEvent.emit(season);
	};

	/*
	 *
	 */
	onClusterChanged() {
		console.log('Cluster modificado : ');

		this.disableBtnKpi = false;
	}

	// callback cuando se clickea el boton "Update KPI's"
	onUpdateKpisClicked() {
		console.log('onUpdateKpisClicked', this.data_clusters);

		if (typeof this.data_clusters !== 'undefined' && typeof this.data_indicators !== 'undefined') {
			const allGrids: AllGrids = {
				clusters: this.data_grid_clusters,
				rask: this.data_grid_rask,
				yield: this.data_grid_yield,
				load_factor: this.data_grid_load_factor,
				short_ap_pax: this.data_grid_short_ap_pax,
				international_connections: this.data_grid_international_connecting,
				domestic_connections: this.data_grid_domestic_connecting
				// atm la data de las otras grillas no entran en los graficos
			};

			// para actualizar la tabla de estadisticas descriptivas
			this.updateGridClustersEvent.emit(this.data_grid_clusters);

			// para actualizar los graficos
			this.updateAllGridsEvent.emit(allGrids);
		}

		this.disableBtnKpi = true;
	}

	/*
	 *
	 */
	save() {
		console.log('save ()');

		function convertOutbount2BffIn(
			tod_start: string,
			tod_end: string,
			dow: number,
			cluster: number,
			cluster_raw: number
		): ClustersSaveBffIn {
			const ret: ClustersSaveBffIn = {
				bound: 'outbound',
				todStart: tod_start,
				todEnd: tod_end,
				dow: dow,
				cluster: cluster,
				clusterRaw: cluster_raw
			};

			return ret;
		}

		function convertInbount2BffIn(
			tod_start: string,
			tod_end: string,
			dow: number,
			cluster: number,
			cluster_raw: number
		): ClustersSaveBffIn {
			const ret: ClustersSaveBffIn = {
				bound: 'inbound',
				todStart: tod_start,
				todEnd: tod_end,
				dow: dow,
				cluster: cluster,
				clusterRaw: cluster_raw
			};

			return ret;
		}

		const outbounds = zip2GridTod(
			this.data_grid_clusters.outbound,
			this.data_grid_clusters_raw.outbound,
			convertOutbount2BffIn
		);
		const inbounds = zip2GridTod(
			this.data_grid_clusters.inbound,
			this.data_grid_clusters_raw.inbound,
			convertInbount2BffIn
		);

		const clusters2save = outbounds.concat(inbounds);
		const cluster2saveValid = clusters2save.filter((f) => isNaN(f.cluster) || f.cluster === null);

		if (cluster2saveValid.length === 0) {
			this.saveEvent.emit([this.source_type, clusters2save]);
		} else {
			const msg = 'All DOW, TOD must have Clusters';
			this.openSnackBarIncorrect(msg);
		}
	}

	/*
	 *
	 */
	applyModel() {
		console.log('apply model');

		if (typeof this.data_grid_model_output !== 'undefined') {
			this.data_grid_clusters.outbound = copyGridTod(this.data_grid_model_output.outbound);
			this.data_grid_clusters.inbound = copyGridTod(this.data_grid_model_output.inbound);
			this.data_grid_clusters_raw.outbound = copyGridTod(this.data_grid_model_output.outbound);
			this.data_grid_clusters_raw.inbound = copyGridTod(this.data_grid_model_output.inbound);

			this.disableBtnKpi = false;

			this.source_type = 'model';

			this.openSnackBarCorrect('Model output successfully copied to Clusters Editor');
		} else {
			const msg = 'Unable to copy Model output';

			this.openSnackBarIncorrect(msg);
		}
	}

	/*
	 *
	 */
	calcDataGridsClusters(data: DataClusters) {
		console.log('Recalcula grilla clusters');

		const tuple = this.fromDataClusters(data);

		this.data_grid_clusters = tuple[0];

		this.data_grid_clusters_raw = tuple[1];

		this.element_code.outbound = data.outbound.leg;
		this.element_code.inbound = data.inbound.leg;
	}

	/*
	 *
	 */
	calcDataGridsBaselineClusters(data: DataClusters) {
		console.log('Recalcula grilla baseline clusters');

		const tuple = this.fromDataClusters(data);

		this.data_grid_baseline_clusters = tuple[0];
	}

	/*
	 *
	 */
	fromDataClusters(data: DataClusters): [Bounds<GridTod<number>>, Bounds<GridTod<number>>] {
		const data_grid_clusters = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		const data_grid_clusters_raw = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);

		data.outbound.cells.forEach((row) => {
			const dow_attr = arr_dow_attr[row.dow];
			data_grid_clusters.outbound.updateValue(row.tod, dow_attr, row.cluster);
			data_grid_clusters_raw.outbound.updateValue(row.tod, dow_attr, row.cluster_raw);
		});

		data.inbound.cells.forEach((row) => {
			const dow_attr = arr_dow_attr[row.dow];
			data_grid_clusters.inbound.updateValue(row.tod, dow_attr, row.cluster);
			data_grid_clusters_raw.inbound.updateValue(row.tod, dow_attr, row.cluster_raw);
		});

		return [data_grid_clusters, data_grid_clusters_raw];
	}

	/*
	 *
	 */
	calcDataGridsIndicators(data: DataIndicators) {
		console.log('Recalcula grillas indicadores');

		console.log(data);

		// recrea data vacia
		this.data_grid_rask = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		this.data_grid_yield = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		this.data_grid_load_factor = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		this.data_grid_short_ap_pax = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		this.data_grid_international_connecting = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		this.data_grid_domestic_connecting = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		this.data_grid_baseline_ask = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		this.data_grid_low_mix = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		this.data_grid_posted_flights = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);
		this.data_grid_target_ask = this.createDataGridNumber(data.outbound.franjas, data.inbound.franjas);

		const outbound = data.outbound.cells;
		const inbound = data.inbound.cells;

		outbound.forEach((row) => {
			const dow_attr = arr_dow_attr[row.dow];
			this.data_grid_rask.outbound.updateValue(row.tod, dow_attr, row.rask);
			this.data_grid_yield.outbound.updateValue(row.tod, dow_attr, row.yield);
			this.data_grid_load_factor.outbound.updateValue(row.tod, dow_attr, row.loadFactor);
			this.data_grid_short_ap_pax.outbound.updateValue(row.tod, dow_attr, row.shortAP);
			this.data_grid_international_connecting.outbound.updateValue(row.tod, dow_attr, row.internationalConnectingPax);
			this.data_grid_domestic_connecting.outbound.updateValue(row.tod, dow_attr, row.domConnectingPax);
			this.data_grid_baseline_ask.outbound.updateValue(row.tod, dow_attr, row.baselineAsk);
			this.data_grid_low_mix.outbound.updateValue(row.tod, dow_attr, row.lowMix);
			this.data_grid_posted_flights.outbound.updateValue(row.tod, dow_attr, row.postedFlights);
			this.data_grid_target_ask.outbound.updateValue(row.tod, dow_attr, row.targetAsk);
		});

		inbound.forEach((row) => {
			const dow_attr = arr_dow_attr[row.dow];
			this.data_grid_rask.inbound.updateValue(row.tod, dow_attr, row.rask);
			this.data_grid_yield.inbound.updateValue(row.tod, dow_attr, row.yield);
			this.data_grid_load_factor.inbound.updateValue(row.tod, dow_attr, row.loadFactor);
			this.data_grid_short_ap_pax.inbound.updateValue(row.tod, dow_attr, row.shortAP);
			this.data_grid_international_connecting.inbound.updateValue(row.tod, dow_attr, row.internationalConnectingPax);
			this.data_grid_domestic_connecting.inbound.updateValue(row.tod, dow_attr, row.domConnectingPax);
			this.data_grid_baseline_ask.inbound.updateValue(row.tod, dow_attr, row.baselineAsk);
			this.data_grid_low_mix.inbound.updateValue(row.tod, dow_attr, row.lowMix);
			this.data_grid_posted_flights.inbound.updateValue(row.tod, dow_attr, row.postedFlights);
			this.data_grid_target_ask.inbound.updateValue(row.tod, dow_attr, row.targetAsk);
		});
	}

	private createDataGridNumber(
		franjas_outbound: Array<number>,
		franjas_inbound: Array<number>
	): Bounds<GridTod<number>> {
		return {
			outbound: createGridTod(franjas_outbound, NaN),
			inbound: createGridTod(franjas_inbound, NaN)
		};
	}

	isBtnApplyModelDisabled(): boolean {
		return !this.data_grid_model_output_selected;
	}

	maxOptionDisabled(opt: any): boolean {
		if (this.selectIndicadores.value !== null) {
			return this.selectIndicadores.value.length === 2 && !this.selectIndicadores.value.find((el) => el === opt);
		}
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackIncorrect']
		});
	}
}
