import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { colores } from 'src/app/core/constants/handsontable.constant';

import { D3DaySeasonValueRenderer } from './D3-day-season-value-scatterplot/D3DaySeasonValueRenderer';

// ----------------------------------------------------------------------------
// TODO: Mover a una variable comun al calendario
// ----------------------------------------------------------------------------

let nextId = 0;

@Component({
	selector: 'app-dia-season-valor-scatterplot',
	templateUrl: './dia-season-valor-scatterplot.component.html',
	styleUrls: ['./dia-season-valor-scatterplot.component.scss']
})
export class DiaSeasonValorScatterplotComponent implements OnChanges, AfterViewInit {
	@Input() outbound_data: Array<D3DaySeasonValueRenderer.SeasonPoint>;
	@Input() inbound_data: Array<D3DaySeasonValueRenderer.SeasonPoint>;

	@Input() title: string;

	@Input() out_in: number;
	@Input() year_from: number;
	@Input() month_from: number;
	@Input() year_to: number;
	@Input() month_to: number;

	ph_id = `ph_d3_day_season_value_${nextId++}`;

	initialized = false;

	private colores = colores;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.initialized) {
			this.plot();
		}
	}

	ngAfterViewInit() {
		this.plot();

		this.initialized = true;
	}

	plot() {
		const colors = new Map(Object.keys(this.colores).map((season) => [season, this.colores[season]]));

		D3DaySeasonValueRenderer.plot(
			this.out_in,
			this.outbound_data,
			this.inbound_data,
			this.year_from,
			this.month_from,
			this.year_to,
			this.month_to,
			colors,
			this.ph_id,
			1022,
			300
		);
	}
}
