import * as d3 from 'd3';

// eslint-disable-next-line @typescript-eslint/no-namespace
export module D3TargetCurveRenderer {
	export interface GraphPoints {
		type_id: number;
		cluster: number; // 0 -> Total
		nameLine: string;
		ap: number;
		porc: number;
	}

	export class Point {
		constructor(public ap: number, public porc: number) {}
	}

	export class TargetCurve {
		constructor(public type_id: number, public cluster: number, public name: string, public points: Array<Point>) {}
	}

	class Margin {
		constructor(public top: number, public right: number, public bottom: number, public left: number) {}
	}

	// const glines;
	// const mouseG;
	// const tooltip;

	const margin = { top: 20, right: 35, bottom: 23, left: 35 };
	const width = 750 - margin.left - margin.right;
	const height = 350 - margin.top - margin.bottom;

	const lineOpacity = 1;
	const lineStroke = '2px';

	const axisPad = 10; // axis formatting
	const R = 6; // legend marker

	export function getColor(colors: Array<string>, row: number, col: number): string {
		return colors[row * 10 + col];
	}

	export function plot(
		data: Array<D3TargetCurveRenderer.GraphPoints>,
		placeholder: string,
		colors: Array<string>,
		_divWidth: number,
		divHeight: number,
		unicos
	) {
		const color = d3.scaleOrdinal().domain(unicos).range(colors.slice(0, unicos.length));

		const xScale = d3
			.scaleLinear()
			.domain([0, d3.max(data, (d) => d.ap)])
			.range([0, width]);

		const yScale = d3.scaleLinear().domain([0, 100]).range([height, 0]);

		const div = d3.select('#' + placeholder);

		div.select('svg').remove();
		div.select('div').remove(); // el tooltip

		const svg = div
			.append('svg')
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		// CREATE AXES //
		// render axis first before lines so that lines will overlay the horizontal ticks
		const xAxis = d3.axisBottom(xScale);
		const yAxis = d3.axisLeft(yScale); // horizontal ticks across svg width

		svg
			.append('g')
			.attr('class', 'x axis')
			.attr('transform', `translate(0, ${height})`)
			.call(xAxis)
			.call((g) => {
				g.selectAll('text')
					.attr('transform', `translate(0, 5)`)
					.style('text-anchor', 'middle')
					.attr('y', axisPad)
					.attr('fill', '#A9A9A9');

				g.selectAll('line').attr('stroke', '#A9A9A9');

				g.select('.domain').attr('stroke', '#A9A9A9');
			});

		svg
			.append('g')
			.attr('class', 'y axis')
			.call(yAxis)
			.call((g) => {
				g.selectAll('text')
					.style('text-anchor', 'middle')
					.attr('x', -axisPad * 2)
					.attr('fill', '#A9A9A9');

				g.selectAll('line')
					.attr('stroke', '#A9A9A9')
					.attr('stroke-width', 0.7) // make horizontal tick thinner and lighter so that line paths can stand out
					.attr('opacity', 0.7);

				g.select('.domain').remove();
			})
			.append('text')
			.attr('x', 0)
			.attr('y', -10)
			.attr('fill', '#A9A9A9')
			.text('%'); // Titulo sobre eje y

		// CREATE LEGEND //

		// line generator
		const line = d3
			.line()
			.x((d) => xScale(d.ap))
			.y((d) => yScale(d.porc));

		const resNew = data;

		const res_nested = d3
			.nest() // necessary to nest data so that keys represent each vehicle category
			.key((d) => d.nameLine)
			.entries(resNew);

		// APPEND MULTIPLE LINES //
		let lines = svg.append('g').attr('class', 'lines');

		const glines = lines.selectAll('.line-group').data(res_nested).enter().append('g').attr('class', 'line-group');

		glines
			.append('path')
			.attr('class', 'line')
			.attr('d', (d) => line(d.values))
			.style('stroke', (d, i) => color(i))
			.style('fill', 'none')
			.style('opacity', lineOpacity)
			.style('stroke-width', lineStroke);

		// CREATE HOVER TOOLTIP WITH VERTICAL LINE //
		const tooltip = d3
			.select('#' + placeholder)
			.append('div')
			.attr('id', 'tooltip')
			.style('position', 'absolute')
			.style('background-color', '#f6f6ff')
			.style('padding', 6)
			.style('display', 'none');

		const mouseG = svg.append('g').attr('class', 'mouse-over-effects');

		mouseG
			.append('path') // create vertical line to follow mouse
			.attr('class', 'mouse-line')
			.style('stroke', '#A9A9A9')
			.style('stroke-width', lineStroke)
			.style('opacity', '0');

		lines = document.getElementsByClassName('line');

		const mousePerLine = mouseG
			.selectAll('.mouse-per-line')
			.data(res_nested)
			.enter()
			.append('g')
			.attr('class', 'mouse-per-line');

		mousePerLine
			.append('circle')
			.attr('r', 4)
			.style('stroke', (d) => {
				return color(d.key);
			})
			.style('fill', 'none')
			.style('stroke-width', lineStroke)
			.style('opacity', '0');

		mouseG
			.append('svg:rect') // append a rect to catch mouse movements on canvas
			.attr('width', width)
			.attr('height', height)
			.attr('fill', 'none')
			.attr('pointer-events', 'all')
			.on('mouseout', () => {
				// on mouse out hide line, circles and text
				d3.select('.mouse-line').style('opacity', '0');
				d3.selectAll('.mouse-per-line circle').style('opacity', '0');
				d3.selectAll('.mouse-per-line text').style('opacity', '0');
				d3.selectAll('#tooltip').style('display', 'none');
			})
			.on('mouseover', () => {
				// on mouse in show line, circles and text
				d3.select('.mouse-line').style('opacity', '1');
				d3.selectAll('.mouse-per-line circle').style('opacity', '1');
				d3.selectAll('#tooltip').style('display', 'block');
			})
			.on('mousemove', function () {
				// WARNING, no cambiar a arrow function, el tooltip no se muestra

				// update tooltip content, line, circles and text when mouse moves
				const mouse = d3.mouse(this);

				d3.selectAll('.mouse-per-line').attr('transform', (d) => {
					const xDate = xScale.invert(mouse[0]); // use 'invert' to get ap corresponding to distance from mouse position relative to svg
					const bisect = d3.bisector((d2) => {
						return d2.ap;
					}).left; // retrieve row index of ap on parsed csv
					const idx = bisect(d.values, xDate);

					d3.select('.mouse-line').attr('d', () => {
						let data2 = 'M' + xScale(d.values[idx].ap) + ',' + height;
						data2 += ' ' + xScale(d.values[idx].ap) + ',' + 0;
						return data2;
					});
					return 'translate(' + xScale(d.values[idx].ap) + ',' + yScale(d.values[idx].porc) + ')';
				});

				updateTooltipContent(tooltip, mouse, res_nested, xScale, color);
			});
	}

	function updateTooltipContent(tooltip, mouse, res_nested, xScale, color) {
		const sortingObj = [];
		res_nested.map((d) => {
			const xDate = xScale.invert(mouse[0]);
			const bisect = d3.bisector((d2) => {
				return d2.ap;
			}).left;
			const idx = bisect(d.values, xDate);
			sortingObj.push({ key: d.values[idx].nameLine, porc: d.values[idx].porc, ap: d.values[idx].ap });
		});

		sortingObj.sort((x, y) => {
			return d3.descending(x.porc, y.porc);
		});

		const sortingArr = sortingObj.map((d) => d.key);

		const s = d3.formatSpecifier('f');
		s.precision = d3.precisionFixed(0.1);
		const f = d3.format(s);

		tooltip
			.html(' AP: ' + sortingObj[0].ap)
			.style('padding', 10 + 'px')
			.style('display', 'block')
			.style('z-index', 99999)
			.style('left', mouse[0] + 60 + 'px') // d3.event.pageX + 20
			.style('top', mouse[1] - 250 + 'px') // d3.event.pageY - 20
			.style('font-size', 11.5)
			.selectAll()
			.data(res_nested)
			.enter() // for each vehicle category, list out name and price of porc
			.append('div')
			.style('color', (d) => {
				return color(d.key);
			})
			.style('font-size', 10)
			.style('width', 130 + 'px')
			.html((d) => {
				const xDate = xScale.invert(mouse[0]);
				const bisect = d3.bisector((d) => {
					return d.ap;
				}).left;
				const idx = bisect(d.values, xDate);
				return d.key + '  : ' + f(d.values[idx].porc) + ' %';
			});
	}
}
