import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fcalcColor } from '@services/calendars/calendar-color.service';
import Handsontable from 'handsontable';
import { GridKpiGeneric } from 'src/app/models/GridKpiGeneric';
import { cloneGridApClusters, GridApClusters } from 'src/app/models/target-curves/GridApClusters';

const ModelSettingsDetailInputs = {
	title: 'title',
	dataGridTargetCurves: 'data_grid_target_curves',
	kpis: 'kpis'
};
@Component({
	selector: 'app-model-settings-detail',
	templateUrl: './model-settings-detail.component.html',
	styleUrls: ['./model-settings-detail.component.scss']
})
export class ModelSettingsDetailComponent {
	title;
	@Input(ModelSettingsDetailInputs.title)
	set setTitle(value) {
		this.title = value;
	}

	@Output() target_curves_edited = new EventEmitter<void>();

	// TODO: extrear lista de clusters
	clusters: Array<number> = [];

	columns_width: Array<number> = [];

	data_grid_target_curves: GridApClusters | undefined = undefined;
	data_grid_target_curves_raw: GridApClusters | undefined = undefined;
	data_grid_target_curves_ready = false;
	@Input(ModelSettingsDetailInputs.dataGridTargetCurves)
	set setDataGridTargetcurves(data: GridApClusters) {
		if (typeof data !== 'undefined') {
			// TODO eliminar llamada a clone()
			// para hacer pruebas con distintos numeros de clusters
			this.data_grid_target_curves = data; // cloneGridApClustersPruebas(data, 10);

			this.data_grid_target_curves_raw = cloneGridApClusters(data);

			this.clusters = this.data_grid_target_curves.clusters;

			const widths = witdhCols(this.data_grid_target_curves.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width = [];
			// ap
			this.columns_width.push(width_ap);
			// clusters
			for (const cluster of this.data_grid_target_curves.clusters) {
				this.columns_width.push(width_cv);
			}
			// total
			this.columns_width.push(width_ap);

			this.data_grid_target_curves_ready = true;

			// atm, esta desactivado el select pq el servicio retorna solamente 2 indicadores
			/*
      const values_selected = this.selectIndicadores.value as Array<string>;

      if (values_selected === null || !values_selected.includes(this.optionIndicators[2])) {
        const other_selected =
          values_selected === null || values_selected.length === 0 ? this.optionIndicators[0] : values_selected[0];

        this.selectIndicadores.setValue([this.optionIndicators[1], other_selected]);
      }
      */
		}
	}

	kpi_1: GridKpiGeneric | undefined = undefined;
	@Input(ModelSettingsDetailInputs.kpis) set setKpi1(data: Array<GridKpiGeneric>) {
		this.kpi_1 = data[0];
	}

	// atm, esta desactivado el select pq el servicio retorna solamente 2 indicadores
	/* selectIndicadores = new FormControl(); */

	data_grid_model_selected = true;
	data_grid_rda1_selected = true;
	data_grid_rda2_selected = false;

	// atm, esta desactivado el select pq el servicio retorna solamente 2 indicadores
	// Nota: si se cambia el orden de las opciones, hay que modificar el codigo que selecciona los model output
	/* optionIndicators: string[] = ['Model Output', 'RDA 1', 'RDA 2']; */

	constructor() {}

	// atm, esta desactivado el select pq el servicio retorna solamente 2 indicadores
	/*   
  onIndicadoresValueChanged = (value: any): void => {
    const arr = value as Array<string>;

    if (arr.length === 2) {
      this.data_grid_model_selected = arr.includes(this.optionIndicators[0]);
      this.data_grid_rda1_selected = arr.includes(this.optionIndicators[1]);
      this.data_grid_rda2_selected = arr.includes(this.optionIndicators[2]);
    }
  };

  maxOptionDisabled(opt: any): boolean {
    if (this.selectIndicadores.value !== null) {
      return this.selectIndicadores.value.length === 2 && !this.selectIndicadores.value.find((el) => el === opt);
    }
  }
  */

	formatPP = (value: number) => {
		// const valuePorc = value * 100.0;
		return value.toFixed(1);
	};

	rendererPP = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridApClusters,
		data_grid_raw: GridApClusters | undefined,
		editable: boolean
	) => {
		this.rendererGrid(
			instance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			data_grid,
			data_grid_raw,
			this.formatPP,
			editable
		);
	};

	formatPax = (value: number) => {
		if (value < 1000) {
			return value.toFixed(0);
		} else if (value < 1000000) {
			const miles = value / 1000;
			if (value < 10000) {
				return miles.toFixed(1) + 'k';
			} else {
				return miles.toFixed(0) + 'k';
			}
		} else {
			const millones = value / 1000000;
			if (value < 10000000) {
				return millones.toFixed(1) + 'm';
			} else {
				return millones.toFixed(0) + 'm';
			}
		}
	};

	rendererPax = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridApClusters,
		data_grid_raw: GridApClusters | undefined,
		editable: boolean
	) => {
		this.rendererGrid(
			instance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			data_grid,
			data_grid_raw,
			this.formatPax,
			editable
		);
	};

	rendererGrid = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridApClusters,
		data_grid_raw: GridApClusters | undefined,
		formatNumber: (n: number) => string,
		editable: boolean
	) => {
		const emptyCellColor = '#F3F2F';

		if (typeof value === 'number') {
			if (Number.isNaN(value)) {
				td.style.backgroundColor = emptyCellColor;
				td.innerHTML = '';
			} else {
				let hay_cambio = false;
				let valueColor;

				if (typeof data_grid_raw !== 'undefined') {
					// esta logica asume que afterChange actualiza la data de la estructura antes
					const valueGrid = data_grid.getValue(row, Number(prop));
					const valueGridRaw = data_grid_raw.getValue(row, Number(prop));

					hay_cambio = valueGrid !== valueGridRaw;
				}

				if (hay_cambio) {
					td.style.borderRadius = '50px';
					td.style.borderColor = '#6C757D';
				}

				if (col <= data_grid.clusters.length && row < data_grid.aps_end.length) {
					const minValue = data_grid.min_value;
					const maxValue = data_grid.max_value;

					if (value > maxValue) {
						valueColor = fcalcColor(value, minValue, value, false);
					} else if (value < minValue) {
						valueColor = fcalcColor(value, value, maxValue, false);
					} else {
						valueColor = fcalcColor(value, minValue, maxValue, false);
					}

					const hexColor = valueColor.toHex();

					if (hexColor.length === 7) {
						td.style.backgroundColor = hexColor;
					}
				}

				const szValue = formatNumber(value as number);

				td.innerHTML = szValue;
			}
		}

		// TODO: falta hacer validacion con raw
		if (typeof value === 'string') {
			td.style.borderRadius = '50px';
			td.style.borderColor = '#6C757D';
		}
	};

	onTargetCurvesEdited = (): void => {
		this.target_curves_edited.emit();
	};
}

function witdhCols(num_clusters: number): [number, number] {
	if (num_clusters === 1) {
		return [120, 100];
	} else if (num_clusters === 2) {
		return [110, 75];
	} else if (num_clusters === 3) {
		return [100, 70];
	} else if (num_clusters === 4) {
		return [80, 60];
	} else if (num_clusters === 5) {
		return [80, 50];
	} else if (num_clusters <= 7) {
		return [60, 42];
	} else {
		return [52, 32]; // [49, 26]
	}
}
