<app-filtro-target
	(emite)="onLoad($event)"
	(newSession)="changeStateSession($event)"
	[restSession]="dataStorage"
	[dataSource]="listSessions"
></app-filtro-target>
<div class="container">
	<app-table-old-sessions
		(restSession)="onRestSession($event)"
		(newSession)="changeStateSession($event)"
		(listSessions)="updateListSessions($event)"
	></app-table-old-sessions>
	<app-card-calendar
		[disableView]="disableTablas"
		[filter]="seEnvia"
		[stateNewSession]="stateSession"
	></app-card-calendar>
</div>
