import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import { CalendarColorService } from '@services/calendars/calendar-color.service';
import Handsontable from 'handsontable';
import { colores } from 'src/app/core/constants/handsontable.constant';
import { ConfigService } from '../../config/config.service';

const CalendarRemInputs = {
	columns: 'columns',
	data: 'data'
};
@Component({
	selector: 'app-calendar-rem',
	templateUrl: './calendar-rem.component.html',
	styleUrls: ['./calendar-rem.component.scss']
})
export class CalendarRemComponent {

	constructor(private calendarColorService: CalendarColorService, private readonly configService: ConfigService) {}

	// Configuraciones Handsontable
	tableSettings: Handsontable.GridSettings = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		contextMenu: false,
		dropdownMenu: false,
		language: 'es-MX',
		className: 'htCenter',
		filters: false,
		columnSorting: false,
		allowInsertRow: false,
		viewportColumnRenderingOffset: 1000,
		colWidths: 32,

		afterCut: (data, coords) => {
			const coordsObject = coords[0];
			for (let row = coordsObject.startRow; row < coordsObject.endRow + 1; row++) {
				for (let col = coordsObject.startCol; col < coordsObject.endCol + 1; col++) {
					const colName = this.columns[col].data;
					this.calendarData[row][colName] = this.originalData[row][colName].season;
				}
			}
		},
		beforeCopy: (data, coords) => {
			if (!this.readOnly && (coords[0].startCol === 0 || coords[0].startCol === 1)) {
				return false;
			}
		},
		beforeCut: (data, coords) => {
			return false;
		},

		afterChange: (changes, source) => {
			if (source === 'CopyPaste.paste' || source === 'Autofill.fill') {
				changes?.forEach((c) => {
					const row = c[0];
					const field = c[1] as string;
					const oldValue = c[2];
					let newValue = c[3];
					if (typeof newValue === 'string') {
						newValue = newValue.toUpperCase();
					}
					const cellColor = this.colores[newValue];

					if (oldValue === null || cellColor === undefined) {
						this.calendarData[row][field] = oldValue;
						this.columns = [...this.columns.map((col) => ({ ...col, renderer: this.rendererColumnTable }))];
					} else {
						this.calendarData[row][field] = newValue;
						this.mutatOriginalData(row, field, newValue);
						this.columns = [...this.columns.map((col) => ({ ...col, renderer: this.rendererColumnTable }))];
						if (oldValue !== newValue) {
							this.estadoEdit.emit(true);
						}
					}
				});
			}
			if (source === 'edit') {
				changes?.forEach((c) => {
					const row = c[0];
					const field = c[1] as string;
					const oldValue = c[2];
					let newValue = c[3];
					if (typeof newValue === 'string') {
						newValue = newValue.toUpperCase();
					}
					const cellColor = this.colores[newValue];
					if ((null === newValue && oldValue !== null) || cellColor === undefined) {
						this.calendarData[row][field] = oldValue;
						this.columns = [...this.columns.map((col) => ({ ...col, renderer: this.rendererColumnTable }))];
					} else if (null !== newValue) {
						this.calendarData[row][field] = newValue;
						this.mutatOriginalData(row, field, newValue);
						this.columns = [...this.columns.map((col) => ({ ...col, renderer: this.rendererColumnTable }))];
					}

					if (oldValue !== newValue) {
						this.estadoEdit.emit(true);
					}
				});
			}
		},
		beforeKeyDown: (event: KeyboardEvent) => {
			if (event.key === 'Delete' && this.keyDelete) {
				Handsontable.dom.stopImmediatePropagation(event);
				event.stopImmediatePropagation();
				this.cellDataSetRaw(this.originalData, this.hotInstance);
			}
		}
	};

	colores = colores;

	@Input() readOnly = true;
	@Input() rowHeaders = true;
	@Input() stretchH = 'all';
	@Input() calendarType = 'seasons';
	@Input() indicatorType = 'rask';
	@Input() keyDelete = false;

	@Input() minValue = null;
	@Input() maxValue = null;

	columns: any = [];
	@Input(CalendarRemInputs.columns)
	set setColumns(columns: any) {
		if (this.calendarType === 'numeric') {
			for (let i = 2; i < 9; i++) {
				columns[i].type = 'numeric';
				columns[i].numericFormat = {
					pattern: '%0.0'
				};
			}
		}
		this.columns = [...columns.map((col) => ({ ...col, renderer: this.rendererColumnTable }))];
		if (this.rowHeaders === false) {
			this.columns = this.columns.splice(2);
		}
	}

	calendarData: Array<any> = [];
	originalData: Array<any> = [];

	@Input(CalendarRemInputs.data)
	set setData(rowsData: any) {
		this.originalData = rowsData;
		rowsData.forEach((r) => {
			let mondata;
			let tuedata;
			let weddata;
			let thudata;
			let fridata;
			let satdata;
			let sundata;

			switch (this.calendarType) {
				case 'seasons':
					mondata = r.mon_data === null ? null : r.mon_data.season;
					tuedata = r.tue_data === null ? null : r.tue_data.season;
					weddata = r.wed_data === null ? null : r.wed_data.season;
					thudata = r.thu_data === null ? null : r.thu_data.season;
					fridata = r.fri_data === null ? null : r.fri_data.season;
					satdata = r.sat_data === null ? null : r.sat_data.season;
					sundata = r.sun_data === null ? null : r.sun_data.season;
					break;

				case 'numeric':
					mondata = r.mon_data === null ? null : r.mon_data;
					tuedata = r.tue_data === null ? null : r.tue_data;
					weddata = r.wed_data === null ? null : r.wed_data;
					thudata = r.thu_data === null ? null : r.thu_data;
					fridata = r.fri_data === null ? null : r.fri_data;
					satdata = r.sat_data === null ? null : r.sat_data;
					sundata = r.sun_data === null ? null : r.sun_data;
					break;

				case 'string':
					mondata = r.mon_data === null ? null : r.mon_data;
					tuedata = r.tue_data === null ? null : r.tue_data;
					weddata = r.wed_data === null ? null : r.wed_data;
					thudata = r.thu_data === null ? null : r.thu_data;
					fridata = r.fri_data === null ? null : r.fri_data;
					satdata = r.sat_data === null ? null : r.sat_data;
					sundata = r.sun_data === null ? null : r.sun_data;
					break;

				default:
					mondata = '';
					tuedata = '';
					weddata = '';
					thudata = '';
					fridata = '';
					satdata = '';
					sundata = '';
					break;
			}

			const calendarRow: any = {
				days: r.days,
				week: r.week,
				mon_data: mondata,
				tue_data: tuedata,
				wed_data: weddata,
				thu_data: thudata,
				fri_data: fridata,
				sat_data: satdata,
				sun_data: sundata
			};

			this.calendarData.push(calendarRow);
		});
	}

	@Output() estadoEdit = new EventEmitter<boolean>();

	mutatOriginalData(row: number, field: string | number, newValue: string): void {
		const originalDataSeasonRaw = this.originalData[row][field].season_raw;
		this.originalData[row][field] = { season: newValue, season_raw: originalDataSeasonRaw };
	}

	// TODO: se rompe el principio DRY, mover las siguienets 3 funciones a algo (clase/interface/mudulo) compartido

	// distintos format de numeric a string
	askFormatNumeric(value: number): string {
		const exp = value.toExponential(1).replace('+', '');
		const ret = exp[0] + '<span style = "font-size: 9px" ;>' + exp.substring(1, 4) + '</span>' + exp.substring(4);

		return ret;
	}

	twoDecimalFormatNumeric(value: number): string {
		return value.toFixed(2);
	}

	oneDecimalFormatNumeric(value: number): string {
		return value.toFixed(1);
	}

	percentFormatNumeric(value: number): string {
		const percent = value * 100.0;
		return percent.toFixed(1) + '%';
	}

	//
	formatNumeric(value: number): string {
		switch (this.indicatorType) {
			case 'rask':
				return this.oneDecimalFormatNumeric(value);
			case 'yield':
				return this.oneDecimalFormatNumeric(value);
			case 'load_factor':
				return this.percentFormatNumeric(value);
			case 'posted_flights':
				return this.percentFormatNumeric(value);
		}

		return 'error';
	}

	rendererColumnTable = (hotInstance, td, row, col, prop, value, cellProperties) => {
		const cellNullColor = '#B8B8B8';
		const emptyCellColor = '##ffffff';

		Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, col, prop, value, cellProperties]);
		td.style.color = '#1B0088';

		const dataCurrent = this.originalData.find((_, index) => index === row);
		const firstValidate = dataCurrent && dataCurrent[prop];
		const secondValidate = firstValidate === undefined || firstValidate === null;

		if (value === null && secondValidate) {
			td.style.backgroundColor = cellNullColor;
			cellProperties.editor = false;
		} else if (value === null && this.calendarData[row][prop] !== null) {
			if (this.calendarType === 'seasons') {
				value = this.calendarData[row][prop].season;
			}
		} else if (value === undefined) {
			td.style.backgroundColor = emptyCellColor;
		} else {
			const cellColor = this.colores[value];
			switch (this.calendarType) {
				case 'numeric':
					const szValue = this.formatNumeric(value);

					value = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2, maximumFractionDigits: 2 }).format(
						value
					);
					let valueColor;

					if (this.indicatorType === 'posted_flights') {
						valueColor = this.calendarColorService.calcColor(value, this.maxValue, this.minValue, false);
					} else {
						valueColor = this.calendarColorService.calcColor(value, this.minValue, this.maxValue, false);
					}
					const hexColor = valueColor.toHex();

					if (hexColor.length === 7) {
						td.style.backgroundColor = hexColor;
					}

					td.innerHTML = szValue;
					break;

				case 'string':
					td.style.backgroundColor = cellColor;

					break;
				case 'seasons':
					// @ts-ignore
					if (value === {}) {
						td.style.backgroundColor = emptyCellColor;
					} else {
						td.style.backgroundColor = cellColor;
						const originalRow = this.originalData[row];
						const seasonRawValue = originalRow[prop] && originalRow[prop].season_raw;
						const validate =
							(originalRow[prop] !== null || originalRow[prop] !== undefined) && value !== seasonRawValue;

						// al editar se redondean las grillas, no aplica para las columnas 1 y 2
						if (col !== 0 && col !== 1 && value !== seasonRawValue) {
							td.style.borderRadius = '50px';
							td.style.borderColor = '#6C757D';
						}

						if (validate) {
							td.className = 'cell-grad-common';
							td.style.setProperty('--main-td-color', cellColor);
						}
					}

					break;

				default:
					td.style.backgroundColor = emptyCellColor;
					break;
			}
		}
	};

	/**
	 *
	 */

	id = 'hotInstance';
	private readonly hotRegisterer = new HotTableRegisterer();
	private hotInstance: Handsontable;

	public onAfterInit = (): void => {
		this.hotInstance = this.hotRegisterer.getInstance(this.id);
	};

	rangeArray(start: number, stop: number, step: number): number[] {
		return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
	}

	cellDataSetRaw(data: any, instance: Handsontable): void {
		const rangeSelected: Handsontable.CellRange[] = instance.getSelectedRange();

		rangeSelected.forEach((element) => {
			const rowStart = element.from.row < 0 ? 0 : element.from.row;
			const colStart = element.from.col <= 1 ? 2 : element.from.col;
			const rowsRange = this.rangeArray(rowStart, element.to.row, 1);
			const colsRange = this.rangeArray(colStart, element.to.col, 1);

			rowsRange.forEach((row: number) => {
				colsRange.forEach((col: number) => {
					const rowPotition: number = row;
					const colPosition: number = col;

					const dataPull = instance.getSourceData(rowPotition, colPosition, rowPotition, colPosition);
					const keyUpdate = Object.keys(dataPull.find((_, index) => index === 0)).find((_, index) => index === 0);
					const getRawKey = data.find((_, index) => index === rowPotition)[keyUpdate];

					const dataRaw = (getRawKey && getRawKey.season_raw) || null;
					const validatorSource = (dataRaw && 'edit') || 'myUpdateData';

					instance.setDataAtCell(rowPotition, colPosition, dataRaw, validatorSource);
				});
			});
		});
	}
}
