import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { bff, environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SessionInfo } from '../models/SessionInfo';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();
@Injectable({
	providedIn: 'root'
})
export class SessionService {
	headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
	private env = `${bff.host}`;

	constructor(private http: HttpClient) {}

	getSessionInfo(): Observable<SessionInfo> {
		const url = `${this.env}`;
		return this.http.get<SessionInfo>(url + bff.tokenValidation, { headers: this.headers });
	}

	public isAuthorized() {
		const token = sessionStorage.getItem('acme-user-token');
		if (token != null) {
			return !helper.isTokenExpired(token);
		}
		return false;
	}
}
