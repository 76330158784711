import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { D3ClusterElbowSilhouetteRenderer } from './D3-cluster-elbow-silhouette-scatterplot/D3ClusterElbowSilhouetteRenderer';

var nextId = 0;

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'cluster-elbow-silhouette-scatterplot',
	templateUrl: './cluster-elbow-silhouette-scatterplot-component.html',
	styleUrls: ['./cluster-elbow-silhouette-scatterplot-component.scss']
})
export class ClusterElbowSilhouetteScatterplotComponent implements OnChanges, AfterViewInit {
	@Input() data: Array<D3ClusterElbowSilhouetteRenderer.ElbowSilhouettePoint>;

	ph_id = `ph_d3_cluster_elbow_silhouette_${nextId++}`;

	initialized = false;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.initialized) {
			this.plot();
		}
	}

	ngAfterViewInit() {
		this.plot();

		this.initialized = true;
	}

	plot() {
		console.log('plot');

		D3ClusterElbowSilhouetteRenderer.plot(this.data, this.ph_id, 490, 350);
	}
}
