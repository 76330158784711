import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogData } from '../models/confirm-dialog-data';
import { DataModalImport, ResponseDataModal } from '../models/ModalImport';
import { ConfirmComponent } from '../shared/dialogs/confirm/confirm.component';
import { CopiaCurvaMetaComponent } from '../shared/dialogs/copia-curva-meta/copia-curva-meta.component';

@Injectable({
	providedIn: 'root'
})
export class DialogService {
	constructor(private dialog: MatDialog) {}

	confirmDialog(data: ConfirmDialogData): Observable<boolean> {
		return this.dialog
			.open(ConfirmComponent, {
				data,
				width: '430px',
				disableClose: true
			})
			.afterClosed();
	}

	copyTargetCurveDialog(data: DataModalImport): Observable<ResponseDataModal> {
		return this.dialog
			.open(CopiaCurvaMetaComponent, {
				data,
				width: '430px',
				disableClose: true
			})
			.afterClosed();
	}
}
