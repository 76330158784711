<app-menu-bar seccion="target" (toggleNotify)="notify.drawer.toggle()"></app-menu-bar>
<app-menu-notify #notify></app-menu-notify>

<div *ngIf="seasonsReady && selectedSeason">
	<app-menu-lateral>
		<ng-container menu-content>
			<app-menu-lateral-inputs
				[viewForm]="viewForm"
				[viewInputs]="viewInputs"
				[selectedSeason]="selectedSeason"
				viewPage="target_curve"
				(baselineClick)="buttonBaselineMenu($event, false)"
				(baselineLoad)="buttonBaselineMenu($event, true)"
				(baselineChange)="baselineMenuChanges($event)"
				(modelLoad)="modelMenuLoad($event)"
				(modelChange)="modelMenuChanges($event)"
				(calculateClick)="buttonModelOutput($event)"
			>
			</app-menu-lateral-inputs>
		</ng-container>
	</app-menu-lateral>
</div>

<br />
<div class="container">
	<app-menu-superior-global (changeTab)="changeSelectedSeason($event)" [seasons]="seasons"></app-menu-superior-global>

	<app-despliegue-curvas-metas
		*ngIf="selectedSeason"
		[all_data]="data_graf"
		[all_clusters]="clusters_graph"
		[grid_model_settings]="data_grid_model_settings"
		[ask_indicators]="data_ask_indicators"
		(smoothMethodChangeEvent)="smoothCurveSelection($event)"
		(saveEvent)="save()"
		(refreshEvent)="refreshButton()"
		[disableSave]="disableSave"
	>
	</app-despliegue-curvas-metas>

	<app-carga-target-curves
		*ngIf="data_tc_ready"
		(Btnaply)="callApplyLoadFactorOut()"
		(btn_import_target_curves)="importTargetCurves($event)"
		(btn_import_baseline_curves)="importBaselineCurves()"
		(btn_copy_model_output)="copyModelOutput()"
		(dataTargetOutTargetlf)="dataTargetOutTargetlf($event)"
		(target_curves_edited)="onTargetCurvesEdited($event)"
		[list_seasons]="seasons"
		[data_grid_target_curves]="data_grid_target_curves"
		[data_grid_target_curves_pp]="data_grid_target_curves"
		[data_grid_target_curves_pax]="data_grid_target_curves_pax"
		[data_grid_baseline_pp]="data_grid_baseline_pp"
		[data_grid_baseline_pax]="data_grid_baseline_pax"
		[data_grid_baseline_delta_pp]="data_grid_baseline_delta_pp"
		[data_grid_baseline_delta_pax]="data_grid_baseline_delta_pax"
		[data_grid_baseline_tc_pp]="data_grid_baseline_tc_pp"
		[data_grid_baseline_tc_pax]="data_grid_baseline_tc_pax"
		[data_grid_productive_pp]="data_grid_productive_pp"
		[data_grid_productive_pax]="data_grid_productive_pax"
		[data_grid_productive_delta_pp]="data_grid_productive_delta_pp"
		[data_grid_productive_delta_pax]="data_grid_productive_delta_pax"
		[data_grid_model_output_pp]="data_grid_model_output_pp"
		[data_grid_model_output_pax]="data_grid_model_output_pax"
		[data_grid_model_output_delta_pp]="data_grid_model_output_delta_pp"
		[data_grid_model_output_delta_pax]="data_grid_model_output_delta_pax"
		[ponderaciones_ask]="ponderaciones_ask"
	>
	</app-carga-target-curves>

	<!-- trend -->
	<!--  && data_grid_model_settings.rows[0][0] -->
	<app-model-settings-detail
		[data_grid_target_curves]="data_grid_model_trend"
		title="Trend"
		[kpis]="trend_kpis"
		*ngIf="data_grid_model_trend"
		(target_curves_edited)="onTrendEdited()"
	>
	</app-model-settings-detail>

	<!-- posted flights: -->
	<!-- se saco del ngIf: && data_grid_model_settings.rows[1][0] -->
	<app-model-settings-detail
		[data_grid_target_curves]="data_grid_model_posted_flight"
		title="Posted Flights"
		[kpis]="posted_flights_kpis"
		*ngIf="data_grid_model_posted_flight"
		(target_curves_edited)="onPostedEdited()"
	>
	</app-model-settings-detail>
</div>

<br /><br />
