import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SessionStorage } from '../core/storages/session.storage';

export interface Notification {
	page: string;
	services: string;
	params: string;
	paramsList: string[];
	msg: string;
	method: string;
	body: string;
	dateError: string;
}

@Injectable()
export class NotificationsService {
	private dataSource = new BehaviorSubject<Notification[]>([]);
	data = this.dataSource.asObservable();

	private notifyLength = new BehaviorSubject<number>(0);
	notify = this.notifyLength.asObservable();

	constructor(public session: SessionStorage) {
		this.updatedData(session.notifications);
	}

	private updatedData(data: Notification[]) {
		this.dataSource.next(data);
	}

	updateNotify(quantity: number) {
		this.notifyLength.next(quantity);
	}

	getNotifications(): Notification[] {
		return this.session.notifications;
	}

	appendNotifyQuantity(): void {
		const quantity = this.notifyLength.value;
		const capacity = this.session.notifications_capacity;
		if (quantity < capacity) {
			this.updateNotify(quantity + 1);
		}
	}

	append(
		page: string,
		services: string,
		msg: string,
		params: string,
		paramsList: string[],
		method: string,
		body: string,
		dateError: string
	) {
		this.session.notifications = [
			...this.appendNotify({ page, services, msg, params, paramsList, method, body, dateError })
		];
		this.updatedData(this.getNotifications());
		this.appendNotifyQuantity();
	}

	private appendNotify(notify: Notification): Notification[] {
		const capacity = this.session.notifications_capacity;
		let notifications = this.getNotifications();
		notifications = [...notifications, notify];

		if (notifications.length > capacity) {
			const validNotify = notifications.slice(1);
			notifications = [...validNotify];
		}

		return notifications;
	}
}
