import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotTableModule } from '@handsontable/angular';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BucketsScatterplotComponent } from '../scatterplots/buckets-scatterplot/buckets-scatterplot.component';
import { BucketsComponent } from './buckets.component';
import { CardInformativeTablesComponent } from './card-informative-tables/card-informative-tables.component';
import { GridInformativeTableComponent } from './card-informative-tables/grid-informative-table/grid-informative-table.component';
import { CardSectionGlobalComponent } from './card-section-global/card-section-global.component';
import { GridBaselineBucketClustersComponent } from './grid-baseline-bucket-clusters/grid-baseline-bucket-clusters.component';
import { GridBucketClustersComponent } from './grid-bucket-clusters/grid-bucket-clusters.component';
import { GridBucketSummaryComponent } from './grid-bucket-summary/grid-bucket-summary.component';
import { PlotsBucketsComponent } from './plots-buckets/plots-buckets.component';

@NgModule({
	declarations: [
		BucketsComponent,
		CardSectionGlobalComponent,
		GridBucketClustersComponent,
		GridBaselineBucketClustersComponent,
		GridBucketSummaryComponent,
		CardInformativeTablesComponent,
		GridInformativeTableComponent,
		PlotsBucketsComponent,
		BucketsScatterplotComponent
	],
	imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, HotTableModule.forRoot(), MaterialModule],
	exports: [BucketsComponent]
})
export class BucketsModule {}
