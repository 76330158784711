import { Component } from '@angular/core';

@Component({
	selector: 'app-plot-info',
	templateUrl: './plot-info.component.html',
	styleUrls: ['./plot-info.component.scss']
})
export class PlotInfoComponent {
	constructor() {}
}
