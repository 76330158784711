import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DialogService } from '@services/dialog.service';

const MenuSuperiorSeasonsInputs = {
	seasons: 'seasons'
};

@Component({
	selector: 'app-menu-superior-seasons',
	templateUrl: './menu-superior-seasons.component.html',
	styleUrls: ['./menu-superior-seasons.component.scss']
})
export class MenuSuperiorSeasonsComponent {
	@Input(MenuSuperiorSeasonsInputs.seasons)
	set setSeasons(values: string[]) {
		if (typeof values !== 'undefined' && values.length > 0) {
			const seasons = values;

			const typeL = seasons.filter((f) => f.substr(0, 1) === 'L').sort();
			const typeH = seasons.filter((f) => f.substr(0, 1) === 'H').sort();
			const typeF = seasons.filter((f) => f.substr(0, 1) === 'F').sort();
			const typeOther = seasons
				.filter((f) => f.substr(0, 1) !== 'L' && f.substr(0, 1) !== 'H' && f.substr(0, 1) !== 'F')
				.sort();

			this.seasons = [...typeL, ...typeH, ...typeF, ...typeOther];

			// this.changeTab.emit(this.seasons.find((_, index) => index === 0));
			this.changeTab.emit();
		}
	}

	@Output() changeTab = new EventEmitter<string>();

	seasons: string[] = [];
	selected = 0;
	tabOld = 0;
	tabClickOne = true;

	constructor(private dialog: DialogService) {}

	tabChanged(tabChangeEvent: MatTabChangeEvent): void {
		if (tabChangeEvent.index !== this.tabOld) {
			if (this.tabClickOne) {
				this.tabClickOne = false;
				this.selected = tabChangeEvent.index;
				this.tabOld = tabChangeEvent.index;
				this.changeTab.emit(tabChangeEvent.tab.textLabel);
			} else {
				this.dialog
					.confirmDialog({
						title: 'Any unsaved progress will be lost',
						message: 'Are you sure you want to continue?',
						confirmCaption: 'Continue',
						cancelCaption: 'Go Back',
						typeDialog: 'warning'
					})
					.subscribe((confirm) => {
						if (confirm) {
							console.log('confirmation');
							this.selected = tabChangeEvent.index;
							this.tabOld = tabChangeEvent.index;
							this.changeTab.emit(tabChangeEvent.tab.textLabel);
						} else {
							console.log('back');
							this.selected = this.tabOld;
						}
					});
			}
		}
	}
}
