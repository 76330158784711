import { Component, Input } from '@angular/core';
import Handsontable from 'handsontable';
import { colores } from 'src/app/core/constants/handsontable.constant';
import { AsksPorSeasonKpi } from 'src/app/models/seasons/AsksPorSeasonKpi';
import { ConfigService } from '../../../../config/config.service';

export interface DataTable {
	[key: string]: number | string;
}

const AsksPorSeasonInputs = {
	kpis: 'kpis'
};

@Component({
	selector: 'app-asks-por-season',
	templateUrl: './asks-por-season.component.html',
	styleUrls: ['./asks-por-season.component.scss']
})
export class AsksPorSeasonComponent {
	total: number = 0.0;
	kpis: Array<AsksPorSeasonKpi>;
	@Input(AsksPorSeasonInputs.kpis)
	set setKpis(kpisData: Array<AsksPorSeasonKpi>) {
		// Formateo de datos tabla
		const formatDataTable: DataTable = [...kpisData].reduce((acc, currentValue) => {
			return { ...acc, [currentValue.season]: currentValue.ask };
		}, {});

		formatDataTable.season = 'ASK %';
		formatDataTable.total = kpisData.reduce((sum, kpi) => sum + kpi.ask, 0.0);

		const dataTable = [formatDataTable];

		// Formateo de columnas tabla
		this.cols = [];
		const formatColumnsTable = [...kpisData].map((kpisCol) => ({
			data: kpisCol.season,
			numericFormat: { pattern: '%0.0' },
			readOnly: true,
			title: kpisCol.season,
			type: 'numeric'
		}));

		if (!this.cols.some((exist) => exist.data === 'total') && kpisData.length > 0) {
			formatColumnsTable.push({
				data: 'total',
				numericFormat: { pattern: '%0.0' },
				readOnly: true,
				title: 'Total',
				type: 'numeric'
			});
		}

		formatColumnsTable.unshift({
			data: 'season',
			numericFormat: null,
			readOnly: true,
			title: 'Seasons',
			type: 'text'
		});

		// Table
		this.cols = [...this.cols, ...formatColumnsTable];

		this.data = dataTable;
	}

	colores = colores;

	cols = [];
	data = [];

	tableSettings: Handsontable.GridSettings = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		contextMenu: true,
		stretchH: 'all',
		dropdownMenu: false,
		manualColumnMove: false,
		filters: false,
		language: 'es-MX',
		rowHeaders: false,
		columnSorting: false,
		allowInsertRow: false,
		copyPaste: false,
		readOnly: true,
		className: 'htCenter',
		viewportColumnRenderingOffset: 1000,
		afterGetColHeader: (column: number, th: HTMLTableHeaderCellElement) => {
			const valueHeader = th.getElementsByTagName('span')[0].innerHTML;
			const color = colores[valueHeader];

			if (column === 0) {
				th.style.color = 'white';
				th.style.backgroundColor = '#1B0088';
			} else {
				th.style.backgroundColor = color;
			}
		}
	};

	constructor(private configService: ConfigService) {}
}
