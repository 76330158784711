import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LinkServices } from '@services/link.service';
import { EditLinksComponent } from '../edit-links/edit-links.component';
@Component({
	selector: 'app-link',
	templateUrl: './link.component.html',
	styleUrls: ['./link.component.scss'],
	providers: [DatePipe, EditLinksComponent]
})
export class LinkComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	displayedColumnsservicio: string[] = ['select', 'nombre', 'date'];
	comboListCategory;
	comboListBusiness;
	comboListCabins;
	loadtable: any;
	category: number;
	cabins: any;
	cabinCode: any;
	productId;
	business;
	idLinks;
	nameLink;
	newDataSource = [];
	routeId;
	activeBlock1 = false;
	selection = new SelectionModel<null>(true, []);
	dataSource: MatTableDataSource<null>;
	selectedRow;
	idSelectedRow: number = null;
	displayEdit = false;
	displayNew = false;
	displayWelcome = true;
	displayLoad = false;

	constructor(private ListService: LinkServices, private router: Router, private comp: EditLinksComponent) {}

	ngOnInit() {
		this.getCategoryList();
		this.getBusiness();
		this.getCabins();
		this.activeBlock1 = false;
	}

	getCategoryList() {
		this.ListService.getCategoryList().subscribe(
			(res) => {
				this.comboListCategory = res;
			},
			(err) => {
				console.log(err);
			}
		);
	}

	getBusiness() {
		this.ListService.getBusinessList().subscribe(
			(res) => {
				this.comboListBusiness = res;
			},
			(err) => {
				console.log(err);
			}
		);
	}

	getCabins() {
		this.ListService.getCabinsList().subscribe(
			(res) => {
				this.comboListCabins = res;
			},
			(err) => {
				console.log(err);
			}
		);
	}

	public function(category, business, cabins) {
		this.displayEdit = false;
		this.displayNew = false;
		this.displayLoad = false;
		this.displayWelcome = false;
		this.activeBlock1 = true;
		this.displayWelcome = false;
		this.displayLoad = true;
		this.idSelectedRow = null;

		this.comboListCabins.forEach((element) => {
			if (element.id === this.cabins) {
				this.cabinCode = element.code;
			}
		});

		this.newDataSource = [];
		this.ListService.getDataSource(this.business, this.category).subscribe(
			(res) => {
				this.newDataSource = Object.assign(res);
			},
			(err) => {
				console.log(err);
			}
		);

		this.ListService.getLoadTable(category, business, cabins).subscribe(
			(res) => {
				this.loadtable = res;
				setTimeout(() => {
					this.dataSource = new MatTableDataSource(this.loadtable);
					this.dataSource.paginator = this.paginator;
					this.dataSource.sort = this.sort;
				});
			},
			(err) => {
				console.log(err);
			}
		);
	}

	public refresTableLinks(category, business, cabins, linksDataSave, nameLinks) {
		this.displayEdit = false;
		this.displayNew = false;
		this.displayLoad = false;
		this.idSelectedRow = null;
		this.ListService.getLoadTable(category, business, cabins).subscribe(
			(res) => {
				this.dataSource = new MatTableDataSource(null);
				this.loadtable = res;
				setTimeout(() => {
					this.loadtable.sort((a, b) => Number(b.id) - Number(a.id));
					this.dataSource = new MatTableDataSource(this.loadtable);
					this.getRecord(linksDataSave, nameLinks);
				});
			},
			(err) => {
				console.log(err);
			}
		);
	}

	public refreshTableEdit(resUpdate) {
		for (const preValueSave of this.loadtable.values()) {
			this.loadtable.forEach(() => {
				if (preValueSave.parameterId === resUpdate.parameterId) {
					preValueSave.parameterName = resUpdate.parameterName;
					preValueSave.lastUpdateUser = resUpdate.lastUpdateUser;
					preValueSave.lastUpdateDate = resUpdate.lastUpdateDate;
				}
			});
		}
	}

	changeParameters() {
		this.activeBlock1 = false;
		this.displayEdit = false;
		this.displayNew = false;
		this.displayWelcome = true;
	}

	applyFilter1(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource ? this.dataSource.data.length : 0;
		return numSelected === numRows;
	}
	masterToggle() {
		this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
	}
	checkboxLabel(row?: null): string {
		if (!row) {
			return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${+1}`;
	}

	getRecord(row, nameLinks) {
		this.selectedRow = row;
		this.idSelectedRow = row.id;
		this.displayNew = false;
		this.displayLoad = false;
		this.displayWelcome = false;
		this.displayEdit = true;
		this.idLinks = row.id;

		if (nameLinks) {
			this.nameLink = nameLinks;
		} else {
			this.nameLink = row.routeName;
		}
		this.comp.refreshDataCluster(row, this.newDataSource, this.cabinCode);
	}

	buttonNew() {
		this.displayNew = null;
		this.idSelectedRow = null;
		this.displayEdit = false;
		this.displayLoad = false;
		this.displayWelcome = false;
		this.displayNew = true;
	}
}
