<app-menu-bar
	seccion="summary"
	[stateDisabled]="stateSession"
	[element]="element"
	[cabin]="cabin"
	(toggleNotify)="notify.drawer.toggle()"
></app-menu-bar>
<app-menu-notify #notify></app-menu-notify>

<div class="container">
	<app-table-old-sessions
		(restSession)="onRestSession($event)"
		(newSession)="changeStateSession($event)"
		(restSessionLoad)="onRestSessionLoad($event)"
		[summarySelect]="true"
	></app-table-old-sessions>
	<app-section-completitud
		(OnCommit)="commitSession()"
		[data]="data"
		[colums]="cols"
		[filter]="filterSelect"
		[disabledCommit]="stateSession"
	></app-section-completitud>
	<br /><br /><br /><br /><br /><br /><br />
</div>
