export const colores = {
	L: '#C9DAF8',
	L1: '#BAD1F8',
	L2: '#ABC8F8',
	L3: '#9CBEF7',
	L4: '#8DB5F7',
	L5: '#7EABF6',
	L6: '#6FA2F6',
	L7: '#6098F5',
	L8: '#518FF5',
	L9: '#4285F4',
	H: '#F4CCCC',
	H1: '#F3BDBC',
	H2: '#F2AEAB',
	H3: '#F19F9A',
	H4: '#F09089',
	H5: '#EF8079',
	H6: '#EE7168',
	H7: '#ED6257',
	H8: '#EC5346',
	H9: '#EA4335',
	F: '#FFF2CC',
	F1: '#FFF0B6',
	F2: '#FFEE9F',
	F3: '#FFEC88',
	F4: '#FFEA72',
	F5: '#FEE85B',
	F6: '#FEE644',
	F7: '#FEE42E',
	F8: '#FEE217',
	F9: '#FDE000',
	BL: '#C9DAF8',
	BL1: '#BAD1F8',
	BL2: '#ABC8F8',
	BL3: '#9CBEF7',
	BL4: '#8DB5F7',
	BL5: '#7EABF6',
	BL6: '#6FA2F6',
	BL7: '#6098F5',
	BL8: '#518FF5',
	BL9: '#4285F4',
	BH: '#F4CCCC',
	BH1: '#F3BDBC',
	BH2: '#F2AEAB',
	BH3: '#F19F9A',
	BH4: '#F09089',
	BH5: '#EF8079',
	BH6: '#EE7168',
	BH7: '#ED6257',
	BH8: '#EC5346',
	BH9: '#EA4335',
	BF: '#FFF2CC',
	BF1: '#FFF0B6',
	BF2: '#FFEE9F',
	BF3: '#FFEC88',
	BF4: '#FFEA72',
	BF5: '#FEE85B',
	BF6: '#FEE644',
	BF7: '#FEE42E',
	BF8: '#FEE217',
	BF9: '#FDE000'
};
