import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GraphPoints, InfoGraf } from 'src/app/models/buckets/PlotBuckets';
import { D3BucketsRenderer } from './D3-buckets-scatterplot/D3BucketsRenderer';

let nextId = 0;

const colors = [
	'#FF3D00',
	'#99CC33',
	'#008BFF',
	'#D236BB',
	'#FFC700',
	'#401279',
	'#24C2B9',
	'#F20B0B',
	'#11837C',
	'#E55CB4',
	'#5C7A1F',
	'#DA9901',
	'#DD5252',
	'#4658DF',
	'#9452FF',
	'#BC6C15',
	'#FA7950',
	'#99E1DE',
	'#7200FF',
	'#5C5C5C'
];

const BucketsScatterplotInputs = {
	data: 'data',
	titles: 'titles',
	clusterList: 'clusterList'
};

@Component({
	selector: 'app-buckets-scatterplot',
	templateUrl: './buckets-scatterplot.component.html',
	styleUrls: ['./buckets-scatterplot.component.scss']
})
export class BucketsScatterplotComponent implements AfterViewInit, OnChanges {
	data: Array<GraphPoints>;
	@Input(BucketsScatterplotInputs.data) set setCurves(value: Array<GraphPoints>) {
		this.data = value;
	}

	dataTitles: InfoGraf;
	@Input(BucketsScatterplotInputs.titles) set setDataTitles(value: InfoGraf) {
		this.dataTitles = value;
	}

	clustersList;
	@Input(BucketsScatterplotInputs.clusterList) set setClusterList(value) {
		this.clustersList = value;
	}

	ph_id = `ph_d3_buckets_${nextId++}`;

	initialized = false;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		// para evitar la doble llamada a plot()
		if (this.initialized && this.data) {
			this.plot();
		}
	}

	ngAfterViewInit() {
		this.plot();
		this.initialized = true;
	}

	plot() {
		if (this.data) {
			D3BucketsRenderer.plot(this.data, this.ph_id, colors, this.clustersList, this.dataTitles);
		}
	}
}
