import { GridBucketCluster } from 'src/app/models/buckets/GridBucketCluster';

export module OneTimeContext {
	export function deleteSessionStorage() {
		// OneTimeContext.deleteSessionStorage();
		/* for (var i = 0; i < sessionStorage.length; i++){
      console.log(sessionStorage.key(i));
      
      if(sessionStorage.key(i) != 'userEmail' && sessionStorage.key(i) != 'idToken' &&
          sessionStorage.key(i) != 'accessToken' && sessionStorage.key(i) != 'userName' &&
          sessionStorage.key(i) != 'photo'){
        sessionStorage.removeItem(sessionStorage.key(i));
      }
    } */
		const userEmail = sessionStorage.getItem('userEmail');
		const idToken = sessionStorage.getItem('idToken');
		const accessToken = sessionStorage.getItem('accessToken');
		const userName = sessionStorage.getItem('userName');
		const photo = sessionStorage.getItem('photo');

		sessionStorage.clear();

		sessionStorage.setItem('userEmail', userEmail);
		sessionStorage.setItem('idToken', idToken);
		sessionStorage.setItem('accessToken', accessToken);
		sessionStorage.setItem('userName', userName);
		sessionStorage.setItem('photo', photo);
	}

	export function deleteSessionStorage_Id() {
		const userEmail = sessionStorage.getItem('userEmail');
		const idToken = sessionStorage.getItem('idToken');
		const accessToken = sessionStorage.getItem('accessToken');
		const userName = sessionStorage.getItem('userName');
		const photo = sessionStorage.getItem('photo');
		const sessionID = sessionStorage.getItem('sessionID');

		sessionStorage.clear();

		sessionStorage.setItem('userEmail', userEmail);
		sessionStorage.setItem('idToken', idToken);
		sessionStorage.setItem('accessToken', accessToken);
		sessionStorage.setItem('userName', userName);
		sessionStorage.setItem('photo', photo);
		sessionStorage.setItem('sessionID', sessionID);
	}

	export function setLevelId(levelId: number): void {
		sessionStorage.setItem('levelId', levelId.toString());
	}

	export function getLevelId(): number {
		return parseInt(sessionStorage.getItem('levelId'));
	}

	//
	export function setCabinCode(cabinCode: string): void {
		sessionStorage.setItem('cabinCode', cabinCode);
	}

	export function getCabinCode(): string {
		return sessionStorage.getItem('cabinCode');
	}

	//
	export function setElementCode(elementCode: string): void {
		sessionStorage.setItem('elementCode', elementCode);
	}

	export function getElementCode(): string {
		return sessionStorage.getItem('elementCode');
	}

	//
	export function setSessionID(sessionID: number): void {
		sessionStorage.setItem('sessionID', sessionID.toString());
	}

	export function getSessionID(): number {
		return parseInt(sessionStorage.getItem('sessionID'));
	}

	//
	export function setBaselineElement(baselineElement: string): void {
		sessionStorage.setItem('baselineElement', baselineElement);
	}

	export function getBaselineElement(): string {
		return sessionStorage.getItem('baselineElement');
	}

	//
	export function setBaselineLevel(baselineLevel: string): void {
		sessionStorage.setItem('baselineLevel', baselineLevel);
	}

	export function getBaselineLevel(): string {
		return sessionStorage.getItem('baselineLevel');
	}

	//
	export function setBaselineCabinCode(baselineCabinCode: string): void {
		sessionStorage.setItem('baselineCabinCode', baselineCabinCode);
	}
	export function getBaselineCabinCode(): string {
		return sessionStorage.getItem('baselineCabinCode');
	}

	//
	export function setBaselineCabinName(baselineCabinName: string): void {
		sessionStorage.setItem('baselineCabinName', baselineCabinName);
	}
	export function getBaselineCabinName(): string {
		return sessionStorage.getItem('baselineCabinName');
	}

	//
	export function setBaselineYear(baselineYear: string): void {
		sessionStorage.setItem('baselineYear', baselineYear);
	}

	export function getBaselineYear(): string {
		return sessionStorage.getItem('baselineYear');
	}

	//
	export function setBaselineStartDate(baselineStartDate: string): void {
		sessionStorage.setItem('baselineStartDate', baselineStartDate);
	}

	export function getBaselineStartDate(): string {
		return sessionStorage.getItem('baselineStartDate');
	}

	//
	export function setBaselineEndDate(baselineEndDate: string): void {
		sessionStorage.setItem('baselineEndDate', baselineEndDate);
	}

	export function getBaselineEndDate(): string {
		return sessionStorage.getItem('baselineEndDate');
	}

	//
	export function setBaselineSeason(baselineSeason: any): void {
		sessionStorage.setItem('baselineSeason', baselineSeason);
	}

	export function getBaselineSeason(): any {
		return sessionStorage.getItem('baselineSeason');
	}

	//
	export function setLevel(level: string): void {
		sessionStorage.setItem('level', level);
	}
	export function getLevel(): string {
		return sessionStorage.getItem('level');
	}

	//
	export function setCabinName(cabinName: string): void {
		sessionStorage.setItem('cabinName', cabinName);
	}
	export function getCabinName(): string {
		return sessionStorage.getItem('cabinName');
	}

	//
	export function setEditorYear(editorYear: string): void {
		sessionStorage.setItem('editorYear', editorYear);
	}

	export function getEditorYear(): string {
		return sessionStorage.getItem('editorYear');
	}

	//
	export function setInbound(element: string): void {
		sessionStorage.setItem('inbound', element);
	}

	export function getInbound(): string {
		return sessionStorage.getItem('inbound');
	}

	//
	export function setOutbound(element: string): void {
		sessionStorage.setItem('outbound', element);
	}

	export function getOutbound(): string {
		return sessionStorage.getItem('outbound');
	}

	export function setStartDateAsk(startDateAsk: string): void {
		sessionStorage.setItem('startDateAsk', startDateAsk);
	}

	export function getStartDateAsk(): string {
		return sessionStorage.getItem('startDateAsk');
	}

	export function setEndDateAsk(endDateAsk: string): void {
		sessionStorage.setItem('endDateAsk', endDateAsk);
	}

	export function getEndDateAsk(): string {
		return sessionStorage.getItem('endDateAsk');
	}

	export function setView(view: string): void {
		sessionStorage.setItem('view', view);
	}

	export function getView(): string {
		return sessionStorage.getItem('view');
	}

	export function setCurrentlyInBuckets(inBuckets): void {
		sessionStorage.setItem('inBuckets', inBuckets);
	}

	export function getCurrentlyInBuckets() {
		return sessionStorage.getItem('inBuckets');
	}

	export function setBucketEditorBackup(bucketEditor: GridBucketCluster, name: string): void {
		sessionStorage.setItem(name + 'bucketEditor', JSON.stringify(bucketEditor));
	}

	export function getBucketEditorBackup(name: string): GridBucketCluster {
		return JSON.parse(sessionStorage.getItem(name + 'bucketEditor'));
	}

	// export function getNotifications(): Array<NotificationOneTime> {
	// 	const nm_st = sessionStorage.getItem('notification_onetime');

	// 	if (nm_st === null) {
	// 		return [];
	// 	} else {
	// 		const nm: NotificationModel = JSON.parse(nm_st);

	// 		return nm.notifications;
	// 	}
	// }

	// export function appendNotification(page: string, service: string, msg: string): void {
	// 	const nm_st = sessionStorage.getItem('notification_onetime');

	// 	let nm: NotificationModel | undefined = undefined;

	// 	if (nm_st === null) {
	// 		nm = createNotificationModel(5);
	// 	} else {
	// 		nm = JSON.parse(nm_st);
	// 	}

	// 	nm.appendPsm(page, service, msg);

	// 	const json = JSON.stringify(nm);
	// 	sessionStorage.setItem('notification_onetime', json);
	// }
}
