<div class="principal">
	<div class="contengo">
		<mat-form-field class="input-new">
			<mat-label>Parameter Name</mat-label>
			<input
				matInput
				placeholder="{{ parameter.parameterName }}"
				onkeypress="return event.keyCode!=13"
				(ngModelChange)="isDisableSave = true"
				[(ngModel)]="parameterName"
				autocomplete="off"
			/>
		</mat-form-field>
		<div class="icon-buton">
			<button type="button" class="btn-select-all" (click)="selectAllCells()">
				<img src="../../../../assets/icons/LATAM Icons/vector-cuadrilla.svg" alt="" />
			</button>
			<button type="button" class="btn-download" (click)="downloadCsv()">
				<img src="../../../../assets/icons/LATAM Icons/vector.svg" alt="" />
			</button>
			<button
				type="button"
				(click)="validateCellSave()"
				class="btn-search"
				[disabled]="!isDisableSave || !parameter.parameterName"
			>
				Save
			</button>
		</div>
	</div>
	<div class="headersParameters">
		<hot-table [hotId]="id" [settings]="hotSettings" class="handsontable-custom"> </hot-table>
	</div>
</div>
