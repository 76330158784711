import { LinksDetail } from './LinskDetail';
export class Links {
	cabinId: number;
	id: number;
	lastUpdateDate: Date;
	lastUpdateUser: string;
	productCategoryId: number;
	routeId: number;
	routeName: string;
	detail: LinksDetail;
}
