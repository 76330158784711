import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	BaselineMenus,
	ModelOutputCluster,
	ModelValues
} from 'src/app/components/one-time/clusters/menu-clusters/menu-clusters.interface';
import { D3ClusterElbowSilhouetteRenderer } from 'src/app/components/one-time/scatterplots/cluster-elbow-silhouette-scatterplot/D3-cluster-elbow-silhouette-scatterplot/D3ClusterElbowSilhouetteRenderer';
import { Bounds } from 'src/app/models/Bounds';
import { ClusterDescriptiveStatistics } from 'src/app/models/clusters/ClusterDescriptiveStatistics';
import { createGridTod, GridTod } from 'src/app/models/clusters/GridTod';
import { bff } from 'src/environments/environment';

export interface ClusterByParams {
	elementCode: string;
	cabinCode: string;
	season: string;
	sessionId: number;
}

export interface TodBffIn {
	bound: string;
	todStart: string;
	todEnd: string;
}

export interface BffClusterModelOutput {
	calc_clusters: Array<BffClusterModelOutputRow>;
	elbow_graph: Array<D3ClusterElbowSilhouetteRenderer.ElbowSilhouettePoint>;
}

export interface BffClusterModelOutputRow {
	bound: string;
	leg: string;
	dow: string;
	todStart: string;
	todEnd: string;
	cluster: string;
}

export interface DataClusterModelOutput {
	grids: Bounds<GridTod<number>>;
	stats: Array<D3ClusterElbowSilhouetteRenderer.ElbowSilhouettePoint>;
}

const arr_dow_attr = Array('', 'mon_data', 'tue_data', 'wed_data', 'thu_data', 'fri_data', 'sat_data', 'sun_data');

// ----------------------------------------------------------------------------
// indicators: https://rem-dev.appslatam.com/one-time/bff/cluster/get-indicators
// ----------------------------------------------------------------------------

export type DataIndicators = Bounds<OneBoundIndicators>;

export interface OneBoundIndicators {
	leg: string;
	season: string;

	franjas: Array<number>;
	cells: Array<CellIndicators>;
}

export interface CellIndicators {
	dow: number;
	tod: number;

	rask: number;
	yield: number;
	loadFactor: number;
	shortAP: number;
	internationalConnectingPax: number;
	domConnectingPax: number;
	postedFlights: number;
	baselineAsk: number;
	targetAsk: number;
	lowMix: number;
}

/*
    {
        "leg": "GRUSSA",
        "bound": "OUTBOUND",
        "season": "L",
        "dow": "4",
        "todStart": "00:00:00",
        "todEnd": "00:00:00",
        "rask": 3.3995725484,
        "yield": 4.6878575881,
        "loadFactor": 0.7251868224,
        "paxShortAP": 0.5491493384,
        "internationalConnectingPax": 0.1117889672,
        "domConnectingPax": 0.1600790514,
        "postedFlights": 0.0,
        "baselineAsk": 2.7590904E7,
        "targetAsk": 2.7590904E7,
        "lowMix": 0.4425158962
    },
 */

//
export interface BffIndicators {
	leg: string;
	bound: string;
	season: string;
	dow: string;
	todStart: string;
	todEnd: string;
	rask: number;
	yield: number;
	loadFactor: number;
	paxShortAP: number;
	internationalConnectingPax: number;
	domConnectingPax: number;
	postedFlights: number;
	baselineAsk: number;
	targetAsk: number;
	lowMix: number;
}

/*
    {
        "elementCode": "CGHSDU",
        "cabinCode": "Y",
        "season": "L9",
        "tods":[
            {
                "bound": "outbound",
                "todStart": "00:00:00",
                "todEnd": "08:59:00"
            },
            {
                "bound": "inbound",
                "todStart": "00:00:00",
                "todEnd": "08:59:00"
            }
        ],
        "elementCodeBaseline": "CGHSDU",
        "cabinCodeBaseline": "Y",
        "seasonsBaseline": [
            "L1",
            "L2",
            "L3"
        ],
        "startDateBaseline":"2021-08-01",
        "endDateBaseline":"2021-08-31"
    }
 */
export interface BffIndicatorsIn {
	sessionID: number;
	elementCode: string;
	cabinCode: string;
	season: string;
	tods: Array<TodBffIn>;
	shortAP: number;
	elementCodeBaseline: string;
	cabinCodeBaseline: string;
	seasonsBaseline: Array<string>;
	startDateBaseline: string;
	endDateBaseline: string;
}

// ----------------------------------------------------------------------------
// clusters: https://rem-dev.appslatam.com/one-time/bff/cluster/find-by-params/?cabinCode=Y&elementCode=CGHSDU&season=L9&sessionId=0
// ----------------------------------------------------------------------------
export interface DataClusters {
	// clusters: Array <number>,
	outbound: OneBoundClusters;
	inbound: OneBoundClusters;
}

export interface OneBoundClusters {
	bound: string;
	leg: string;
	franjas: Array<number>;

	cells: Array<CellClusters>;
}

export interface CellClusters {
	dow: number; // 1,
	tod: number; // 1,
	cluster: number; // 1
	cluster_raw: number;
}

//
export interface BffCluster {
	outbound: string;
	inbound: string;
	clusters: Array<ClusterInfo>;
}

export interface ClusterInfo {
	bound: string; // "outbound"
	cabinCode: string; // "Y"
	clusterSessionId: number; // 1
	clusterValue: string; // "1"
	clusterValueRaw: string; // "1"
	creationDateTime: string; // "2021-08-25T09:24:12"
	dow: string; // "7"
	elementCode: string; // "CGHSDU"
	id: number; // 15
	leg: string; // "SDUCGH"
	season: string; // "L"
	todEnd: string; // "15:59:00"
	todStart: string; // "00:09:00"
}

// ----------------------------------------------------------------------------
// save: https://rem-dev.appslatam.com/one-time/bff/cluster/save ?
// ----------------------------------------------------------------------------

export interface SaveBffIn {
	sessionId: number; // 1
	level: number; //
	elementCode: string; // "MIASCL",
	cabinCode: string; // "Y",
	season: string; // "L",
	model: string; // "AA"
	modelParameters: string; // "AA"
	baseLineElementCode: string; // "MIASCL"
	baseLineCabinCode: string; // "Y",
	baseLineStartDate: string; // "2021-10-09",
	baseLineEndDate: string; // "2021-10-10",
	baseLineSeason: string; // "L",

	sourceType: string;
	clusters: Array<ClustersSaveBffIn>;
	clusterDescription: Array<ClusterDescriptionSaveBffIn>;
	menues: MenuesSaveBffIn;
}

export interface ClusterDescriptionSaveBffIn {
	cluster: number;
	description: string;
}

export interface MenuesSaveBffIn {
	menu_baseline: BaselineMenus;
	menu_model: ModelValues;
}

export interface ClustersSaveBffIn {
	bound: string;
	todStart: string;
	todEnd: string;
	dow: number;
	cluster: number;
	clusterRaw: number;
}

// TODO: adecuar al formato real del servicio
export interface SaveBffOut {
	description: String;
}

// ----------------------------------------------------------------------------
// save: https://rem-dev.appslatam.com/one-time/bff/cluster/save ?
// ----------------------------------------------------------------------------
export interface UpdateKpiBffIn {
	elementCode: string;
	cabinCode: string;
	season: string;
	sessionID: number;
	numberClusters: number;
	shortAP: number;
	tods: Array<TodBffIn>;
	elementCodeBaseline: string;
	cabinCodeBaseline: string;
	seasonsBaseline: Array<string>;
	startDateBaseline: string;
	endDateBaseline: string;

	clusters: Array<ClustersUpdateKpiBffIn>;
}

export interface ClustersUpdateKpiBffIn {
	elementCode: string;
	cabinCode: string;
	season: string;
	bound: string;
	todStart: string;
	todEnd: string;
	dow: number;
	clusterValue: number;
	clusterValueRaw: number;
	leg: string;
}

// TODO: llamar al servicio
const data_dummy_save: SaveBffOut = { description: "don't worry, be happy" };

/*
 *
 */
@Injectable({
	providedIn: 'root'
})
export class IndicatorClustersService {
	private host = `${bff.host}`;

	private ENDPOINT_GET_CLUSTERS_TOD_DOW = `${this.host}${bff.getCluster}`;
	private ENDPOINT_GET_INDICATORS = `${this.host}${bff.getIndicators}`;
	private ENDPOINT_GET_MODEL_OUTPUT_CLUSTER = `${this.host}${bff.getModelOutputCluster}`;
	private ENDPOINT_SAVE_CLUSTERS = `${this.host}${bff.getSaveClusters}`;

	private ENDPOINT_UPDATE_KPIS = `${this.host}${bff.updateKpi}`;

	constructor(private http: HttpClient) {}

	getAllIndicators(bff_in: BffIndicatorsIn): Observable<DataIndicators> {
		function bff2CellIndicators(bff: BffIndicators, franjas: Array<string>): CellIndicators {
			const ret: CellIndicators = {
				dow: parseInt(bff.dow),
				tod: franjas.indexOf(bff.todStart),

				rask: bff.rask,
				yield: bff.yield,
				loadFactor: bff.loadFactor,
				shortAP: bff.paxShortAP,
				internationalConnectingPax: bff.internationalConnectingPax,
				domConnectingPax: bff.domConnectingPax,
				postedFlights: bff.postedFlights,
				baselineAsk: bff.baselineAsk,
				targetAsk: bff.targetAsk,
				lowMix: bff.lowMix
			};

			return ret;
		}

		//
		const post = JSON.stringify(bff_in);

		console.log('getAllIndicators-in', bff_in);

		return this.invokeBffIndictatos(post).pipe(
			map((res) => {
				console.log('getAllIndicators-out', res);

				const rows_outbound = res.filter((row) => row.bound === 'outbound');
				const rows_inbound = res.filter((row) => row.bound === 'inbound');

				const [leg_outbound, season_outbound] =
					rows_outbound.length > 0 ? [rows_outbound[0].leg, rows_outbound[0].season] : ['', ''];
				const [leg_inbound, season_inbound] =
					rows_inbound.length > 0 ? [rows_inbound[0].leg, rows_inbound[0].season] : ['', ''];

				const [franjas_sz_outbound, franjas_num_outbound] = this.calcFranjasBffIndicators(rows_outbound);
				const [franjas_sz_inbound, franjas_num_inbound] = this.calcFranjasBffIndicators(rows_inbound);

				const outbound: OneBoundIndicators = {
					leg: leg_outbound,
					season: season_outbound,
					franjas: franjas_num_outbound,
					cells: rows_outbound.map((row) => bff2CellIndicators(row, franjas_sz_outbound))
				};

				const inbound: OneBoundIndicators = {
					leg: leg_inbound,
					season: season_inbound,
					franjas: franjas_num_inbound,
					cells: rows_inbound.map((row) => bff2CellIndicators(row, franjas_sz_inbound))
				};

				const ret: DataIndicators = {
					outbound: outbound,
					inbound: inbound
				};

				return ret;
			})
		);

		/*
        // TODO dummy -> llamada servicio BFF a ENDPOINT_GET_INDICATORS
        return new Observable(subscriber => {
            subscriber.next (data_dummy_indicators) ;
        });
        */
	}

	calcFranjasClusterInfo(rows: Array<ClusterInfo>): [Array<string>, Array<number>] {
		const franjas_dup = rows.map((row) => row.todStart);

		return this.calcFranjas(franjas_dup);
	}

	calcFranjasBffIndicators(rows: Array<BffIndicators>): [Array<string>, Array<number>] {
		const franjas_dup = rows.map((row) => row.todStart);

		return this.calcFranjas(franjas_dup);
	}

	calcFranjas(franjas_dup: Array<string>): [Array<string>, Array<number>] {
		const franjas = franjas_dup.filter((franja, i) => franjas_dup.indexOf(franja) === i).sort();
		const franjas_num = franjas.map((franja) => {
			const arr = franja.split(':');
			return Math.floor(Number(arr[0]) * 100 + Number(arr[1]));
		});

		franjas_num.shift();

		return [franjas, franjas_num];
	}

	//

	getDataClusters(cabinCode: string, elementCode: string, season: string, sessionId: number): Observable<DataClusters> {
		//
		function toCellCLuster(row: ClusterInfo, franjas: Array<string>): CellClusters {
			const ret: CellClusters = {
				dow: Number(row.dow),
				cluster: Number(row.clusterValue),
				cluster_raw: Number(row.clusterValueRaw),
				tod: franjas.indexOf(row.todStart)
			};

			return ret;
		}

		// TODO dummy -> llamada servicio BFF
		const data: ClusterByParams = {
			cabinCode: cabinCode,
			elementCode: elementCode,
			season: season,
			sessionId: sessionId
		};

		return this.invokeBffClusters(data).pipe(
			map((res) => {
				// console.log (res) ;

				const outbound = res.clusters.filter((row) => row.bound === 'outbound');
				const inbound = res.clusters.filter((row) => row.bound === 'inbound');

				const [franjas_outbound, franjas_num_outbound] = this.calcFranjasClusterInfo(outbound);
				const [franjas_inbound, franjas_num_inbound] = this.calcFranjasClusterInfo(inbound);

				// const clusters = this.calcDistinctClusters (res.clusters) ;

				let leg_outbound = res.outbound;
				let leg_inbound = res.inbound;

				const ret: DataClusters = {
					// clusters: clusters,
					outbound: {
						bound: 'outbound',
						franjas: franjas_num_outbound,
						leg: leg_outbound,
						cells: outbound.map((row) => toCellCLuster(row, franjas_outbound))
					},
					inbound: {
						bound: 'inbound',
						franjas: franjas_num_inbound,
						leg: leg_inbound,
						cells: inbound.map((row) => toCellCLuster(row, franjas_inbound))
					}
				};

				return ret;
			})
		);
	}

	private invokeBffClusters(clustersParams: ClusterByParams): Observable<BffCluster> {
		let queryParams: HttpParams;

		queryParams = new HttpParams()
			.set('cabinCode', clustersParams.cabinCode)
			.set('elementCode', clustersParams.elementCode)
			.set('season', clustersParams.season)
			.set('sessionId', clustersParams.sessionId.toString());

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}),
			params: queryParams
		};

		return this.http.get(this.ENDPOINT_GET_CLUSTERS_TOD_DOW, httpOptions) as Observable<BffCluster>;
	}

	private invokeBffIndictatos(payload: any): Observable<Array<BffIndicators>> {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

		// console.log (payload) ;

		return this.http.post(this.ENDPOINT_GET_INDICATORS, payload, httpOptions) as Observable<Array<BffIndicators>>;
	}

	calcFranjasClusterModelOutput(rows: Array<BffClusterModelOutputRow>): [Array<string>, Array<number>] {
		const franjas_dup = rows.map((row) => row.todStart);

		return this.calcFranjas(franjas_dup);
	}

	public updateKpis(payload: UpdateKpiBffIn): Observable<Array<ClusterDescriptiveStatistics>> {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

		return this.http.post(this.ENDPOINT_UPDATE_KPIS, payload, httpOptions) as Observable<
			Array<ClusterDescriptiveStatistics>
		>;
	}

	/*
	 *
	 */
	getModelOutputCluster(calculateInput: ModelOutputCluster): Observable<DataClusterModelOutput> {
		return this.invokeModelOutputCluster(calculateInput).pipe(
			map((bff) => {
				// console.log (bff) ;

				const outbound_data = bff.calc_clusters.filter((row) => row.bound === 'outbound');
				const inbound_data = bff.calc_clusters.filter((row) => row.bound === 'inbound');

				const [franjas_outbound, franjas_num_outbound] = this.calcFranjasClusterModelOutput(outbound_data);
				const [franjas_inbound, franjas_num_inbound] = this.calcFranjasClusterModelOutput(inbound_data);

				const outbound_grid = createGridTod(franjas_num_outbound, NaN);
				const inbound_grid = createGridTod(franjas_num_inbound, NaN);

				outbound_data.forEach((row) => {
					const tod = franjas_outbound.indexOf(row.todStart);
					const dow_attr = arr_dow_attr[row.dow];
					const cluster = Number(row.cluster);

					outbound_grid.updateValue(tod, dow_attr, cluster);
				});

				inbound_data.forEach((row) => {
					const tod = franjas_inbound.indexOf(row.todStart);
					const dow_attr = arr_dow_attr[row.dow];
					const cluster = Number(row.cluster);

					inbound_grid.updateValue(tod, dow_attr, cluster);
				});

				const grids: Bounds<GridTod<number>> = {
					outbound: outbound_grid,
					inbound: inbound_grid
				};

				return { grids: grids, stats: bff.elbow_graph };
			})
		);
	}

	/*
	 *
	 */
	invokeModelOutputCluster(calculateInput: ModelOutputCluster): Observable<BffClusterModelOutput> {
		const data = {
			elementCode: calculateInput.elementCode,
			cabinCode: calculateInput.cabinCode,
			season: calculateInput.season,
			sessionID: calculateInput.sessionID,
			numberClusters: calculateInput.numberClusters,
			shortAP: calculateInput.shortAP,
			modelInputs: calculateInput.modelInputs,
			tods: calculateInput.tod,
			startDateBaseline: calculateInput.startDate,
			endDateBaseline: calculateInput.endDate,
			elementCodeBaseline: calculateInput.elementCodeBaseline,
			cabinCodeBaseline: calculateInput.cabinCodeBaseline,
			seasonsBaseline: calculateInput.seasonBaseline
		};

		const payload = JSON.stringify(data);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};

		return this.http.post(
			this.ENDPOINT_GET_MODEL_OUTPUT_CLUSTER,
			payload,
			httpOptions
		) as Observable<BffClusterModelOutput>;
	}

	/*
	 *
	 */
	save(toBeSaved: SaveBffIn): Observable<SaveBffOut> {
		const payload = JSON.stringify(toBeSaved);

		console.log(payload);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};

		// TODO dummy -> llamada servicio BFF
		// return new Observable(subscriber => {
		//    subscriber.next (data_dummy_save) ;
		// });

		return this.http.post(this.ENDPOINT_SAVE_CLUSTERS, payload, httpOptions) as Observable<SaveBffOut>;
	}
}
