import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';

import moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { TodStartEnd } from 'src/app/models/clusters/GridTod';

const _moment = _rollupMoment || moment;

@Component({
	selector: 'app-tod-tools-clusters',
	templateUrl: './tod-tools-clusters.component.html',
	styleUrls: ['./tod-tools-clusters.component.scss']
})
export class TodToolsClustersComponent implements OnInit, OnChanges {
	@Input() titleTodTools: string;
	@Input() dataTodTolls: Array<TodStartEnd>;

	@Output() validezRefreshClusters = new EventEmitter();
	@ViewChild(MatTable, { static: true }) table: MatTable<any>;

	displayedColumns: string[] = ['tod-start', 'tod-end'];

	public todStart: UntypedFormControl = new UntypedFormControl(_moment().format('HH:mm'));
	public todEnd: UntypedFormControl = new UntypedFormControl(_moment().format('HH:mm'));

	dataSource: Array<TodStartEnd>;
	todToolsTitle: string;
	validaErrorFechas: boolean;

	constructor() {}

	ngOnInit() {
		this.todStart.setValue('00:00');
		this.todEnd.setValue('23:59');
		this.todEnd.disable();
		this.validaErrorFechas = false;
	}

	ngOnChanges() {
		this.dataSource = this.dataTodTolls;
		this.todToolsTitle = this.titleTodTools;

		/*
	  if (this.dataSource !== undefined && this.dataSource.length > 0) {
	    this.sortArrayOfDates(this.dataSource);
	  //  this.dataSource.reverse();
	    this.table.renderRows();
	  }
	  */
	}

	addHours() {
		const size = this.dataSource.length;
		this.dataSource[size - 1].end = null;
		this.dataSource.push({
			start: this.todStart.value,
			end: this.todEnd.value
		});
		this.table.renderRows();
		this.validezRefreshClusters.emit(true);
	}

	removeHours() {
		if (this.dataSource.length > 1) {
			this.dataSource.splice(-1);
			this.table.renderRows();
		}
		const size = this.dataSource.length;
		this.dataSource[size - 1].end = this.todEnd.value;
		this.validaErrorFechas = false;
		this.validezRefreshClusters.emit(false);
	}

	changeStartHour(index: number) {
		let temporalDate;
		if (this.dataSource.length > 1 && _moment('2014-06-07 ' + this.todStart.value).isValid()) {
			if (this.validateNewStartDate(index, this.todStart.value)) {
				temporalDate = _moment('2014-06-07 ' + this.todStart.value).subtract(1, 'minutes');
				this.dataSource[index].start = this.todStart.value;
				this.dataSource[index - 1].end = _moment(temporalDate).format('HH:mm');
				this.table.renderRows();
				this.validaErrorFechas = false;
				if (this.dataSource.length - 1 > index) {
					if (_moment('2014-06-07 ' + this.todStart.value) > _moment('2014-06-07 ' + this.dataSource[index + 1].start)) {
						this.validaErrorFechas = true;
					} else {
						this.validaErrorFechas = false;
					}
				}
			} else {
				this.dataSource[index].start = null;
				this.dataSource[index - 1].end = null;
				this.table.renderRows();
				this.validaErrorFechas = true;
			}
			this.validezRefreshClusters.emit(this.validaErrorFechas);
		}
	}

	validateNewStartDate(index: number, newDate: string) {
		if (_moment('2014-06-07 ' + newDate) > _moment('2014-06-07 ' + this.dataSource[index - 1].start)) {
			return true;
		} else {
			return false;
		}
	}

	sortArrayOfDates(array: Array<TodStartEnd>) {
		array.sort((a, b) => (a.start > b.start ? -1 : 1));
		array.reverse();
		this.calculateTodEnd(array);
	}

	calculateTodEnd(array: Array<TodStartEnd>) {
		let temporalDate;
		for (var i = 0; array.length > i; i++) {
			if (i !== array.length - 1) {
				temporalDate = _moment('2014-06-07 ' + array[i + 1].start).subtract(1, 'minutes');
				array[i].end = _moment(temporalDate).format('HH:mm');
			} else if (i === array.length - 1) {
				array[i].end = '23:59';
			}
		}
	}
}
