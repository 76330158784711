import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from '@services/notifications.service';

@Component({
	selector: 'app-modal-error-notify',
	templateUrl: './modal-error-notify.component.html',
	styleUrls: ['./modal-error-notify.component.scss']
})
export class ModalErrorNotifyComponent {
	page: string = '';
	services: string = '';
	method: string = '';
	params: string = '';
	paramsList: string[] = [];
	body: any = null;
	message: string = '';
	dateError: string = '';

	constructor(
		public dialogRef: MatDialogRef<ModalErrorNotifyComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Notification
	) {
		this.page = data.page;
		this.services = data.services;
		this.params = data.params;
		this.paramsList = data.paramsList;
		this.body = data.body;
		this.message = data.msg;
		this.method = data.method;
		this.dateError = data.dateError;
	}
}
