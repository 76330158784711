import { Component, Input } from '@angular/core';
import Handsontable from 'handsontable';
import { GridKpiGeneric } from 'src/app/models/GridKpiGeneric';
import { ConfigService } from '../../config/config.service';

interface HTColumn {
	title: string;
	readOnly: boolean;
	type: string;
}

const GridKpiGenericInputs = {
	data: 'data'
};

@Component({
	selector: 'app-grid-kpi-generic',
	templateUrl: './grid-kpi-generic.component.html',
	styleUrls: ['./grid-kpi-generic.component.scss']
})
export class GridKpiGenericComponent {
	@Input() title = '';

	id: string;
	columns: Array<HTColumn>;
	rows: Array<Array<any>>;
	data: GridKpiGeneric;
	data_ready = false;
	@Input(GridKpiGenericInputs.data) set setData(data: GridKpiGeneric) {
		if (typeof data !== 'undefined') {
			this.data = data;

			this.id = 'ht-' + data.name;

			this.columns = [];
			this.columns.push({ title: ' ', readOnly: true, type: 'text' });
			for (const label_col of data.columns) {
				this.columns.push({ title: label_col, readOnly: true, type: 'text' });
			}

			this.rows = [];
			for (const row_grid of data.rows) {
				const row: Array<any> = [];

				row.push(row_grid.label);

				for (const row_grid_value of row_grid.values) {
					row.push(row_grid_value);
				}

				this.rows.push(row);
			}

			this.data_ready = true;
		}
	}

	tableSettings: Handsontable.GridSettings = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		contextMenu: false,
		dropdownMenu: false,
		language: 'es-MX',
		className: 'htCenter',
		filters: false,
		columnSorting: false,
		allowInsertRow: false,
		viewportColumnRenderingOffset: 1000
		// colWidths: this.columns_width,
	};

	constructor(private configService: ConfigService) {}
}
