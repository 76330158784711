import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HotTableRegisterer } from '@handsontable/angular';
import { LinkServices } from '@services/link.service';
import 'handsontable/languages/es-MX';
import moment from 'moment';
import { Subject } from 'rxjs';
import { SessionStorage } from 'src/app/core/storages/session.storage';
import { HttpSpinnerService } from 'src/app/http-spinner/http-spinner.service';
import Swal from 'sweetalert2';
import { Links } from '../../../models/Links';
import { LinksDetail } from '../../../models/LinskDetail';
import { SnackbarComponent } from '../SnackbarLink/Snackbar.component';
import { ConfigService } from '../../../config/config.service';
@Component({
	selector: 'app-edit-links',
	templateUrl: './edit-links.component.html',
	styleUrls: ['./edit-links.component.scss'],
	providers: [DatePipe]
})
export class EditLinksComponent implements OnInit, OnChanges, OnDestroy {
	@Input() business: any;
	@Input() idLinks: any;
	@Input() nameLink: string;
	@Input() links: [];
	@Input() newDataSource;
	@Input() category: any;
	@Input() cabinCode: string;
	@Input() nameLinkSave: string;
	@Input() loadtable: any;
	// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
	protected _onDestroy = new Subject<void>();
	sendSnackbarActive;
	dataNew;
	linksDataSave: Links;
	linksdatadetailSave: LinksDetail;
	userEmail;
	private hotRegisterer = new HotTableRegisterer();
	id = 'hotInstance';
	isDisableSave: boolean;
	comboLinkrutas;
	linksrutas;
	tableData = [];
	newDataSourceClusters: any;
	newDataSourceSeasons: any;
	date: moment.Moment = moment();
	tableSettings: any = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		colHeaders: [
			'Month Start',
			'Month End',
			'Season',
			'Cluster',
			'Cockpit',
			'Competition<br>Match',
			'Competition<br>Position',
			'Competition<br>Gap',
			'Decision',
			'Limits'
		],
		colWidths: [70, 70, 80, 80, 120, 120, 120, 130, 120, 100],
		stretchH: 'all',
		dropdownMenu: false,
		columns: [
			{
				data: 'monthStart',
				type: 'date',
				dateFormat: 'YYYY-MM',
				correctFormat: true,
				allowEmpty: false
			},
			{
				data: 'monthEnd',
				type: 'date',
				dateFormat: 'YYYY-MM',
				correctFormat: true,
				allowEmpty: false
			},
			{
				data: 'season',
				type: 'dropdown',
				strict: true,
				source: [],
				allowEmpty: false
			},
			{
				data: 'cluster',
				type: 'dropdown',
				strict: true,
				source: [],
				allowEmpty: false
			},
			{
				data: 'cockpitName',
				type: 'dropdown',
				strict: true,
				allowEmpty: false
			},
			{
				data: 'competitionMatchName',
				type: 'dropdown',
				strict: true,
				allowEmpty: true
			},
			{
				data: 'competitionPositionName',
				type: 'dropdown',
				strict: true,
				allowEmpty: true
			},
			{
				data: 'competitionGapName',
				type: 'dropdown',
				strict: true,
				allowEmpty: true
			},
			{
				data: 'decisionName',
				type: 'dropdown',
				strict: true,
				allowEmpty: false
			},
			{
				data: 'limitsName',
				type: 'dropdown',
				strict: true,
				allowEmpty: true
			}
		],
		manualColumnMove: false,
		contextMenu: true,
		language: 'es-MX',
		className: 'htCenter',
		allowInsertRow: true,
		minSpareRows: 1,

		afterGetColHeader: (column, th) => {
			th.style.color = 'black';
			if (column < 4) {
				th.style.backgroundColor = '#FAA0A0';
			}
		},

		afterRemoveRow: (index, amount, rows, source) => {
			const rowCount = this.hotRegisterer.getInstance(this.id).countRows();
			if (rowCount === 1) {
				Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'warning');
				return;
			}

			if (rowCount > 1) {
				var rowValids = [];
				for (var i = 0; i < rowCount - 1; i++) {
					rowValids.push(i);
				}
			}

			console.log('RowValids : ', rowValids);

			this.hotRegisterer.getInstance(this.id).validateRows(rowValids, (valid) => {
				if (valid) {
					console.log('Ok');
				} else {
					console.log('fallo validacion');
				}
			});
		},

		afterChange: (changes, row) => {
			if (row !== null) {
				this.isDisableSave = true;
			}
		}
	};

	constructor(
		private ListService: LinkServices,
		private datepipe: DatePipe,
		private snackBar: MatSnackBar,
		private httpSpinnerService: HttpSpinnerService,
		private sessionData: SessionStorage,
		private configService: ConfigService
	) {}

	ngOnInit() {
		this.isDisableSave = false;
		this.userEmail = this.sessionData.userEmail;
	}

	sendSnackbar(errMensaje) {
		this.sendSnackbarActive = true;
		this.snackBar.openFromComponent(SnackbarComponent, {
			data: errMensaje,
			panelClass: ['snackValidation']
		});
	}

	validateCellSave() {
		this.httpSpinnerService.nextState$(true);
		this.dataNew = this.hotRegisterer.getInstance(this.id).getSourceData();
		const rowCount = this.hotRegisterer.getInstance(this.id).countRows();
		if (rowCount === 1) {
			Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'warning');
			this.httpSpinnerService.nextState$(false);
			return;
		}

		if (rowCount > 1) {
			var rowValids = [];
			for (var i = 0; i < rowCount - 1; i++) {
				rowValids.push(i);
			}
		}
		this.hotRegisterer.getInstance(this.id).validateRows(rowValids, (valid) => {
			if (valid) {
				for (const value of this.dataNew.values()) {
					if (value.monthEnd) {
						value.monthEnd = value.monthEnd + '-20';
						value.monthStart = value.monthStart + '-01';
					}
				}

				for (const preValueSave of this.dataNew.values()) {
					this.newDataSource.Cockpit.forEach((element) => {
						if (element.parameterName === preValueSave.cockpitName) {
							preValueSave.cockpitId = element.parameterId;
						} else if (preValueSave.cockpitName === '') {
							preValueSave.cockpitId = null;
						}
					});

					this.newDataSource['Competition Match'].forEach((element) => {
						if (element.parameterName === preValueSave.competitionMatchName) {
							preValueSave.competitionMatchId = element.parameterId;
						} else if (preValueSave.competitionMatchName === '') {
							preValueSave.competitionMatchId = null;
						}
					});

					this.newDataSource['Competition Position'].forEach((element) => {
						if (element.parameterName === preValueSave.competitionPositionName) {
							preValueSave.competitionPositionId = element.parameterId;
						} else if (preValueSave.competitionPositionName === '') {
							preValueSave.competitionPositionId = null;
						}
					});

					this.newDataSource['Competition Gap'].forEach((element) => {
						if (element.parameterName === preValueSave.competitionGapName) {
							preValueSave.competitionGapId = element.parameterId;
						} else if (preValueSave.competitionGapName === '') {
							preValueSave.competitionGapId = null;
						}
					});

					this.newDataSource.Decision.forEach((element) => {
						if (element.parameterName === preValueSave.decisionName) {
							preValueSave.decisionId = element.parameterId;
						} else if (preValueSave.decisionName === '') {
							preValueSave.decisionId = null;
						}
					});

					this.newDataSource.Limits.forEach((element) => {
						if (element.parameterName === preValueSave.limitsName) {
							preValueSave.limitsId = element.parameterId;
						} else if (preValueSave.limitsName === '') {
							preValueSave.limitsId = null;
						}
					});
				}

				this.dataNew.length = this.dataNew.length - 1;
				this.linksDataSave = Object.assign(this.links);
				this.linksDataSave.lastUpdateUser = this.userEmail;
				this.linksDataSave.detail = this.dataNew;

				const saveData = Object.assign(this.linksDataSave);
				this.ListService.putEditLinks(saveData).subscribe(
					(res) => {
						this.httpSpinnerService.nextState$(false);
						this.openSnackBarCorrect('Link has been successfully saved!');
						this.isDisableSave = false;
						this.refreshDataTableSave(res);
					},
					(err) => {
						if (err.status === 400) {
							this.sendSnackbar(err);
							this.httpSpinnerService.nextState$(false);
							console.error(err);
						} else {
							this.openSnackBarIncorrect('Error! Unable to save Links');
							this.httpSpinnerService.nextState$(false);
							console.error(err);
						}
					}
				);
			} else {
				this.httpSpinnerService.nextState$(false);
				Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'warning');
				return;
			}
		});
	}
	refreshDataTableSave(resUpdate) {
		for (const preValueSave of this.loadtable.values()) {
			this.loadtable.forEach(() => {
				if (preValueSave.id === resUpdate.id) {
					preValueSave.lastUpdateUser = resUpdate.lastUpdateUser;
					preValueSave.lastUpdateDate = resUpdate.lastUpdateDate;
				}
			});
		}

		for (const value of this.dataNew.values()) {
			if (value.monthEnd) {
				value.monthEnd = this.datepipe.transform(value.monthEnd, 'yyyy-MM');
				value.monthStart = this.datepipe.transform(value.monthStart, 'yyyy-MM');
			}
			this.tableData.push(value);
		}
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackIncorrect']
		});
	}

	ngOnChanges() {
		this.tableData = [];
		this.isDisableSave = false;
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}
	refreshDataCluster(row, newDataSource, cabinCode) {
		this.tableData = [];
		this.newDataSourceClusters = [];
		this.ListService.getDataSourceClusters(row.routeId, cabinCode).subscribe(
			(res) => {
				this.newDataSourceClusters = Object.assign(res);
				this.refreshDataSeasons(row.routeId, cabinCode, row, newDataSource);
			},
			(err) => {
				console.log(err);
			}
		);
	}
	refreshDataSeasons(routeId, cabinCode, row, newDataSource) {
		this.newDataSourceSeasons = [];
		this.ListService.getDataSourceSeasons(routeId, cabinCode).subscribe(
			(res) => {
				this.newDataSourceSeasons = Object.assign(res);
				this.refreshDataTable(row, newDataSource);
			},
			(err) => {
				console.log(err);
			}
		);
	}
	refreshDataTable(row, newDataSource) {
		this.ListService.getDataLinks(row.id).subscribe(
			(res) => {
				for (const value of res.values()) {
					if (value.monthEnd) {
						value.monthEnd = this.datepipe.transform(value.monthEnd, 'yyyy-MM');
						value.monthStart = this.datepipe.transform(value.monthStart, 'yyyy-MM');
					}
					this.tableData.push(Object.assign(value));
				}
				for (const value of this.tableSettings.columns.values()) {
					if (value.data === 'cluster') {
						value.source = this.newDataSourceClusters.map((source) => source.name);
					}

					if (value.data === 'season') {
						value.source = this.newDataSourceSeasons.map((source) => source.name);
					}

					if (value.data === 'cockpitName') {
						value.source = newDataSource.Cockpit.map((source) => source.parameterName);
					}

					if (value.data === 'competitionMatchName') {
						value.source = newDataSource['Competition Match'].map((source) => source.parameterName);
					}

					if (value.data === 'competitionPositionName') {
						value.source = newDataSource['Competition Position'].map((source) => source.parameterName);
					}

					if (value.data === 'competitionGapName') {
						value.source = newDataSource['Competition Gap'].map((source) => source.parameterName);
					}

					if (value.data === 'decisionName') {
						value.source = newDataSource.Decision.map((source) => source.parameterName);
					}

					if (value.data === 'limitsName') {
						value.source = newDataSource.Limits.map((source) => source.parameterName);
					}
				}
				this.hotRegisterer.getInstance(this.id).loadData(Object.assign(this.tableData));
				this.hotRegisterer.getInstance(this.id).updateSettings({ columns: this.tableSettings.columns });
			},
			(err) => {
				console.log(err);
			}
		);
	}

	selectAllCells() {
		const hotInstance = this.hotRegisterer.getInstance(this.id);
		hotInstance.selectCells([[0, 0, hotInstance.countRows() - 1, hotInstance.countCols() - 1]], true, true);
	}

	downloadCsv() {
		const hot = this.hotRegisterer.getInstance(this.id).getPlugin('exportFile');
		hot.downloadFile('csv', {
			bom: true,
			columnDelimiter: ',',
			columnHeaders: true,
			exportHiddenColumns: true,
			exportHiddenRows: true,
			fileExtension: 'csv',
			filename: 'Links[YYYY]-[MM]-[DD]',
			mimeType: 'text/csv',
			rowDelimiter: '\r\n',
			rowHeaders: true
		});
	}

	ngOnDestroy() {
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
		this._onDestroy.next();
		this._onDestroy.complete();
	}
}
