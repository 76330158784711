import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { LinkComponent } from './components/link/link/link.component';
import { DashboardComponent } from './components/main/dashboard/dashboard.component';
import { LoginComponent } from './components/main/login/login.component';
import { BucketsComponent } from './components/one-time/buckets/buckets.component';
import { ClustersComponent } from './components/one-time/clusters/clusters.component';
import { HomeComponent } from './components/one-time/home/home.component';
import { OneTimeComponent } from './components/one-time/one-time.component';
import { SeasonsComponent } from './components/one-time/seasons/seasons.component';
import { SummaryComponent } from './components/one-time/summary/summary.component';
import { TargetCurvesComponent } from './components/one-time/target-curves/target-curves.component';
import { EditParameterComponent } from './components/parameter/edit-parameter/edit-parameter.component';
import { LoadMainComponent } from './components/parameter/load-main/load-main.component';
import { NewParameterComponent } from './components/parameter/new-parameter/new-parameter.component';
import { ParametersComponent } from './components/parameter/parameters/parameters.component';
import { WelcomeParametersComponent } from './components/parameter/welcome-parameters/welcome-parameters.component';
import { SessionAuthLatamGuard } from './route-guard/session-auth-latam.guard';

const appRoutes: Routes = [
	{ path: 'login', component: LoginComponent },
	{
		path: 'dashboard',
		canActivate: mapToCanActivate([SessionAuthLatamGuard]),
		component: DashboardComponent,
		children: [
			{
				path: 'parameters',
				component: ParametersComponent,
				canActivate: mapToCanActivate([SessionAuthLatamGuard]),
				children: [
					{
						path: '',
						component: WelcomeParametersComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					},
					{
						path: 'load',
						component: LoadMainComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					},
					{
						path: 'new-parameter',
						component: NewParameterComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					},
					{
						path: 'edit-parameter',
						component: EditParameterComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					}
				]
			},
			{
				path: 'link',
				component: LinkComponent,
				canActivate: mapToCanActivate([SessionAuthLatamGuard])
			},
			{
				path: 'one-time',
				component: OneTimeComponent,
				canActivate: mapToCanActivate([SessionAuthLatamGuard]),
				children: [
					{
						path: '',
						component: HomeComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					},
					{
						path: 'seasons',
						component: SeasonsComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					},
					{
						path: 'clusters',
						component: ClustersComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					},
					{
						path: 'summary',
						component: SummaryComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					},
					{
						path: 'target-curves',
						component: TargetCurvesComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					},
					{
						path: 'buckets',
						component: BucketsComponent,
						canActivate: mapToCanActivate([SessionAuthLatamGuard])
					}
				]
			}
		]
	},
	{ path: '**', redirectTo: 'login' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			useHash: true,
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
