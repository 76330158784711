import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HotTableModule } from '@handsontable/angular';

import { ClustersComponent } from './clusters.component';
import { MenuClustersComponent } from './menu-clusters/menu-clusters.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material.module';
import { MenuSuperiorSeasonsComponent } from './menu-superior-seasons/menu-superior-seasons.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClusterInd1VsInd2ScatterplotComponent } from '../scatterplots/cluster-ind1-vs-ind2-scatterplot/cluster-ind1-vs-ind2-scatterplot-component';
import { ClusterElbowSilhouetteScatterplotComponent } from '../scatterplots/cluster-elbow-silhouette-scatterplot/cluster-elbow-silhouette-scatterplot-component';
import { TodToolsClustersComponent } from './tod-tools-clusters/tod-tools-clusters.component';
import { TodToolsContainerComponent } from './tod-tools-container/tod-tools-container.component';
import { GridTodDowComponent } from './grids-tod-dow/grid-tod-dow/grid-tod-dow.component';
import { GridOutboundInboundComponent } from './grids-tod-dow/grid-outbound-inbound/grid-outbound-inbound.component';
import { GridClustersIndicatorsComponent } from './grids-tod-dow/grid-clusters-indicators/grid-clusters-indicators.component';
import { CardTodDowComponent } from './grids-tod-dow/card-tod-dow/card-tod-dow.component';
import { SectionPlotsComponent } from './section-plots/section-plots.component';
import { DescriptiveStatisticsComponent } from './descriptive-statistics/descriptive-statistics.component';

@NgModule({
	declarations: [
		ClustersComponent,
		MenuClustersComponent,
		MenuSuperiorSeasonsComponent,
		ClusterInd1VsInd2ScatterplotComponent,
		ClusterElbowSilhouetteScatterplotComponent,
		TodToolsClustersComponent,
		TodToolsContainerComponent,
		GridTodDowComponent,
		GridOutboundInboundComponent,
		GridClustersIndicatorsComponent,
		CardTodDowComponent,
		SectionPlotsComponent,
		DescriptiveStatisticsComponent
	],
	imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, MaterialModule, HotTableModule.forRoot()],
	exports: [ClustersComponent, ClusterInd1VsInd2ScatterplotComponent]
})
export class ClustersModule {}
