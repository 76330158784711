import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fcalcColor } from '@services/calendars/calendar-color.service';
import Handsontable from 'handsontable';
import {
	GridApClusters,
	calcTotalRowGripApCluster,
	cloneGridApClusters
} from 'src/app/models/target-curves/GridApClusters';

const CargaTargetCurvesInputs = {
	ponderaciones_ask: 'ponderaciones_ask',
	list_seasons: 'list_seasons',
	data_grid_target_curves: 'data_grid_target_curves',
	data_grid_target_curves_pp: 'data_grid_target_curves_pp',
	data_grid_target_curves_pax: 'data_grid_target_curves_pax',
	data_grid_baseline_pp: 'data_grid_baseline_pp',
	data_grid_baseline_pax: 'data_grid_baseline_pax',
	data_grid_baseline_delta_pp: 'data_grid_baseline_delta_pp',
	data_grid_baseline_delta_pax: 'data_grid_baseline_delta_pax',
	data_grid_baseline_tc_pp: 'data_grid_baseline_tc_pp',
	data_grid_baseline_tc_pax: 'data_grid_baseline_tc_pax',
	data_grid_productive_pp: 'data_grid_productive_pp',
	data_grid_productive_pax: 'data_grid_productive_pax',
	data_grid_productive_delta_pp: 'data_grid_productive_delta_pp',
	data_grid_productive_delta_pax: 'data_grid_productive_delta_pax',
	data_grid_model_output_pp: 'data_grid_model_output_pp',
	data_grid_model_output_pax: 'data_grid_model_output_pax',
	data_grid_model_output_delta_pp: 'data_grid_model_output_delta_pp',
	data_grid_model_output_delta_pax: 'data_grid_model_output_delta_pax'
};

@Component({
	selector: 'app-carga-target-curves',
	templateUrl: './carga-target-curves.component.html',
	styleUrls: ['./carga-target-curves.component.scss']
})
export class CargaTargetCurvesComponent implements OnInit {
	// lista de clusters de la ruta
	clusters: Array<number> = [];

	// columns_width: Array<number> = [];
	columns_width_tc: Array<number> = [];
	columns_width_pp_tc: Array<number> = [];
	columns_width_pax_tc: Array<number> = [];
	columns_width_baseline_pp: Array<number> = [];
	columns_width_baseline_pax: Array<number> = [];
	columns_width_baseline_delta_pp: Array<number> = [];
	columns_width_baseline_delta_pax: Array<number> = [];
	columns_width_baseline_tc_pp: Array<number> = [];
	columns_width_baseline_tc_pax: Array<number> = [];
	columns_width_productive_pp: Array<number> = [];
	columns_width_productive_pax: Array<number> = [];
	columns_width_productive_delta_pp: Array<number> = [];
	columns_width_productive_delta_pax: Array<number> = [];
	columns_width_model_output_pp: Array<number> = [];
	columns_width_model_output_pax: Array<number> = [];
	columns_width_model_output_delta_pp: Array<number> = [];
	columns_width_model_output_delta_pax: Array<number> = [];

	ponderaciones_ask: Array<number>;

	@Input('ponderaciones_ask')
	set setPonderacionesAsk(data: Array<number>) {
		this.ponderaciones_ask = data;
		if (this.ponderaciones_ask !== undefined && this.data_grid_target_curves !== undefined) {
			calcTotalRowGripApCluster(this.data_grid_target_curves, this.ponderaciones_ask);
		}
	}

	selectSeason = new UntypedFormControl();

	data_list_seasons: Array<string>;
	@Input('list_seasons')
	set setListSeasons(data: Array<string>) {
		this.data_list_seasons = data;
	}

	data_grid_target_curves: GridApClusters | undefined = undefined;
	data_grid_target_curves_raw: GridApClusters | undefined = undefined;
	data_grid_target_curves_ready = false;
	@Input('data_grid_target_curves')
	set setDataGridTargetcurves(data: GridApClusters) {
		this.data_grid_target_curves = data;
		this.data_grid_target_curves_raw = undefined;

		if (typeof data !== 'undefined') {
			if (this.ponderaciones_ask !== undefined && this.data_grid_target_curves !== undefined) {
				calcTotalRowGripApCluster(this.data_grid_target_curves, this.ponderaciones_ask);
			}

			this.data_grid_target_curves_raw = cloneGridApClusters(data);

			this.clusters = this.data_grid_target_curves.clusters;

			const widths = witdhCols(this.data_grid_target_curves.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_tc = [];

			// ap
			this.columns_width_tc = [...this.columns_width_tc, width_ap];
			// clusters
			for (const cluster of this.data_grid_target_curves.clusters) {
				this.columns_width_tc = [...this.columns_width_tc, width_cv];
			}
			// total
			this.columns_width_tc = [...this.columns_width_tc, width_ap];

			this.data_grid_target_curves_ready = true;
			this.refreshUI();
		} else {
			this.columns_width_tc = [];
			this.clusters = [];
		}
	}

	// Target Curves
	data_grid_target_curves_pp: GridApClusters | undefined = undefined;
	data_grid_target_curves_pp_ready = false;
	@Input('data_grid_target_curves_pp')
	set setDataGridTargetCurvesPp(data: GridApClusters) {
		this.data_grid_target_curves_pp = data;

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_target_curves_pp.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_pp_tc = [];

			// ap
			this.columns_width_pp_tc = [...this.columns_width_pp_tc, width_ap];
			// clusters
			for (const cluster of this.data_grid_target_curves_pp.clusters) {
				this.columns_width_pp_tc = [...this.columns_width_pp_tc, width_cv];
			}
			// total
			this.columns_width_pp_tc = [...this.columns_width_pp_tc, width_ap];

			this.data_grid_target_curves_pp_ready = true;
		} else {
			this.data_grid_target_curves_pp_ready = false;
		}

		this.refreshUI();
	}

	data_grid_target_curves_pax: GridApClusters | undefined = undefined;
	data_grid_target_curves_pax_ready = false;
	@Input('data_grid_target_curves_pax')
	set setDataGridTargetCurvesPax(data: GridApClusters) {
		this.data_grid_target_curves_pax = data;

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_target_curves_pax.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_pax_tc = [];

			// ap
			this.columns_width_pax_tc = [...this.columns_width_pax_tc, width_ap];
			// clusters
			for (const cluster of this.data_grid_target_curves_pax.clusters) {
				this.columns_width_pax_tc = [...this.columns_width_pax_tc, width_cv];
			}
			// total
			this.columns_width_pax_tc = [...this.columns_width_pax_tc, width_ap];

			this.data_grid_target_curves_pax_ready = true;
		} else {
			this.data_grid_target_curves_pax_ready = false;
		}

		this.refreshUI();
	}

	// baseline
	data_grid_baseline_pp: GridApClusters | undefined = undefined;
	data_grid_baseline_pp_ready = false;
	@Input('data_grid_baseline_pp')
	set setDataGridbaselinePp(data: GridApClusters) {
		this.data_grid_baseline_pp = data; // cloneGridApClusters(data, 10);

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_baseline_pp.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_baseline_pp = [];

			// ap
			this.columns_width_baseline_pp = [...this.columns_width_baseline_pp, width_ap];
			// clusters
			for (const cluster of this.data_grid_baseline_pp.clusters) {
				this.columns_width_baseline_pp = [...this.columns_width_baseline_pp, width_cv];
			}
			// total
			this.columns_width_baseline_pp = [...this.columns_width_baseline_pp, width_ap];

			this.data_grid_baseline_pp_ready = true;
		} else {
			this.data_grid_baseline_pp_ready = false;
		}

		this.refreshUI();
	}

	data_grid_baseline_pax: GridApClusters | undefined = undefined;
	data_grid_baseline_pax_ready = false;
	@Input('data_grid_baseline_pax')
	set setDataGridbaselinePax(data: GridApClusters) {
		this.data_grid_baseline_pax = data; // cloneGridApClusters(data, 10);

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_baseline_pax.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_baseline_pax = [];

			// ap
			this.columns_width_baseline_pax = [...this.columns_width_baseline_pax, width_ap];
			// clusters
			for (const cluster of this.data_grid_baseline_pax.clusters) {
				this.columns_width_baseline_pax = [...this.columns_width_baseline_pax, width_cv];
			}
			// total
			this.columns_width_baseline_pax = [...this.columns_width_baseline_pax, width_ap];

			this.data_grid_baseline_pax_ready = true;
		} else {
			this.data_grid_baseline_pax_ready = false;
		}

		this.refreshUI();
	}

	// baseline delta
	data_grid_baseline_delta_pp: GridApClusters | undefined = undefined;
	data_grid_baseline_delta_pp_ready = false;
	@Input('data_grid_baseline_delta_pp')
	set setDataGridbaselineDeltaPp(data: GridApClusters) {
		this.data_grid_baseline_delta_pp = data; // cloneGridApClusters(data, 10);

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_baseline_delta_pp.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_baseline_delta_pp = [];

			// ap
			this.columns_width_baseline_delta_pp = [...this.columns_width_baseline_delta_pp, width_ap];
			// clusters
			for (const cluster of this.data_grid_baseline_delta_pp.clusters) {
				this.columns_width_baseline_delta_pp = [...this.columns_width_baseline_delta_pp, width_cv];
			}
			// total
			this.columns_width_baseline_delta_pp = [...this.columns_width_baseline_delta_pp, width_ap];

			this.data_grid_baseline_delta_pp_ready = true;
		} else {
			this.data_grid_baseline_delta_pp_ready = false;
		}

		this.refreshUI();
	}

	data_grid_baseline_delta_pax: GridApClusters | undefined = undefined;
	data_grid_baseline_delta_pax_ready = false;
	@Input('data_grid_baseline_delta_pax')
	set setDataGridbaselineDeltaPax(data: GridApClusters) {
		this.data_grid_baseline_delta_pax = data; // cloneGridApClusters(data, 10);

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_target_curves.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_baseline_delta_pax = [];

			// ap
			this.columns_width_baseline_delta_pax = [...this.columns_width_baseline_delta_pax, width_ap];
			// clusters
			for (const cluster of this.data_grid_target_curves.clusters) {
				this.columns_width_baseline_delta_pax = [...this.columns_width_baseline_delta_pax, width_cv];
			}
			// total
			this.columns_width_baseline_delta_pax = [...this.columns_width_baseline_delta_pax, width_ap];

			this.data_grid_baseline_delta_pax_ready = true;
		} else {
			this.data_grid_baseline_delta_pax_ready = false;
		}

		this.refreshUI();
	}

	// baseline target curves
	data_grid_baseline_tc_pp: GridApClusters | undefined = undefined;
	data_grid_baseline_tc_pp_ready = false;
	@Input('data_grid_baseline_tc_pp')
	set setDataGridbaselineTargetCurvesPp(data: GridApClusters) {
		this.data_grid_baseline_tc_pp = data; // cloneGridApClusters(data, 10);

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_baseline_tc_pp.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_baseline_tc_pp = [];
			// ap
			this.columns_width_baseline_tc_pp = [...this.columns_width_baseline_tc_pp, width_ap];
			// clusters
			for (const cluster of this.data_grid_baseline_tc_pp.clusters) {
				this.columns_width_baseline_tc_pp = [...this.columns_width_baseline_tc_pp, width_cv];
			}
			// total
			this.columns_width_baseline_tc_pp = [...this.columns_width_baseline_tc_pp, width_ap];

			this.data_grid_baseline_tc_pp_ready = true;
		} else {
			this.data_grid_baseline_tc_pp_ready = false;
		}

		this.refreshUI();
	}

	data_grid_baseline_tc_pax: GridApClusters | undefined = undefined;
	data_grid_baseline_tc_pax_ready = false;
	@Input('data_grid_baseline_tc_pax')
	set setDataGridbaselineTargetCurvesPax(data: GridApClusters) {
		this.data_grid_baseline_tc_pax = data; // cloneGridApClusters(data, 10);

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_baseline_tc_pax.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_baseline_tc_pax = [];

			// ap
			this.columns_width_baseline_tc_pax = [...this.columns_width_baseline_tc_pax, width_ap];
			// clusters
			for (const cluster of this.data_grid_baseline_tc_pax.clusters) {
				this.columns_width_baseline_tc_pax = [...this.columns_width_baseline_tc_pax, width_cv];
			}
			// total
			this.columns_width_baseline_tc_pax = [...this.columns_width_baseline_tc_pax, width_ap];

			this.data_grid_baseline_tc_pax_ready = true;
		} else {
			this.data_grid_baseline_tc_pax_ready = false;
		}

		this.refreshUI();
	}

	// productive
	data_grid_productive_pp: GridApClusters | undefined = undefined;
	data_grid_productive_pp_ready = false;
	@Input('data_grid_productive_pp')
	set setDataGridProductivePp(data: GridApClusters) {
		this.data_grid_productive_pp = data;

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_productive_pp.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_productive_pp = [];

			// ap
			this.columns_width_productive_pp = [...this.columns_width_productive_pp, width_ap];
			// clusters
			for (const cluster of this.data_grid_productive_pp.clusters) {
				this.columns_width_productive_pp = [...this.columns_width_productive_pp, width_cv];
			}
			// total
			this.columns_width_productive_pp = [...this.columns_width_productive_pp, width_ap];

			this.data_grid_productive_pp_ready = true;
		} else {
			this.data_grid_productive_pp_ready = false;
		}

		this.refreshUI();
	}

	data_grid_productive_pax: GridApClusters | undefined = undefined;
	data_grid_productive_pax_ready = false;
	@Input('data_grid_productive_pax')
	set setDataGridProductivePax(data: GridApClusters) {
		this.data_grid_productive_pax = data;

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_productive_pax.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_productive_pax = [];

			// ap
			this.columns_width_productive_pax = [...this.columns_width_productive_pax, width_ap];
			// clusters
			for (const cluster of this.data_grid_productive_pax.clusters) {
				this.columns_width_productive_pax = [...this.columns_width_productive_pax, width_cv];
			}
			// total
			this.columns_width_productive_pax = [...this.columns_width_productive_pax, width_ap];
			this.data_grid_productive_pax_ready = true;
		} else {
			this.data_grid_productive_pax_ready = false;
		}

		this.refreshUI();
	}

	// productive delta
	data_grid_productive_delta_pp: GridApClusters | undefined = undefined;
	data_grid_productive_delta_pp_ready = false;
	@Input('data_grid_productive_delta_pp')
	set setDataGridProductiveDeltaPp(data: GridApClusters) {
		this.data_grid_productive_delta_pp = data;

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_productive_delta_pp.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_productive_delta_pp = [];

			// ap
			this.columns_width_productive_delta_pp = [...this.columns_width_productive_delta_pp, width_ap];
			// clusters
			for (const cluster of this.data_grid_productive_delta_pp.clusters) {
				this.columns_width_productive_delta_pp = [...this.columns_width_productive_delta_pp, width_cv];
			}
			// total
			this.columns_width_productive_delta_pp = [...this.columns_width_productive_delta_pp, width_ap];
			this.data_grid_productive_delta_pp_ready = true;
		} else {
			this.data_grid_productive_delta_pp_ready = false;
		}

		this.refreshUI();
	}

	data_grid_productive_delta_pax: GridApClusters | undefined = undefined;
	data_grid_productive_delta_pax_ready = false;
	@Input('data_grid_productive_delta_pax')
	set setDataGridProductiveDeltaPax(data: GridApClusters) {
		this.data_grid_productive_delta_pax = data;

		if (typeof data !== 'undefined') {
			const widths = witdhCols(this.data_grid_productive_delta_pax.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_productive_delta_pax = [];

			// ap
			this.columns_width_productive_delta_pax = [...this.columns_width_productive_delta_pax, width_ap];

			// clusters
			for (const cluster of this.data_grid_productive_delta_pax.clusters) {
				this.columns_width_productive_delta_pax = [...this.columns_width_productive_delta_pax, width_cv];
			}
			// total
			this.columns_width_productive_delta_pax = [...this.columns_width_productive_delta_pax, width_ap];

			this.data_grid_productive_delta_pax_ready = true;
		} else {
			this.data_grid_productive_delta_pax_ready = false;
		}
		this.refreshUI();
	}

	// model output
	data_grid_model_output_pp: GridApClusters | undefined = undefined;
	data_grid_model_output_pp_ready = false;
	@Input('data_grid_model_output_pp')
	set setDataGridModelOutputPp(data: GridApClusters) {
		if (typeof data !== 'undefined') {
			this.data_grid_model_output_pp = data;

			const widths = witdhCols(this.data_grid_model_output_pp.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_model_output_pp = [];

			// ap
			this.columns_width_model_output_pp = [...this.columns_width_model_output_pp, width_ap];
			// clusters
			for (const cluster of this.data_grid_model_output_pp.clusters) {
				this.columns_width_model_output_pp = [...this.columns_width_model_output_pp, width_cv];
			}
			// total
			this.columns_width_model_output_pp = [...this.columns_width_model_output_pp, width_ap];

			this.data_grid_model_output_pp_ready = true;
		} else {
			this.data_grid_model_output_pp = undefined;
			this.data_grid_model_output_pp_ready = false;
		}

		this.refreshUI();
	}

	data_grid_model_output_pax: GridApClusters | undefined = undefined;
	data_grid_model_output_pax_ready = false;
	@Input('data_grid_model_output_pax')
	set setDataGridModelOutputPax(data: GridApClusters) {
		// para diferenciar el caso que se carga por primera vez por el boton calculate del caso
		//   que se recarga por la edicion de una celda en la grilla "target curves"
		// es probable que no sea super exacto, pero debe ser suficiente
		const first_time = typeof this.data_grid_model_output_pax === 'undefined' && typeof data !== 'undefined';

		if (typeof data !== 'undefined') {
			this.data_grid_model_output_pax = data;

			const widths = witdhCols(this.data_grid_model_output_pax.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_model_output_pax = [];

			// ap
			this.columns_width_model_output_pax = [...this.columns_width_model_output_pax, width_ap];
			// clusters
			for (const cluster of this.data_grid_model_output_pax.clusters) {
				this.columns_width_model_output_pax = [...this.columns_width_model_output_pax, width_cv];
			}
			// total
			this.columns_width_model_output_pax = [...this.columns_width_model_output_pax, width_ap];

			this.data_grid_model_output_pax_ready = true;

			const values_selected = this.selectIndicadores.value as Array<string>;

			if (first_time) {
				if (values_selected === null || !values_selected.includes(this.optionIndicators[1])) {
					const other_selected =
						values_selected === null || values_selected.length === 0 ? this.optionIndicators[0] : values_selected[0];

					this.selectIndicadores.setValue([this.optionIndicators[1], other_selected]);
				}
			}
		} else {
			this.data_grid_model_output_pax = undefined;
			this.data_grid_model_output_pax_ready = false;
		}

		this.refreshUI();
	}

	// model output delta
	data_grid_model_output_delta_pp: GridApClusters | undefined = undefined;
	data_grid_model_output_delta_pp_ready = false;
	@Input('data_grid_model_output_delta_pp')
	set setDataGridModelOutputDeltaPp(data: GridApClusters) {
		const first_time = typeof this.data_grid_model_output_delta_pp === 'undefined' && typeof data !== 'undefined';

		if (typeof data !== 'undefined') {
			this.data_grid_model_output_delta_pp = data;

			const widths = witdhCols(this.data_grid_model_output_delta_pp.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_model_output_delta_pp = [];

			// ap
			this.columns_width_model_output_delta_pp = [...this.columns_width_model_output_delta_pp, width_ap];
			// clusters
			for (const cluster of this.data_grid_model_output_delta_pp.clusters) {
				this.columns_width_model_output_delta_pp = [...this.columns_width_model_output_delta_pp, width_cv];
			}
			// total
			this.columns_width_model_output_delta_pp = [...this.columns_width_model_output_delta_pp, width_ap];

			this.data_grid_model_output_delta_pp_ready = true;

			if (first_time) {
				const values_selected = this.selectIndicadores.value as Array<string>;

				if (values_selected === null || !values_selected.includes(this.optionIndicators[2])) {
					const other_selected =
						values_selected === null || values_selected.length === 0 ? this.optionIndicators[0] : values_selected[0];

					this.selectIndicadores.setValue([this.optionIndicators[2], other_selected]);
				}
			}
		} else {
			this.data_grid_model_output_delta_pp = undefined;
			this.data_grid_model_output_delta_pp_ready = false;
		}

		this.refreshUI();
	}

	data_grid_model_output_delta_pax: GridApClusters | undefined = undefined;
	data_grid_model_output_delta_pax_ready = false;
	@Input('data_grid_model_output_delta_pax')
	set setDataGridModelOutputDeltaPax(data: GridApClusters) {
		if (typeof data !== 'undefined') {
			this.data_grid_model_output_delta_pax = data;

			const widths = witdhCols(this.data_grid_model_output_delta_pax.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];

			this.columns_width_model_output_delta_pax = [];

			// ap
			this.columns_width_model_output_delta_pax = [...this.columns_width_model_output_delta_pax, width_ap];
			// clusters
			for (const cluster of this.data_grid_model_output_delta_pax.clusters) {
				this.columns_width_model_output_delta_pax = [...this.columns_width_model_output_delta_pax, width_cv];
			}
			// total
			this.columns_width_model_output_delta_pax = [...this.columns_width_model_output_delta_pax, width_ap];

			this.data_grid_model_output_delta_pax_ready = true;
		} else {
			this.data_grid_model_output_delta_pax = undefined;
			this.data_grid_model_output_delta_pax_ready = false;
		}

		this.refreshUI();
	}

	// los eventos de outputs
	@Output() Btnaply = new EventEmitter();

	@Output() btn_import_target_curves = new EventEmitter<string>();
	@Output() btn_import_baseline_curves = new EventEmitter<void>();

	@Output() btn_copy_model_output = new EventEmitter<void>();
	@Output() dataTargetOutTargetlf = new EventEmitter();

	@Output() target_curves_edited = new EventEmitter<string>();

	//
	selectIndicadores = new UntypedFormControl();

	data_grid_target_curves_selected = false;

	data_grid_baseline_selected = true;
	data_grid_baseline_delta_selected = true;

	data_grid_baseline_tc_selected = false;

	data_grid_productive_selected = false;
	data_grid_productive_delta_selected = false;

	data_grid_model_output_selected = false;
	data_grid_model_output_delta_selected = false;

	// Nota: si se cambia el orden de las opciones, hay que modificar onIndicadoresValueChanged (el codigo que selecciona los model output
	optionIndicators: string[] = [
		'Target Curve',
		'Model Output',
		'Δ Model Output',
		'Baseline',
		'Δ Baseline',
		// 5
		'Baseline target curves',
		'Productive',
		'Δ Productive'
	];

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit() {
		this.selectIndicadores.valueChanges.subscribe(this.onIndicadoresValueChanged);
		this.selectSeason.valueChanges.subscribe((val) => {
			const season = val as string;
			if (season !== null) {
				this.btn_import_target_curves.emit(season);
				this.selectSeason.setValue(null);
			}
		});
	}

	// re-emite el evento
	onTargetCurvesEdited = (hot: [string, Handsontable]): void => {
		const id = hot[0];

		if (this.ponderaciones_ask !== undefined && this.data_grid_target_curves !== undefined) {
			calcTotalRowGripApCluster(this.data_grid_target_curves, this.ponderaciones_ask);
		}

		hot[1].render();

		this.target_curves_edited.emit(id);
	};

	onIndicadoresValueChanged = (value: any): void => {
		const arr = value as Array<string>;

		if (arr.length === 2) {
			this.data_grid_target_curves_selected = arr.includes(this.optionIndicators[0]);
			this.data_grid_model_output_selected = arr.includes(this.optionIndicators[1]);
			this.data_grid_model_output_delta_selected = arr.includes(this.optionIndicators[2]);
			this.data_grid_baseline_selected = arr.includes(this.optionIndicators[3]);
			this.data_grid_baseline_delta_selected = arr.includes(this.optionIndicators[4]);
			// 5
			this.data_grid_baseline_tc_selected = arr.includes(this.optionIndicators[5]);
			this.data_grid_productive_selected = arr.includes(this.optionIndicators[6]);
			this.data_grid_productive_delta_selected = arr.includes(this.optionIndicators[7]);
		}
	};

	onClickCopyModelOutput() {
		this.btn_copy_model_output.emit();
	}

	onClickImportBaselineCurves() {
		this.btn_import_baseline_curves.emit();
	}

	maxOptionDisabled(opt: any): boolean {
		if (this.selectIndicadores.value !== null) {
			return this.selectIndicadores.value.length === 2 && !this.selectIndicadores.value.find((el) => el === opt);
		}
	}

	formatPP = (value: number) => {
		// const valuePorc = value * 100.0;
		return value.toFixed(1);
	};

	rendererPP = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridApClusters,
		data_grid_raw: GridApClusters | undefined,
		editable: boolean
	) => {
		this.rendererGrid(
			instance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			data_grid,
			data_grid_raw,
			this.formatPP,
			editable
		);
	};

	formatPax = (value: number) => {
		return Math.abs(value) > 999 ? this.formatNumberSymbol(value) : (Math.sign(value) * Math.abs(value)).toFixed(1);
	};

	formatNumberSymbol(value: number) {
		return Math.abs(value) > 999999
			? (Math.sign(value) * (Math.abs(value) / 1000000)).toFixed(1) + 'm'
			: (Math.sign(value) * (Math.abs(value) / 1000)).toFixed(1) + 'k';
	}

	rendererPax = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridApClusters,
		data_grid_raw: GridApClusters | undefined,
		editable: boolean
	) => {
		this.rendererGrid(
			instance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			data_grid,
			data_grid_raw,
			this.formatPax,
			editable
		);
	};

	rendererGrid = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridApClusters,
		data_grid_raw: GridApClusters | undefined,
		formatNumber: (n: number) => string,
		editable: boolean
	) => {
		const emptyCellColor = '#F3F2F';

		if (typeof value === 'number') {
			if (Number.isNaN(value)) {
				td.style.backgroundColor = emptyCellColor;
				td.innerHTML = '';
			} else {
				let hay_cambio = false;
				let valueColor;
				const totalRowDataNumber = data_grid.aps_end.length;
				const totalClusters = data_grid.clusters.length;

				if (typeof data_grid_raw !== 'undefined') {
					// esta logica asume que afterChange actualiza la data de la estructura antes
					const valueGrid = data_grid.getValue(row, Number(prop));
					const valueGridRaw = data_grid_raw.getValue(row, Number(prop));

					hay_cambio = valueGrid !== valueGridRaw;
				}

				if (hay_cambio && row < totalRowDataNumber && col > 0 && col < totalClusters + 1) {
					td.style.borderRadius = '50px';
					td.style.borderColor = '#6C757D';
				}

				if (col <= totalClusters && row < totalRowDataNumber) {
					const data = this.getDataSource(data_grid.rows);
					const minValue = Math.min.apply(Math, data);
					const maxValue = Math.max.apply(Math, data);

					if (value > maxValue) {
						valueColor = fcalcColor(value, minValue, value, false);
					} else if (value < minValue) {
						valueColor = fcalcColor(value, value, maxValue, false);
					} else {
						valueColor = fcalcColor(value, minValue, maxValue, false);
					}

					const hexColor = valueColor.toHex();

					if (hexColor.length === 7) {
						td.style.backgroundColor = hexColor;
					}
				}

				const szValue = formatNumber(value as number);

				td.innerHTML = szValue;
			}
		}

		// TODO: falta hacer validacion con raw
		if (typeof value === 'string') {
			td.style.borderRadius = '50px';
			td.style.borderColor = '#6C757D';
		}
	};

	getDataSource(rows: Array<any>): Array<any> {
		return rows
			.filter((cellData) => cellData[0] !== 'Total')
			.map((cellValue) => cellValue.slice(1, cellValue.length - 1))
			.reduce((acc, cell) => {
				return [...acc, ...cell];
			}, []);
	}

	applyClick() {
		this.Btnaply.emit();
	}

	dataTargetOut(params) {
		this.dataTargetOutTargetlf.emit(params);
	}

	// parche: llamar cuando Angular no refresca la pagina
	refreshUI() {
		this.changeDetectorRef.detectChanges();
	}
}

/*
 *
 */
function witdhCols(num_clusters: number): [number, number] {
	console.log('num_clusters', num_clusters);

	if (num_clusters === 1) {
		return [120, 100];
	} else if (num_clusters === 2) {
		return [110, 75];
	} else if (num_clusters === 3) {
		return [100, 70];
	} else if (num_clusters === 4) {
		return [80, 60];
	} else if (num_clusters === 5) {
		return [80, 50];
	} else if (num_clusters <= 7) {
		return [50, 38];
	} else {
		return [49, 26];
	}
}
