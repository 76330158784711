import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HttpSpinnerService {
	constructor() {}
	private bState = new BehaviorSubject<any>(null);

	public get state$(): Observable<boolean> {
		return this.bState.asObservable();
	}

	public nextState$(v: boolean): void {
		this.bState.next(v);
	}
}
