<div class="container_grid_clusters">
	<div class="row mt-3">
		<div class="col-md-6 border-right">
			<app-grid-outbound-inbound
				*ngIf="data_grid_clusters_ready"
				title="Clusters"
				[data_grid_outbound]="data_grid_clusters.outbound"
				[data_grid_inbound]="data_grid_clusters.inbound"
				[data_grid_raw_outbound]="data_grid_clusters_raw.outbound"
				[data_grid_raw_inbound]="data_grid_clusters_raw.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[readOnly]="false"
				[afterChange]="afterChangeClusters"
				[rendererGrid]="rendererGridClusters"
				[keyDownCellOutbound]="beforeKeyDownOutbound"
				[keyDownCellInbound]="beforeKeyDownInbound"
				(hotInstanceTableIn)="getInstanceIn($event)"
				(hotInstanceTableOut)="getInstanceOut($event)"
			>
			</app-grid-outbound-inbound>
		</div>

		<!-- indicators -->
		<div class="col-md-6">
			<app-grid-outbound-inbound
				*ngIf="data_grid_model_output_visible()"
				title="Model output"
				[data_grid_outbound]="data_grid_model_output.outbound"
				[data_grid_inbound]="data_grid_model_output.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridClusters"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_rask_visible()"
				title="Rask"
				[data_grid_outbound]="data_grid_rask.outbound"
				[data_grid_inbound]="data_grid_rask.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridRask"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_yield_visible()"
				title="Yield"
				[data_grid_outbound]="data_grid_yield.outbound"
				[data_grid_inbound]="data_grid_yield.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridYield"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_load_factor_visible()"
				title="Load factor"
				[data_grid_outbound]="data_grid_load_factor.outbound"
				[data_grid_inbound]="data_grid_load_factor.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridLoadFactor"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_short_ap_pax_visible()"
				title="Short ap pax"
				[data_grid_outbound]="data_grid_short_ap_pax.outbound"
				[data_grid_inbound]="data_grid_short_ap_pax.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridShortApPax"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_international_connecting_visible()"
				title="International connecting"
				[data_grid_outbound]="data_grid_international_connecting.outbound"
				[data_grid_inbound]="data_grid_international_connecting.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridInternationalConnecting"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_domestic_connecting_visible()"
				title="Domestic connecting"
				[data_grid_outbound]="data_grid_domestic_connecting.outbound"
				[data_grid_inbound]="data_grid_domestic_connecting.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridDomesticConnecting"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_baseline_ask_visible()"
				title="Baseline ask"
				[data_grid_outbound]="data_grid_baseline_ask.outbound"
				[data_grid_inbound]="data_grid_baseline_ask.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridBaselineAsk"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_low_mix_visible()"
				title="Low mix"
				[data_grid_outbound]="data_grid_low_mix.outbound"
				[data_grid_inbound]="data_grid_low_mix.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridLowMix"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_posted_flights_visible()"
				title="Posted flights"
				[data_grid_outbound]="data_grid_posted_flights.outbound"
				[data_grid_inbound]="data_grid_posted_flights.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridPostedFlights"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_target_ask_visible()"
				title="Target ask"
				[data_grid_outbound]="data_grid_target_ask.outbound"
				[data_grid_inbound]="data_grid_target_ask.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridTargetAsk"
			>
			</app-grid-outbound-inbound>

			<app-grid-outbound-inbound
				*ngIf="data_grid_baseline_clusters_visible()"
				title="Baseline Clusters"
				[data_grid_outbound]="data_grid_baseline_clusters.outbound"
				[data_grid_inbound]="data_grid_baseline_clusters.inbound"
				[element_outbound]="element_code.outbound"
				[element_inbound]="element_code.inbound"
				[rendererGrid]="rendererGridClusters"
			>
			</app-grid-outbound-inbound>
		</div>
	</div>
</div>
