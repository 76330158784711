import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalDate } from '@js-joda/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { bff } from 'src/environments/environment';
import { CreacionSession } from '../models/NewSession';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	})
};

@Injectable({
	providedIn: 'root'
})
export class OneTimeServices {
	private env = `${bff.host}`;
	private ENDPOINT_GET_COMMIT_SESSION = `${bff.host}${bff.getCommitSession}`;
	private ENDPOINT_GET_SOURCEKPI = `${bff.host}${bff.getKPI}`;
	private ENDPOINT_GET_CABINS = `${bff.host}${bff.getOneTimeCabis}`;

	headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

	constructor(private http: HttpClient) {}

	getCabins(): Observable<any> {
		return this.http.get(this.ENDPOINT_GET_CABINS);
	}

	getLevelsAndElements(): Observable<any> {
		return this.http.get(this.env + bff.getElementsAndLevels);
	}

	getSessionList(user: string): Observable<any> {
		return this.http.get(this.env + bff.getUserSessions + '/' + user);
	}

	getSourceKPI(level, element, cabin, session): Observable<any> {
		return this.http
			.get(`${this.ENDPOINT_GET_SOURCEKPI}/${level.toString()}/${element}/${cabin.toString()}/${session}`)
			.pipe(
				map((response: any) => {
					const typeL = response.data.filter((f) => this.getFirstLetter(f.season) === 'L').sort();
					const typeH = response.data.filter((f) => this.getFirstLetter(f.season) === 'H').sort();
					const typeF = response.data.filter((f) => this.getFirstLetter(f.season) === 'F').sort();
					const typeOther = response.data
						.filter(
							(f) =>
								this.getFirstLetter(f.season) !== 'L' &&
								this.getFirstLetter(f.season) !== 'H' &&
								this.getFirstLetter(f.season) !== 'F'
						)
						.sort();

					return {
						columns: response.columns,
						data: [...typeL, ...typeH, ...typeF, ...typeOther]
					};
				})
			);
	}

	getFirstLetter(text: string): string {
		return text.substring(0, 1);
	}

	postNewSession(datos: CreacionSession): Observable<any> {
		return this.http.post(this.env + bff.postNewSession, datos, httpOptions);
	}

	getSeasons(
		cabinCode: string,
		elementCode: string,
		initDate: LocalDate,
		endDate: LocalDate,
		_sessionId: number
	): Observable<any> {
		let sessionId = _sessionId;

		if (Number.isNaN(_sessionId)) {
			// console.log ("getSeasons NaN -> 0") ;
			sessionId = 0;
		}

		// console.log ("getSeasons " + sessionId) ;

		const url = `${this.env}${bff.getSeasonsByParams}?cabinCode=${cabinCode}&elementCode=${elementCode}&initDate=${initDate}&endDate=${endDate}&sessionId=${sessionId}`;
		return this.http.get(url);
	}

	getBaselines(
		cabinCode: string,
		elementCode: string,
		year: number,
		level: string,
		baseLineLevel: string,
		baseLineElementCode: string,
		baseLineCabinCode: string,
		baseLineYear: number
	): Observable<any> {
		const url = `${this.env}${bff.getBaselinesByParams}?cabinCode=${cabinCode}&elementCode=${elementCode}&year=${year}&level=${level}&baseLineLevel=${baseLineLevel}&baseLineElementCode=${baseLineElementCode}&baseLineCabinCode=${baseLineCabinCode}&baseLineYear=${baseLineYear}`;
		return this.http.get(url);
	}

	getModelOutputs(
		cabinCode: string,
		elementCode: string,
		year: number,
		level: string,
		baseLineLevel: string,
		baseLineElementCode: string,
		baseLineCabinCode: string,
		baseLineYear: number
	): Observable<any> {
		const url = `${this.env}${bff.getModelOutputsByParams}?cabinCode=${cabinCode}&elementCode=${elementCode}&year=${year}&level=${level}&baseLineLevel=${baseLineLevel}&baseLineElementCode=${baseLineElementCode}&baseLineCabinCode=${baseLineCabinCode}&baseLineYear=${baseLineYear}`;
		return this.http.get(url);
	}

	getKpiAskPorSeasons(payload: any): Observable<any> {
		const url = `${this.env}${bff.getKpiAskPorSeason}`;
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

		console.log('Llamando a service kpis ask por season: ', url);
		return this.http.post(url, payload, httpOptions);
	}

	saveSeasons(payload: any): Observable<any> {
		const url = `${this.env}${bff.saveSeasons}`;
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

		console.log('Llamando a save : ', url);
		return this.http.post(url, payload, httpOptions);
	}

	deleteSession(sessionId: number): Observable<any> {
		return this.http.delete(this.env + bff.deleteSessionWork + sessionId);
	}

	getCommitSession(sessionId: number): Observable<any> {
		return this.http.get(this.env + bff.getCommitSession + '/' + sessionId);
	}

	getLastMenu(type: string, sessionId: number, season: string = 'X'): Observable<any> {
		return this.http.get(this.env + bff.getMenuLateral + '/' + type + '/' + sessionId + '/' + season);
	}
}
