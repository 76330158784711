import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnCompletitud, DataCompletitud } from 'src/app/shared/table-completitud/table-completitud.component';

export interface Filter {
	level: string;
	element: string;
	cabin: string;
}
@Component({
	selector: 'app-section-completitud',
	templateUrl: './section-completitud.component.html',
	styleUrls: ['./section-completitud.component.scss']
})
export class SectionCompletitudComponent {
	@Input() filter: Filter;
	@Input() data: DataCompletitud[] = [];
	@Input() colums: ColumnCompletitud[] = [];
	@Input() disabledCommit: boolean = false;

	@Output() OnCommit = new EventEmitter<any>();

	constructor() {}

	commitEvent(): void {
		this.OnCommit.emit();
	}
}
