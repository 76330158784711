import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { GridModelSetting } from 'src/app/models/target-curves/GridModelSetting';
import { D3TargetCurveRenderer } from '../../scatterplots/target-curve-scatterplot/D3-target-curve-scatterplot/D3TargetCurveRenderer';

const DespliegueCurvasMetasInputs = {
	allData: 'all_data',
	allClusters: 'all_clusters',
	askIndicators: 'ask_indicators'
};

@Component({
	selector: 'app-despliegue-curvas-metas',
	templateUrl: './despliegue-curvas-metas.component.html',
	styleUrls: ['./despliegue-curvas-metas.component.scss']
})
export class DespliegueCurvasMetasComponent implements OnInit {
	// toda la data que se puede graficar
	all_data: Array<D3TargetCurveRenderer.GraphPoints> = [];
	@Input(DespliegueCurvasMetasInputs.allData)
	set setAllData(data: Array<D3TargetCurveRenderer.GraphPoints>) {
		this.all_data = data;
		this.data = calculateData(this.all_data, this.selectedCurves, this.selectedClusters);
		this.selectSmooth.setValue(null);
	}

	subjectCluster$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
	dataCluster$: Observable<string[]> = this.subjectCluster$.asObservable();

	@Input(DespliegueCurvasMetasInputs.allClusters) set setClusters(data: Array<number>) {
		if (typeof data !== 'undefined') {
			this.optionClusters = [];

			const clusters = data.sort((a, b) => a - b);

			for (const cluster of clusters) {
				this.optionClusters.push('C' + cluster);
			}

			this.optionClusters.push('Total');
			this.subjectCluster$.next(this.optionClusters);
		}
	}

	@Output() smoothMethodChangeEvent = new EventEmitter<string>();

	@Output() saveEvent = new EventEmitter();
	@Output() refreshEvent = new EventEmitter();

	@Input() grid_model_settings: GridModelSetting;

	data_ask_indicators;
	@Input(DespliegueCurvasMetasInputs.askIndicators)
	set AskIndicators(data_ask_indicators) {
		this.data_ask_indicators = data_ask_indicators;
	}

	// la data que se grafica una vez que se seleciona las opciones en los botones
	data: Array<D3TargetCurveRenderer.GraphPoints>;

	selectCurves = new UntypedFormControl();
	selectClusters = new UntypedFormControl();
	selectSmooth = new UntypedFormControl();

	// importante, estas opciones deben estar consistente con los siguientes arreglos:
	// - prefix_array en target-curves.services.ts
	// - selectedCurves, mas adelante
	// o sea, misma cantidad en mismo orden
	optionCurves: string[] = [
		'Target Curves (TC)',
		'Model Output (M)',
		'Baseline Bookings (BLB)',
		'Baseline Curves (BLC)',
		'Productive (Prod)'
	];

	selectedCurves: Array<number> = [0, 1, 2, 3, 4];
	selectedClusters: Array<number> = [0];

	optionClusters: string[] = ['Total'];

	optionSmooth: string[] = ['Strong', 'Soft'];

	@Input() disableSave;

	constructor() {}

	ngOnInit() {
		this.selectCurves.setValue(this.optionCurves);

		const cluster = this.dataCluster$.subscribe((data) => {
			if (data.length > 1) {
				this.selectClusters.setValue(data);
				cluster.unsubscribe();
			}
		});

		this.selectCurves.valueChanges.subscribe((val) => {
			const arr = val as Array<string>;

			const selected: Array<number> = [];

			for (const sz of arr) {
				const index = this.optionCurves.indexOf(sz);

				selected.push(index);
			}

			this.selectedCurves = selected;

			this.data = calculateData(this.all_data, this.selectedCurves, this.selectedClusters);
		});

		this.selectClusters.valueChanges.subscribe((val) => {
			const arr = val as Array<string>;

			const selected: Array<number> = [];

			for (const sz of arr) {
				const lower = sz.toLowerCase();

				if (lower === 'total') {
					selected.push(0);
				} else {
					const sz_cluster = lower.substring(1);
					const cluster = parseInt(sz_cluster, 10);

					selected.push(cluster);
				}
			}

			this.selectedClusters = selected;

			this.data = calculateData(this.all_data, this.selectedCurves, this.selectedClusters);
		});

		this.selectSmooth.valueChanges.subscribe((val) => {
			const smooth_method = val as string;
			if (smooth_method !== null) {
				this.smoothMethodChangeEvent.emit(smooth_method);
			}
		});
	}

	save() {
		this.saveEvent.emit();
	}

	refresh() {
		this.refreshEvent.emit();
	}
}

function calculateData(
	all_data: Array<D3TargetCurveRenderer.GraphPoints>,
	selectedCurves: Array<number>,
	selectedClusters: Array<number>
): Array<D3TargetCurveRenderer.GraphPoints> {
	const data: Array<D3TargetCurveRenderer.GraphPoints> = [];

	for (const one of all_data) {
		if (selectedCurves.includes(one.type_id) && selectedClusters.includes(one.cluster)) {
			data.push(one);
		}
	}

	return data;
}
