import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@services/notifications.service';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';

const MenuBarInputs = {
	stateDisabled: 'stateDisabled',
	element: 'element',
	cabin: 'cabin'
};

@Component({
	selector: 'app-menu-bar',
	templateUrl: './menu-bar.component.html',
	styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {
	badgeHidden: boolean = false;
	badgeNumber: number = 0;

	@Input() seccion: string;
	disabledAll = false;

	@Input(MenuBarInputs.stateDisabled)
	set setStateDisabled(data: any) {
		this.disabledAll = data;
	}

	@Input(MenuBarInputs.element)
	set setElement(element: string) {
		this.elemento = OneTimeContext.getElementCode();
	}

	@Input(MenuBarInputs.cabin)
	set setCabin(cabin: string) {
		this.cabina = OneTimeContext.getCabinName();
	}

	elemento = 'ELEMENT';
	cabina = 'Cabin';

	stickyMenu: boolean = false;
	@Output() toggleNotify = new EventEmitter<void>();

	constructor(private router: Router, public notify: NotificationsService) {}

	ngOnInit() {
		this.elemento = OneTimeContext.getElementCode();
		this.cabina = OneTimeContext.getCabinName();

		this.notify.notify.subscribe((quantity) => {
			if (quantity) {
				this.badgeNumber = quantity;
				this.badgeHidden = false;
			} else {
				this.badgeHidden = true;
			}
		});
	}

	linkHome() {
		if (this.disabledAll) {
			sessionStorage.removeItem('sessionID');
		}
		this.router.navigateByUrl('dashboard/one-time');
	}

	linkSeasons() {
		this.router.navigateByUrl('dashboard/one-time/seasons');
	}

	linkClusters() {
		this.router.navigateByUrl('dashboard/one-time/clusters');
	}

	linkSummary() {
		this.router.navigateByUrl('dashboard/one-time/summary');
	}

	linkTarget() {
		this.router.navigateByUrl('dashboard/one-time/target-curves');
	}

	linkBuckets() {
		this.router.navigateByUrl('dashboard/one-time/buckets');
	}

	toggleMenu() {
		this.notify.updateNotify(0);
		this.toggleNotify.emit();
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(e: Event) {
		const scrollTop = window.scrollY;
		if (scrollTop > 80) {
			this.stickyMenu = true;
		} else {
			this.stickyMenu = false;
		}
	}
}
