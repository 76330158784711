<div class="menu-lateral" [ngClass]="{ 'menu-lateral-sticky': stickyMenu }">
	<ng-container class="contenido">
		<mat-drawer-container class="mi-mat-drawer-container" [ngStyle]="{ height: menuHeight + 'px' }">
			<mat-drawer #drawer mode="side" [disableClose]="true">
				<mat-card appearance="outlined" class="matcard-propio">
					<mat-card-content class="matcontent-propio">
						<div class="btn-close">
							<mat-icon type="button" (click)="drawer.toggle(); changeBtnShow()"> backspace </mat-icon>
						</div>

						<ng-content select="[menu-content]"></ng-content>
					</mat-card-content>
				</mat-card>
			</mat-drawer>

			<div>
				<div class="contenedor-btn">
					<button type="button" class="btn-side" *ngIf="btnShow" (click)="drawer.toggle(); changeBtnShow()"></button>
				</div>

				<div class="contenedor-page">
					<ng-content select="[content-page]"></ng-content>
				</div>
			</div>
		</mat-drawer-container>
	</ng-container>
</div>
