import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataModalImport, ResponseDataModal } from 'src/app/models/ModalImport';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';

@Component({
	selector: 'app-copia-curva-meta',
	templateUrl: './copia-curva-meta.component.html',
	styleUrls: ['./copia-curva-meta.component.scss']
})
export class CopiaCurvaMetaComponent implements OnInit {
	request: ResponseDataModal;
	selectedValue: Array<string>;
	disabledImport: boolean;

	targetElement: string;
	targetCabin: string;
	targetCabinName: string;
	targetSeason: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: DataModalImport) {}

	ngOnInit() {
		this.disabledImport = true;
		this.targetElement = OneTimeContext.getElementCode();
		this.targetCabin = OneTimeContext.getCabinCode();
		this.targetCabinName = OneTimeContext.getCabinName();

		this.request = {
			targetElement: this.targetElement,
			targetCabinCode: this.targetCabin,
			targetCabinName: this.targetCabinName,
			targetSeason: this.data.targetSeason,
			sourceElement: this.data.sourceElement,
			sourceCabinCode: this.data.sourceCabinCode,
			sourceCabinName: this.data.sourceCabinName,
			sourceSeason: this.data.sourceSeason,
			clusters: []
		};
	}

	cambioCluster(value, index) {
		this.request.clusters[index] = value;

		if (this.request.clusters.filter(Boolean).length === this.data.targetClusters.length) {
			this.disabledImport = false;
		}
	}
}
