<app-menu-bar seccion="buckets" (toggleNotify)="notify.drawer.toggle()"></app-menu-bar>
<app-menu-notify #notify></app-menu-notify>

<div *ngIf="seasons_ready && selectedSeason">
	<app-menu-lateral>
		<ng-container menu-content>
			<app-menu-lateral-inputs
				[switchView]="view"
				[viewForm]="viewForm"
				[viewInputs]="viewInputs"
				viewPage="bucket"
				[selectedSeason]="selectedSeason"
				(baselineClick)="buttonBaselineMenu($event)"
				(baselineLoad)="baselineMenuLoad($event)"
				(baselineChange)="baselineMenuChanges($event)"
				(modelLoad)="modelMenuLoad($event)"
				(modelChange)="modelMenuChanges($event)"
				(calculateClick)="buttonModelOutput($event)"
				(switchClick)="switchEvent($event)"
			>
			</app-menu-lateral-inputs>
		</ng-container>
	</app-menu-lateral>
</div>
<br />
<div class="container">
	<app-menu-superior-global (changeTab)="changeSelectedSeason($event)" [seasons]="seasons"></app-menu-superior-global>

	<app-card-section-global
		*ngIf="selectedSeason"
		[buckets_data_grid_au]="buckets_data_grid_au"
		[buckets_data_grid_bp]="buckets_data_grid_bp"
		[raw_buckets_data_grid_au]="raw_buckets_data_grid_au"
		[raw_buckets_data_grid_bp]="raw_buckets_data_grid_bp"
		[list_seasons]="seasons"
		[disableUndoUpdateBPandAF]="disableUndoUpdateBPandAF"
		(buckets_edited)="onBucketsEdited($event)"
		[baseline_buckets_data_grid_au]="baseline_buckets_data_grid_au"
		[baseline_buckets_data_grid_bp]="baseline_buckets_data_grid_bp"
		[model_output_buckets_data_grid_au]="model_output_buckets_data_grid_au"
		[model_output_buckets_data_grid_bp]="model_output_buckets_data_grid_bp"
		[cluster_pax_list]="cluster_pax_list"
		[buckets_data_summary_grid]="buckets_data_summary_grid"
		[view]="view"
		[disableSave]="disableSave"
		(saveEvent)="save()"
		(refreshEvent)="btnRefresh()"
		(updateBPandAFEvent)="updateBPandAF()"
		(rollbackUpdateBPandAFEvent)="rollbackUpdateBPandAF()"
		(btn_import_buckets)="importBuckets($event)"
		(btn_copy_model_input)="copyModelOutput($event)"
	>
	</app-card-section-global>
	<br />
	<app-plots-buckets *ngIf="bucketsPlotsSalida" [buckets_data_plots]="bucketsPlotsSalida"></app-plots-buckets>
	<br />
	<app-card-informative-tables
		*ngIf="selectedSeason"
		[data_grid_info_table]="data_grid_info_table"
	></app-card-informative-tables>
</div>

<br /><br />
