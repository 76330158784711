<div class="header">
	<img src="../../../../assets/images/logo-dashboard.svg" alt="REM" />
	<ul>
		<li>
			<a routerLink="./one-time" routerLinkActive="active">{{ 'one-time' | translate }}</a>
		</li>
		<li>
			<a routerLink="./parameters" routerLinkActive="active">{{ 'parameters' | translate }}</a>
		</li>
		<li>
			<a routerLink="./link" routerLinkActive="active">{{ 'link' | translate }}</a>
		</li>
		<li>
			<span class="form-inline">
				<select class="form-control" hidden #selectedLang (change)="switchLang(selectedLang.value)">
					<option
						*ngFor="let language of translate.getLangs()"
						[value]="language"
						[selected]="language === translate.currentLang"
					>
						{{ language }}
					</option>
				</select>
			</span>
		</li>
	</ul>
	<a routerLink=".." (click)="closeSession()">{{ 'exit' | translate }}</a>
</div>
