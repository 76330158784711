import { HttpHandler, HttpInterceptor, HttpRequest, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@services/notifications.service';
import moment from 'moment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	private codec = new HttpUrlEncodingCodec();
	constructor(private router: Router, private notify: NotificationsService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		return next.handle(req).pipe(
			catchError((error) => {
				let errorMessage = '';
				console.log('Error:', error);

				if (error instanceof ErrorEvent) {
					// client-side error
					errorMessage = `Client-side error: ${error.error.message}`;
				} else {
					// backend error
					if (typeof error.error === 'object') {
						errorMessage = `Error: (${error.status}) ${error.message}`;
					} else {
						errorMessage = `Error: (${error.status}) ${error.error}`;
					}

					const urlOrigin = error.url;
					let url = urlOrigin;
					let paramsUrl = '';
					let paramsList = [];

					if (urlOrigin.includes('?')) {
						const urlArr = urlOrigin.split('?');
						url = urlArr[0];
						paramsUrl = urlArr[1];
						paramsList = [...this.codec.decodeValue(paramsUrl).split('&')];
					}

					const page = this.router.routerState.snapshot.url;
					const message = errorMessage;
					const body =
						req.body === null
							? null
							: typeof req.body === 'string'
							? JSON.stringify(JSON.parse(req.body), undefined, 4)
							: JSON.stringify(req.body, undefined, 4);
					const dateError = moment().format('DD MMM, HH:mm');
					this.notify.append(page, url, message, paramsUrl, paramsList, req.method, body, dateError);
				}

				// aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.
				return throwError(error);
			})
		);
	}
}
