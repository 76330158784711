export const colors_clusters: Map<number, string> = new Map([
	[1, '#FF3D00'],
	[2, '#99CC33'],
	[3, '#008BFF'],
	[4, '#D236BB'],
	[5, '#FFC700'],
	[6, '#401279'],
	[7, '#24C2B9'],
	[8, '#F20B0B'],
	[9, '#11837C'],
	[10, '#E55CB4'],
	[11, '#5C7A1F'],
	[12, '#DA9901'],
	[13, '#DD5252'],
	[14, '#4658DF'],
	[15, '#9452FF'],
	[16, '#BC6C15'],
	[17, '#FA7950'],
	[18, '#99E1DE'],
	[19, '#7200FF'],
	[20, '#5C5C5C']
]);
