import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { bff } from 'src/environments/environment';
import { Parameter } from '../models/Parameter';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	})
};

@Injectable({
	providedIn: 'root'
})
export class LinkServices {
	private env = `${bff.host}`;

	headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

	constructor(private http: HttpClient) {}

	getCategoryList(): Observable<any> {
		return this.http.get(this.env + bff.getCategory);
	}

	getBusinessList(): Observable<any> {
		return this.http.get(this.env + bff.getBusiness);
	}

	getCabinsList(): Observable<any> {
		return this.http.get(this.env + bff.getCabins);
	}

	getLoadTable(productCategory: number, businessUnit: number, cabin: number): Observable<any> {
		return this.http.get(this.env + bff.getLinksList + businessUnit + '/' + productCategory + '/' + cabin);
	}

	getNewTable(nameProduct: string): Observable<any> {
		return this.http.get(this.env + bff.getTable + nameProduct + '/0');
	}

	getEditLinks(idbussines, category, cabins): Observable<any> {
		return this.http.get(this.env + bff.getEditLinks + idbussines + '/' + category + '/' + cabins);
	}

	getDataLinks(idLinks): Observable<any> {
		return this.http.get(this.env + bff.getDataLinks + idLinks);
	}

	getDataSource(business, category) {
		return this.http.get(this.env + bff.getDataSource + business + '/' + category);
	}

	postNewLinks(parameter: Parameter): Observable<any> {
		return this.http.post(this.env + bff.postNewLinks, parameter, httpOptions);
	}

	putEditParameter(parameter: Parameter): Observable<any> {
		console.log(parameter);
		return this.http.put(this.env + bff.putEditParameter, parameter, httpOptions);
	}

	putEditLinks(parameter): Observable<any> {
		return this.http.post(this.env + bff.putEditLinks, parameter, httpOptions);
	}

	getDataSourceClusters(routerId, cabinsCode) {
		return this.http.get(this.env + bff.dataClusters + routerId + '/' + cabinsCode);
	}

	getDataSourceSeasons(routerId, cabinsCode) {
		return this.http.get(this.env + bff.dataSeason + routerId + '/' + cabinsCode);
	}
}
