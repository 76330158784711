<app-card-container>
	<ng-container content>
		<div class="contenido">
			<div class="contengo">
				<table id="customers">
					<tr>
						<th>Level</th>
						<th>Element</th>
						<th>Cabin</th>
					</tr>
					<tr>
						<td>{{ filter?.level }}</td>
						<td>{{ filter?.element }}</td>
						<td>{{ filter?.cabin }}</td>
					</tr>
				</table>
			</div>
			<div class="kpiHeaders">
				<app-table-completitud [data]="data" [colums]="colums"></app-table-completitud>
			</div>
		</div>
	</ng-container>
	<ng-container actions>
		<button class="btn-commit" [disabled]="disabledCommit" (click)="commitEvent()">Commit</button>
	</ng-container>
</app-card-container>
