import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	ApplyLoadFactorOutInput,
	AskIndicatorsParams,
	BaselineOut,
	BASELINE_BOOKINGS_T,
	DataBaseline,
	DataModelOutput,
	DataProductive,
	DataRefreshModel,
	DataTargetCurves,
	ModelOutputOut,
	ModelSettingOut,
	MODEL_OUTPUT_T,
	ProductiveOut,
	PRODUCTIVE_T,
	RefreshModelOut,
	RefreshModelPayload,
	SmoothCurveOutput,
	TargetCurveApplyFactorOut,
	TargetCurveSessionProgressInput,
	TargetCurvesModelOutputParams,
	TargetCurvesOut,
	TargetCurvesParams,
	TARGET_CURVE_T,
	TCAskIndicators,
	TClusterCurveTable,
	TCTableRow
} from '@services/target-curves/target-curves.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { D3TargetCurveRenderer } from 'src/app/components/one-time/scatterplots/target-curve-scatterplot/D3-target-curve-scatterplot/D3TargetCurveRenderer';
import {
	ApValue,
	createGridApClustersNumbers,
	GridApClusters,
	loadApCluster,
	loadApTotal
} from 'src/app/models/target-curves/GridApClusters';
import { GridModelSetting } from 'src/app/models/target-curves/GridModelSetting';
import { bff } from 'src/environments/environment';

const prefix_array = ['TC', 'M', 'BLB', 'BLC', 'Prod'];

// https://docs.google.com/spreadsheets/d/17Y0oQXLJWcfLMbpopfuRhyBABNyBfiFjrmVgCadlCsc/edit#gid=0

/*
 *
 */
@Injectable({
	providedIn: 'root'
})
export class IndicatorTargetCurveService {
	private host = `${bff.host}`;
	private ENDPOINT_GET_TARGET_CURVES = `${this.host}${bff.getTargetCurves}`;
	private ENDPOINT_GET_BASELINE = `${this.host}${bff.getTargetCurveBaseline}`;
	private ENDPOINT_GET_PRODUCTIVE = `${this.host}${bff.getTargetCurveProductive}`;
	private ENDPOINT_POST_MODEL_OUTPUT = `${this.host}${bff.getTargetCurveModelOutput}`;
	private ENDPOINT_POST_APPLY_LOAD_FACTOR = `${this.host}${bff.getApplyLoadFactorOut}`;
	private ENDPOINT_GET_ASK_INDICATORS = `${this.host}${bff.getAskIndicators}`;
	private ENDPOINT_SMOOTH_CURVES = `${this.host}${bff.smoothCurves}`;
	private ENDPOINT_SAVE_PROGRESS = `${this.host}${bff.saveProgressTargetCurves}`;
	private ENDPOINT_REFRESH = `${this.host}${bff.refreshTarget}`;
	private ENDPOINT_REFRESH_TARGET = `${this.host}${bff.refreshTargetCurves}`;

	constructor(private http: HttpClient) {}

	invokeDataTargetCurves(targetCurvesParams: TargetCurvesParams): Observable<TargetCurvesOut> {
		// TODO: llamar al servicio
		let queryParams: HttpParams;

		queryParams = new HttpParams()
			.set('cabinCode', targetCurvesParams.cabinCode)
			.set('elementCode', targetCurvesParams.elementCode)
			.set('level', targetCurvesParams.level.toString())
			.set('season', targetCurvesParams.season)
			.set('sessionId', targetCurvesParams.sessionId.toString())
			.set('startDateAsk', targetCurvesParams.startDateAsk)
			.set('endDateAsk', targetCurvesParams.endDateAsk);
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}),
			params: queryParams
		};
		return this.http.get(this.ENDPOINT_GET_TARGET_CURVES, httpOptions) as Observable<TargetCurvesOut>;
	}

	getDataTargetCurves(targetCurvesParams: TargetCurvesParams, type_curve: number): Observable<DataTargetCurves> {
		return this.invokeDataTargetCurves(targetCurvesParams).pipe(
			map((service_out) => {
				const orden = service_out.sort((a, b) => {
					return a.cluster.localeCompare(b.cluster, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});

				const [data_grid_pp, data_grid_pax] = transformGrid(orden);
				const data_curve = transformCurve(orden, type_curve);

				return {
					curve: data_curve,
					grid_pp: data_grid_pp,
					grid_pax: data_grid_pax
				};
			})
		);
	}

	// baseline
	invokeDataBaseline(targetCurvesParams: TargetCurvesParams): Observable<BaselineOut> {
		// TODO: llamar al servicio
		let queryParams: HttpParams;

		queryParams = new HttpParams()
			.set('cabinCode', targetCurvesParams.cabinCode)
			.set('elementCode', targetCurvesParams.elementCode)
			.set('level', targetCurvesParams.level.toString())
			.set('season', targetCurvesParams.season)
			.set('sessionId', targetCurvesParams.sessionId.toString())
			.set('levelBaseLine', targetCurvesParams.levelBaseLine)
			.set('elementCodeBaseLine', targetCurvesParams.elementCodeBaseLine)
			.set('cabinCodeBaseLine', targetCurvesParams.cabinCodeBaseLine)
			.set('seasonsBaseLine', JSON.stringify(targetCurvesParams.seasonBaseLine))
			.set('startDateBaseLine', targetCurvesParams.startDateBaseLine)
			.set('endDateBaseLine', targetCurvesParams.endDateBaseLine)
			.set('startDateAsk', targetCurvesParams.startDateAsk)
			.set('endDateAsk', targetCurvesParams.endDateAsk);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}),
			params: queryParams
		};
		return this.http.get(this.ENDPOINT_GET_BASELINE, httpOptions) as Observable<TargetCurvesOut>;
	}

	getDataBaseline(targetCurvesParams: TargetCurvesParams): Observable<DataBaseline> {
		return this.invokeDataBaseline(targetCurvesParams).pipe(
			map((service_out) => {
				const orden = service_out.sort((a, b) => {
					return a.cluster.localeCompare(b.cluster, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});
				const [data_grid_pp, data_grid_pax] = transformGrid(orden);
				const data_curve = transformCurve(orden, BASELINE_BOOKINGS_T);

				const ret: DataBaseline = {
					curve: data_curve,
					grid_pp: data_grid_pp,
					grid_pax: data_grid_pax
				};

				return ret;
			})
		);
	}

	// productive
	invokeProductive(targetCurvesParams: TargetCurvesParams): Observable<ProductiveOut> {
		// TODO: llamar al servicio
		let queryParams: HttpParams;

		queryParams = new HttpParams()
			.set('cabinCode', targetCurvesParams.cabinCode)
			.set('elementCode', targetCurvesParams.elementCode)
			.set('level', targetCurvesParams.level.toString())
			.set('season', targetCurvesParams.season)
			.set('sessionId', targetCurvesParams.sessionId.toString())
			.set('startDateAsk', targetCurvesParams.startDateAsk)
			.set('endDateAsk', targetCurvesParams.endDateAsk);
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}),
			params: queryParams
		};
		return this.http.get(this.ENDPOINT_GET_PRODUCTIVE, httpOptions) as Observable<TargetCurvesOut>;
	}

	getProductive(targetCurvesParams: TargetCurvesParams): Observable<DataProductive> {
		return this.invokeProductive(targetCurvesParams).pipe(
			map((service_out) => {
				const orden = service_out.sort((a, b) => {
					return a.cluster.localeCompare(b.cluster, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});
				const [data_grid_pp, data_grid_pax] = transformGrid(orden);
				const data_curve = transformCurve(orden, PRODUCTIVE_T);

				const ret: DataProductive = {
					curve: data_curve,
					grid_pp: data_grid_pp,
					grid_pax: data_grid_pax
				};

				return ret;
			})
		);
	}

	invokeModelOutput(payload: string): Observable<ModelOutputOut> {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

		// return new Observable((subscriber) => {
		//   subscriber.next(dummy_calculate);
		// });

		return this.http.post(this.ENDPOINT_POST_MODEL_OUTPUT, payload, httpOptions) as Observable<ModelOutputOut>;
	}

	getModelOutput(params: TargetCurvesModelOutputParams): Observable<DataModelOutput> {
		const payload = JSON.stringify(params);

		return this.invokeModelOutput(payload).pipe(
			map((service_out) => {
				const modelOutput = service_out.modelOutput.sort((a, b) => {
					return a.cluster.localeCompare(b.cluster, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});

				const [data_grid_pp, data_grid_pax] = transformGrid(modelOutput);
				const data_curve = transformCurve(service_out.modelOutput, MODEL_OUTPUT_T);

				const trendEffectOutput = service_out.trend.effectOutput.sort((a, b) => {
					return a.cluster.localeCompare(b.cluster, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});
				const [data_grid_trend_pp, data_grid_trend_pax] = transformGrid(trendEffectOutput);

				const postedFlightsEffectOutput = service_out.postedFlights.effectOutput.sort((a, b) => {
					return a.cluster.localeCompare(b.cluster, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});
				const [data_grid_postedflights_pp, data_grid_postedflights_pax] = transformGrid(postedFlightsEffectOutput);

				const modelSetting = service_out.modelSetting.map((model) => ({
					...model,
					clustersMap: model.clusters
						.map((cluster, index) => ({
							clusterKey: cluster,
							clusterValue: model.clustersValues[index]
						}))
						.sort((a, b) => {
							return a.clusterKey.localeCompare(b.clusterKey, undefined, {
								numeric: true,
								sensitivity: 'base'
							});
						})
				}));

				const modelSettingMap = modelSetting.map((model) => ({
					...model,
					clusters: model.clusters.map((_cluster, index) => model.clustersMap[index].clusterKey),
					clustersValues: model.clusters.map((_cluster, index) => model.clustersMap[index].clusterValue)
				}));

				modelSettingMap.forEach((model) => {
					delete model.clustersMap;
				});

				// Se agrega las columnas en las rows
				const trendKpi = service_out.trend.kpi.map((kpi) => ({
					...kpi,
					rows: kpi.rows.map((row) => ({ ...row, columns: kpi.columns }))
				}));

				// Se ordenan los clusters
				const trendKpiSort = trendKpi.map((trend) => ({
					...trend,
					columns: trend.columns.sort((a, b) => {
						return a.localeCompare(b, undefined, {
							numeric: true,
							sensitivity: 'base'
						});
					}),
					rows: trend.rows.map((row) => ({
						...row,
						clusters: row.columns
							.map((_cluster, index) => ({
								key: row.columns[index],
								value: row.values[index]
							}))
							.sort((a, b) => {
								return a.key.localeCompare(b.key, undefined, {
									numeric: true,
									sensitivity: 'base'
								});
							})
					}))
				}));

				// Se actualiza los datos
				const trendKpiMap = trendKpiSort.map((kpi) => ({
					...kpi,
					rows: kpi.rows.map((row, _index) => ({
						...row,
						values: row.clusters.map((value) => value.value)
					}))
				}));

				// Se elimina variables para ordenar
				trendKpiMap.forEach((trend) => {
					trend.rows.forEach((row) => {
						delete row.clusters;
						delete row.columns;
					});
				});

				// Se agrega las columnas en las rows
				const postedFlightsKpi = service_out.postedFlights.kpi.map((kpi) => ({
					...kpi,
					rows: kpi.rows.map((row) => ({ ...row, columns: kpi.columns }))
				}));

				// Se ordenan los clusters
				const postedFlightsKpiSort = postedFlightsKpi.map((posted) => ({
					...posted,
					columns: posted.columns.sort((a, b) => {
						return a.localeCompare(b, undefined, {
							numeric: true,
							sensitivity: 'base'
						});
					}),
					rows: posted.rows.map((row) => ({
						...row,
						clusters: row.columns
							.map((_cluster, index) => ({
								key: row.columns[index],
								value: row.values[index]
							}))
							.sort((a, b) => {
								return a.key.localeCompare(b.key, undefined, {
									numeric: true,
									sensitivity: 'base'
								});
							})
					}))
				}));

				// Se actualiza los datos
				const postedFlightsKpiMap = postedFlightsKpiSort.map((kpi) => ({
					...kpi,
					rows: kpi.rows.map((row, _index) => ({
						...row,
						values: row.clusters.map((value) => value.value)
					}))
				}));

				// Se elimina variables para ordenar
				postedFlightsKpiMap.forEach((trend) => {
					trend.rows.forEach((row) => {
						delete row.clusters;
						delete row.columns;
					});
				});

				const ret: DataModelOutput = {
					curve: data_curve,
					grid_pp: data_grid_pp,
					grid_pax: data_grid_pax,
					model_settings: transformModelSetting(modelSettingMap),
					grid_trend: [data_grid_trend_pp, data_grid_trend_pax],
					grid_trend_kpis: trendKpiMap,
					grid_posted_flight: [data_grid_postedflights_pp, data_grid_postedflights_pax],
					grid_posted_flight_kpis: postedFlightsKpiMap
				};

				return ret;
			})
		);
	}

	invokeApplyLoadFactorOut(payload: string): Observable<TargetCurveApplyFactorOut> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};

		return this.http.post(
			this.ENDPOINT_POST_APPLY_LOAD_FACTOR,
			payload,
			httpOptions
		) as Observable<TargetCurveApplyFactorOut>;
	}

	getApplyLoadFactorOut(params: ApplyLoadFactorOutInput): Observable<DataTargetCurves> {
		const payload = JSON.stringify(params);
		return this.invokeApplyLoadFactorOut(payload).pipe(
			map((service_out) => {
				const [data_grid_pp, data_grid_pax] = transformGrid(service_out);
				const data_curve = transformCurve(service_out, TARGET_CURVE_T);
				return {
					curve: data_curve,
					grid_pp: data_grid_pp,
					grid_pax: data_grid_pax
				};
			})
		);
	}

	getAskIndicators(askIndicatorsParams: AskIndicatorsParams): Observable<TCAskIndicators> {
		// TODO: llamar al servicio
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};
		const payload = JSON.stringify(askIndicatorsParams);
		return this.http.post(this.ENDPOINT_GET_ASK_INDICATORS, payload, httpOptions).pipe(
			map((response) => {
				const data = Object.entries(response).map((m) => {
					const key = m[0];
					const values = m[1].sort((a, b) => {
						return a.cluster.localeCompare(b.cluster, undefined, {
							numeric: true,
							sensitivity: 'base'
						});
					});
					return [key, values];
				});

				return data.reduce((acc, values) => ({ ...acc, [values[0]]: values[1] }), {});
			})
		) as Observable<TCAskIndicators>;
	}

	save(toBeSaved: TargetCurveSessionProgressInput): Observable<TargetCurveSessionProgressInput> {
		const payload = JSON.stringify(toBeSaved);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};

		return this.http.post(
			this.ENDPOINT_SAVE_PROGRESS,
			payload,
			httpOptions
		) as Observable<TargetCurveSessionProgressInput>;
	}

	invokeSmoothCurves(payload: string): Observable<TargetCurveApplyFactorOut> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};
		return this.http.post(this.ENDPOINT_SMOOTH_CURVES, payload, httpOptions) as Observable<TargetCurveApplyFactorOut>;
	}

	smoothCurves(params: SmoothCurveOutput): Observable<DataTargetCurves> {
		const payload = JSON.stringify(params);
		return this.invokeSmoothCurves(payload).pipe(
			map((service_out) => {
				const [data_grid_pp, data_grid_pax] = transformGrid(service_out);
				const data_curve = transformCurve(service_out, TARGET_CURVE_T);
				return {
					curve: data_curve,
					grid_pp: data_grid_pp,
					grid_pax: data_grid_pax
				};
			})
		);
	}

	refreshTargetCurves(payload: Array<TClusterCurveTable>): Observable<TargetCurvesOut> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};
		const body = payload;
		return this.http.post(this.ENDPOINT_REFRESH_TARGET, body, httpOptions) as Observable<TargetCurvesOut>;
	}

	getRefreshTargetCurves(payload: Array<TClusterCurveTable>): Observable<DataTargetCurves> {
		return this.refreshTargetCurves(payload).pipe(
			map((service_out) => {
				const orden = service_out.sort((a, b) => {
					return a.cluster.localeCompare(b.cluster, undefined, {
						numeric: true,
						sensitivity: 'base'
					});
				});
				const [data_grid_pp, data_grid_pax] = transformGrid(orden);
				const data_curve = transformCurve(orden, TARGET_CURVE_T);

				return {
					curve: data_curve,
					grid_pp: data_grid_pp,
					grid_pax: data_grid_pax
				};
			})
		);
	}

	refreshModel(payload: RefreshModelPayload): Observable<RefreshModelOut> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};
		const body = payload;
		return this.http.post(this.ENDPOINT_REFRESH, body, httpOptions) as Observable<RefreshModelOut>;
	}

	getRefreshModel(payload: RefreshModelPayload): Observable<DataRefreshModel> {
		return this.refreshModel(payload).pipe(
			map((service_out) => {
				const [data_grid_pp, data_grid_pax] = transformGrid(service_out.modelOutput);
				const data_curve = transformCurve(service_out.modelOutput, MODEL_OUTPUT_T);

				const ret: DataRefreshModel = {
					curve: data_curve,
					grid_pp: data_grid_pp,
					grid_pax: data_grid_pax,
					model_settings: transformModelSetting(service_out.modelSetting)
				};

				return ret;
			})
		);
	}
}
/*
 *
 *
 */

/*
 *
 */
function getClusters(serviceOut: TargetCurvesOut): Array<number> {
	const clusters = serviceOut
		.map((cluster) => (cluster.cluster.toLowerCase() === 'total' ? 0 : parseInt(cluster.cluster, 10)))
		.filter((cluster) => cluster > 0);

	return clusters;
}

/*
 * -> curves
 */
function transformPoints(clusterData: TClusterCurveTable): Array<D3TargetCurveRenderer.Point> {
	const points = clusterData.curve.map((data) => {
		const ap = data.ap;
		const porc = data.loadFactor * 100.0;

		return new D3TargetCurveRenderer.Point(ap, porc);
	});

	return points;
}

function transformCurve(serviceOut: TargetCurvesOut, type_id: number): Array<D3TargetCurveRenderer.TargetCurve> {
	const ret: Array<D3TargetCurveRenderer.TargetCurve> = [];

	const prefix = prefix_array[type_id];

	for (const clusterData of serviceOut) {
		const name =
			clusterData.cluster.toLowerCase() !== 'total' ? prefix + '_c' + clusterData.cluster : prefix + '_total';
		const points = transformPoints(clusterData);

		const cluster = clusterData.cluster.toLowerCase() !== 'total' ? parseInt(clusterData.cluster, 10) : 0;

		const curve = new D3TargetCurveRenderer.TargetCurve(type_id, cluster, name, points);

		ret.push(curve);
	}

	return ret;
}

/*
 * -> Grids
 */
function transformRows(table: Array<TCTableRow>): Array<ApValue> {
	return table.map((row) => {
		const apValue: ApValue = {
			ap: row.apEnd,
			value_pp: row.loadFactor * 100.0,
			value_pax: row.pax
		};

		return apValue;
	});
}

function transformGrid(serviceOut: TargetCurvesOut): [GridApClusters, GridApClusters] {
	const clusters = getClusters(serviceOut);

	const ap_ends = serviceOut[0].table.map((row) => row.apEnd);

	const grid_pp = createGridApClustersNumbers(clusters, ap_ends);
	const grid_pax = createGridApClustersNumbers(clusters, ap_ends);

	for (const clusterData of serviceOut) {
		const rows = transformRows(clusterData.table);

		if (clusterData.cluster.toLowerCase() === 'total') {
			loadApTotal(grid_pp, grid_pax, rows);
		} else {
			const cluster = parseInt(clusterData.cluster, 10);

			loadApCluster(grid_pp, grid_pax, cluster, rows);
		}
	}

	return [grid_pp, grid_pax];
}

function formatPorcModelSetting(value: number): string {
	const valuePorc = value * 100.0;
	return valuePorc.toFixed(1) + '%';
}

function transformModelSetting(data: Array<ModelSettingOut>): GridModelSetting | undefined {
	if (data.length === 0) {
		return undefined;
	}
	const clusters = data[0].clusters.slice(0, data[0].clusters.length - 1).map((sz) => parseInt(sz, 10));

	const rows: Array<Array<any>> = [];
	const rowsWitthoutCb: Array<number> = [];

	let row_offset = 0;

	for (const data_row of data) {
		const row: Array<any> = [];
		if (data_row.hasCheckbox) {
			if (data_row.checked !== null && data_row.checked !== undefined) {
				row.push(data_row.checked);
			} else {
				row.push(true);
			}
		} else {
			rowsWitthoutCb.push(row_offset);
			row.push('');
		}
		row.push(data_row.name);
		for (const value of data_row.clustersValues) {
			row.push(formatPorcModelSetting(value));
		}

		rows.push(row);

		row_offset++;
	}

	const grid = new GridModelSetting(clusters, rowsWitthoutCb, rows);

	return grid;
}
