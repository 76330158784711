import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class SnackBarService {
	constructor(private snackBar: MatSnackBar) {}

	success(message: string): void {
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackCorrect']
		});
	}

	error(message: string): void {
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackIncorrect']
		});
	}
}
