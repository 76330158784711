import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { D3ClusterInd1Ind2Renderer } from './D3-cluster-ind1-vs-ind2-scatterplot/D3ClusterInd1Ind2Renderer';

var nextId = 0;

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'cluster-ind1-vs-ind2-scatterplot-component',
	templateUrl: './cluster-ind1-vs-ind2-scatterplot-component.html',
	styleUrls: ['./cluster-ind1-vs-ind2-scatterplot-component.scss']
})
export class ClusterInd1VsInd2ScatterplotComponent implements OnChanges, AfterViewInit {
	@Input() data: Array<D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point> = [];

	@Input() title: string = '';
	@Input() titleX: string = '';
	@Input() titleY: string = '';

	ph_id = `ph_d3_cluster_ind1_vs_ind2_${nextId++}`;

	dataClear: Array<D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point> = [];

	initialized = false;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.initialized) {
			this.plot();
		}
	}

	ngAfterViewInit() {
		this.plot();

		this.initialized = true;
	}

	plot() {
		console.log('plot');

		this.dataClear = this.data.filter((x) => !Number.isNaN(x.indicator1) && !Number.isNaN(x.indicator2));

		D3ClusterInd1Ind2Renderer.plot(this.dataClear, this.ph_id, 500, 250, this.titleX, this.titleY);
	}
}
