<div class="title">
	<h2 class="mg0" mat-dialog-title>{{ data.title }}</h2>
</div>

<div mat-dialog-content class="content">
	<div class="target">
		<div class="seccion">Target</div>
		<div class="item">{{ targetElement }}</div>
		<div class="item">{{ targetCabinName }}</div>
		<div class="item">Season {{ data.targetSeason }}</div>
	</div>
	<div class="source">
		<div class="seccion">Source</div>
		<div class="item">{{ data.sourceElement }}</div>
		<div class="item">{{ data.sourceCabinName }}</div>
		<div class="item">Season {{ data.sourceSeason }}</div>
	</div>
</div>
<div mat-dialog-content class="content">
	<div class="target">
		<div *ngFor="let tCluster of data.targetClusters" class="target-input">Target C{{ tCluster }}</div>
	</div>
	<div class="source">
		<mat-form-field *ngFor="let cluster of data.targetClusters; let i = index">
			<mat-select (ngModel)="(selectedValue[i])" name="cluster" (selectionChange)="cambioCluster($event.value, i)">
				<mat-option *ngFor="let cluster of data.clusters" [value]="cluster"> Source C{{ cluster }} </mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</div>

<div mat-dialog-actions>
	<button mat-raised-button [mat-dialog-close]="false" class="btnBack">Cancel</button>
	<button mat-raised-button [mat-dialog-close]="request" [disabled]="disabledImport" class="btnDanger">Import</button>
</div>
