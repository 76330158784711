<div *ngIf="data?.typeDialog === 'warning'" class="circulo text">
	<span class="material-icons icon"> warning </span>
</div>
<div *ngIf="data?.typeDialog === 'danger'" class="circuloDanger text">
	<span class="material-icons icon"> warning </span>
</div>
<div class="text title">
	<h2 class="mg0" mat-dialog-title>{{ data?.title }}</h2>
</div>

<div mat-dialog-content class="text content">
	<p class="mg0">{{ data?.message }}</p>
</div>

<div *ngIf="data?.typeDialog === 'warning'" mat-dialog-actions>
	<button mat-raised-button [mat-dialog-close]="false" class="btnBack">{{ data?.cancelCaption }}</button>
	<button mat-raised-button [mat-dialog-close]="true" class="btnWarning">{{ data?.confirmCaption }}</button>
</div>

<div *ngIf="data?.typeDialog === 'danger'" mat-dialog-actions>
	<button mat-raised-button [mat-dialog-close]="false" class="btnBack">{{ data?.cancelCaption }}</button>
	<button mat-raised-button [mat-dialog-close]="true" class="btnDanger">{{ data?.confirmCaption }}</button>
</div>
