<ng-container class="table-clusters">
	<div class="header-title">{{ title }}</div>
	<hot-table
		[settings]="tableSettings"
		[columns]="columns"
		[data]="data_grid.rows"
		[readOnly]="readOnly"
		[stretchH]="stretchH"
		[afterInit]="onAfterInit"
		[beforeKeyDown]="keyDownCell"
		[hotId]="id"
		class="hot handsontable htColumnHeaders"
	>
	</hot-table>
</ng-container>
