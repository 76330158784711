import { Injectable } from '@angular/core';
import { isNumber } from 'util';

@Injectable()
export class Validate {
	validations: any;
	private type: string;
	private source: any;
	data: any;

	constructor() {}

	public validatorGeneral(value, callback) {
		//console.log("ENTRA A VALIDAR DATA : " + this.data);
		console.log('ENTRA A VALIDAR VALUE: ' + value);
		if (value === null) {
			value = '';
		}

		if (value.toString().includes('%')) {
			callback(false);
			return;
		}

		if (this.data === 'dow') {
			let valueGet = value;
			var newValue = valueGet.split(',');
			let result = newValue.filter((item, index) => {
				return newValue.indexOf(item) === index;
			});

			if (newValue.length !== result.length) {
				callback(false);
				return;
			}
		}

		if (this.data === 'competitors' || this.data === 'pos' || this.data === 'min_class' || this.data === 'max_class') {
			let valueGet = value;

			const typeData = typeof valueGet;
			if (typeData === 'string') {
				value = valueGet.toUpperCase();
			}
		}

		if (this.data === 'competitor') {
			let valueGet = value;
			const typeData = typeof valueGet;

			if (valueGet === 'default' || typeData === 'number') {
			} else {
				value = valueGet.toUpperCase();
			}
		}

		if (
			this.data === 'time_of_day_start' ||
			this.data === 'time_of_day_end' ||
			this.data === 'competitors_time_of_day_start' ||
			this.data === 'competitors_time_of_day_end'
		) {
			let valueGet = value;

			const regex = new RegExp('^-?[0-9][0-9]?:?[0-9][0-9]$');

			if (regex.test(valueGet)) {
				let esNegativo = false;
				let minutos = '';
				let final = '';

				if (valueGet !== '') {
					if (valueGet.includes('-')) {
						esNegativo = true;
					}

					valueGet = valueGet.replace('-', '');
					valueGet = valueGet.replace(':', '');

					minutos = valueGet.substr(-2);

					valueGet = valueGet.slice(0, -2);

					if (esNegativo === true) {
						final = '-';
					}

					if (valueGet.length === 1) {
						final = final + '0' + valueGet;
					} else {
						final = final + valueGet;
					}

					final = final + ':';
					final = final + minutos;

					value = final;
				}
			}
		}

		switch (this.type) {
			case 'numeric':
				//console.log("VALIDA NUMERICO : " + this.source);
				//console.log("VALIDAciones : " + JSON.stringify(this.validations));
				if (this.validations.pattern) {
					const regex = new RegExp(this.validations.pattern);
					if (regex.test(value)) {
						if (this.validations.max && this.validations.min) {
							const timeMin = this.validations.min.split(':');
							const timeMax = this.validations.max.split(':');
							const splitValue = value.split(':');
							console.log(timeMin + ' ' + timeMax + ' ' + splitValue);
							console.log(parseInt(timeMin[0]));
							if (
								splitValue[0] <= timeMax[0] &&
								splitValue[0] >= timeMin[0] &&
								splitValue[1] <= timeMax[1] &&
								splitValue >= timeMin[1]
							) {
								callback(true);
							} else if (splitValue[0] <= timeMax[0]) {
								if (
									(splitValue[0] === timeMax[0] && splitValue[1] <= timeMax[1]) ||
									(splitValue[0] === timeMin[0] && splitValue[1] === timeMin[1])
								) {
									callback(true);
								} else if (splitValue[0] < timeMax[0] && splitValue[0] >= parseInt(timeMin[0])) {
									if (parseInt(splitValue[0]) === parseInt(timeMin[0]) && splitValue[1] === timeMax[1]) {
										callback(true);
									} else if (splitValue[1] >= timeMin[1] && splitValue[1] <= 59) {
										if (splitValue[0] === timeMin[0] && splitValue[1] === timeMin[1]) {
											callback(true);
											break;
										} else if (splitValue[0] === timeMin[0] && splitValue[1] != timeMin[1]) {
											callback(false);
											break;
										} else {
											callback(true);
										}
									} else {
										console.log('The last one 4');
										callback(false);
									}
								} else {
									console.log('The last one 3');
									callback(false);
								}
							} else {
								console.log('The last one');
								callback(false);
							}
						} else {
							callback(true);
						}
					} else if ((regex.test(value) || value === null || value === '') && this.validations.nullable === 'true') {
						callback(true);
					} else {
						callback(false);
					}
				} else if (this.validations.max && this.validations.min) {
					if (this.validations.nullable === 'false' && (value === null || value === '')) {
						callback(false);
						break;
					} else if (this.validations.nullable === 'true' && (value === null || value === '')) {
						callback(true);
						break;
					}
					if (value <= this.validations.max && value >= this.validations.min) {
						//console.log('VALIDACION TRUE');
						callback(true);
					} else {
						callback(false);
					}
				} else if (this.validations.min && !this.validations.max) {
					if (isNumber(value) && this.validations.nullable === 'false' && value >= this.validations.min) {
						console.log(value);
						callback(true);
					} else if (
						((isNumber(value) && value >= this.validations.min) || value === null || value === '') &&
						this.validations.nullable === 'true'
					) {
						callback(true);
					} else {
						console.log(value);
						callback(false);
					}
				}
				break;
			case 'dropdown':
				//console.log("VALIDAR DROPDOWN, value : " +  value);
				//console.log("VALIDAR DROPDOWN, source : " +  this.source);
				if (value === null || value === '') {
					//console.log("VALIDACION DROPDOWN FALSA NULA")
					callback(false);
					break;
				}
				if (this.source.includes(value.toString())) {
					//console.log("VALIDACION DROPDOWN true")
					callback(true);
				} else {
					console.log('VALIDACION DROPDOWN FALSA error');

					callback(false);
				}
				break;
			case 'text':
				if (this.validations.pattern) {
					const regex = new RegExp(this.validations.pattern);
					if (regex.test(value)) {
						if (this.validations.max && this.validations.min) {
							const timeMin = this.validations.min.split(':');
							const timeMax = this.validations.max.split(':');
							const splitValue = value.split(':');
							console.log(timeMin + ' ' + timeMax + ' ' + splitValue);
							console.log(parseInt(timeMin[0]));
							if (
								splitValue[0] <= timeMax[0] &&
								splitValue[0] >= timeMin[0] &&
								splitValue[1] <= timeMax[1] &&
								splitValue >= timeMin[1]
							) {
								callback(true);
							} else if (splitValue[0] <= timeMax[0]) {
								if (
									(splitValue[0] === timeMax[0] && splitValue[1] <= timeMax[1]) ||
									(splitValue[0] === timeMin[0] && splitValue[1] === timeMin[1])
								) {
									callback(true);
								} else if (splitValue[0] < timeMax[0] && splitValue[0] >= parseInt(timeMin[0])) {
									if (parseInt(splitValue[0]) === parseInt(timeMin[0]) && splitValue[1] === timeMax[1]) {
										callback(true);
									} else if (splitValue[1] >= timeMin[1] && splitValue[1] <= 59) {
										if (splitValue[0] === timeMin[0] && splitValue[1] === timeMin[1]) {
											callback(true);
											break;
										} else if (splitValue[0] === timeMin[0] && splitValue[1] != timeMin[1]) {
											callback(false);
											break;
										} else {
											callback(true);
										}
									} else {
										console.log('The last one 4');
										callback(false);
									}
								} else {
									console.log('The last one 3');
									callback(false);
								}
							} else {
								console.log('The last one');
								callback(false);
							}
						} else {
							callback(true);
						}
					} else if ((regex.test(value) || value === null || value === '') && this.validations.nullable === 'true') {
						callback(true);
					} else {
						callback(false);
					}
				} else if (this.validations.max && this.validations.min) {
					if (this.validations.nullable === 'false' && (value === null || value === '')) {
						callback(false);
						break;
					} else if (this.validations.nullable === 'true' && (value === null || value === '')) {
						callback(true);
						break;
					}
					if (value <= this.validations.max && value >= this.validations.min) {
						callback(true);
					} else {
						callback(false);
					}
				} else if (this.validations.min && !this.validations.max) {
					if (isNumber(value) && this.validations.nullable === 'false' && value >= this.validations.min) {
						console.log(value);
						callback(true);
					} else if (
						((isNumber(value) && value >= this.validations.min) || value === null || value === '') &&
						this.validations.nullable === 'true'
					) {
						callback(true);
					} else {
						console.log(value);
						callback(false);
					}
				}
				break;
		}
	}
}
