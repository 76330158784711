export class GridBucketSummary {
	// filas ussadas en la grilla.  Son la concatenacion de las filas de KPI retornadas por el servicio y los KPI's calculados en la UI misma
	rows: Array<Array<any>>;

	// Se inicializa con los KPI's calculados y retornados por el servicio
	constructor(
		public clusters: Array<number>,
		public rows_service: Array<Array<any>>,
		public rows_ui: Array<Array<any>> = []
	) {
		this.rows = rows_service.concat(rows_ui);
	}

	// Actualiza los KPI's que son calculados en la UI
	updateRowsUI(rows_ui: Array<Array<any>>): GridBucketSummary {
		return new GridBucketSummary(this.clusters, this.rows_service, rows_ui);
	}
}
