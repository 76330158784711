import { Component, Input, OnChanges } from '@angular/core';
import Handsontable from 'handsontable';
import { GridModelSetting } from 'src/app/models/target-curves/GridModelSetting';
import { ConfigService } from '../../../../../config/config.service';

interface HTColumn {
	title: string;
	readOnly: boolean;
	type: string;
}

interface HTCell {
	row: number;
	col: number;
	readOnly: boolean;
	type: string;
}

const column_name: HTColumn = {
	title: ' ',
	readOnly: true,
	type: 'text'
};

const column_total: HTColumn = {
	title: 'Total',
	readOnly: true,
	type: 'numeric'
};

const AskIndicatorsInputs = {
	data: 'data'
};

@Component({
	selector: 'app-ask-indicators',
	templateUrl: './ask-indicators.component.html',
	styleUrls: ['./ask-indicators.component.scss']
})
export class AskIndicatorsComponent implements OnChanges {
	grid_model_settings: GridModelSetting | undefined;
	columns: Array<HTColumn> = [];
	cells: Array<HTCell> = [];
	grid_model_settings_ready = false;

	formated_data_ask_indicators: Array<Array<any>> = [];

	data_ask_indicators;
	@Input(AskIndicatorsInputs.data) set DataAskIndicators(data_ask_indicators) {
		this.data_ask_indicators = data_ask_indicators;
	}

	id = 'ht_model_settings';

	tableSettings: Handsontable.GridSettings = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		contextMenu: false,
		dropdownMenu: false,
		language: 'es-MX',
		className: 'htCenter',
		filters: false,
		columnSorting: false,
		allowInsertRow: false,
		viewportColumnRenderingOffset: 1000,
		// colWidths: this.columns_width,

		colWidths(index) {
			if (index === 0) {
				return 90;
			} else {
				return 35;
			}
		}
	};

	constructor(private configService: ConfigService) {}

	ngOnChanges() {
		this.formated_data_ask_indicators = [];
		this.columns = [];
		this.columns.push(column_name);
		if (this.data_ask_indicators !== undefined) {
			for (var row in this.data_ask_indicators) {
				let arrayTemp = [];
				if (row === 'askAy') {
					arrayTemp.push('ASK AY');
				} else if (row === 'askBl') {
					arrayTemp.push('ASK BL');
				} else if (row === 'askrBl') {
					arrayTemp.push('ASK RBL');
				}
				this.data_ask_indicators[row].forEach((element) => {
					if (element.value !== null) {
						if (row === 'askBl' || row === 'askAy') {
							arrayTemp.push(this.formatKorM(element.value));
						} else {
							arrayTemp.push(this.percentFormatNumeric(element.value));
						}
					} else {
						arrayTemp.push(0);
					}
				});
				this.formated_data_ask_indicators.push(arrayTemp);
			}

			this.data_ask_indicators.askAy.forEach((element) => {
				if (element.cluster != 'Total') {
					const column: HTColumn = {
						title: 'C' + element.cluster,
						readOnly: true,
						type: 'numeric'
					};
					this.columns.push(column);
				}
			});
			this.columns.push(column_total);
		}
	}

	formatKorM = (value: number) => {
		if (value === null) {
			return '';
		} else if (value < 1000) {
			return value.toFixed(0);
		} else if (value < 1000000) {
			const miles = value / 1000;
			if (value < 10000) {
				return miles.toFixed(1) + 'k';
			} else {
				return miles.toFixed(0) + 'k';
			}
		} else {
			const millones = value / 1000000;
			if (value < 10000000) {
				return millones.toFixed(1) + 'm';
			} else {
				return millones.toFixed(0) + 'm';
			}
		}
	};

	percentFormatNumeric(value: number): string {
		const percent = value * 100.0;
		return percent.toFixed(1) + '%';
	}
}
