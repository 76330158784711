import { AfterViewInit, Component, ElementRef, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OneTimeServices } from '@services/one-time.service';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { FilterSession, SessionElement } from 'src/app/shared/table-old-sessions/table-old-sessions.component';
import { Filter } from './section-completitud/section-completitud.component';

@Component({
	selector: 'app-summary',
	templateUrl: './summary.component.html',
	styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnDestroy, AfterViewInit {
	stateSession = false;
	dataStorage: SessionElement;
	sendSnackbarActive;

	filterSelect: Filter;

	data = [];
	cols = [];

	element: string;
	cabin: string;

	constructor(private oneTimeService: OneTimeServices, private snackBar: MatSnackBar, private elementRef: ElementRef) {}

	ngAfterViewInit() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'scroll';
	}

	ngOnDestroy() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'hidden';
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}

	onRestSessionLoad(data: FilterSession) {
		console.log('INFO KPIS', data);

		const filter: Filter = {
			level: data.level,
			element: data.element,
			cabin: data.cabinName
		};

		this.filterSelect = filter;
		this.getDataCompletitud(data.level, data.element, data.cabin);
	}

	onRestSession(data: SessionElement) {
		console.log('INFO KPIS 2', data);
		const filter: Filter = {
			level: data.levelName,
			element: data.elementCode,
			cabin: data.cabinName
		};
		this.filterSelect = filter;
		this.dataStorage = data;
		this.getDataCompletitud(data.levelCode, data.elementCode, data.cabinCode);
	}

	changeStateSession(rest: boolean) {
		this.stateSession = rest;
		this.element = OneTimeContext.getElementCode();
		this.cabin = OneTimeContext.getCabinName();
	}

	commitSession() {
		const session = OneTimeContext.getSessionID();
		this.oneTimeService.getCommitSession(session).subscribe(
			(response) => {
				console.log(response);
				this.openSnackBarCorrect('Session commited successfully');
				this.stateSession = true;
				OneTimeContext.deleteSessionStorage();
			},
			(err) => {
				console.log('Unable to commit session', err);
				this.openSnackBarIncorrect('Unable to commit session');
			}
		);
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackIncorrect']
		});
	}

	getDataCompletitud(level: string, element: string, cabin: string) {
		const sessionId = OneTimeContext.getSessionID();
		this.oneTimeService.getSourceKPI(level, element, cabin, sessionId).subscribe(
			(res) => {
				this.data = [...res.data];
				this.cols = [...res.columns];
			},
			(error) => {
				console.log('getDataCompletitud', error);
			}
		);
	}
}
