import { Component, Input } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { ConfigService } from '../../config/config.service';

type RendererFunc = (
	instance: Handsontable,
	TD: HTMLTableCellElement,
	row: number,
	col: number,
	prop: number | string,
	value: number | string,
	cellProperties: any
) => void;

export interface ColumnCompletitud {
	data: string;
	numericFormat?: { pattern?: string } | null;
	readOnly?: boolean;
	title: string;
	type: 'numeric' | 'text';
	placeholder?: string;
	renderer?: string | RendererFunc;
}

export interface DataCompletitud {
	season: string;
	seasonCommited: boolean;
	ask: number;
	clusters: number;
	clustersCommited: boolean;
	targetCurves: number;
	targetCurvesCommited: boolean;
	buckets: number;
	bucketsCommited: boolean;
}

const TableCompletitudInputs = {
	data: 'data',
	colums: 'colums'
};

@Component({
	selector: 'app-table-completitud',
	templateUrl: './table-completitud.component.html',
	styleUrls: ['./table-completitud.component.scss']
})
export class TableCompletitudComponent {
	tableSettings: Handsontable.GridSettings = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		contextMenu: true,
		stretchH: 'all',
		dropdownMenu: false,
		manualColumnMove: false,
		filters: false,
		language: 'es-MX',
		rowHeaders: false,
		columnSorting: false,
		allowInsertRow: false,
		copyPaste: false,
		readOnly: true,
		className: 'htCenter'
	};

	id = 'hotInstance';
	private readonly hotRegisterer = new HotTableRegisterer();
	private hotInstance: Handsontable;

	data: DataCompletitud[] = [];
	cols: ColumnCompletitud[] = [];

	@Input(TableCompletitudInputs.data)
	set setData(data: DataCompletitud[]) {
		this.data = [...data];
	}

	@Input(TableCompletitudInputs.colums)
	set setColumns(cols: ColumnCompletitud[]) {
		const addRenderer = cols.map((c) => ({ renderer: this.rendererColum, ...c }));
		this.cols = [...addRenderer];
	}

	constructor(private readonly configService: ConfigService) {}

	afterGetColHeader = (col: number, th: HTMLTableCellElement) => {
		th.style.backgroundColor = '#1b0088';
		th.style.color = 'white';
		th.style.fontWeight = 'regular';
	};

	afterChange = (changes: Array<any>, source: string) => {
		if ((source === 'loadData' || source === 'updateData') && this.data.length !== 0) {
			this.hotInstance = this.hotRegisterer.getInstance(this.id);
			this.addLastRow();
		}
	};

	addLastRow() {
		this.hotInstance.alter('insert_row_below');
		const data: DataCompletitud[] = this.hotInstance.getSettings().data as DataCompletitud[];
		const indexRowTotal = data.length - 1;

		const valueRowTotal = data.map((m) => m.ask).reduce((acc, value) => acc + value, 0.0);

		this.hotInstance.setDataAtCell(indexRowTotal, 0, 'TOTAL');
		this.hotInstance.setDataAtCell(indexRowTotal, 1, valueRowTotal);
	}

	rendererColum: RendererFunc = (instance, TD, row, col, prop, value, cellProperties) => {
		const argumentsRender = [instance, TD, row, col, prop, value, cellProperties];
		const type = cellProperties && cellProperties.type;
		const cellData: any = instance.getSettings().data[row];
		const rowTotalNumber = instance.getSettings().data.length - 1;

		const colors = {
			yellow: '#FEE644',
			green: '#7FD698',
			red: '#F19F9A'
		};
		TD.className = 'htCenter';

		switch (type) {
			case 'numeric':
				Handsontable.renderers.NumericRenderer.apply(this, argumentsRender);
				break;
			case 'text':
				Handsontable.renderers.TextRenderer.apply(this, argumentsRender);
				break;
		}

		this.rendererSeasons(prop, cellData, TD, colors);
		this.rendererAsk(prop, cellData, TD, row, rowTotalNumber, colors);
		this.rendererClusters(prop, cellData, TD, row, rowTotalNumber, colors);
		this.rendererTargetCurves(prop, cellData, TD, row, rowTotalNumber, colors);
		this.rendererBuckets(prop, cellData, TD, row, rowTotalNumber, colors);
	};

	rendererSeasons(prop: string | number, cellData: any, TD: HTMLTableCellElement, colors: any): void {
		if (prop === 'season') {
			if (cellData.seasonCommited === false) {
				TD.style.backgroundColor = colors.yellow;
				TD.style.color = '#303030';
			}
		}
	}

	rendererAsk(
		prop: string | number,
		cellData: any,
		TD: HTMLTableCellElement,
		row: number,
		rowTotalNumber: number,
		colors: any
	): void {
		if (prop === 'ask') {
			if (rowTotalNumber === row) {
				if (cellData.ask > 0.9995) {
					TD.style.backgroundColor = colors.green;
					TD.style.color = '#303030';
				} else {
					TD.style.backgroundColor = colors.red;
					TD.style.color = '#303030';
				}
			}
		}
	}

	rendererClusters(
		prop: string | number,
		cellData: any,
		TD: HTMLTableCellElement,
		row: number,
		rowTotalNumber: number,
		colors: any
	): void {
		if (prop === 'clusters') {
			if (rowTotalNumber !== row) {
				if (cellData.clustersCommited === false) {
					TD.style.backgroundColor = colors.yellow;
					TD.style.color = '#303030';
				} else if (cellData.clusters === 0) {
					TD.style.backgroundColor = colors.red;
					TD.style.color = '#303030';
				}
			}
		}
	}

	rendererTargetCurves(
		prop: string | number,
		cellData: any,
		TD: HTMLTableCellElement,
		row: number,
		rowTotalNumber: number,
		colors: any
	): void {
		if (prop === 'targetCurves') {
			if (rowTotalNumber !== row) {
				if (cellData.targetCurvesCommited === false) {
					TD.style.backgroundColor = colors.yellow;
					TD.style.color = '#303030';
				} else if (cellData.targetCurves === 1.0) {
					TD.style.backgroundColor = colors.green;
					TD.style.color = '#303030';
				} else {
					TD.style.backgroundColor = colors.red;
					TD.style.color = '#303030';
				}
			}
		}
	}

	rendererBuckets(
		prop: string | number,
		cellData: any,
		TD: HTMLTableCellElement,
		row: number,
		rowTotalNumber: number,
		colors: any
	): void {
		if (prop === 'buckets') {
			if (rowTotalNumber !== row) {
				if (cellData.bucketsCommited === false) {
					TD.style.backgroundColor = colors.yellow;
					TD.style.color = '#303030';
				} else if (cellData.buckets === 1.0) {
					TD.style.backgroundColor = colors.green;
					TD.style.color = '#303030';
				} else {
					TD.style.backgroundColor = colors.red;
					TD.style.color = '#303030';
				}
			}
		}
	}
}
