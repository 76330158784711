import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { GridApClusters } from 'src/app/models/target-curves/GridApClusters';
import { ConfigService } from '../../../../../config/config.service';

interface HTColumn {
	title: string;
	readOnly: boolean;
	renderer?: (
		hotInstance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties
	) => void;
}

const column_ap: HTColumn = {
	title: 'AP',
	readOnly: true
};

const column_total: HTColumn = {
	title: 'Total',
	readOnly: true
};

let nextId = 0;

const GridInformativeTableInputs = {
	data_grid: 'data_grid'
};

@Component({
	selector: 'app-grid-informative-table',
	templateUrl: './grid-informative-table.component.html',
	styleUrls: ['./grid-informative-table.component.scss']
})
export class GridInformativeTableComponent {
	@Input() title = '';
	@Input() readOnly = true;
	@Input() show_ap = true;

	private hotRegisterer = new HotTableRegisterer();
	columns_width: Array<number> = [];
	data_grid: GridApClusters;
	columns: Array<HTColumn> = [];
	ready: boolean;
	@Input(GridInformativeTableInputs.data_grid) set setDataGrid(data: GridApClusters) {
		if (typeof data !== 'undefined') {
			this.data_grid = data;
			this.ready = true;
			this.columns = [];
			this.columns.push(column_ap);

			for (const cluster of data.clusters) {
				if (cluster < data.clusters.length) {
					this.columns.push({
						title: 'C' + cluster,
						readOnly: this.readOnly,
						renderer: this.callRendererGrid
					});
				} else {
					this.columns.push({
						title: cluster.toString().toUpperCase(),
						readOnly: this.readOnly,
						renderer: this.callRendererGrid
					});
				}
			}

			const widths = witdhCols(this.data_grid.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];
			this.columns_width = [];
			// ap
			this.columns_width.push(width_ap);
			// clusters
			for (const cluster of this.data_grid.clusters) {
				this.columns_width.push(width_cv);
			}
			// total
			this.columns_width.push(width_ap);
		}
	}

	@Input() rendererGrid: (
		instance: Handsontable,
		TD: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridApClusters,
		editable: boolean
	) => void;

	id = 'hot-id-buckets-inf-' + nextId++;

	// Configuraciones Handsontable
	tableSettings(): Handsontable.GridSettings {
		if (this.show_ap) {
			return {
				licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
				contextMenu: false,
				dropdownMenu: false,
				language: 'es-MX',
				className: 'htCenter',
				filters: false,
				columnSorting: false,
				allowInsertRow: false,
				viewportColumnRenderingOffset: 1000,
				colWidths: this.columns_width
			};
		} else {
			return {
				licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
				contextMenu: false,
				dropdownMenu: false,
				language: 'es-MX',
				className: 'htCenter',
				filters: false,
				columnSorting: false,
				allowInsertRow: false,
				viewportColumnRenderingOffset: 1000,
				colWidths: this.columns_width,
				hiddenColumns: {
					columns: [0]
				}
			};
		}
	}

	calculateColumnSum(colNumber: number, totalRows: number) {
		let sum = 0;
		for (let i = 0; i < totalRows - 1; i++) {
			sum = sum + Number(this.data_grid.rows[i][colNumber]);
		}
		//TODO: Refrescar celda total
		this.data_grid.rows[totalRows - 1][colNumber] = sum;
		this.hotRegisterer.getInstance(this.id).loadData(this.data_grid.rows);
		this.hotRegisterer.getInstance(this.id).render();
		this.changeDetectorRef.detectChanges();
	}

	stretchH(): string {
		if (this.show_ap) return 'none';
		else return 'all';
	}

	isReady(): boolean {
		return this.ready;
	}

	callRendererGrid = (
		hotInstance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties
	) => {
		if (typeof this.rendererGrid !== 'undefined') {
			let value_num = 0;
			if (col !== 1) {
				value_num = parseFloat(value);
			} else {
				value_num = parseInt(value, 10);
			}
			this.rendererGrid(hotInstance, td, row, col, prop, value_num, cellProperties, this.data_grid, !this.readOnly);
		} else {
			Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, col, prop, value, cellProperties]);
		}
	};

	constructor(private changeDetectorRef: ChangeDetectorRef, private configService: ConfigService) {}
}

function witdhCols(num_clusters: number): [number, number] {
	if (num_clusters === 1) {
		return [120, 100];
	} else if (num_clusters === 2) {
		return [110, 75];
	} else if (num_clusters === 3) {
		return [100, 70];
	} else if (num_clusters === 4) {
		return [80, 60];
	} else if (num_clusters === 5) {
		return [80, 50];
	} else if (num_clusters <= 7) {
		return [60, 42];
	} else {
		return [52, 32]; // [49, 26]
	}
}
