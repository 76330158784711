import { LocalDate } from '@js-joda/core';
import { RowCalendar } from './RowCalendar';

export class MonthCalendar<T> {
	constructor(
		public year: number,
		public month: string,
		public first_day_first_row: LocalDate,
		public rows: Array<RowCalendar<T>>
	) {}
}
