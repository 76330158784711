import { Component } from '@angular/core';

@Component({
	selector: 'app-load-main',
	templateUrl: './load-main.component.html',
	styleUrls: ['./load-main.component.scss']
})
export class LoadMainComponent {
	constructor() {}
}
