<app-menu-bar seccion="seasons" (toggleNotify)="notify.drawer.toggle()"></app-menu-bar>
<app-menu-notify #notify></app-menu-notify>

<app-menu-lateral>
	<ng-container menu-content>
		<app-menu-seasons (clickUpdateBaseline)="getUpdateBaseline($event)" (infoMenu)="updateMenu($event)">
		</app-menu-seasons>
	</ng-container>
</app-menu-lateral>

<div class="container mb-container">
	<app-card-container class="calendar-seasons" [styleContent]="{ height: '600px', 'overflow-y': 'scroll' }">
		<ng-container content>
			<div class="container">
				<div class="row" *ngFor="let calendar of calendars; let i = index">
					<div class="col-md-6">
						<app-calendar-container
							titleCalendar="Outbound"
							[elementCode]="outboundElementCode"
							[month]="calendar.month"
							[year]="calendar.year"
							[multi]="true"
						>
							<app-calendar-rem
								(estadoEdit)="editCampo($event)"
								stretchH="none"
								[readOnly]="false"
								[rowHeaders]="true"
								[calendarType]="firstCalendarType"
								[columns]="columns"
								[data]="calendar.outboundSeasonCalendar.calendar"
								[keyDelete]="true"
							>
							</app-calendar-rem>
							<app-calendar-rem
								stretchH="none"
								[readOnly]="true"
								[rowHeaders]="false"
								[calendarType]="calendarType"
								[columns]="columns"
								[minValue]="calendar.outboundIndicatorCalendar.minValue"
								[indicatorType]="selectIndicadores"
								[maxValue]="calendar.outboundIndicatorCalendar.maxValue"
								[data]="calendar.outboundIndicatorCalendar.calendar"
							>
							</app-calendar-rem>
						</app-calendar-container>
					</div>
					<div class="col-md-6">
						<app-calendar-container
							titleCalendar="Inbound"
							[elementCode]="inboundElementCode"
							[month]="calendar.month"
							[year]="calendar.year"
							[multi]="true"
						>
							<app-calendar-rem
								(estadoEdit)="editCampo($event)"
								[readOnly]="false"
								[rowHeaders]="true"
								stretchH="none"
								[calendarType]="firstCalendarType"
								[columns]="columns"
								[data]="calendar.inboundSeasonCalendar.calendar"
								[keyDelete]="true"
							>
							</app-calendar-rem>
							<app-calendar-rem
								stretchH="none"
								[readOnly]="true"
								[rowHeaders]="false"
								[calendarType]="calendarType"
								[columns]="columns"
								[indicatorType]="selectIndicadores"
								[minValue]="calendar.inboundIndicatorCalendar.minValue"
								[maxValue]="calendar.inboundIndicatorCalendar.maxValue"
								[data]="calendar.inboundIndicatorCalendar.calendar"
							>
							</app-calendar-rem>
						</app-calendar-container>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container actions>
			<div class="contenedor-btn">
				<div class="btn-select">
					<mat-form-field class="indicadores-seasons">
						<button class="btn-holiday">
							<mat-select [(value)]="selectIndicadores" (selectionChange)="desplegarCalendario()">
								<mat-option value="model_output">Model output</mat-option>
								<mat-option value="rask">RASK</mat-option>
								<mat-option value="yield">Yield</mat-option>
								<mat-option value="load_factor">Load Factor</mat-option>
								<mat-option value="posted_flights">Posted Flights</mat-option>
								<mat-option value="baseline_seasons">Baseline Seasons</mat-option>
							</mat-select>
						</button>
					</mat-form-field>
				</div>
				<div class="btn-trio">
					<button class="btn-copy" (click)="copyAll()" [disabled]="copyDisabled" [hidden]="copyDisabled">
						{{ copyAllName }}
					</button>
					<button class="btn-copy" (click)="updateKPIs()" [disabled]="disabledUpdate">Update KPIs</button>
					<button class="btn-save" (click)="saveSeasons()">Save</button>
				</div>
			</div>
		</ng-container>
	</app-card-container>

	<app-card-container class="table-ask-rask" [actionsView]="false">
		<ng-container content>
			<app-asks-por-season [kpis]="asksPorSeasonKpis"></app-asks-por-season>
		</ng-container>
	</app-card-container>

	<app-card-container [styleContent]="{ height: '800px', 'overflow-y': 'scroll' }" [actionsView]="false">
		<ng-container content>
			<app-out-in-from-to
				(messageEvent)="receiveMessage($event)"
				[year_from_min]="year_from_min"
				[month_from_min]="month_from_min"
				[year_to_max]="year_to_max"
				[month_to_max]="month_to_max"
			>
			</app-out-in-from-to>
			<div class="seasons-container">
				<div class="plot">
					<app-dia-season-valor-scatterplot
						title="Baseline Rask"
						[year_from]="year_from"
						[month_from]="month_from"
						[year_to]="year_to"
						[month_to]="month_to"
						[outbound_data]="outbound_data_rask"
						[inbound_data]="inbound_data_rask"
						[out_in]="out_in"
					>
					</app-dia-season-valor-scatterplot>
				</div>

				<div class="plot">
					<app-dia-season-valor-scatterplot
						title="Baseline Yield"
						[year_from]="year_from"
						[month_from]="month_from"
						[year_to]="year_to"
						[month_to]="month_to"
						[outbound_data]="outbound_data_yield"
						[inbound_data]="inbound_data_yield"
						[out_in]="out_in"
					>
					</app-dia-season-valor-scatterplot>
				</div>
				<!-- TO-DO: los otros indicadores -->
				<div class="plot">
					<app-dia-season-valor-scatterplot
						title="Load Factor"
						[year_from]="year_from"
						[month_from]="month_from"
						[year_to]="year_to"
						[month_to]="month_to"
						[outbound_data]="outbound_data_load_factor"
						[inbound_data]="inbound_data_load_factor"
						[out_in]="out_in"
					>
					</app-dia-season-valor-scatterplot>
				</div>
				<div class="plot">
					<app-dia-season-valor-scatterplot
						title="Posted Flights"
						[year_from]="year_from"
						[month_from]="month_from"
						[year_to]="year_to"
						[month_to]="month_to"
						[outbound_data]="outbound_data_posted_flights"
						[inbound_data]="inbound_data_posted_flights"
						[out_in]="out_in"
					>
					</app-dia-season-valor-scatterplot>
				</div>
			</div>
		</ng-container>
	</app-card-container>
</div>
