<div class="divided-content">
	<div class="content-header-table">
		<p class="text-header-table">{{ todToolsTitle }}</p>
		<mat-icon matPrefix (click)="removeHours()" class="color-btn">remove_circle</mat-icon>
		<mat-icon matPrefix (click)="addHours()" class="color-btn">add_circle</mat-icon>
	</div>
	<mat-table [dataSource]="dataSource">
		<ng-container matColumnDef="tod-start">
			<th mat-header-cell *matHeaderCellDef class="tod-tools-th">TOD Start</th>
			<td mat-cell *matCellDef="let element; let i = index">
				<div>
					<input
						[formControl]="todStart"
						type="time"
						matInput
						name="filter"
						(change)="changeStartHour(i)"
						[readonly]="i === '0'"
						value="{{ element.start }}"
						[ngClass]="{ errorFechaClass: validaErrorFechas }"
					/>
				</div>
			</td>
		</ng-container>
		<ng-container matColumnDef="tod-end">
			<th mat-header-cell *matHeaderCellDef class="tod-tools-th">TOD End</th>
			<td mat-cell *matCellDef="let element">
				<div>
					<input [formControl]="todEnd" type="time" matInput name="filter" value="{{ element.end }}" />
				</div>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</mat-table>
</div>
