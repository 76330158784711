import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-calendar-container',
	templateUrl: './calendar-container.component.html',
	styleUrls: ['./calendar-container.component.scss']
})
export class CalendarContainerComponent {
	@Input() elementCode: string;
	@Input() month = null;
	@Input() year = null;
	@Input() titleCalendar: string;
	@Input() multi = false;

	get getTitle() {
		return `${this.titleCalendar} - ${this.elementCode}`;
	}
}
