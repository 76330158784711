export class Color {
	constructor(public r: number, public g: number, public b: number, public a: number) {}

	toHex() {
		return '#' + this.r.toString(16) + this.g.toString(16) + this.b.toString(16);
	}

	toRgb() {
		return `rgb(${this.r}, ${this.g}, ${this.b})`;
	}

	toRgba() {
		return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
	}
}
