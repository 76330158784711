import { Injectable } from '@angular/core';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { RemCalendarService } from '@services/calendars/rem-calendar.service';
import { OneTimeServices } from '@services/one-time.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Bounds } from 'src/app/models/Bounds';
import { createCalendars, PeriodCalendarStructure } from '../../models/calendars/PeriodCalendarStructure';

export interface IndicatorsCalendars {
	rask: Bounds<PeriodCalendarStructure<number>>;
	yield: Bounds<PeriodCalendarStructure<number>>;
	loadFactor: Bounds<PeriodCalendarStructure<number>>;
	postedFlights: Bounds<PeriodCalendarStructure<number>>;
	baselineSeasons: Bounds<PeriodCalendarStructure<string>>;
}

@Injectable({
	providedIn: 'root'
})
export class IndicatorCalendarService {
	constructor(private oneTimeService: OneTimeServices, private remCalendarService: RemCalendarService) {}

	loadInCalendar<T>(calendar: PeriodCalendarStructure<T>, dateRangeCellData: any[], queryField: string) {
		const formater = DateTimeFormatter.ofPattern('yyyy-MM-dd');

		for (var dailyCellData of dateRangeCellData) {
			const dt = LocalDate.parse(dailyCellData.flight_date, formater);

			const value = dailyCellData[queryField];

			calendar.updateOneCell(dt, value);
		}
	}

	generateIndicatorCalendars(
		initDate: LocalDate,
		endDate: LocalDate,
		cabinCode: string,
		elementCode: string,
		year: number,
		level: string,
		baseLineLevel: string,
		baseLineElementCode: string,
		baseLineCabinCode: string,
		baseLineYear: number,
		monthsNumber: number
	): Observable<IndicatorsCalendars> {
		return this.oneTimeService
			.getBaselines(
				cabinCode,
				elementCode,
				year,
				level,
				baseLineLevel,
				baseLineElementCode,
				baseLineCabinCode,
				baseLineYear
			)
			.pipe(
				map((response: any) => {
					console.log('getBaselines', response);
					var inboundRaskCalendars = createCalendars(initDate.year(), initDate.monthValue(), monthsNumber, NaN);
					var inboundYieldCalendars = createCalendars(initDate.year(), initDate.monthValue(), monthsNumber, NaN);
					var inboundLoadFactorCalendars = createCalendars(initDate.year(), initDate.monthValue(), monthsNumber, NaN);
					var inboundPostedFlightsCalendars = createCalendars(
						initDate.year(),
						initDate.monthValue(),
						monthsNumber,
						NaN
					);
					var inboundBaselineSeasonsCalendars = createCalendars(
						initDate.year(),
						initDate.monthValue(),
						monthsNumber,
						''
					);

					var outboundRaskCalendars = createCalendars(initDate.year(), initDate.monthValue(), monthsNumber, NaN);
					var outboundYieldCalendars = createCalendars(initDate.year(), initDate.monthValue(), monthsNumber, NaN);
					var outboundLoadFactorCalendars = createCalendars(initDate.year(), initDate.monthValue(), monthsNumber, NaN);
					var outboundPostedFlightsCalendars = createCalendars(
						initDate.year(),
						initDate.monthValue(),
						monthsNumber,
						NaN
					);
					var outboundBaselineSeasonsCalendars = createCalendars(
						initDate.year(),
						initDate.monthValue(),
						monthsNumber,
						''
					);

					response.forEach((r) => {
						if (r.Rask === null) {
							r.Rask = undefined;
						}
						if (r.Yield === null) {
							r.Yield = undefined;
						}
						if (r.Load_Factor === null) {
							r.Load_Factor = undefined;
						}
						if (r.Posted_Flights === null) {
							r.Posted_Flights = undefined;
						}
						if (r.Baseline_Seasons === null) {
							r.Baseline_Seasons = undefined;
						}
					});

					const inboundData = response.filter((s) => s.bound === 'INBOUND');
					const outboundData = response.filter((s) => s.bound === 'OUTBOUND');

					this.loadInCalendar(inboundRaskCalendars, inboundData, 'Rask');
					this.loadInCalendar(inboundYieldCalendars, inboundData, 'Yield');
					this.loadInCalendar(inboundLoadFactorCalendars, inboundData, 'Load_Factor');
					this.loadInCalendar(inboundPostedFlightsCalendars, inboundData, 'Posted_Flights');
					this.loadInCalendar(inboundBaselineSeasonsCalendars, inboundData, 'Baseline_Seasons');
					this.loadInCalendar(outboundRaskCalendars, outboundData, 'Rask');
					this.loadInCalendar(outboundYieldCalendars, outboundData, 'Yield');
					this.loadInCalendar(outboundLoadFactorCalendars, outboundData, 'Load_Factor');
					this.loadInCalendar(outboundPostedFlightsCalendars, outboundData, 'Posted_Flights');
					this.loadInCalendar(outboundBaselineSeasonsCalendars, outboundData, 'Baseline_Seasons');

					return {
						rask: {
							outbound: outboundRaskCalendars,
							inbound: inboundRaskCalendars
						},
						yield: {
							outbound: outboundYieldCalendars,
							inbound: inboundYieldCalendars
						},
						loadFactor: {
							outbound: outboundLoadFactorCalendars,
							inbound: inboundLoadFactorCalendars
						},
						postedFlights: {
							outbound: outboundPostedFlightsCalendars,
							inbound: inboundPostedFlightsCalendars
						},
						baselineSeasons: {
							outbound: outboundBaselineSeasonsCalendars,
							inbound: inboundBaselineSeasonsCalendars
						}
					};
				})
			);
	}
}
