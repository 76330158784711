import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClusterAPOut, DataInformativeKPI } from '@services/buckets/buckets.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createGridBucketApClustersNumbers, GridBucketApClusters } from 'src/app/models/buckets/GridBucketApClusters';
import { bff } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class BucketInformativeApService {
	private host = `${bff.host}`;
	private ENDPOINT_GET_BUCKET_KPI = `${this.host}${bff.getBucketKpi}`;
	headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	});

	constructor(private http: HttpClient) {}

	invokePostBucketKpiData(bucketKPIParams): Observable<ClusterAPOut> {
		const httpOptions = {
			headers: this.headers
		};
		const bucketKpiBody = bucketKPIParams;

		return this.http.post(this.ENDPOINT_GET_BUCKET_KPI, bucketKpiBody, httpOptions) as Observable<Array<any>>;
	}

	getDataInformativeAP(params): Observable<DataInformativeKPI> {
		return this.invokePostBucketKpiData(params).pipe(
			map((service_out) => {
				const [data_grid_av_bp, data_grid_lf_au, data_grid_av_bp_bsln, data_grip_pax_bsln] = transformGrid(service_out);
				return {
					grid_average_bp: data_grid_av_bp,
					grid_lf_composition: data_grid_lf_au,
					grid_average_bp_vs_baseline: data_grid_av_bp_bsln,
					grid_pax_vs_baseline: data_grip_pax_bsln
				};
			})
		);
	}

	getValidBucketKpiData() {
		return {
			level: '1',
			elementCode: 'AQPLIM',
			cabinCode: 'Y',
			season: 'L',
			sessionId: 50,
			levelBaseLine: '1',
			elementCodeBaseLine: 'AQPLIM',
			cabinCodeBaseLine: 'Y',
			seasonsBaseLine: ['L'],
			startDateBaseLine: '2020-12-01',
			endDateBaseLine: '2021-11-30',
			buckets: [
				{
					control: 'AU',
					clusters: [
						{
							cluster: '1',
							table: [
								{
									bucketNumber: 1,
									bucket: 'Y',
									paxShare: 0.16,
									paxShareRaw: null,
									fare: 1500,
									fareRaw: null
								},
								{
									bucketNumber: 2,
									bucket: 'B',
									paxShare: 0.16,
									paxShareRaw: null,
									fare: 1400,
									fareRaw: null
								}
							]
						},
						{
							cluster: '2',
							table: [
								{
									bucketNumber: 1,
									bucket: 'Y',
									paxShare: 0.17,
									paxShareRaw: null,
									fare: 1500,
									fareRaw: null
								},
								{
									bucketNumber: 2,
									bucket: 'B',
									paxShare: 0.17,
									paxShareRaw: null,
									fare: 1400,
									fareRaw: null
								}
							]
						},
						{
							cluster: '3',
							table: [
								{
									bucketNumber: 1,
									bucket: 'Y',
									paxShare: 0.18,
									paxShareRaw: null,
									fare: 1500,
									fareRaw: null
								},
								{
									bucketNumber: 2,
									bucket: 'B',
									paxShare: 0.18,
									paxShareRaw: null,
									fare: 1400,
									fareRaw: null
								}
							]
						}
					]
				},
				{
					control: 'BP',
					clusters: [
						{
							cluster: '1',
							table: [
								{
									bucketNumber: 1,
									bucket: 'B1',
									paxShare: 0.16,
									paxShareRaw: null,
									fare: 1400,
									fareRaw: null
								},
								{
									bucketNumber: 2,
									bucket: 'B2',
									paxShare: 0.16,
									paxShareRaw: null,
									fare: 1200,
									fareRaw: null
								}
							]
						},
						{
							cluster: '2',
							table: [
								{
									bucketNumber: 1,
									bucket: 'B1',
									paxShare: 0.17,
									paxShareRaw: null,
									fare: 1400,
									fareRaw: null
								},
								{
									bucketNumber: 2,
									bucket: 'B2',
									paxShare: 0.17,
									paxShareRaw: null,
									fare: 1200,
									fareRaw: null
								}
							]
						},
						{
							cluster: '3',
							table: [
								{
									bucketNumber: 1,
									bucket: 'B1',
									paxShare: 0.18,
									paxShareRaw: null,
									fare: 1400,
									fareRaw: null
								},
								{
									bucketNumber: 2,
									bucket: 'B2',
									paxShare: 0.18,
									paxShareRaw: null,
									fare: 1200,
									fareRaw: null
								}
							]
						}
					]
				}
			]
		};
	}
}

function transformGrid(
	serviceOut: ClusterAPOut
): [
	GridBucketApClusters<number>,
	GridBucketApClusters<number>,
	GridBucketApClusters<number>,
	GridBucketApClusters<number>
] {
	//TODO: agregar indices de tablas 3 y 4
	const grid_average_bp = createGridBucketApClustersNumbers(serviceOut[0]);
	const grid_lf_composition = createGridBucketApClustersNumbers(serviceOut[1]);
	const grid_average_bp_vs_baseline = createGridBucketApClustersNumbers(serviceOut[0]);
	const grid_pax_vs_baseline = createGridBucketApClustersNumbers(serviceOut[1]);

	return [grid_average_bp, grid_lf_composition, grid_average_bp_vs_baseline, grid_pax_vs_baseline];
}

function formatPorcModelSetting(value: number): string {
	const valuePorc = value * 100.0;
	return valuePorc.toFixed(1) + '%';
}
