import { Injectable } from '@angular/core';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { RemCalendarService } from '@services/calendars/rem-calendar.service';
import { OneTimeServices } from '@services/one-time.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { editorOutput } from 'src/app/components/one-time/seasons/menu-seasons/menu-seasons.component';
import { Bounds } from 'src/app/models/Bounds';
import { CellSeason } from 'src/app/models/calendars/CellSeason';
import { AsksPorSeasonKpi } from 'src/app/models/seasons/AsksPorSeasonKpi';
import { SeasonInProgress, SeasonSessionInProgress } from 'src/app/models/seasons/SeasonInProgress';
import { createCalendars, PeriodCalendarStructure } from '../../models/calendars/PeriodCalendarStructure';
import { Season } from '../../models/calendars/Season';

// para hacer pruebas con data dummy
// TODO: esta data debe ser obtenida llamando a un servicio backend
const kpisDummy = [
	new AsksPorSeasonKpi('L', 1.0),
	new AsksPorSeasonKpi('L1', 0.9),
	new AsksPorSeasonKpi('L2', 0.8),
	new AsksPorSeasonKpi('L3', 0.7),
	new AsksPorSeasonKpi('H', 2.0),
	new AsksPorSeasonKpi('H1', 1.9),
	new AsksPorSeasonKpi('H2', 1.8),
	new AsksPorSeasonKpi('H3', 1.7)
];

@Injectable({
	providedIn: 'root'
})
export class SeasonCalendarService {
	emptyCellSeason: CellSeason = { season: '', season_raw: '' };

	constructor(private oneTimeService: OneTimeServices, private remCalendarService: RemCalendarService) {}

	loadInCalendar(calendar: PeriodCalendarStructure<CellSeason>, daySeasons: Season[]) {
		const formater = DateTimeFormatter.ofPattern('yyyy-MM-dd');

		for (const daySeason of daySeasons) {
			const dt = LocalDate.parse(daySeason.date, formater);

			const cellSeason: CellSeason = { season: daySeason.seasonValue, season_raw: daySeason.seasonValueRaw };

			calendar.updateOneCell(dt, cellSeason);
		}
	}

	generateSeasonCalendars(
		cabinCode: string,
		elementCode: string,
		initDate: LocalDate,
		endDate: LocalDate,
		monthsNumber: number,
		sessionId: number
	): Observable<Bounds<PeriodCalendarStructure<CellSeason>>> {
		return this.oneTimeService.getSeasons(cabinCode, elementCode, initDate, endDate, sessionId).pipe(
			map((response: any) => {
				const inboundCalendars = createCalendars(
					initDate.year(),
					initDate.monthValue(),
					monthsNumber,
					this.emptyCellSeason
				);
				const outboundCalendars = createCalendars(
					initDate.year(),
					initDate.monthValue(),
					monthsNumber,
					this.emptyCellSeason
				);

				// console.log (response) ;

				const inboundData = response.filter((s) => s.bound === 'inbound');
				const outboundData = response.filter((s) => s.bound === 'outbound');

				this.loadInCalendar(inboundCalendars, inboundData);
				this.loadInCalendar(outboundCalendars, outboundData);

				return {
					outbound: outboundCalendars,
					inbound: inboundCalendars
				};
			})
		);
	}

	/*
	 *
	 */
	saveSeasons(
		sessionId: number,
		elementCode: string,
		cabinCode: string,
		sourceType: string,
		baseLineElementCode: string,
		baseLineCabinCode: string,
		baseLineYear: string,
		outboundSeasonCalendars: PeriodCalendarStructure<CellSeason>,
		inboundSeasonCalendars: PeriodCalendarStructure<CellSeason>,
		menues: editorOutput
	): Observable<string> {
		function extractSeasonInProgress(): Array<SeasonInProgress> {
			function extraeOutboundSeason(dt: LocalDate, cell: CellSeason): SeasonInProgress {
				return {
					elementCode: elementCode,
					cabinCode: cabinCode,
					date: dt.toString(),
					bound: 'outbound',
					season: cell.season,
					seasonRaw: cell.season_raw
				};
			}

			function extraeInboundSeason(dt: LocalDate, cell: CellSeason): SeasonInProgress {
				return {
					elementCode: elementCode,
					cabinCode: cabinCode,
					date: dt.toString(),
					bound: 'inbound',
					season: cell.season,
					seasonRaw: cell.season_raw
				};
			}

			const outbound = outboundSeasonCalendars.map(extraeOutboundSeason);
			const inbound = inboundSeasonCalendars.map(extraeInboundSeason);

			const cells = outbound.concat(inbound);

			return cells;
		}

		function extractSeasonSessionInProgress(): SeasonSessionInProgress {
			const seasons_in_progress = extractSeasonInProgress();

			return {
				sessionId: sessionId,
				elementCode: elementCode,
				cabinCode: cabinCode,
				sourceType: sourceType,
				model: 'model1',
				modelParameters: '',
				baseLineElementCode: baseLineElementCode,
				baseLineCabinCode: baseLineCabinCode,
				baseLineYear: baseLineYear,

				seasons: seasons_in_progress,
				menues: menues
			};
		}

		const data = extractSeasonSessionInProgress();

		const payload = JSON.stringify(data);
		console.log(payload);

		/*
        // dummy
        return new Observable(subscriber => {
            subscriber.next("OK");
        });
        */
		return this.oneTimeService.saveSeasons(payload);
	}

	/*
	 *
	 */
	generateKpisAsksPorSeason(
		year: number,
		elementCode: string,
		cabinCode: string,
		outboundSeasonCalendars: PeriodCalendarStructure<CellSeason>,
		inboundSeasonCalendars: PeriodCalendarStructure<CellSeason>
	): Observable<Array<AsksPorSeasonKpi>> {
		interface BoundDateSeason {
			bound: string;
			date: string;
			season: string;
		}

		function extractSeasonCells(
			outboundSeasonCalendars: PeriodCalendarStructure<CellSeason>,
			inboundSeasonCalendars: PeriodCalendarStructure<CellSeason>
		): Array<BoundDateSeason> {
			function convertOutbound(dt: LocalDate, cellSeason: CellSeason): BoundDateSeason {
				return {
					bound: 'outbound',
					date: dt.toString(),
					season: cellSeason.season
				};
			}

			function convertInbound(dt: LocalDate, cellSeason: CellSeason): BoundDateSeason {
				return {
					bound: 'inbound',
					date: dt.toString(),
					season: cellSeason.season
				};
			}

			const outbounds = outboundSeasonCalendars.map(convertOutbound);
			const inbounds = inboundSeasonCalendars.map(convertInbound);

			return outbounds.concat(inbounds);
		}

		const seasonsCells = extractSeasonCells(outboundSeasonCalendars, inboundSeasonCalendars);

		const data = {
			year: year,
			element: elementCode,
			cabin: cabinCode,
			bound_day_season: seasonsCells
		};

		const payload = JSON.stringify(data);

		return this.oneTimeService.getKpiAskPorSeasons(payload);
		/*
        console.log ("entrada servicio");
        console.log (payload);
        console.log ("salida servicio");
        console.log (JSON.stringify(kpisDummy));
        return new Observable(subscriber => {
            subscriber.next(kpisDummy);
        });
        */
	}
}
