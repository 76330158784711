import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { ClusterPax } from 'src/app/models/buckets/ClusterPax';
import { calculateTotals, copyDataFromGrid, GridBucketCluster } from 'src/app/models/buckets/GridBucketCluster';
import { ConfigService } from '../../../../config/config.service';

interface HTColumn {
	title: string;
	readOnly: boolean;
	renderer?: (
		hotInstance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties
	) => void;
}

const column_bucket: HTColumn = {
	title: '',
	readOnly: true
};

const fare_column: HTColumn = {
	title: '$',
	readOnly: false
};

// para que cada grilla hot tenga un id unico
let id_buckets = 0;

const GridBucketClustersInputs = {
	data_grid: 'data_grid'
};

@Component({
	selector: 'app-grid-bucket-clusters',
	templateUrl: './grid-bucket-clusters.component.html',
	styleUrls: ['./grid-bucket-clusters.component.scss']
})
export class GridBucketClustersComponent {
	id = 'hot-instance.target-curves-' + id_buckets++;
	private hotRegisterer = new HotTableRegisterer();
	private hotInstance: Handsontable;

	@Input() title = '';
	@Input() show_bucket_col = true;
	@Input() readOnly = true;
	@Input() controlView = 'AU';
	@Input() columns_width: Array<number> = [];

	columns: Array<HTColumn> = [];

	rows_number;

	data_grid: GridBucketCluster;
	@Input() cluster_pax_list: Array<ClusterPax>;

	@Output() buckets_edited = new EventEmitter<string>();

	tableSettings: Handsontable.GridSettings | undefined = undefined;

	ready: boolean;
	@Input(GridBucketClustersInputs.data_grid) set setDataGrid(data: GridBucketCluster) {
		if (typeof data !== 'undefined' && data !== undefined) {
			this.data_grid = data;
			/*if (this.hotRegisterer.getInstance(this.id) !== undefined) {
				this.hotRegisterer.getInstance(this.id).updateSettings({ mergeCells: this.getColumnMerge() });
			}*/
			this.ready = true;
			this.rows_number = this.data_grid.rows.length;
			//
			this.columns = [];
			fare_column.renderer = this.callRendererGrid;
			this.columns.push(column_bucket);
			this.columns.push(fare_column);

			for (const cluster of this.data_grid.clusters) {
				this.columns.push({
					title: 'C' + cluster,
					readOnly: this.readOnly,
					renderer: this.callRendererGrid
				});
			}

			const column_total: HTColumn = {
				title: 'Total Pax',
				readOnly: true,
				renderer: this.callRendererGrid
			};

			const widths = witdhCols(this.data_grid.clusters.length);
			const width_ap = widths[0];
			const width_cv = widths[1];
			this.columns_width = [];
			// ap
			this.columns_width.push(width_ap);
			// clusters
			for (const cluster of this.data_grid.clusters) {
				this.columns_width.push(width_cv);
			}
			this.columns_width.push(width_cv);
			this.columns_width.push(width_cv);
			this.columns.push(column_total);

			// Setea en null las celdas de AF Editor
			const totalRows = this.data_grid.rows.length;
			for (let i = 2; i < this.data_grid.clusters.length + 2; i++) {
				this.data_grid.rows[totalRows - 1][i] = null;
			}

			this.tableSettings = this.calcTableSetting();
		} else {
			this.ready = false;
			this.data_grid = undefined;
			this.tableSettings = undefined;
			this.data_grid_raw = undefined;
		}
	};

	@Input() data_grid_raw: GridBucketCluster | undefined;

	@Input() rendererGrid: (
		instance: Handsontable,
		TD: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridBucketCluster,
		data_grid_raw: GridBucketCluster | undefined,
		editable: boolean
	) => void;

	private calcTableSetting(): Handsontable.GridSettings {
		return {
			licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
			contextMenu: false,
			dropdownMenu: false,
			language: 'es-MX',
			className: 'htCenter',
			filters: false,
			columnSorting: false,
			allowInsertRow: false,
			viewportColumnRenderingOffset: 1000,
			colWidths: this.columns_width,
			mergeCells: this.getColumnMerge(),

			afterChange: (changes, source) => {
				if (source !== 'loadData' && source !== 'populateFromArray' && source !== 'updateData') {
					// Controlar boton suprimir y atras
					if (source === 'edit') {
						changes?.forEach(([row, prop, oldValue, newValue]) => {
							prop = this.hotInstance.propToCol(prop as string | number);
							try {
								const newValueNumeric = parseFloat(newValue);
								if (!isNaN(newValueNumeric)) {
									this.data_grid.rows[row][prop] = newValueNumeric;
									this.changeDetectorRef.detectChanges();
								} else {
									this.data_grid.rows[row][prop] = oldValue;
								}
							} catch (e) {
								this.data_grid.rows[row][prop] = oldValue;
								this.changeDetectorRef.detectChanges();
							}
						});
					}
					calculateTotals(this.data_grid, this.cluster_pax_list);
					this.hotRegisterer.getInstance(this.id).render();
					this.changeDetectorRef.detectChanges();
				}

				if (source !== 'populateFromArray') {
					this.buckets_edited.emit(source);
				}

				if (this.hotRegisterer.getInstance(this.id) !== undefined && source === 'updateData') {
					this.hotRegisterer.getInstance(this.id).updateSettings({ mergeCells: this.getColumnMerge() });
				}
			},
			beforeKeyDown: (event: KeyboardEvent) => {
				if (event.key === 'Delete' && typeof this.data_grid_raw !== 'undefined') {
					Handsontable.dom.stopImmediatePropagation(event);
					event.stopImmediatePropagation();
					this.backRawCell();
					this.buckets_edited.emit('delete');
				}
			}
		};
	}

	constructor(private changeDetectorRef: ChangeDetectorRef, private configService: ConfigService) {}

	public onAfterInit = (): void => {
		this.hotInstance = this.hotRegisterer.getInstance(this.id);
	};

	backRawCell(): void {
		const rangeSelected: Handsontable.CellRange[] = this.hotInstance.getSelectedRange();

		rangeSelected.forEach((element, index) => {
			let rowStart = 0;
			let rowEnd = 0;

			if (element.from.row <= element.to.row) {
				rowStart = element.from.row;
				rowEnd = element.to.row;
			} else {
				rowStart = element.to.row;
				rowEnd = element.from.row;
			}

			let colStart = 0;
			let colEnd = 0;

			if (element.from.col <= element.to.col) {
				colStart = element.from.col;
				colEnd = element.to.col;
			} else {
				colStart = element.to.col;
				colEnd = element.from.col;
			}

			copyDataFromGrid(this.hotInstance, this.data_grid, this.data_grid_raw, rowStart, rowEnd, colStart, colEnd);
			calculateTotals(this.data_grid, this.cluster_pax_list);
		});

		this.hotInstance.render();
	}

	isReady(): boolean {
		return this.ready;
	}

	getColumnMerge() {
		const bucketNumber = this.data_grid.buckets.length;
		const numberRowSumary = this.data_grid.rows.length - bucketNumber;

		const ret = [];
		// otras filas dinamicas
		for (let i = 0; i < numberRowSumary; i++) {
			ret.push({ row: bucketNumber + i, col: 0, rowspan: 1, colspan: 2 });
		}
		return ret;
	}

	stretchH(): string {
		if (this.show_bucket_col) {
			return 'none';
		} else {
			return 'all';
		}
	}

	callRendererGrid = (
		hotInstance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties
	) => {
		if (typeof this.rendererGrid !== 'undefined') {
			let value_num = 0;
			if (col !== 1) {
				value_num = parseFloat(value);
			} else {
				value_num = parseInt(value, 10);
			}

			this.rendererGrid(
				hotInstance,
				td,
				row,
				col,
				prop,
				value_num,
				cellProperties,
				this.data_grid,
				this.data_grid_raw,
				!this.readOnly
			);
		} else {
			Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, col, prop, value, cellProperties]);
		}
	};
}

function witdhCols(num_clusters: number): [number, number] {
	if (num_clusters === 1) {
		return [105, 100];
	} else if (num_clusters === 2) {
		return [90, 75];
	} else if (num_clusters === 3) {
		return [80, 70];
	} else if (num_clusters === 4) {
		return [70, 60];
	} else if (num_clusters === 5) {
		return [65, 50];
	} else if (num_clusters <= 7) {
		return [60, 42];
	} else {
		return [52, 32]; // [49, 26]
	}
}
