import { Injectable } from '@angular/core';
import { Notification } from '@services/notifications.service';
import { LocalStorageService, ngxLocalStorage } from 'ngx-localstorage';

@Injectable({
	providedIn: 'root'
})
export class SessionStorage {
	// Variables de SessionStorage y LocalStorage
	// Login
	@ngxLocalStorage({ storage: sessionStorage })
	userName: string;

	@ngxLocalStorage({ storage: sessionStorage })
	idToken: string;

	@ngxLocalStorage({ storage: sessionStorage })
	accessToken: string;

	@ngxLocalStorage({ storage: sessionStorage })
	userEmail: string;

	@ngxLocalStorage({ storage: sessionStorage })
	photo: string;

	// Menu Lateral Baseline
	@ngxLocalStorage({ storage: sessionStorage })
	baselineElement: string;

	@ngxLocalStorage({ storage: sessionStorage })
	baselineSeason: string[];

	// Notificaciones
	@ngxLocalStorage({ storage: sessionStorage, nullTransformer: () => [] })
	notifications: Notification[];

	@ngxLocalStorage({ storage: sessionStorage, nullTransformer: () => 5 })
	notifications_capacity: number;

	constructor(private session: LocalStorageService) {}

	// Elimina todos los datos del session storage
	clearSession() {
		this.session.clear();
	}
}
