import { Component, Input } from '@angular/core';

import { colors_clusters } from 'src/app/models/clusters/ColorsClusters';

interface ClusterColor {
	cluster: number;
	color: string;
}

const SectionPlotsInputs = {
	clusters: 'clusters'
};

@Component({
	selector: 'app-section-plots',
	templateUrl: './section-plots.component.html',
	styleUrls: ['./section-plots.component.scss']
})
export class SectionPlotsComponent {
	clusters_colors: Array<ClusterColor>;
	@Input(SectionPlotsInputs.clusters) set setClusters(clusters: Array<number>) {
		this.clusters_colors = clusters.map((cluster) => {
			const color = colors_clusters.get(cluster);
			const cluster_color: ClusterColor = { cluster: cluster, color: color };
			return cluster_color;
		});
	}

	constructor() {}
}
