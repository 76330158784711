<div>
	<div class="title-indicadores border-card">
		<div class="indicador" *ngFor="let cluster_color of clusters_colors">
			<div class="cluster-name">{{ cluster_color.cluster }}</div>
			<div class="material-icons" [style.color]="cluster_color.color">radio_button_checked</div>
		</div>
		<!-- 
    <div class="indicador">
      <div class="cluster-name">2</div>
      <div class="material-icons punto2">
        radio_button_checked
      </div>
    </div>
    <div class="indicador">
      <div class="cluster-name">3</div>
      <div class="material-icons punto3">
        radio_button_checked
      </div>
    </div>
    -->
	</div>

	<div class="plots-grid">
		<div class="plot-container border-card">
			<ng-content select="[content1]"></ng-content>
		</div>
		<div class="plot-container border-card">
			<ng-content select="[content2]"></ng-content>
		</div>
		<div class="plot-container border-card">
			<ng-content select="[content3]"></ng-content>
		</div>
		<div class="plot-container border-card">
			<ng-content select="[content4]"></ng-content>
		</div>
	</div>
</div>
