import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-card-container',
	templateUrl: './card-container.component.html',
	styleUrls: ['./card-container.component.scss']
})
export class CardContainerComponent {
	@Input() actionsView = true;
	@Input() styleContent = {};
}
