<ng-container class="table-clusters">
	<div *ngIf="grid_model_settings_ready" class="title-type">{{ title }}</div>
	<div *ngIf="grid_model_settings_ready">
		<hot-table
			[settings]="tableSettings"
			[columns]="columns"
			[cell]="cells"
			[data]="grid_model_settings.rows"
			readOnly="false"
			stretchH="all"
			[hotId]="id"
			class="hot handsontable htColumnHeaders"
		>
		</hot-table>
	</div>
</ng-container>
