<div class="contenido">
	<div class="textData">
		{{ titleText }}
		<button mat-button class="close-icon" (click)="cerrarDialogo()">
			<mat-icon>close</mat-icon>
		</button>
	</div>

	<div class="textError">
		<ul style="list-style-type: disc">
			<li *ngFor="let item of dataMessageErr">
				<span>{{ item.errorMessage }}</span>
			</li>
		</ul>
	</div>
</div>
