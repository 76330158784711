import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	OnChanges,
	OnDestroy,
	OnInit
} from '@angular/core';
// TODO:  Reemplazar al integrar servicio de tablas informativas
import { BucketInformativeApService } from '@services/buckets/bucket-informative-ap.service';
import {
	BaselineBucketInputParams,
	Bucket,
	BucketCluster,
	BucketClustersControl,
	BucketFareProgress,
	BucketInputParams,
	BucketPaxProgress,
	BucketSessionProgressInput,
	BucketsParams,
	DataInformativeKPI,
	ModelOutputBucketInputParams,
	RefreshPayload
} from '@services/buckets/buckets.interface';
import { BucketsService } from '@services/buckets/buckets.service';
import { ClustersService, SeasonByParams } from '@services/clusters/clusters.service';
import { DialogService } from '@services/dialog.service';
import { SnackBarService } from '@services/snack-bar.service';
import moment from 'moment';
import { ClusterPax } from 'src/app/models/buckets/ClusterPax';
import { ControlGridBucketCluster } from 'src/app/models/buckets/ControlGridBucketCluster';
import {
	back2percentBuckets,
	calculateDeltaRow,
	calculateTotals,
	cloneBucketsGrid,
	cloneFromAnotherGrid,
	diffPorcCalculatedAF,
	GridBucketCluster
} from 'src/app/models/buckets/GridBucketCluster';
import { GridBucketSummary } from 'src/app/models/buckets/GridBucketSummary';
import {
	BucketsClustersGraf,
	BucketsDataGraf,
	BucketsTableGraf,
	DataBucketsKpiRequest,
	DataBucketsSummaryRequest,
	DataPlotRequest,
	DataPlotResponse
} from 'src/app/models/buckets/PlotBuckets';
import { DataModalImport, ResponseDataModal } from 'src/app/models/ModalImport';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { BaselineInput, FormMenu, ModelInput } from 'src/app/shared/menu-lateral-inputs/menu-lateral-inputs.constant';
import {
	BaselineValues,
	MenuesSaveBffIn,
	ModelValues,
	ViewComponentMenu
} from 'src/app/shared/menu-lateral-inputs/menu-lateral-inputs.interface';
import { SnackbarComponent } from '../../link/SnackbarLink/Snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-buckets',
	templateUrl: './buckets.component.html',
	styleUrls: ['./buckets.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BucketsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	viewForm: ViewComponentMenu[] = [
		{ name: FormMenu.baseline, view: true },
		{ name: FormMenu.model, view: true },
		{ name: FormMenu.switchView, view: true }
	];

	viewInputs: ViewComponentMenu[] = [
		{ name: BaselineInput.level, view: true },
		{ name: BaselineInput.cabin, view: true },
		{ name: BaselineInput.element, view: true },
		{ name: BaselineInput.startDate, view: true },
		{ name: BaselineInput.endDate, view: true },
		{ name: BaselineInput.seasons, view: true },

		{ name: ModelInput.minimumFare, view: true },
		{ name: ModelInput.bPb1Pivot, view: true },
		{ name: ModelInput.numberOfBuckets, view: true }
	];

	// Menu superior
	seasons: string[] = [];
	seasons_ready = false;

	menu_baseline: BaselineValues | undefined = undefined;
	menu_model: ModelValues;
	seasonBaseline: string[];

	// Menu lateral
	selectedSeason: string | undefined = undefined;
	flagChangedSeason = false;

	source_type = '';

	AU_VIEW = 'AU';
	BP_VIEW = 'BP';

	view = this.BP_VIEW;
	MODAL_TITLE = 'Import Buckets';

	disableSave = true;
	disableUndoUpdateBPandAF = true;

	bucket_data_views: Array<ControlGridBucketCluster> | undefined = undefined;
	buckets_data_grid_au: GridBucketCluster | undefined = undefined;
	buckets_data_grid_bp: GridBucketCluster | undefined = undefined;
	raw_buckets_data_grid_au: GridBucketCluster | undefined = undefined;
	raw_buckets_data_grid_bp: GridBucketCluster | undefined = undefined;

	bucketsPlotsSalida: Array<DataPlotResponse> | undefined = undefined;
	dataBuckets: Array<BucketsDataGraf> | undefined = undefined;

	baseline_buckets_data_grid_au: GridBucketCluster | undefined = undefined;
	baseline_buckets_data_grid_bp: GridBucketCluster | undefined = undefined;

	model_output_buckets_data_grid_au: GridBucketCluster | undefined = undefined;
	model_output_buckets_data_grid_bp: GridBucketCluster | undefined = undefined;

	cluster_pax_list: Array<ClusterPax>;
	selected_cluster_pax_list: Array<ClusterPax>;

	buckets_data_summary_grid: GridBucketSummary | undefined = undefined;

	data_grid_info_table: DataInformativeKPI | undefined = undefined;

	loadedBusinessUnit: EventEmitter<boolean> = new EventEmitter();

	sendSnackbarActive: boolean;

	// bucket KPi
	bucketKpiData;

	dataModal: DataModalImport;

	constructor(
		private clustersServices: ClustersService,
		private bucketServices: BucketsService,
		private changeDetectorRef: ChangeDetectorRef,
		private elementRef: ElementRef,
		private snack: SnackBarService,
		// TODO: cambiar por bucket services
		private informativeTableBucketService: BucketInformativeApService,
		private dialog: DialogService,
		private snackBar: MatSnackBar
	) {
		OneTimeContext.setCurrentlyInBuckets(true);
	}

	ngOnInit() {
		this.getSeasonsMenu();
		this.getBucketSummaryData();
	}

	changeSelectedSeason(event: string) {
		if (typeof event !== 'undefined' && this.selectedSeason !== event) {
			this.source_type = '';
			this.selectedSeason = event;
			this.flagChangedSeason = true;
			this.seasons_ready = true;

			this.resetAll();

			OneTimeContext.setView(this.view);
			this.getDataSelectedSeason();
			this.getBucketSummaryData();
		}
	}

	private resetAll() {
		this.bucket_data_views = undefined;
		this.buckets_data_grid_au = undefined;
		this.buckets_data_grid_bp = undefined;
		this.raw_buckets_data_grid_au = undefined;
		this.raw_buckets_data_grid_bp = undefined;

		this.bucketsPlotsSalida = undefined;
		this.dataBuckets = undefined;

		this.baseline_buckets_data_grid_au = undefined;
		this.baseline_buckets_data_grid_bp = undefined;

		this.model_output_buckets_data_grid_au = undefined;
		this.model_output_buckets_data_grid_bp = undefined;

		this.cluster_pax_list = [];
		this.selected_cluster_pax_list = [];

		this.buckets_data_summary_grid = undefined;

		this.data_grid_info_table = undefined;
	}

	ngOnDestroy() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'hidden';
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}

	ngAfterViewInit() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'scroll';
	}

	ngOnChanges() {
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}

	baselineMenuLoad(values: BaselineValues) {
		this.baselineMenuChanges(values);

		this.getDataSelectedSeason();
		this.getBucketSummaryData();
	}

	refreshUI() {
		this.changeDetectorRef.detectChanges();
	}

	baselineMenuChanges(values: BaselineValues) {
		this.menu_baseline = values;
		OneTimeContext.setBaselineCabinName(values.cabin.description);
		OneTimeContext.setBaselineCabinCode(values.cabin.code);
		OneTimeContext.setBaselineElement(values.element.elementCode);
		OneTimeContext.setBaselineLevel(values.level.levelId.toString());
		OneTimeContext.setBaselineStartDate(values.startDate);
		OneTimeContext.setBaselineEndDate(values.endDate);
		OneTimeContext.setBaselineSeason(values.seasons);

		this.seasonBaseline = values.seasons;
	}

	getSeasonsMenu() {
		const dateStart = moment().startOf('month').add(-6, 'month').format('YYYY-MM-DD');
		const dateEnd = moment().endOf('month').add(200, 'year').format('YYYY-MM-DD');

		const params: SeasonByParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			initDate: dateStart,
			endDate: dateEnd,
			sessionId: OneTimeContext.getSessionID()
		};

		this.clustersServices.getSeasonsByParams(params).subscribe(
			(response) => {
				this.seasons = response;
				this.seasons_ready = true;

				// el componente MenuSuperiorGlobalComponent reordena la lista de seasons
				// => la posicion 0 retornada por el servicio puede ser distinta a la primera season escogida por el componente

				// this.selectedSeason = this.seasons[0]; // ver comentario anterior
				// this.getDataSelectedSeason();

				// hay que esperar que el componente MenuSuperiorGlobalComponent se despliegue y emita la season que escogio como primera
				this.refreshUI();
			},
			(error) => {
				console.log('getSeasonsMenu', error);

				this.seasons = [];
				this.seasons_ready = false;
			}
		);
	}

	/*
	 * Busca la data una vez que esta disponible:
	 * - menu_baseline (formulario lateral)
	 * - selected season
	 * - seasons
	 */
	getDataSelectedSeason() {
		if (typeof this.menu_baseline !== 'undefined' && typeof this.selectedSeason !== 'undefined') {
			this.getClusterPax(this.selectedSeason, true);
		}
	}

	modelMenuLoad(values: ModelValues) {
		this.menu_model = values;
	}

	modelMenuChanges(values: ModelValues) {
		this.menu_model = values;
	}

	getClusterPax(seasonInput: string, globalPax: boolean) {
		const params: BucketInputParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			season: seasonInput,
			level: OneTimeContext.getLevelId().toString(),
			sessionId: OneTimeContext.getSessionID(),
			levelBaseLine: OneTimeContext.getBaselineLevel().toString(),
			elementCodeBaseLine: OneTimeContext.getBaselineElement(),
			cabinCodeBaseLine: OneTimeContext.getBaselineCabinCode(),
			startDateBaseLine: OneTimeContext.getBaselineStartDate(),
			endDateBaseLine: OneTimeContext.getBaselineEndDate(),
			seasonsBaseLine: this.seasonBaseline
		};

		this.bucketServices.getClusterPax(params).subscribe(
			(response) => {
				if (globalPax === true) {
					this.cluster_pax_list = response;
					this.getBucketData();
				} else {
					this.selected_cluster_pax_list = response;
					this.getImportBuckets(seasonInput);
				}
			},
			(error) => {
				console.log('getClusterPax', error);

				this.cluster_pax_list = [];
				this.selected_cluster_pax_list = [];

				if (globalPax === true) {
					this.getBucketData();
				} else {
					this.getImportBuckets(seasonInput);
				}
			}
		);
	}

	getBucketData() {
		const inputParams: BucketsParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			level: OneTimeContext.getLevelId(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID()
		};

		this.bucketServices.getBuckets(inputParams).subscribe(
			(response) => {
				this.bucket_data_views = response;
				this.buckets_data_grid_au = this.bucket_data_views[0].data_grid;
				calculateTotals(this.buckets_data_grid_au, this.cluster_pax_list);
				this.raw_buckets_data_grid_au = this.bucket_data_views[0].raw_data_grid;
				calculateTotals(this.raw_buckets_data_grid_au, this.cluster_pax_list);
				this.buckets_data_grid_bp = this.bucket_data_views[1].data_grid;
				calculateTotals(this.buckets_data_grid_bp, this.cluster_pax_list);
				this.raw_buckets_data_grid_bp = this.bucket_data_views[1].raw_data_grid;
				calculateTotals(this.raw_buckets_data_grid_bp, this.cluster_pax_list);

				this.refreshUI();
				this.getBaselineBucketData();
				this.getDataGraf();
				this.getBucketsKpi();
			},
			(error) => {
				console.log('getBucketData', error);

				this.bucket_data_views = undefined;
				this.buckets_data_grid_au = undefined;
				this.raw_buckets_data_grid_au = undefined;
				this.buckets_data_grid_bp = undefined;
				this.raw_buckets_data_grid_bp = undefined;
			}
		);
	}

	getBaselineBucketData() {
		this.dataBuckets = [
			this.formatDataGraf(this.buckets_data_grid_au, this.raw_buckets_data_grid_au, this.AU_VIEW),
			this.formatDataGraf(this.buckets_data_grid_bp, this.raw_buckets_data_grid_bp, this.BP_VIEW)
		];

		const inputParams: BaselineBucketInputParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			level: OneTimeContext.getLevelId().toString(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			levelBaseLine: OneTimeContext.getBaselineLevel().toString(),
			elementCodeBaseLine: OneTimeContext.getBaselineElement(),
			cabinCodeBaseLine: OneTimeContext.getBaselineCabinCode(),
			startDateBaseLine: OneTimeContext.getBaselineStartDate(),
			endDateBaseLine: OneTimeContext.getBaselineEndDate(),
			seasonsBaseLine: this.seasonBaseline,
			buckets: this.dataBuckets
		};

		this.bucketServices.getBaselineBuckets(inputParams).subscribe(
			(response) => {
				this.baseline_buckets_data_grid_au = response[0].data_grid;
				calculateTotals(this.baseline_buckets_data_grid_au, this.cluster_pax_list);

				this.baseline_buckets_data_grid_bp = response[1].data_grid;
				calculateTotals(this.baseline_buckets_data_grid_bp, this.cluster_pax_list);
				this.refreshUI();
			},
			(error) => {
				console.log('getBaselineBucketData', error);
				this.baseline_buckets_data_grid_au = undefined;
				this.baseline_buckets_data_grid_bp = undefined;
			}
		);
	}

	save() {
		let saveView = false;
		let bucketGrid;

		if (this.view === this.BP_VIEW) {
			bucketGrid = this.buckets_data_grid_bp;
			const totales = bucketGrid.rows.find((f) => f[0] === 'Total').filter((_, i) => i > 1);
			saveView = totales.every((e) => Number(e.toFixed(1)) === 100);
		}

		if (this.view === this.AU_VIEW) {
			bucketGrid = this.buckets_data_grid_au;
			const totales = bucketGrid.rows.find((f) => f[0] === 'Total').filter((_, i) => i > 1);
			saveView = totales.every((e) => Number(e.toFixed(1)) === 100);
		}
		// verify last bucket values
		const bucketsSize = bucketGrid.buckets.length;
		const valuesBucket = bucketGrid.rows[bucketsSize-1].filter((_, i) => i > 1);
		const valueBidPrice = bucketGrid.rows[bucketsSize-1][1]

		const allZero = valuesBucket.every((e) => Number(e.toFixed(1)) === 0);
		const nonZero = (Math.trunc(valueBidPrice)) > 0;

		let errorMessage = [];

		if (!nonZero) {
			errorMessage.push('The last bucket Bid Price must be greater than 0');
		}

		if (!allZero) {
			errorMessage.push('All last bucket for each cluster must be equal to 0');
		}

		if (!saveView) {
			errorMessage.push('Total for each cluster must be equal to 100%');
		}

		if (errorMessage.length > 0) {
			errorMessage = errorMessage.map(msg => `• ${msg}`);
			errorMessage.unshift("Unable to save Buckets: ");
			this.openSnackMessage(errorMessage.join('\n'), false);
			return;
		}

		const menuesJson: MenuesSaveBffIn = {
			menu_baseline: this.menu_baseline,
			menu_model: this.menu_model
		};
		delete menuesJson.menu_baseline.level.elements;
		delete menuesJson.menu_baseline.startDateAsk;
		delete menuesJson.menu_baseline.endDateAsk;
		delete menuesJson.menu_baseline.monthly;
		const data_grid_au = back2percentBuckets(this.buckets_data_grid_au);
		const data_grid_raw_au = back2percentBuckets(this.raw_buckets_data_grid_au);
		const data_grid_bp = back2percentBuckets(this.buckets_data_grid_bp);
		const data_grid_raw_bp = back2percentBuckets(this.raw_buckets_data_grid_bp);

		const bucketsPax_au: Array<BucketPaxProgress> = this.getBucketPax(data_grid_au, data_grid_raw_au);
		const bucketsFare_au: Array<BucketFareProgress> = this.getBucketFare(data_grid_au, data_grid_raw_au);
		const bucketsPax_bp: Array<BucketPaxProgress> = this.getBucketPax(data_grid_bp, data_grid_raw_bp);
		const bucketsFare_bp: Array<BucketFareProgress> = this.getBucketFare(data_grid_bp, data_grid_raw_bp);

		const bucketsPax = bucketsPax_au.concat(bucketsPax_bp);
		const bucketsFare = bucketsFare_au.concat(bucketsFare_bp);

		const serviceInput: BucketSessionProgressInput = {
			sessionId: OneTimeContext.getSessionID(),
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			season: this.selectedSeason,
			sourceType: this.source_type,
			menues: menuesJson,
			bucketsPax: bucketsPax,
			bucketsFare: bucketsFare
		};

		this.bucketServices.save(serviceInput).subscribe(
			(out) => {
				this.openSnackMessage('Buckets have been successfully saved');
			},
			(error) => {
				console.log('save', error);
				this.openSnackMessage('Unable to save Buckets', false);
			}
		);
	}

	getBucketPax(
		buckets_data_grid: GridBucketCluster,
		raw_buckets_data_grid: GridBucketCluster
	): Array<BucketPaxProgress> {
		const result: Array<BucketPaxProgress> = [];
		const buckets = buckets_data_grid.buckets;
		const rows = buckets_data_grid.rows;
		const rowsRaw = raw_buckets_data_grid.rows;
		let index = 0;
		const numOfClusters = buckets_data_grid.clusters.length;
		const clusters = buckets_data_grid.clusters;
		for (const bucket of buckets) {
			for (let i = 2; i < numOfClusters + 3; i++) {
				const bucketPax: BucketPaxProgress = {
					elementCode: OneTimeContext.getElementCode(),
					cabinCode: OneTimeContext.getCabinCode(),
					season: this.selectedSeason,
					inventoryControl: buckets_data_grid.control,
					bucket: bucket,
					paxShare: rows[index][i],
					paxShareRaw: rowsRaw[index][i],
					bucketNumber: index + 1,
					cluster: i === numOfClusters + 2 ? '0' : clusters[i - 2].toString()
				};
				result.push(bucketPax);
			}

			index++;
		}
		return result;
	}

	getBucketFare(
		buckets_data_grid_au: GridBucketCluster,
		raw_buckets_data_grid_au: GridBucketCluster
	): Array<BucketFareProgress> {
		const result: Array<BucketFareProgress> = [];
		const buckets = buckets_data_grid_au.buckets;
		const rows = buckets_data_grid_au.rows;
		const rowsRaw = raw_buckets_data_grid_au.rows;
		let index = 0;
		for (const bucket of buckets) {
			const bucketFare: BucketFareProgress = {
				elementCode: OneTimeContext.getElementCode(),
				cabinCode: OneTimeContext.getCabinCode(),
				season: this.selectedSeason,
				inventoryControl: buckets_data_grid_au.control,
				bucket: bucket,
				fare: rows[index][1],
				fareRaw: rowsRaw[index][1],
				bucketNumber: index + 1
			};
			result.push(bucketFare);
			index++;
		}
		return result;
	}

	getBucketSummaryData() {
		if (this.selectedSeason !== undefined && this.seasonBaseline !== undefined) {
			const _bucketSummaryPayload = this.getDataBucketsSummaryRequest();

			this.bucketServices.getBucketSummary(_bucketSummaryPayload).subscribe(
				(response) => {
					this.buckets_data_summary_grid = response.data_grid;

					this.updateSummaryUIs();

					this.refreshUI();
				},
				(error) => {
					console.log('getBucketSummaryData', error);

					this.buckets_data_summary_grid = undefined;
				}
			);
		}
	}

	getView(businessUnit) {
		if (businessUnit === 'DOMBR') {
			return this.AU_VIEW;
		} else {
			return this.BP_VIEW;
		}
	}

	getDataBucketsKpiRequest(): DataBucketsKpiRequest {
		this.dataBuckets = [
			this.formatDataGraf(this.buckets_data_grid_au, this.raw_buckets_data_grid_au, this.AU_VIEW),
			this.formatDataGraf(this.buckets_data_grid_bp, this.raw_buckets_data_grid_bp, this.BP_VIEW)
		];

		const inputParams: DataBucketsKpiRequest = {
			level: OneTimeContext.getLevelId().toString(),
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			levelBaseLine: OneTimeContext.getBaselineLevel(),
			elementCodeBaseLine: OneTimeContext.getBaselineElement(),
			cabinCodeBaseLine: OneTimeContext.getBaselineCabinCode(),
			seasonsBaseLine: this.seasonBaseline,
			startDateBaseLine: OneTimeContext.getBaselineStartDate(),
			endDateBaseLine: OneTimeContext.getBaselineEndDate(),
			buckets: this.dataBuckets
		};

		return inputParams;
	}

	getDataBucketsSummaryRequest(): DataBucketsSummaryRequest {
		const inputParams: DataBucketsSummaryRequest = {
			level: OneTimeContext.getLevelId().toString(),
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			levelBaseLine: OneTimeContext.getBaselineLevel(),
			elementCodeBaseLine: OneTimeContext.getBaselineElement(),
			cabinCodeBaseLine: OneTimeContext.getBaselineCabinCode(),
			seasonsBaseLine: this.seasonBaseline,
			startDateBaseLine: OneTimeContext.getBaselineStartDate(),
			endDateBaseLine: OneTimeContext.getBaselineEndDate()
		};

		return inputParams;
	}

	getBucketsKpi() {
		const inputParams = this.getDataBucketsKpiRequest();

		this.informativeTableBucketService.getDataInformativeAP(inputParams).subscribe(
			(response) => {
				this.data_grid_info_table = response;
				this.refreshUI();
			},
			(error) => {
				console.log('getBucketsKpi', error);
				this.data_grid_info_table = undefined;
			}
		);
	}

	buttonBaselineMenu(values: BaselineValues) {
		this.menu_baseline = values;

		this.dataBuckets = [
			this.formatDataGraf(this.buckets_data_grid_au, this.raw_buckets_data_grid_au, this.AU_VIEW),
			this.formatDataGraf(this.buckets_data_grid_bp, this.raw_buckets_data_grid_bp, this.BP_VIEW)
		];

		OneTimeContext.setBaselineCabinName(values.cabin.description);
		OneTimeContext.setBaselineCabinCode(values.cabin.code);
		OneTimeContext.setBaselineElement(values.element.elementCode);
		OneTimeContext.setBaselineLevel(values.level.levelId.toString());
		OneTimeContext.setBaselineStartDate(values.startDate);
		OneTimeContext.setBaselineEndDate(values.endDate);
		OneTimeContext.setBaselineSeason(values.seasons);

		this.seasonBaseline = values.seasons;

		this.getBucketSummaryData();
		this.updateSummaryUIs();

		const inputParams: BaselineBucketInputParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			level: OneTimeContext.getLevelId().toString(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			levelBaseLine: OneTimeContext.getBaselineLevel().toString(),
			elementCodeBaseLine: OneTimeContext.getBaselineElement(),
			cabinCodeBaseLine: OneTimeContext.getBaselineCabinCode(),
			startDateBaseLine: values.startDate,
			endDateBaseLine: values.endDate,
			seasonsBaseLine: this.seasonBaseline,
			buckets: this.dataBuckets
		};

		this.bucketServices.getBaselineBuckets(inputParams).subscribe(
			(response) => {
				this.baseline_buckets_data_grid_au = response[0].data_grid;
				calculateTotals(this.baseline_buckets_data_grid_au, this.cluster_pax_list);

				this.baseline_buckets_data_grid_bp = response[1].data_grid;
				calculateTotals(this.baseline_buckets_data_grid_bp, this.cluster_pax_list);

				if (typeof this.model_output_buckets_data_grid_au !== 'undefined') {
					calculateDeltaRow(this.baseline_buckets_data_grid_au, this.model_output_buckets_data_grid_au);
				}

				if (typeof this.model_output_buckets_data_grid_bp !== 'undefined') {
					calculateDeltaRow(this.baseline_buckets_data_grid_bp, this.model_output_buckets_data_grid_bp);
				}

				this.refreshUI();
				this.openSnackMessage('Baseline data has been updated successfully');
			},
			(error) => {
				console.log('buttonBaselineMenu', error);

				this.openSnackMessage('Unable to get Baseline data', false);
				this.baseline_buckets_data_grid_au = undefined;
				this.baseline_buckets_data_grid_bp = undefined;
			}
		);
	}

	buttonModelOutput(event: [BaselineValues, ModelValues]) {
		const [baseline, model] = event;
		const inputParams: ModelOutputBucketInputParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			level: OneTimeContext.getLevelId().toString(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			levelBaseLine: OneTimeContext.getLevelId().toString(),
			elementCodeBaseLine: baseline.element.elementCode,
			cabinCodeBaseLine: baseline.cabin.code,
			startDateBaseLine: baseline.startDate,
			endDateBaseLine: baseline.endDate,
			seasonsBaseLine: baseline.seasons,
			minimunFare: model.minimumFare,
			numberOfBuckets: model.numberOfBuckets
		};
		this.bucketServices.getModelOutputBuckets(inputParams).subscribe(
			(response) => {
				this.model_output_buckets_data_grid_au = response[0].data_grid;
				calculateTotals(this.model_output_buckets_data_grid_au, this.cluster_pax_list);

				this.model_output_buckets_data_grid_bp = response[1].data_grid;
				calculateTotals(this.model_output_buckets_data_grid_bp, this.cluster_pax_list);

				// calcula Delta AF MO vs BL
				calculateDeltaRow(this.baseline_buckets_data_grid_au, this.model_output_buckets_data_grid_au);
				calculateDeltaRow(this.baseline_buckets_data_grid_bp, this.model_output_buckets_data_grid_bp);

				this.updateSummaryUIs();

				this.refreshUI();
				this.openSnackMessage('Buckets have been calculated successfully and are available in Model output');
			},
			(error) => {
				console.log('buttonModelOutput', error);

				this.openSnackMessage('Unable to calculate Buckets', false);
				this.model_output_buckets_data_grid_au = undefined;
				this.model_output_buckets_data_grid_bp = undefined;
			}
		);
	}

	switchEvent(event: [BaselineValues, ModelValues]) {
		this.view = OneTimeContext.getView();
		this.openSnackMessage('Switched view successfully');
		this.refreshUI();
	}

	getDataGraf() {
		this.dataBuckets = [
			this.formatDataGraf(this.buckets_data_grid_au, this.raw_buckets_data_grid_au, this.AU_VIEW),
			this.formatDataGraf(this.buckets_data_grid_bp, this.raw_buckets_data_grid_bp, this.BP_VIEW)
		];

		const inputParams: DataPlotRequest = {
			level: OneTimeContext.getLevelId().toString(),
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			buckets: this.dataBuckets
		};

		this.bucketServices.getDataGrafBuckets(inputParams).subscribe(
			(response) => {
				this.bucketsPlotsSalida = response;
				this.refreshUI();
			},
			(error) => {
				console.log('getDataGraf', error);
				this.bucketsPlotsSalida = undefined;
			}
		);
	}

	formatDataGraf(data: GridBucketCluster, dataRaw: GridBucketCluster, dataControl: string): BucketsDataGraf {
		const arrCluster: Array<BucketsClustersGraf> = [];

		for (let j = 0; j < data.clusters.length; j++) {
			const arrTable: Array<BucketsTableGraf> = [];
			for (let i = 0; i < data.buckets.length; i++) {
				const table: BucketsTableGraf = {
					bucketNumber: i + 1,
					bucket: data.rows[i][0],
					paxShare: data.rows[i][j + 2] / 100.0,
					paxShareRaw: dataRaw.rows[i][j + 2] / 100.0,
					fare: data.rows[i][1],
					fareRaw: dataRaw.rows[i][1]
				};
				arrTable.push(table);
			}
			const cluster: BucketsClustersGraf = {
				cluster: data.clusters[j],
				table: arrTable
			};
			arrCluster.push(cluster);
		}

		const bucket: BucketsDataGraf = {
			control: dataControl,
			clusters: arrCluster
		};

		return bucket;
	}

	formatDataBuckets(data: GridBucketCluster, dataRaw: GridBucketCluster, dataControl: string): BucketClustersControl {
		const arrCluster: Array<BucketCluster> = [];

		for (let j = 0; j < data.clusters.length; j++) {
			const arrTable: Array<Bucket> = [];
			for (let i = 0; i < data.buckets.length; i++) {
				const table: Bucket = {
					bucketNumber: i + 1,
					bucket: data.rows[i][0],
					paxShare: data.rows[i][j + 2] / 100.0,
					paxShareRaw: dataRaw.rows[i][j + 2] / 100.0,
					fare: data.rows[i][1],
					fareRaw: dataRaw.rows[i][1]
				};
				arrTable.push(table);
			}
			const cluster: BucketCluster = {
				cluster: data.clusters[j].toString(),
				table: arrTable
			};
			arrCluster.push(cluster);
		}

		const bucket: BucketClustersControl = {
			control: dataControl,
			clusters: arrCluster
		};

		return bucket;
	}

	btnRefresh() {
		this.refreshBuckets();
	}

	refreshBuckets() {
		const bucketsData = [
			this.formatDataBuckets(this.buckets_data_grid_au, this.raw_buckets_data_grid_au, this.AU_VIEW),
			this.formatDataBuckets(this.buckets_data_grid_bp, this.raw_buckets_data_grid_bp, this.BP_VIEW)
		];

		const payload: RefreshPayload = {
			view: this.view,
			tableAfEditor: null,
			buckets: bucketsData
		};
		this.bucketServices.refreshBuckets(payload).subscribe(
			(response) => {
				this.bucket_data_views = response;
				this.buckets_data_grid_au = this.bucket_data_views[0].data_grid;
				calculateTotals(this.buckets_data_grid_au, this.cluster_pax_list);
				this.raw_buckets_data_grid_au = this.bucket_data_views[0].raw_data_grid;
				calculateTotals(this.raw_buckets_data_grid_au, this.cluster_pax_list);
				this.buckets_data_grid_bp = this.bucket_data_views[1].data_grid;
				calculateTotals(this.buckets_data_grid_bp, this.cluster_pax_list);
				this.raw_buckets_data_grid_bp = this.bucket_data_views[1].raw_data_grid;
				calculateTotals(this.raw_buckets_data_grid_bp, this.cluster_pax_list);

				this.getDataGraf();
				this.getBucketsKpi();
				this.refreshUI();
				this.openSnackMessage('Refreshed successfully');
				this.disableSave = false;
			},
			(error) => {
				console.log('refreshBuckets', error);

				this.disableSave = true;
				if (error.status === 400) {
					this.sendSnackbarActive = true;
					this.snackBar.openFromComponent(SnackbarComponent, {
						data: error,
						panelClass: ['snackValidation']
					});
				} else {
					this.openSnackMessage('Unable to refresh buckets', false);
				}
			}
		);
	}

	onBucketsEdited(source: string) {
		this.updateSummaryUIs();

		this.disableSave = true;
		this.refreshUI(); // ojo que provoca eventos en cascada en hot
	}

	private copyOneModelOutput(data_grid_orig: GridBucketCluster, data_grid_from: GridBucketCluster): GridBucketCluster {
		const data_grid = cloneBucketsGrid(data_grid_from);
		calculateTotals(data_grid, this.cluster_pax_list);

		// se puede pasar la fila "AF Editor" por referencia (no se necesita hacer una deep copy)
		data_grid.rows[data_grid.rows.length - 1] = data_grid_orig.rows[data_grid_orig.rows.length - 1];

		return data_grid;
	}

	copyModelOutput(event: string) {
		this.buckets_data_grid_au = this.copyOneModelOutput(
			this.buckets_data_grid_au,
			this.model_output_buckets_data_grid_au
		);
		this.raw_buckets_data_grid_au = this.copyOneModelOutput(
			this.raw_buckets_data_grid_au,
			this.model_output_buckets_data_grid_au
		);
		this.buckets_data_grid_bp = this.copyOneModelOutput(
			this.buckets_data_grid_bp,
			this.model_output_buckets_data_grid_bp
		);
		this.raw_buckets_data_grid_bp = this.copyOneModelOutput(
			this.raw_buckets_data_grid_bp,
			this.model_output_buckets_data_grid_bp
		);

		this.source_type = 'model';

		this.disableSave = true;
	}

	importBuckets(seasonEvent: string) {
		this.source_type = 'other season';
		this.getClusterPax(seasonEvent, false);
	}

	getImportBuckets(seasonEvent: string) {
		this.disableUndoUpdateBPandAF = true;
		let data_grid_season: Array<ControlGridBucketCluster>;
		let clusters_season;
		let originClusters: Array<any> = [];
		const inputParams: BucketsParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			level: OneTimeContext.getLevelId(),
			season: seasonEvent,
			sessionId: OneTimeContext.getSessionID()
		};

		this.bucketServices.getBuckets(inputParams).subscribe(
			(response) => {
				this.disableSave = true;
				// TODO: Asignar RAW a RAW y NO RAW
				data_grid_season = response;

				if (this.view === this.AU_VIEW) {
					originClusters = this.buckets_data_grid_au.clusters;
					clusters_season = arrayClusterInt2String(data_grid_season[0].data_grid.clusters);
				} else {
					originClusters = this.buckets_data_grid_bp.clusters;
					clusters_season = arrayClusterInt2String(data_grid_season[1].data_grid.clusters);
				}

				this.dataModal = {
					title: this.MODAL_TITLE,
					targetSeason: this.selectedSeason,
					targetClusters: originClusters,
					sourceElement: OneTimeContext.getBaselineElement(),
					sourceCabinCode: OneTimeContext.getBaselineCabinCode(),
					sourceCabinName: OneTimeContext.getBaselineCabinName(),
					sourceSeason: seasonEvent,
					clusters: clusters_season
				};

				this.dialog.copyTargetCurveDialog(this.dataModal).subscribe((confirm) => {
					if (typeof confirm !== 'boolean' && this.selected_cluster_pax_list !== undefined) {
						const responseOK = confirm as ResponseDataModal;
						const clusters = arrayClusterString2Int(responseOK.clusters);

						const input_au_grid = data_grid_season[0].data_grid;
						const input_au_grid_raw = data_grid_season[0].raw_data_grid;
						calculateTotals(input_au_grid, this.selected_cluster_pax_list);
						calculateTotals(input_au_grid_raw, this.selected_cluster_pax_list);

						const input_bp_grid = data_grid_season[1].data_grid;
						const input_bp_grid_raw = data_grid_season[1].raw_data_grid;
						calculateTotals(input_bp_grid, this.selected_cluster_pax_list);
						calculateTotals(input_bp_grid_raw, this.selected_cluster_pax_list);

						this.buckets_data_grid_au = cloneFromAnotherGrid(this.buckets_data_grid_au, input_au_grid, clusters);
						this.buckets_data_grid_bp = cloneFromAnotherGrid(this.buckets_data_grid_bp, input_bp_grid, clusters);

						this.raw_buckets_data_grid_au = cloneFromAnotherGrid(
							this.raw_buckets_data_grid_au,
							input_au_grid_raw,
							clusters
						);
						this.raw_buckets_data_grid_bp = cloneFromAnotherGrid(
							this.raw_buckets_data_grid_bp,
							input_bp_grid_raw,
							clusters
						);

						calculateTotals(this.buckets_data_grid_au, this.cluster_pax_list);
						calculateTotals(this.raw_buckets_data_grid_au, this.cluster_pax_list);
						calculateTotals(this.buckets_data_grid_bp, this.cluster_pax_list);
						calculateTotals(this.raw_buckets_data_grid_bp, this.cluster_pax_list);

						this.refreshUI();

						this.openSnackMessage('Buckets were imported successfully');
					}
				});
			},
			(error) => {
				console.log('getImportBuckets', error);

				this.openSnackMessage('Unable to import Bucket', false);
				this.buckets_data_grid_au = undefined;
				this.buckets_data_grid_bp = undefined;
				this.raw_buckets_data_grid_au = undefined;
				this.raw_buckets_data_grid_bp = undefined;
			}
		);

		this.refreshUI();
	}

	updateBPandAF() {
		const bucketsData = [
			this.formatDataBuckets(this.buckets_data_grid_au, this.raw_buckets_data_grid_au, this.AU_VIEW),
			this.formatDataBuckets(this.buckets_data_grid_bp, this.raw_buckets_data_grid_bp, this.BP_VIEW)
		];

		const aFEditorRowAU = this.buckets_data_grid_au.rows[this.buckets_data_grid_au.rows.length - 1];
		const aFEditorRowBP = this.buckets_data_grid_bp.rows[this.buckets_data_grid_bp.rows.length - 1];

		OneTimeContext.setBucketEditorBackup(this.buckets_data_grid_au, 'buckets_data_grid_au');
		OneTimeContext.setBucketEditorBackup(this.raw_buckets_data_grid_au, 'raw_buckets_data_grid_au');
		OneTimeContext.setBucketEditorBackup(this.buckets_data_grid_bp, 'buckets_data_grid_bp');
		OneTimeContext.setBucketEditorBackup(this.raw_buckets_data_grid_bp, 'raw_buckets_data_grid_bp');

		let afEditorData;
		if (this.view === this.AU_VIEW) {
			afEditorData = this.mapAFEditor(this.buckets_data_grid_au);
		} else {
			afEditorData = this.mapAFEditor(this.buckets_data_grid_bp);
		}
		const payload: RefreshPayload = {
			view: this.view,
			tableAfEditor: afEditorData,
			buckets: bucketsData
		};

		this.bucketServices.updateBPandAF(payload).subscribe(
			(response) => {
				this.disableSave = false;

				this.bucket_data_views = response;
				this.buckets_data_grid_au = this.bucket_data_views[0].data_grid;
				calculateTotals(this.buckets_data_grid_au, this.cluster_pax_list);
				this.raw_buckets_data_grid_au = this.bucket_data_views[0].raw_data_grid;
				calculateTotals(this.raw_buckets_data_grid_au, this.cluster_pax_list);
				this.buckets_data_grid_bp = this.bucket_data_views[1].data_grid;
				calculateTotals(this.buckets_data_grid_bp, this.cluster_pax_list);
				this.raw_buckets_data_grid_bp = this.bucket_data_views[1].raw_data_grid;
				calculateTotals(this.raw_buckets_data_grid_bp, this.cluster_pax_list);

				this.buckets_data_grid_au.rows[this.buckets_data_grid_au.rows.length - 1] = Array.from(aFEditorRowAU);
				this.buckets_data_grid_bp.rows[this.buckets_data_grid_bp.rows.length - 1] = Array.from(aFEditorRowBP);

				this.refreshUI();
				this.openSnackMessage('Bid Prices and Average Fares were successfully updated');
			},
			(error) => {
				console.log('updateBPandAF', error);
				this.openSnackMessage('Unable to update Bid Prices and Average Fares', false);

				// this.bucket_data_views = undefined;
				// this.buckets_data_grid_au = undefined;
				// this.raw_buckets_data_grid_au = undefined;
				// this.buckets_data_grid_bp = undefined;
				// this.raw_buckets_data_grid_bp = undefined;
			}
		);
	}

	mapAFEditor(grid: GridBucketCluster) {
		const clusterList = grid.clusters;
		const aFEditorRow = grid.rows[grid.rows.length - 1];
		const calculatedAFRow = grid.rows[grid.rows.length - 2];
		const clustersAF = [];
		const clustersAFValues = [];
		let index = 2;
		for (const cluster of clusterList) {
			clustersAF.push(cluster.toString());
			if (aFEditorRow[index] === undefined || aFEditorRow[index] === 'undefined' || aFEditorRow[index] === null) {
				aFEditorRow[index] = calculatedAFRow[index];
			}
			clustersAFValues.push(aFEditorRow[index]);
			index++;
		}

		return {
			clusters: clustersAF,
			clustersValues: clustersAFValues
		};
	}

	rollbackUpdateBPandAF() {
		const recoveredAUGrid = OneTimeContext.getBucketEditorBackup('buckets_data_grid_au');
		this.buckets_data_grid_au = cloneFromAnotherGrid(
			this.buckets_data_grid_au,
			recoveredAUGrid,
			this.buckets_data_grid_au.clusters
		);
		calculateTotals(this.buckets_data_grid_au, this.cluster_pax_list);

		const recoveredAURaw = OneTimeContext.getBucketEditorBackup('raw_buckets_data_grid_au');
		this.raw_buckets_data_grid_au = cloneFromAnotherGrid(
			this.raw_buckets_data_grid_au,
			recoveredAURaw,
			this.raw_buckets_data_grid_au.clusters
		);
		calculateTotals(this.raw_buckets_data_grid_au, this.cluster_pax_list);

		const recoveredBP = OneTimeContext.getBucketEditorBackup('buckets_data_grid_bp');
		this.buckets_data_grid_bp = cloneFromAnotherGrid(
			this.buckets_data_grid_bp,
			recoveredBP,
			this.buckets_data_grid_bp.clusters
		);
		calculateTotals(this.buckets_data_grid_bp, this.cluster_pax_list);

		const recoveredBPRaw = OneTimeContext.getBucketEditorBackup('raw_buckets_data_grid_bp');
		this.raw_buckets_data_grid_bp = cloneFromAnotherGrid(
			this.raw_buckets_data_grid_bp,
			recoveredBPRaw,
			this.raw_buckets_data_grid_bp.clusters
		);
		calculateTotals(this.raw_buckets_data_grid_bp, this.cluster_pax_list);
		this.openSnackMessage('Changes in Buckets reverted successfully');
	}

	// recalcula los KPI's (los que se deben calcular en la UI)
	// ver https://projectmanagement.appslatam.com/browse/PSISRM-1220
	updateSummaryUIs() {
		if (typeof this.buckets_data_summary_grid !== 'undefined') {
			let buckets_data_grid = this.buckets_data_grid_au;
			let model_output_buckets_data_grid = this.model_output_buckets_data_grid_au;
			let baseline_buckets_data_grid = this.baseline_buckets_data_grid_au;

			if (this.view === this.BP_VIEW) {
				buckets_data_grid = this.buckets_data_grid_bp;
				model_output_buckets_data_grid = this.model_output_buckets_data_grid_bp;
				baseline_buckets_data_grid = this.baseline_buckets_data_grid_bp;
			}

			const kpis: Array<Array<any>> = [];

			// edicion vs model output
			if (typeof model_output_buckets_data_grid !== 'undefined' && typeof buckets_data_grid !== 'undefined') {
				const kpi_1: Array<any> = ['ΔAF (Buckets vs MO)'];
				kpis.push(kpi_1.concat(diffPorcCalculatedAF(model_output_buckets_data_grid, buckets_data_grid)));
			}

			// edicion vs baseline
			if (typeof baseline_buckets_data_grid !== 'undefined' && typeof buckets_data_grid !== 'undefined') {
				const kpi_2: Array<any> = ['ΔAF (Buckets vs BL)'];
				kpis.push(kpi_2.concat(diffPorcCalculatedAF(baseline_buckets_data_grid, buckets_data_grid)));
			}

			// model output vs baseline
			if (typeof baseline_buckets_data_grid !== 'undefined' && typeof model_output_buckets_data_grid !== 'undefined') {
				const kpi_3: Array<any> = ['ΔAF (MO vs BL)'];
				kpis.push(kpi_3.concat(diffPorcCalculatedAF(baseline_buckets_data_grid, model_output_buckets_data_grid)));
			}

			this.buckets_data_summary_grid = this.buckets_data_summary_grid.updateRowsUI(kpis);
		}
	}

	openSnackMessage(message, isSuccess = true) {
		this.sendSnackbarActive = false;
		if (isSuccess) {
			this.snack.success(message);
		} else {
			this.snack.error(message);
		}
	}
}

function arrayClusterInt2String(arr: Array<number>): Array<string> {
	const ret = arr.map((num) => num.toString());

	ret.push('Total');

	return ret;
}

function arrayClusterString2Int(arr: Array<string>): Array<number> {
	const ret = arr.map((sz) => (sz.toLowerCase() === 'total' ? 0 : parseInt(sz, 10)));

	return ret;
}
