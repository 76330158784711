<app-menu-bar seccion="clusters" (toggleNotify)="notify.drawer.toggle()"></app-menu-bar>
<app-menu-notify #notify></app-menu-notify>

<div *ngIf="seasons_ready && selectedSeason">
	<app-menu-lateral>
		<ng-container menu-content>
			<app-menu-clusters
				[dataClusters]="data_clusters"
				[selectedSeason]="selectedSeason"
				(baselineClick)="updateBaseline($event)"
				(calculateClick)="updateIndicatorModelOutput($event)"
				(baselineChanges)="updateChangesBaseline($event)"
				(modelChanges)="updateChangesModel($event)"
				(baselineLoad)="updateLoadBaseline($event)"
			>
			</app-menu-clusters>
		</ng-container>
	</app-menu-lateral>
</div>
<br />
<div class="container">
	<app-menu-superior-seasons (changeTab)="changeSelectedSeason($event)" [seasons]="seasons">
	</app-menu-superior-seasons>
	<br />
	<div *ngIf="grids_ready && grids_baseline_ready">
		<app-tod-tools-container
			[seasons]="seasons"
			[outbound_leg]="data_clusters.outbound.leg"
			[inbound_leg]="data_clusters.inbound.leg"
			[outbound_franjas]="data_clusters.outbound.franjas"
			[inbound_franjas]="data_clusters.inbound.franjas"
			(todToolsChanged)="callRefreshClusters($event)"
		>
		</app-tod-tools-container>
		<br />
		<app-descriptive-statistics
			[dataInputKpi]="dataInputKpi"
			[flagChangedSeason]="flagChangedSeason"
			(renderStatisticsEvent)="refreshDescriptiveStatistics()"
		></app-descriptive-statistics>
		<br />
		<app-card-tod-dow
			[seasons]="seasons"
			[data_clusters]="data_clusters"
			[data_baseline_clusters]="data_baseline_clusters"
			[baseline_clusters_visible]="grid_baseline_visible"
			[data_indicators]="data_indicators"
			(updateAllGridsEvent)="refreshScatterplots($event)"
			(updateGridClustersEvent)="updateKpiTable($event)"
			(seasonImportClusterChangeEvent)="importClustersFromSeason($event)"
			(saveEvent)="saveAll($event)"
			[disableBtnKpi]="disableStatusKpi"
			[data_grid_model_output]="grids_model_output"
			[resetStatus]="resetSource"
		></app-card-tod-dow>
		<br />
		<app-section-plots [clusters]="clusters_distinct">
			<ng-container content1>
				<cluster-ind1-vs-ind2-scatterplot-component
					titleX="Load factor"
					titleY="Yield"
					[data]="data_loadFactor_yield"
				></cluster-ind1-vs-ind2-scatterplot-component>
			</ng-container>
			<ng-container content2>
				<cluster-ind1-vs-ind2-scatterplot-component
					titleX="Yield"
					titleY="Short AP pax"
					[data]="data_yield_shortAP"
				></cluster-ind1-vs-ind2-scatterplot-component>
			</ng-container>
			<ng-container content3>
				<cluster-ind1-vs-ind2-scatterplot-component
					titleX="Load factor"
					titleY="Inter. connect."
					[data]="data_loadFactor_inter"
				></cluster-ind1-vs-ind2-scatterplot-component>
			</ng-container>
			<ng-container content4>
				<cluster-ind1-vs-ind2-scatterplot-component
					titleX="Load factor"
					titleY="Domestic connect."
					[data]="data_loadFactor_domestic"
				></cluster-ind1-vs-ind2-scatterplot-component>
			</ng-container>
		</app-section-plots>
		<br />
		<app-plot-info *ngIf="flagCalculate">
			<ng-container contentGraf>
				<cluster-elbow-silhouette-scatterplot [data]="data_elbow_silhouette"> </cluster-elbow-silhouette-scatterplot>
			</ng-container>
		</app-plot-info>
		<br />
	</div>
</div>
