import Handsontable from 'handsontable';
import { ClusterPax } from './ClusterPax';

export class GridBucketCluster {
	constructor(
		public control: string,
		public clusters: Array<number>,
		public buckets: Array<string>,
		public rows: Array<Array<any>>,
		public min = Number.MAX_VALUE,
		public max = Number.MIN_VALUE
	) {}

	getValue(row: number, col: number): number {
		const rowFilter = this.rows[row] || [];
		return rowFilter.find((_, index) => index === col);
	}

	getData(fila: string): Array<number> {
		for (const row of this.rows) {
			if (row[0] === fila) {
				return row.slice(2, 2 + this.clusters.length);
			}
		}
	}
}

//
const calculated_AF = 'Calculated AF';

function getDataCalculatedAF(grid: GridBucketCluster): Array<number> {
	return grid.getData(calculated_AF);
}

// retorna el valor <porc> de tal forma que:
//   y = (1 + porc) * x
function calcPorc(x: number, y: number): number {
	return y / x - 1.0;
}

export function diffPorcCalculatedAF(gridX: GridBucketCluster, gridY: GridBucketCluster): Array<number> {
	const calcAFx = getDataCalculatedAF(gridX);
	const calcAFy = getDataCalculatedAF(gridY);

	if (calcAFx.length !== calcAFy.length) {
		throw Error('grillas no contienen el mismo numero de clusters');
	}

	return calcAFx.map((x, i) => {
		const y = calcAFy[i];
		return calcPorc(x, y);
	});
}

/*
 */
export function back2percentBuckets(data_grid: GridBucketCluster): GridBucketCluster {
	const totalRowsData = data_grid.buckets.length;
	const totalClusters = data_grid.clusters.length;

	const rows: Array<Array<any>> = [];

	for (let rowIndex = 0; rowIndex < data_grid.rows.length; rowIndex++) {
		const oldRow: Array<any> = data_grid.rows[rowIndex];
		const newRow: Array<any> = [];

		for (let colIndex = 0; colIndex < oldRow.length; colIndex++) {
			if (rowIndex < totalRowsData && colIndex >= 2) {
				// && colIndex < totalClusters + 2, comentado para incluir la columna total
				newRow.push(oldRow[colIndex] / 100.0);
			} else {
				newRow.push(oldRow[colIndex]);
			}
		}

		rows.push(newRow);
	}

	return new GridBucketCluster(data_grid.control, data_grid.clusters, data_grid.buckets, rows);
}

export function cloneBucketsGrid(data_grid_from: GridBucketCluster): GridBucketCluster {
	let rows: Array<Array<string | number>> = [];

	data_grid_from.buckets.forEach((_, i) => {
		const row_cloned = [...data_grid_from.rows[i]];
		rows = [...rows, row_cloned];
	});

	rows = [...rows, ...addFooterBuckets()];

	return new GridBucketCluster(data_grid_from.control, data_grid_from.clusters, data_grid_from.buckets, rows);
}

//
export function addFooterTotalCalculatedAF(): string[][] {
	return [['Total'], [calculated_AF]];
}

//
export function addFooterBuckets(): string[][] {
	return [...addFooterTotalCalculatedAF(), ['AF editor', null]];
}

/*
 *
 */
function refreshMinMax(data_grid: GridBucketCluster, value: number) {
	if (data_grid.min > value) {
		data_grid.min = value;
	}
	if (data_grid.max < value) {
		data_grid.max = value;
	}
}

/*
 * y recalcula min/max
 */
export function calculateTotals(data_grid: GridBucketCluster, cluster_pax_list: Array<ClusterPax>) {
	function getPax(pos: number): number {
		return cluster_pax_list[pos].pax;
	}

	const totalRowsData = data_grid.buckets.length;
	// const totalRows = data_grid.rows.length;
	const totalClusters = data_grid.clusters.length;

	data_grid.min = Number.MAX_VALUE;
	data_grid.max = Number.MIN_VALUE;

	// Itera por columnas para calcular filas total
	for (let c = 2; c < totalClusters + 2; c++) {
		let percTotal = 0;
		let afTotal = 0;
		for (let i = 0; i < totalRowsData - 1; i++) {
			const media = getMedia(Number(data_grid.rows[i][1]), Number(data_grid.rows[i + 1][1]));
			const tmpSum = media * Number(data_grid.rows[i][c]);
			afTotal = afTotal + tmpSum;
			percTotal = percTotal + Number(data_grid.rows[i][c]);

			refreshMinMax(data_grid, Number(data_grid.rows[i][c]));
		}

		percTotal = percTotal + Number(data_grid.rows[totalRowsData - 1][c]);
		const lastMedia = getMedia(
			Number(data_grid.rows[totalRowsData - 1][1]),
			Number(data_grid.rows[totalRowsData - 1][1]) / 2
		);
		const lastSum = lastMedia * Number(data_grid.rows[totalRowsData - 1][c]);
		afTotal = afTotal + lastSum;
		data_grid.rows[totalRowsData][c] = percTotal;

		data_grid.rows[totalRowsData + 1][c] = afTotal / 100.0;

		refreshMinMax(data_grid, Number(data_grid.rows[totalRowsData - 1][c]));
	}

	// Itera por filas para calular columna total
	for (let row = 0; row < totalRowsData; row++) {
		let clusterTotal = 0;

		if (cluster_pax_list.length === totalClusters) {
			let clusterPaxIndex = 0;

			for (let col = 2; col < totalClusters + 2; col++) {
				clusterTotal = clusterTotal + getPax(clusterPaxIndex) * Number(data_grid.rows[row][col]);
				clusterPaxIndex++;
			}
		}

		data_grid.rows[row][totalClusters + 2] = clusterTotal;
	}
}

export function calculateDeltaRow(baseline: GridBucketCluster, modelOutput: GridBucketCluster) {
	const num_data_rows_baseline = baseline.buckets.length;
	const num_data_rows_model = modelOutput.buckets.length;
	const baseline_af_row = baseline.rows[num_data_rows_baseline + 1];
	const model_output_af_row = modelOutput.rows[num_data_rows_model + 1];
	const totalRowNumber = modelOutput.rows.length;
	const totalColNumber = modelOutput.rows[0].length;

	for (let i = 2; i < totalColNumber - 1; i++) {
		const baselineAFValue = baseline_af_row[i];
		const modelOutputAFValue = model_output_af_row[i];
		const delta_mo_bl = (modelOutputAFValue / baselineAFValue - 1.0) * 100.0;
		modelOutput.rows[totalRowNumber - 1][i] = delta_mo_bl;
	}
}

export function cloneFromAnotherGrid(original: GridBucketCluster, from: GridBucketCluster, clusters: Array<number>) {
	// Se calcula los indeces de los clusters en los rows
	const fromClusters = from.clusters;
	const indexClusters = clusters.map((cluster) =>
		cluster === 0 ? fromClusters.length + 2 : fromClusters.indexOf(cluster) + 2
	);

	// Se define los buckets dependiendo la diferencias de cantidad de buckets entre from y el original
	const originalLength = original.buckets.length;
	const fromLength = from.buckets.length;

	let buckets = from.buckets;
	if (originalLength < fromLength) {
		// Se concatenan ambos buckets del original y from, se eliminan los duplicados
		buckets = [...new Set([...original.buckets, ...from.buckets])];
	}

	// Se quita las filas totales de los rows
	const fromRowLength = from.rows.length;
	const rows = from.rows.filter((_, i) => i < fromRowLength - 3);

	// Se crean nuevas filas segun los indices obtenidos anteriormente
	let rowsOutput: Array<Array<number>> = [];
	rows.forEach((row) => {
		let newRow: Array<number> = [];

		newRow = [...newRow, row[0]];
		newRow = [...newRow, row[1]];

		indexClusters.forEach((iCluster) => {
			newRow = [...newRow, row[iCluster]];
		});

		rowsOutput = [...rowsOutput, newRow];
	});

	// Se agregar filas de totales
	rowsOutput = [...rowsOutput, from.rows[fromRowLength - 3]];
	rowsOutput = [...rowsOutput, from.rows[fromRowLength - 2]];
	rowsOutput = [...rowsOutput, from.rows[fromRowLength - 1]];

	// Se genera nuevo grilla
	const ret = new GridBucketCluster(original.control, original.clusters, buckets, rowsOutput, from.min, from.max);

	return ret;
}

function getMedia(a: number, b: number) {
	return (a + b) / 2;
}

export function copyDataFromGrid(
	instance: Handsontable,
	to: GridBucketCluster,
	from: GridBucketCluster,
	row_start: number,
	row_end: number,
	col_start: number,
	col_end: number
): void {
	const rows_from = from.rows;
	const rows_to = to.rows;

	for (let row_num = row_start; row_num <= row_end; row_num++) {
		const rowIgnore = ['Total', 'Calculated AF', 'AF editor'];
		const rowData = instance.getDataAtRow(row_num).find((data, index) => index === 0);
		if (!rowIgnore.some((data) => data === rowData)) {
			const row_from = rows_from[row_num];
			const row_to = rows_to[row_num];

			for (let col_num = col_start; col_num <= col_end; col_num++) {
				row_to[col_num] = row_from[col_num];
			}
		}
	}
}
