import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { configProperties } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	configUrl: string = configProperties.configPath;
	private configSettings: any = null;

	constructor(private readonly http: HttpClient) {}

	get GSuiteClientID() {
		return this.configSettings.gsuite_client_id;
	}

	getSettings(key?: string | Array<string>): any {
		if (!key || (Array.isArray(key) && !key[0])) {
			return this.configSettings;
		}

		if (!Array.isArray(key)) {
			key = key.split('.');
		}

		return key.reduce((account: any, current: string) => account && account[current], this.configSettings);
	}

	public load(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http.get(this.configUrl).subscribe((response: any) => {
				this.configSettings = response;
				resolve(true);
			});
		});
	}
}
