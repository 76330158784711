import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotTableModule } from '@handsontable/angular';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TargetCurveScatterplotComponent } from '../scatterplots/target-curve-scatterplot/target-curve-scatterplot.component';
import { CargaTargetCurvesModule } from './carga-target-curves/carga-target-curves.module';
import { AskIndicatorsComponent } from './despliegue-curvas-metas/ask-indicators/ask-indicators.component';
import { DespliegueCurvasMetasComponent } from './despliegue-curvas-metas/despliegue-curvas-metas.component';
import { ModelSettingsComponent } from './despliegue-curvas-metas/model-settings/model-settings.component';
import { ModelSettingsDetailComponent } from './model-settings-detail/model-settings-detail.component';
import { TargetCurvesComponent } from './target-curves.component';

@NgModule({
	declarations: [
		TargetCurvesComponent,
		DespliegueCurvasMetasComponent,
		TargetCurveScatterplotComponent,
		ModelSettingsComponent,
		AskIndicatorsComponent,
		ModelSettingsDetailComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		HotTableModule.forRoot(),
		CargaTargetCurvesModule
	],
	exports: [TargetCurvesComponent]
})
export class TargetCurvesModule {}
