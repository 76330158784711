<div>
	<hot-table
		[hotId]="id"
		[settings]="tableSettings"
		[data]="data"
		[columns]="cols"
		[afterGetColHeader]="afterGetColHeader"
		[afterChange]="afterChange"
	>
	</hot-table>
</div>
