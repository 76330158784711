import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fcalcColor } from '@services/calendars/calendar-color.service';
import Handsontable from 'handsontable';
import { SnackbarComponent } from 'src/app/components/link/SnackbarLink/Snackbar.component';
import { ClusterPax } from 'src/app/models/buckets/ClusterPax';
import { GridBucketCluster } from 'src/app/models/buckets/GridBucketCluster';
import { GridBucketSummary } from 'src/app/models/buckets/GridBucketSummary';
import { Color } from '../../../../models/calendars/Color';

const CardSectionGlobalInputs = {
	list_seasons: 'list_seasons',
	buckets_data_grid_au: 'buckets_data_grid_au',
	buckets_data_grid_bp: 'buckets_data_grid_bp',
	raw_buckets_data_grid_au: 'raw_buckets_data_grid_au',
	raw_buckets_data_grid_bp: 'raw_buckets_data_grid_bp',
	buckets_data_summary_grid: 'buckets_data_summary_grid',
	baseline_buckets_data_grid_au: 'baseline_buckets_data_grid_au',
	baseline_buckets_data_grid_bp: 'baseline_buckets_data_grid_bp',
	model_output_buckets_data_grid_au: 'model_output_buckets_data_grid_au',
	model_output_buckets_data_grid_bp: 'model_output_buckets_data_grid_bp'
};

@Component({
	selector: 'app-card-section-global',
	templateUrl: './card-section-global.component.html',
	styleUrls: ['./card-section-global.component.scss']
}) /*, OnChanges, AfterContentInit, AfterContentChecked, AfterViewChecked */
export class CardSectionGlobalComponent implements OnInit {
	columns_width: Array<number> = [];
	optionsIndicator: Array<string> = ['Baseline', 'Model output'];
	indicatorSelect = 'Baseline';

	buckets_data_summary_grid: GridBucketSummary | undefined = undefined;

	buckets_data_grid_au: GridBucketCluster | undefined = undefined;
	buckets_data_grid_bp: GridBucketCluster | undefined = undefined;
	raw_buckets_data_grid_au: GridBucketCluster | undefined = undefined;
	raw_buckets_data_grid_bp: GridBucketCluster | undefined = undefined;
	buckets_data_grid_au_ready = false;
	buckets_data_grid_bp_ready = false;

	baseline_buckets_data_grid_au: GridBucketCluster | undefined = undefined;
	baseline_buckets_data_grid_bp: GridBucketCluster | undefined = undefined;
	baseline_buckets_data_grid_ready = false;

	model_output_buckets_data_grid_au: GridBucketCluster | undefined = undefined;
	model_output_buckets_data_grid_bp: GridBucketCluster | undefined = undefined;
	model_output_buckets_data_grid_ready = false;

	// baselineAFRow: any[];

	data_list_seasons: Array<string>;

	selectSeason = new UntypedFormControl();
	sendSnackbarActive;

	@Output() saveEvent = new EventEmitter();
	@Output() refreshEvent = new EventEmitter();
	@Output() updateBPandAFEvent = new EventEmitter();
	@Output() rollbackUpdateBPandAFEvent = new EventEmitter();

	@Output() btn_import_buckets = new EventEmitter<string>();
	@Output() btn_copy_model_input = new EventEmitter<string>();

	@Output() buckets_edited = new EventEmitter<string>();

	@Input(CardSectionGlobalInputs.list_seasons)
	set setListSeasons(data: Array<string>) {
		this.data_list_seasons = data;
		this.selectSeason.patchValue('Import Buckets');
	}

	@Input() disableSave = false;
	@Input() disableUndoUpdateBPandAF = true;
	@Input() view: 'AU' | 'BP' = 'AU';

	@Input(CardSectionGlobalInputs.buckets_data_grid_au)
	set setBucketDataGridAU(data: GridBucketCluster) {
		this.buckets_data_grid_au = data;

		if (typeof data !== 'undefined') {
			this.buckets_data_grid_au_ready = true;
		} else {
			this.buckets_data_grid_au_ready = false;
		}
	}

	@Input(CardSectionGlobalInputs.buckets_data_grid_bp)
	set setBucketDataGridBP(data: GridBucketCluster) {
		this.buckets_data_grid_bp = data;

		if (typeof data !== 'undefined') {
			this.buckets_data_grid_bp_ready = true;
		} else {
			this.buckets_data_grid_bp_ready = false;
		}
	}

	@Input(CardSectionGlobalInputs.raw_buckets_data_grid_au)
	set setRawBucketDataGridAU(data: GridBucketCluster) {
		this.raw_buckets_data_grid_au = data;

		if (typeof data !== 'undefined') {
			this.buckets_data_grid_au_ready = true;
		} else {
			this.buckets_data_grid_au_ready = false;
		}
	}

	@Input(CardSectionGlobalInputs.raw_buckets_data_grid_bp)
	set setRawBucketDataGridBP(data: GridBucketCluster) {
		this.raw_buckets_data_grid_bp = data;

		if (typeof data !== 'undefined') {
			this.buckets_data_grid_bp_ready = true;
		} else {
			this.buckets_data_grid_bp_ready = false;
		}
	}

	@Input(CardSectionGlobalInputs.buckets_data_summary_grid)
	set setBucketDataSummaryGrid(data: GridBucketSummary) {
		this.buckets_data_summary_grid = data;

		if (typeof data !== 'undefined') {
			this.buckets_data_grid_au_ready = true;
		} else {
			this.buckets_data_grid_au_ready = false;
		}
	}

	@Input() cluster_pax_list: Array<ClusterPax> | undefined = undefined;

	@Input(CardSectionGlobalInputs.baseline_buckets_data_grid_au)
	set setBaselineBucketDataGridAU(data: GridBucketCluster) {
		this.baseline_buckets_data_grid_au = data;

		if (typeof data !== 'undefined') {
			this.baseline_buckets_data_grid_ready = true;
		} else {
			this.baseline_buckets_data_grid_ready = false;
		}
		this.refreshUI();
	}

	@Input(CardSectionGlobalInputs.baseline_buckets_data_grid_bp)
	set setBaselineBucketDataGridBP(data: GridBucketCluster) {
		this.baseline_buckets_data_grid_bp = data;

		if (typeof data !== 'undefined') {
			this.baseline_buckets_data_grid_ready = true;
		} else {
			this.baseline_buckets_data_grid_ready = false;
		}
		this.refreshUI();
	}

	@Input(CardSectionGlobalInputs.model_output_buckets_data_grid_au)
	set setModelOutputBucketDataGridAU(data: GridBucketCluster) {
		this.model_output_buckets_data_grid_au = data;

		if (typeof data !== 'undefined') {
			this.model_output_buckets_data_grid_ready = true;
			this.indicatorSelect = 'Model output';
		} else {
			this.model_output_buckets_data_grid_ready = false;
		}
	}

	@Input(CardSectionGlobalInputs.model_output_buckets_data_grid_bp)
	set setModelOutputBucketDataGridBP(data: GridBucketCluster) {
		this.model_output_buckets_data_grid_bp = data;

		if (typeof data !== 'undefined') {
			this.model_output_buckets_data_grid_ready = true;
			this.indicatorSelect = 'Model output';
		} else {
			this.model_output_buckets_data_grid_ready = false;
		}
	}

	constructor(private snackBar: MatSnackBar, private changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit() {
		this.selectSeason.valueChanges.subscribe((val) => {
			const season = val as string;
			if (season !== null && season !== 'Import Buckets') {
				this.btn_import_buckets.emit(season);
				this.disableUndoUpdateBPandAF = true;
				this.selectSeason.patchValue(null);
			}
		});
	}

	save() {
		this.saveEvent.emit();
	}

	copyModelOutput() {
		if (
			typeof this.buckets_data_grid_au !== 'undefined' &&
			typeof this.model_output_buckets_data_grid_bp !== 'undefined'
		) {
			this.btn_copy_model_input.emit('');
			this.disableUndoUpdateBPandAF = true;
			this.openSnackBarCorrect('Buckets were copied successfully');
		} else {
			this.openSnackBarIncorrect('Unable to copy Buckets');
		}
	}

	refresh() {
		this.refreshEvent.emit();
	}

	updateBPandAF() {
		this.updateBPandAFEvent.emit();
		this.disableUndoUpdateBPandAF = false;
	}

	rollbackUpdateBPandAF() {
		this.rollbackUpdateBPandAFEvent.emit();
		this.disableUndoUpdateBPandAF = true;
	}

	// re-emite el evento
	onBucketsEdited = (sz: string): void => {
		this.buckets_edited.emit(sz);
	};

	formatBucketCell = (value: number) => {
		return value.toFixed(1);
	};

	formatFareCell = (value: number) => {
		return value.toFixed();
	};

	rendererBucket = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridBucketCluster,
		data_grid_raw: GridBucketCluster,
		editable: boolean
	) => {
		if (col !== 1 && row !== data_grid.buckets.length + 1) {
			this.rendererGrid(
				instance,
				td,
				row,
				col,
				prop,
				value,
				cellProperties,
				data_grid,
				data_grid_raw,
				this.formatBucketCell,
				editable
			);
		} else {
			this.rendererGrid(
				instance,
				td,
				row,
				col,
				prop,
				value,
				cellProperties,
				data_grid,
				data_grid_raw,
				this.formatFareCell,
				editable
			);
		}
	};

	rendererGrid = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridBucketCluster,
		data_grid_raw: GridBucketCluster,
		formatNumber: (n: number) => string,
		editable: boolean
	) => {
		const emptyCellColor = '#F3F2F';
		if (typeof value === 'number') {
			if (Number.isNaN(value)) {
				td.style.backgroundColor = emptyCellColor;
				td.innerHTML = '';
			} else {
				let hay_cambio = false;
				const totalRowDataNumber = data_grid.buckets.length;

				if (typeof data_grid_raw !== 'undefined') {
					// esta logica asume que afterChange actualiza la data de la estructura antes
					const valueGrid = Number(data_grid.getValue(row, Number(prop)));
					const valueGridRaw = Number(data_grid_raw.getValue(row, Number(prop)));

					hay_cambio = valueGrid !== valueGridRaw;
				}
				// TODO: Validar cambios con raw au y bp
				if (hay_cambio && row < totalRowDataNumber && col < 2 + data_grid.clusters.length) {
					td.style.borderRadius = '50px';
					td.style.borderColor = '#6C757D';
				}

				if (col >= 2 && col < 2 + data_grid.clusters.length && value !== null) {
					const minValue = data_grid.min;
					const maxValue = data_grid.max;

					let valueColor = null;

					if (row < totalRowDataNumber) {
						if (value > maxValue) {
							valueColor = fcalcColor(value, minValue, value, false);
						} else if (value < minValue) {
							valueColor = fcalcColor(value, value, maxValue, false);
						} else {
							valueColor = fcalcColor(value, minValue, maxValue, false);
						}
					} else {
						valueColor = new Color(243, 242, 240, 1);
					}

					const hexColor = valueColor.toHex();

					if (hexColor.length === 7) {
						td.style.backgroundColor = hexColor;
					}
				}

				const szValue = formatNumber(value);
				td.innerHTML = szValue;
			}
		}

		if (typeof value === 'string') {
			td.style.borderRadius = '50px';
			td.style.borderColor = '#6C757D';
		}
	};

	sendSnackbar(errMensaje) {
		this.sendSnackbarActive = true;
		this.snackBar.openFromComponent(SnackbarComponent, {
			data: errMensaje,
			panelClass: ['snackValidation']
		});
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackIncorrect']
		});
	}

	refreshUI() {
		this.changeDetectorRef.detectChanges();
	}
}
