import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClustersService, SeasonByParams } from '@services/clusters/clusters.service';
import {
	BffIndicatorsIn,
	ClusterDescriptionSaveBffIn,
	ClustersSaveBffIn,
	ClustersUpdateKpiBffIn,
	DataClusterModelOutput,
	DataClusters,
	DataIndicators,
	IndicatorClustersService,
	MenuesSaveBffIn,
	SaveBffIn,
	TodBffIn,
	UpdateKpiBffIn
} from '@services/clusters/indicator-cluster.service';
import { DialogService } from '@services/dialog.service';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Bounds } from 'src/app/models/Bounds';
import { ClusterDescriptiveStatistics } from 'src/app/models/clusters/ClusterDescriptiveStatistics';
import { convertTods, GridTod, TodStartEnd, zipGridTod } from 'src/app/models/clusters/GridTod';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { D3ClusterElbowSilhouetteRenderer } from '../scatterplots/cluster-elbow-silhouette-scatterplot/D3-cluster-elbow-silhouette-scatterplot/D3ClusterElbowSilhouetteRenderer';
import { D3ClusterInd1Ind2Renderer } from '../scatterplots/cluster-ind1-vs-ind2-scatterplot/D3-cluster-ind1-vs-ind2-scatterplot/D3ClusterInd1Ind2Renderer';
import { AllGrids } from './grids-tod-dow/card-tod-dow/card-tod-dow.component';
import { BaselineValues, ModelValues } from './menu-clusters/menu-clusters.interface';
import { TemporalTimeZone } from './tod-tools-container/tod-tools-container.component';

@Component({
	selector: 'app-clusters',
	templateUrl: './clusters.component.html',
	styleUrls: ['./clusters.component.scss']
})
export class ClustersComponent implements OnInit, AfterViewInit, OnDestroy {
	selectedSeason: string;

	menuBaseline: BaselineValues;
	menu_model: ModelValues;

	data_clusters: DataClusters;
	data_baseline_clusters: DataClusters;
	data_indicators: DataIndicators;

	// ojo: es distinto a los anteriores
	grids_model_output: Bounds<GridTod<number>>;

	grids_ready = false;
	grids_baseline_ready = false;
	grid_baseline_visible = false;

	disableStatusKpi = true;

	flagChangedSeason = false;
	dataInputKpi: Array<ClusterDescriptiveStatistics>;

	// Flag de snack bar activo
	sendSnackbarActive;
	flagUpdateBaseline = false;
	flagRefreshClusters = false;

	flagCalculate = false;

	/*
	 * graficos indicator 1 (ej Rask) vs indicator 2 (ej load factor)
	 */

	clusters_distinct: Array<number> = [];

	// contiene la data que se grafica (cluster + indicador1 + indicador2)
	// data_rask_yield: Array<D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point> = [];
	// data_rask_loadfactor: Array<D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point> = [];
	data_loadFactor_yield: Array<D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point> = [];
	data_yield_shortAP: Array<D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point> = [];
	data_loadFactor_inter: Array<D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point> = [];
	data_loadFactor_domestic: Array<D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point> = [];
	// TODO: agregar data de todos los graficos

	/*
	 * grafico elbow / silhouette
	 */
	data_elbow_silhouette: Array<D3ClusterElbowSilhouetteRenderer.ElbowSilhouettePoint> = [];

	// Menu superior
	seasons: string[] = [];
	seasons_ready = false;

	resetSource = new BehaviorSubject<string>('');

	constructor(
		private indicatorClusterServices: IndicatorClustersService,
		private elementRef: ElementRef,
		private clustersServices: ClustersService,
		private dialog: DialogService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.getSeasonsMenu();
	}

	ngOnDestroy() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'hidden';
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}

	ngAfterViewInit() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'scroll';
	}

	changeSelectedSeason(event: string) {
		if (typeof event !== 'undefined') {
			this.resetSource.next('reset');
			this.selectedSeason = event;
			this.flagChangedSeason = true;
			this.dataInputKpi = [];
			this.getDataClusters(this.selectedSeason);
		}
	}

	// callback:
	// - cuando la grilla de cluster es cargada / modificada
	// - cuando se clickea el boton "Update KPI's"
	updateKpiTable(data_grid_clusters: Bounds<GridTod<number>>) {
		this.displayDescriptiveStatistics(data_grid_clusters);
		this.disableStatusKpi = true;
	}

	// callback
	// - cuando las grillas de incicadores son cargadas
	// - cuando se clickea el boton "Update KPI's"
	refreshScatterplots(allGrids: AllGrids) {
		this.calcArreglosScatterplots(allGrids);
	}

	// callback cuando estadisticas descriptivas son cargadas
	refreshDescriptiveStatistics() {
		// TODO: no funciona pq esta variable no cambia de valor con la edicion de la grilla de clusters.
		// Cuando se edita una celda, es la misma variable, pero dentro del componente hijo, que cambia de valor
		// => hay que manejar el estado en un solo componente
		this.disableStatusKpi = true;
		// this.displayDescriptiveStatistics();
	}

	// callback cuando se selecciona otra season para importar clusters
	importClustersFromSeason(season: string) {
		this.disableStatusKpi = false;
		this.getDataClusters(season);
	}

	// callback del boton save en cuadro grillas:
	// - se recibe el contenido de la grilla de clusters desde el cuadro grillas, el que emite el evento.
	// - hay que complementar con el contenido del menu lateral y con informacion de la sesion
	saveAll(save_info: [string, Array<ClustersSaveBffIn>]) {
		const clusters_descriptions = this.dataInputKpi.map((kpi) => {
			const row: ClusterDescriptionSaveBffIn = {
				cluster: Number(kpi.cluster),
				description: kpi.description
			};

			return row;
		});

		const menuMap = this.menuBaseline;
		delete menuMap.level.elements;

		const menues: MenuesSaveBffIn = {
			menu_baseline: {
				level: menuMap && menuMap.level && menuMap.level.level,
				elementCode: menuMap && menuMap.elementCode && menuMap.elementCode.elementCode,
				cabinCode: menuMap && menuMap.cabinCode && menuMap.cabinCode.code,
				startDate: menuMap && menuMap.startDate,
				endDate: menuMap && menuMap.endDate,
				seasons: menuMap && menuMap.seasons,
				shortAP: menuMap && menuMap.shortAP
			},
			menu_model: this.menu_model
		};

		const levelId = OneTimeContext.getLevelId();
		const cabinCode = OneTimeContext.getCabinCode();
		const elementCode = OneTimeContext.getElementCode();
		const sessionId = OneTimeContext.getSessionID();

		const toBeSaved: SaveBffIn = {
			sessionId,
			level: levelId,
			elementCode,
			cabinCode,
			season: this.selectedSeason,
			model: '',
			modelParameters: '',
			// TODO: ?????
			baseLineElementCode: this.menuBaseline.elementCode.elementCode,
			baseLineCabinCode: this.menuBaseline.cabinCode.code,
			baseLineStartDate: this.menuBaseline.startDate,
			baseLineEndDate: this.menuBaseline.endDate,
			baseLineSeason: this.menuBaseline.seasons.join(','),

			sourceType: save_info[0],
			clusters: save_info[1],
			clusterDescription: clusters_descriptions,
			menues
		};

		this.indicatorClusterServices.save(toBeSaved).subscribe(
			(out) => {
				console.log(out.description);
				this.openSnackBarCorrect('Clusters have been successfully saved');
			},
			(error) => {
				console.log('saveAll', error);
				this.openSnackBarIncorrect('Unable to save Clusters');
			}
		);
	}

	/*
	 *
	 */
	getDataClusters(selectedSeason: string): void {
		const cabinCode = OneTimeContext.getCabinCode();
		const elementCode = OneTimeContext.getElementCode();
		const season = selectedSeason;
		const sessionId = OneTimeContext.getSessionID();

		this.cleanDataIndicators();

		this.indicatorClusterServices.getDataClusters(cabinCode, elementCode, season, sessionId).subscribe(
			(dataClusters) => {
				this.data_clusters = dataClusters;
				this.grids_ready = true;
				this.loadIndicators();
			},
			(error) => {
				console.log('getDataClusters', error);
			}
		);
	}

	getDataBaselineClusters(formBaselineValue: BaselineValues): void {
		if (formBaselineValue.seasons.length >= 1) {
			this.grid_baseline_visible = true;
			const cabinCode = formBaselineValue.cabinCode.code;
			const elementCode = formBaselineValue.elementCode.elementCode;
			const season = this.selectedSeason;
			const sessionId = OneTimeContext.getSessionID();

			this.indicatorClusterServices.getDataClusters(cabinCode, elementCode, season, sessionId).subscribe(
				(dataClusters) => {
					this.data_baseline_clusters = dataClusters;

					this.grids_baseline_ready = true;
				},
				(error) => {
					console.log('getDataBaselineClusters', error);
				}
			);
		} else {
			this.grid_baseline_visible = false;
		}
	}

	loadIndicators() {
		if (typeof this.menuBaseline !== 'undefined' && typeof this.data_clusters !== 'undefined') {
			// console.log ("Todo listo para cargar indicadores") ;

			this.getDataIndicators(this.selectedSeason, this.data_clusters, this.menuBaseline);
		} else {
			console.log('falta data para cargar indicadores', this.menuBaseline, this.data_clusters);
		}
	}

	getDataIndicators(selectedSeason: string, dataClusters: DataClusters, menuBaseline: BaselineValues) {
		const sessionId = OneTimeContext.getSessionID();

		const tods_outbound = convertTods(dataClusters.outbound.franjas).map((tod) => this.convToTodBffIn('outbound', tod));
		const tods_inbound = convertTods(dataClusters.inbound.franjas).map((tod) => this.convToTodBffIn('inbound', tod));

		const tods = tods_outbound.concat(tods_inbound);

		const bffIn: BffIndicatorsIn = {
			sessionID: sessionId,
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			season: selectedSeason,
			tods: tods,
			shortAP: this.menuBaseline.shortAP,
			elementCodeBaseline: OneTimeContext.getBaselineElement(),
			cabinCodeBaseline: menuBaseline.cabinCode.code,
			seasonsBaseline: menuBaseline.seasons,
			startDateBaseline: moment(menuBaseline.startDate).format('YYYY-MM-DD'),
			endDateBaseline: moment(menuBaseline.endDate).format('YYYY-MM-DD')
		};

		// console.log (bffIn) ;
		// this.displayDescriptiveStatistics();

		this.indicatorClusterServices.getAllIndicators(bffIn).subscribe(
			(dataIndicators) => {
				this.data_indicators = dataIndicators;
				if (this.flagUpdateBaseline) {
					this.openSnackBarCorrect('Baseline data has been updated successfully');
					this.flagUpdateBaseline = false;
				} else if (this.flagRefreshClusters) {
					this.openSnackBarCorrect('Clusters refreshed successfully');
					this.flagRefreshClusters = false;
				}
			},
			(error) => {
				console.log('getDataIndicators', error);

				if (this.flagUpdateBaseline) {
					this.openSnackBarIncorrect('Unable to get Baseline data');
					this.flagUpdateBaseline = false;
				} else if (this.flagRefreshClusters) {
					this.openSnackBarIncorrect('Unable to refresh Clusters');
					this.flagRefreshClusters = false;
				}
			}
		);
	}

	/*
	 * se puede llamar una vez que se cargaron las grillas
	 */
	calcArreglosScatterplots(grids: AllGrids): void {
		function boundsToPoints(
			leg_outbound: string,
			leg_inbound: string,
			clusters: Bounds<GridTod<number>>,
			bound1: Bounds<GridTod<number>>,
			bound2: Bounds<GridTod<number>>
		) {
			const newClusterInd1Ind2PointOutbound = (
				tod_start: string,
				tod_end: string,
				dow: number,
				cluster: number,
				ind1: number,
				ind2: number
			) => {
				return new D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point(
					leg_outbound,
					tod_start + '-' + tod_end,
					dow,
					cluster,
					ind1,
					ind2
				);
			};
			const newClusterInd1Ind2PointInbound = (
				tod_start: string,
				tod_end: string,
				dow: number,
				cluster: number,
				ind1: number,
				ind2: number
			) => {
				return new D3ClusterInd1Ind2Renderer.ClusterInd1Ind2Point(
					leg_inbound,
					tod_start + '-' + tod_end,
					dow,
					cluster,
					ind1,
					ind2
				);
			};

			const outbound = zipGridTod(clusters.outbound, bound1.outbound, bound2.outbound, newClusterInd1Ind2PointOutbound);
			const inbound = zipGridTod(clusters.inbound, bound1.inbound, bound2.inbound, newClusterInd1Ind2PointInbound);

			return outbound.concat(inbound);
		}

		const leg_outbound = this.data_clusters.outbound.leg;
		const leg_inbound = this.data_clusters.inbound.leg;

		const clusters_cells = grids.clusters.outbound.toArray().concat(grids.clusters.inbound.toArray());
		this.clusters_distinct = clusters_cells.filter((cluster, i) => clusters_cells.indexOf(cluster) === i).sort();

		// this.data_rask_yield = boundsToPoints (grids.clusters, grids.rask, grids.yield) ;
		// this.data_rask_loadfactor = boundsToPoints (grids.clusters, grids.rask, grids.load_factor);
		this.data_loadFactor_yield = boundsToPoints(
			leg_outbound,
			leg_inbound,
			grids.clusters,
			grids.load_factor,
			grids.yield
		);
		this.data_yield_shortAP = boundsToPoints(
			leg_outbound,
			leg_inbound,
			grids.clusters,
			grids.yield,
			grids.short_ap_pax
		);
		this.data_loadFactor_inter = boundsToPoints(
			leg_outbound,
			leg_inbound,
			grids.clusters,
			grids.load_factor,
			grids.international_connections
		);
		this.data_loadFactor_domestic = boundsToPoints(
			leg_outbound,
			leg_inbound,
			grids.clusters,
			grids.load_factor,
			grids.domestic_connections
		);
	}

	// llamado cuando se clickea boton "Update Baseline"
	updateBaseline(formValue: BaselineValues): void {
		// Evento Boton Update Baseline
		this.flagUpdateBaseline = true;
		formValue.endDate = moment(formValue.endDate).format('YYYY-MM-DD');
		formValue.startDate = moment(formValue.startDate).format('YYYY-MM-DD');
		this.menuBaseline = formValue;
		this.getDataBaselineClusters(formValue);
		this.loadIndicators();
		this.disableStatusKpi = false;
		// endpoint
	}

	// llamado cada vez que cambia un valor de un campo del formulario baseline
	updateChangesBaseline(formValue: BaselineValues): void {
		this.menuBaseline = formValue;
	}

	// llamado cada vez que cambia un valor de un campo del formulario Model
	updateChangesModel(formValue: ModelValues): void {
		this.menu_model = formValue;
	}

	// llamado, una sola vez, cuando termina de cargar los datos del formulario baseline
	updateLoadBaseline(formValue: BaselineValues): void {
		this.menuBaseline = formValue;

		this.cleanDataIndicators();
		if (this.selectedSeason) {
			this.getDataBaselineClusters(formValue);
		}
		this.loadIndicators();
		// this.displayDescriptiveStatistics();
	}

	/*
	 *
	 */
	convToTodBffIn(boundParam: string, tod: TodStartEnd): TodBffIn {
		const ret: TodBffIn = {
			bound: boundParam,
			todStart: tod.start + ':00',
			todEnd: tod.end + ':00'
		};

		return ret;
	}

	displayDescriptiveStatistics(data_grid_clusters: Bounds<GridTod<number>>) {
		const tods_outbound = convertTods(this.data_clusters.outbound.franjas).map((tod) =>
			this.convToTodBffIn('outbound', tod)
		);
		const tods_inbound = convertTods(this.data_clusters.inbound.franjas).map((tod) =>
			this.convToTodBffIn('inbound', tod)
		);

		const todsInbound = tods_outbound.concat(tods_inbound);

		const payload_cluster = this.getClustersFromData(
			data_grid_clusters,
			OneTimeContext.getElementCode(),
			OneTimeContext.getCabinCode(),
			this.selectedSeason,
			this.data_clusters.outbound.leg,
			this.data_clusters.inbound.leg
		);

		const payload: UpdateKpiBffIn = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			season: this.selectedSeason,
			sessionID: OneTimeContext.getSessionID(),
			numberClusters: 1,
			shortAP: this.menuBaseline.shortAP,
			tods: todsInbound,
			elementCodeBaseline: OneTimeContext.getBaselineElement(),
			cabinCodeBaseline: this.menuBaseline.cabinCode.code,
			seasonsBaseline: this.menuBaseline.seasons,
			startDateBaseline: moment(this.menuBaseline.startDate).format('YYYY-MM-DD'),
			endDateBaseline: moment(this.menuBaseline.endDate).format('YYYY-MM-DD'),
			clusters: payload_cluster
		};

		console.log('descriptive statistics-in', payload);

		this.indicatorClusterServices.updateKpis(payload).subscribe(
			(res) => {
				console.log('descriptive statistics-out', res);
				this.dataInputKpi = res;
				this.openSnackBarCorrect('KPIs have been updated successfully');
			},
			(error) => {
				console.log('Error al actualizar KPI para Descriptive Statistics', error);
				this.openSnackBarIncorrect('Unable to update KPIs');
			}
		);
	}

	getSeasonsMenu() {
		const dateStart = moment().startOf('month').add(-6, 'month').format('YYYY-MM-DD');
		const dateEnd = moment().endOf('month').add(200, 'year').format('YYYY-MM-DD');

		const params: SeasonByParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			initDate: dateStart,
			endDate: dateEnd,
			sessionId: OneTimeContext.getSessionID()
		};

		this.clustersServices.getSeasonsByParams(params).subscribe(
			(response) => {
				this.seasons = response;
				this.seasons_ready = true;
			},
			(error) => {
				console.log('getSeasonsMenu', error);
			}
		);
	}

	/*
	 *
	 */
	updateIndicatorModelOutput(data: DataClusterModelOutput) {
		console.log(data);
		this.grids_model_output = data.grids;

		this.data_elbow_silhouette = data.stats;
		this.flagCalculate = true;
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackIncorrect']
		});
	}

	private getClustersFromData(
		data: Bounds<GridTod<number>>,
		elementCode: string,
		cabinCode: string,
		season: string,
		leg_outbound: string,
		leg_inbound: string
	): Array<ClustersUpdateKpiBffIn> {
		const outbound_clusters = this.getClustersByBoundRows(
			data.outbound,
			'outbound',
			elementCode,
			cabinCode,
			season,
			leg_outbound
		);
		const inbound_clusters = this.getClustersByBoundRows(
			data.inbound,
			'inbound',
			elementCode,
			cabinCode,
			season,
			leg_inbound
		);

		return outbound_clusters.concat(inbound_clusters);
	}

	private getClustersByBoundRows(
		bound_data: GridTod<number>,
		bound: string,
		elementCode: string,
		cabinCode: string,
		season: string,
		leg: string
	): Array<ClustersUpdateKpiBffIn> {
		const clustersList = bound_data.map((tod_start: string, tod_end: string, dow: number, value: number) => {
			const cluster: ClustersUpdateKpiBffIn = {
				elementCode,
				cabinCode,
				season,
				bound,
				todStart: tod_start + ':00',
				todEnd: tod_end + ':00',
				dow,
				clusterValue: value,
				clusterValueRaw: value,
				leg
			};

			return cluster;
		});

		return clustersList;
	}

	// callback import clusters
	callRefreshClusters(franjas: TemporalTimeZone) {
		console.log('callRefreshClusters', franjas);
		const tempOutbound = this.indicatorClusterServices.calcFranjas(
			franjas.temporalOutbound.map((franja) => franja.start)
		);
		const tempInbound = this.indicatorClusterServices.calcFranjas(
			franjas.temporalInbound.map((franja) => franja.start)
		);

		this.cleanDataIndicators();

		/* seteo nuevo cluster para llamada de indicadores */
		this.data_clusters.inbound.cells = [];
		this.data_clusters.inbound.franjas = tempInbound[1];
		this.data_clusters.outbound.cells = [];
		this.data_clusters.outbound.franjas = tempOutbound[1];

		this.grids_model_output = undefined;

		this.data_clusters = { ...this.data_clusters };
		this.flagRefreshClusters = true;

		this.loadIndicators();
	}

	/*
	 * Vacia las grillas de los indicadores.  Se uaa antes de recargar la grilla de cluster.
	 *
	 * De esta forma, evita que se dispare el evento cuyo callback es refreshScatterplots(): no queremos que la lista
	 * de franjas de la grilla de clusters sea distinta a la lista de franjas de los indicadores.
	 */
	cleanDataIndicators() {
		this.data_indicators = undefined;
	}
}
