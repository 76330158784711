<app-card-container *ngIf="disableView">
	<ng-container content>
		<div class="contenido">
			<div class="contengo">
				<table id="customers">
					<tr>
						<th>Level</th>
						<th>Element</th>
						<th>Cabin</th>
					</tr>
					<tr>
						<td>{{ dataFilter.level }}</td>
						<td>{{ dataFilter.element.elementCode }}</td>
						<td>{{ dataFilter.cabin.description }}</td>
					</tr>
				</table>
			</div>
			<div class="kpiHeaders">
				<app-kpi-table [objetoLlamada]="dataFilter" [createSession]="stateNewSession"></app-kpi-table>
			</div>
		</div>
	</ng-container>
	<ng-container actions>
		<button class="btn-goto" (click)="createNewSessionAndNavigateTo('seasons')">Seasons</button>
		<button class="btn-goto" (click)="createNewSessionAndNavigateTo('clusters')">Clusters</button>
		<button class="btn-goto" (click)="createNewSessionAndNavigateTo('target-curves')">Target Curves</button>
		<button class="btn-goto" (click)="createNewSessionAndNavigateTo('buckets')">Buckets</button>
	</ng-container>
</app-card-container>

<app-card-container *ngIf="disableView" [actionsView]="false">
	<ng-container content>
		<div class="container">
			<div class="row" *ngFor="let calendar of calendars">
				<div class="col-md-6">
					<app-calendar-container
						titleCalendar="Outbound"
						[elementCode]="dataFilter.element.outbound"
						[month]="calendar.month"
						[year]="calendar.year"
					>
						<app-calendar-rem
							[readOnly]="true"
							[rowHeaders]="true"
							[calendarType]="calendarType"
							[columns]="columns"
							[data]="calendar.outboundCalendars"
						></app-calendar-rem>
					</app-calendar-container>
				</div>
				<div class="col-md-6">
					<app-calendar-container
						titleCalendar="Inbound"
						[elementCode]="dataFilter.element.inbound"
						[month]="calendar.month"
						[year]="calendar.year"
					>
						<app-calendar-rem
							[readOnly]="true"
							[rowHeaders]="true"
							[calendarType]="calendarType"
							[columns]="columns"
							[data]="calendar.inboundCalendars"
						></app-calendar-rem>
					</app-calendar-container>
				</div>
			</div>
		</div>
	</ng-container>
</app-card-container>

<div style="height: 50px"></div>
