<ng-container class="table-clusters">
	<div class="title-type">{{ title }}</div>

	<div *ngIf="data_ready">
		<hot-table
			[settings]="tableSettings"
			[columns]="columns"
			[data]="rows"
			readOnly="false"
			stretchH="all"
			[hotId]="id"
			class="hot handsontable htColumnHeaders"
		>
		</hot-table>
	</div>
</ng-container>
