<ng-container>
	<mat-card appearance="outlined" class="mat-card">
		<mat-card-content class="mat-card-content">
			<div class="col-md-4">
				<div class="linea-container" *ngIf="data_grid_target_curves_ready">
					<!-- Tabla -->
					<app-grid-ap-clusters
						[readOnly]="false"
						title="Target curves"
						[data_grid]="data_grid_target_curves"
						[data_grid_raw]="data_grid_target_curves_raw"
						[show_ap]="true"
						[rendererGrid]="rendererPP"
						[columns_width]="columns_width_tc"
						(target_curves_edited)="onTargetCurvesEdited($event)"
					>
					</app-grid-ap-clusters>

					<br />

					<div>
						<app-target-lf
							[clusters]="clusters"
							[columns_width]="columns_width_tc"
							(dataTargetOut)="dataTargetOut($event)"
						>
						</app-target-lf>
					</div>
				</div>
			</div>
			<div class="col-md-8">
				<!-- target curves -->
				<div
					class="col-md-4 container-tablas"
					*ngIf="
						data_grid_target_curves_pp_ready && data_grid_target_curves_pax_ready && data_grid_target_curves_selected
					"
				>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Target Curves (pp)"
							[data_grid]="data_grid_target_curves_pp"
							[show_ap]="false"
							[rendererGrid]="rendererPP"
							[columns_width]="columns_width_pp_tc"
						>
						</app-grid-ap-clusters>
					</div>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Target Curves (pax)"
							[data_grid]="data_grid_target_curves_pax"
							[show_ap]="false"
							[rendererGrid]="rendererPax"
							[columns_width]="columns_width_pax_tc"
						>
						</app-grid-ap-clusters>
					</div>
				</div>

				<!-- baseline -->
				<div
					class="col-md-4 container-tablas"
					*ngIf="data_grid_baseline_pp_ready && data_grid_baseline_pax_ready && data_grid_baseline_selected"
				>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Baseline bookings (pp)"
							[data_grid]="data_grid_baseline_pp"
							[show_ap]="false"
							[rendererGrid]="rendererPP"
							[columns_width]="columns_width_baseline_pp"
						>
						</app-grid-ap-clusters>
					</div>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Baseline bookings (pax)"
							[data_grid]="data_grid_baseline_pax"
							[show_ap]="false"
							[rendererGrid]="rendererPax"
							[columns_width]="columns_width_baseline_pax"
						>
						</app-grid-ap-clusters>
					</div>
				</div>

				<!-- Δ baseline -->
				<div
					class="col-md-4 container-tablas"
					*ngIf="
						data_grid_baseline_delta_pp_ready && data_grid_baseline_delta_pax_ready && data_grid_baseline_delta_selected
					"
				>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Δ Baseline bookings (pp)"
							[data_grid]="data_grid_baseline_delta_pp"
							[show_ap]="false"
							[rendererGrid]="rendererPP"
							[columns_width]="columns_width_baseline_delta_pp"
						>
						</app-grid-ap-clusters>
					</div>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Δ Baseline bookings (pax)"
							[data_grid]="data_grid_baseline_delta_pax"
							[show_ap]="false"
							[rendererGrid]="rendererPax"
							[columns_width]="columns_width_baseline_delta_pax"
						>
						</app-grid-ap-clusters>
					</div>
				</div>

				<!-- baseline target curves -->
				<div
					class="col-md-4 container-tablas"
					*ngIf="data_grid_baseline_tc_pp_ready && data_grid_baseline_tc_pax_ready && data_grid_baseline_tc_selected"
				>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Baseline target curves (pp)"
							[data_grid]="data_grid_baseline_tc_pp"
							[show_ap]="false"
							[rendererGrid]="rendererPP"
							[columns_width]="columns_width_baseline_tc_pp"
						>
						</app-grid-ap-clusters>
					</div>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Baseline target curves (pax)"
							[data_grid]="data_grid_baseline_tc_pax"
							[show_ap]="false"
							[rendererGrid]="rendererPax"
							[columns_width]="columns_width_baseline_tc_pax"
						>
						</app-grid-ap-clusters>
					</div>
				</div>

				<!-- productive -->
				<div
					class="col-md-4 container-tablas"
					*ngIf="data_grid_productive_pp_ready && data_grid_productive_pax_ready && data_grid_productive_selected"
				>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Productive (pp)"
							[data_grid]="data_grid_productive_pp"
							[show_ap]="false"
							[rendererGrid]="rendererPP"
							[columns_width]="columns_width_productive_pp"
						>
						</app-grid-ap-clusters>
					</div>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Productive (pax)"
							[data_grid]="data_grid_productive_pax"
							[rendererGrid]="rendererPax"
							[show_ap]="false"
							[columns_width]="columns_width_productive_pax"
						>
						</app-grid-ap-clusters>
					</div>
				</div>

				<!-- productive delta-->
				<div
					class="col-md-4 container-tablas"
					*ngIf="
						data_grid_productive_delta_pp_ready &&
						data_grid_productive_delta_pax_ready &&
						data_grid_productive_delta_selected
					"
				>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Δ Productive (pp)"
							[data_grid]="data_grid_productive_delta_pp"
							[show_ap]="false"
							[rendererGrid]="rendererPP"
							[columns_width]="columns_width_productive_delta_pp"
						>
						</app-grid-ap-clusters>
					</div>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Δ Productive (pax)"
							[data_grid]="data_grid_productive_delta_pax"
							[rendererGrid]="rendererPax"
							[show_ap]="false"
							[columns_width]="columns_width_productive_delta_pax"
						>
						</app-grid-ap-clusters>
					</div>
				</div>

				<!-- model output -->
				<div
					class="col-md-4 container-tablas"
					*ngIf="data_grid_model_output_pp_ready && data_grid_model_output_pax_ready && data_grid_model_output_selected"
				>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Model output (pp)"
							[data_grid]="data_grid_model_output_pp"
							[show_ap]="false"
							[rendererGrid]="rendererPP"
							[columns_width]="columns_width_model_output_pp"
						>
						</app-grid-ap-clusters>
					</div>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Model output (pax)"
							[data_grid]="data_grid_model_output_pax"
							[rendererGrid]="rendererPax"
							[show_ap]="false"
							[columns_width]="columns_width_model_output_pax"
						>
						</app-grid-ap-clusters>
					</div>
				</div>

				<!-- Δ model output -->
				<div
					class="col-md-4 container-tablas"
					*ngIf="
						data_grid_model_output_delta_pp_ready &&
						data_grid_model_output_delta_pax_ready &&
						data_grid_model_output_delta_selected
					"
				>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Δ Model output (pp)"
							[data_grid]="data_grid_model_output_delta_pp"
							[show_ap]="false"
							[rendererGrid]="rendererPP"
							[columns_width]="columns_width_model_output_delta_pp"
						>
						</app-grid-ap-clusters>
					</div>
					<div>
						<!-- Tabla -->
						<app-grid-ap-clusters
							[readOnly]="true"
							title="Δ Model output (pax)"
							[data_grid]="data_grid_model_output_delta_pax"
							[rendererGrid]="rendererPax"
							[show_ap]="false"
							[columns_width]="columns_width_model_output_delta_pax"
						>
						</app-grid-ap-clusters>
					</div>
				</div>
			</div>
			<!-- -->
		</mat-card-content>

		<mat-card-actions class="select-rem">
			<div class="btn-trio">
				<button class="btn-apply" (click)="applyClick()">Apply Target out</button>
			</div>
			<div class="contenedor-btn">
				<span class="select-white">
					<mat-form-field class="ml-22">
						<mat-select [formControl]="selectSeason" placeholder="Import Target Curves">
							<mat-option [value]="season" *ngFor="let season of data_list_seasons">{{ season }}</mat-option>
						</mat-select>
					</mat-form-field>
				</span>
				<button class="btn-copy" (click)="onClickImportBaselineCurves()">Import Baseline Curves</button>
				<div *ngIf="data_grid_model_output_selected">
					<button class="btn-copy" (click)="onClickCopyModelOutput()">Copy Model Output</button>
				</div>

				<div class="btn-select">
					<mat-form-field appearance="outline" class="ml-22">
						<mat-select multiple [formControl]="selectIndicadores" placeholder="Select Indicator">
							<mat-option
								[value]="indicator"
								*ngFor="let indicator of optionIndicators"
								[disabled]="maxOptionDisabled(indicator)"
							>
								{{ indicator }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</mat-card-actions>
	</mat-card>
</ng-container>
