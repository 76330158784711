/*
 *
 */
export interface ApValue {
	ap: number;
	value_pp: number;
	value_pax: number;
}

/*
 *
 */
export class GridApClusters {
	constructor(
		public clusters: Array<number>,
		public aps_end: Array<number>,
		public rows: Array<Array<number>>,
		public min_value: number,
		public max_value: number,
		public calcTotal: (t1: number, t2: number) => number
	) {}

	getValue(row: number, col: number): number {
		const rowFilter = this.rows[row] || [];
		return rowFilter.find((_, index) => index === col);
	}
}

// ----------------------------------------------------------------------------
// funciones
// ----------------------------------------------------------------------------

/*
 *
 */
function createRow(clusters: Array<number>, aps: Array<number>, firstCol: string): Array<any> {
	let row: Array<any> = [];

	// ap_start - ap_end  o "Total"
	row = [...row, firstCol];

	// los clusters
	for (const i of clusters) {
		row = [...row, Number.NaN];
	}

	// columna total
	row = [...row, 0];

	return row;
}

/*
 *
 */
export function createGridApClustersNumbers(clusters: Array<number>, aps: Array<number>): GridApClusters {
	function sumNumber(t1: number, t2: number): number {
		if (Number.isNaN(t1)) {
			return sumNumber(0.0, t2);
		}

		if (Number.isNaN(t2)) {
			return sumNumber(t1, 0.0);
		}

		return t1 + t2;
	}

	return createGridApClusters(clusters, aps, sumNumber);
}

/*
 *
 */
export function createGridApClusters(
	clusters: Array<number>,
	aps: Array<number>,
	calcTotal: (t1: number, t2: number) => number
): GridApClusters {
	let rows: Array<Array<any>> = [];

	let ap_start = 1;

	for (const ap_end of aps) {
		const ap = ap_start.toString() + '-' + ap_end.toString();
		const row = createRow(clusters, aps, ap);
		rows = [...rows, row];

		ap_start = ap_end + 1;
	}

	const row = createRow(clusters, aps, 'Total');

	rows = [...rows, row];

	return new GridApClusters(clusters, aps, rows, Number.MAX_VALUE, Number.MIN_VALUE, calcTotal);
}

/*
 *
 */
export function loadApCluster(
	grid_pp: GridApClusters,
	grid_pax: GridApClusters,
	cluster: number,
	aps_values: Array<ApValue>
): void {
	// se suma 1, pq AP es la primera columna
	const offsetCluster = grid_pp.clusters.indexOf(cluster) + 1;

	if (offsetCluster === 0) {
		throw new Error('cluster no es parte de esta grilla ' + cluster);
	}

	const zero = 0.0;
	let totalPP = zero;
	let totalPax = zero;

	for (const ap_value of aps_values) {
		const offsetAp = grid_pp.aps_end.indexOf(ap_value.ap);

		if (offsetAp === -1) {
			throw new Error('ap not found ' + ap_value.ap);
		}

		const row_pp = grid_pp.rows[offsetAp];
		const row_pax = grid_pax.rows[offsetAp];

		row_pp[offsetCluster] = ap_value.value_pp;
		row_pax[offsetCluster] = ap_value.value_pax;

		totalPP = grid_pp.calcTotal(totalPP, ap_value.value_pp);
		totalPax = grid_pax.calcTotal(totalPax, ap_value.value_pax);

		// actualiza min/max
		const value_pp = ap_value.value_pp as unknown as number;
		if (value_pp < grid_pp.min_value) {
			grid_pp.min_value = value_pp;
		}
		if (value_pp > grid_pp.max_value) {
			grid_pp.max_value = value_pp;
		}

		const value_pax = ap_value.value_pax as unknown as number;
		if (value_pax < grid_pax.min_value) {
			grid_pax.min_value = value_pax;
		}
		if (value_pax > grid_pax.max_value) {
			grid_pax.max_value = value_pax;
		}
	}

	const row_pp_total = grid_pp.rows[grid_pp.rows.length - 1];
	const row_pax_total = grid_pax.rows[grid_pax.rows.length - 1];

	row_pp_total[offsetCluster] = totalPP;
	row_pax_total[offsetCluster] = totalPax;
}

export function loadApTotal(grid_pp: GridApClusters, grid_pax: GridApClusters, aps_values: Array<ApValue>): void {
	const offset = grid_pp.rows[0].length - 1;

	for (const ap_value of aps_values) {
		const offsetAp = grid_pp.aps_end.indexOf(ap_value.ap);

		if (offsetAp === -1) {
			throw new Error('ap  ' + ap_value);
		}

		const row_pp = grid_pp.rows[offsetAp];
		const row_pax = grid_pax.rows[offsetAp];

		row_pp[offset] = ap_value.value_pp;
		row_pax[offset] = ap_value.value_pax;
	}
}

export function cloneGridApClusters(grid: GridApClusters): GridApClusters {
	const new_clusters: Array<number> = [];

	for (const grid_cluster of grid.clusters) {
		new_clusters.push(grid_cluster);
	}

	const new_rows: Array<Array<number>> = [];

	for (const orig_row of grid.rows) {
		const new_row: Array<number> = [];

		new_row.push(orig_row[0]);

		for (let i = 0; i < grid.clusters.length; i++) {
			new_row.push(orig_row[1 + i]);
		}

		new_row.push(orig_row[orig_row.length - 1]);

		new_rows.push(new_row);
	}

	const new_grid = new GridApClusters(
		new_clusters,
		grid.aps_end,
		new_rows,
		grid.min_value,
		grid.max_value,
		grid.calcTotal
	);

	return new_grid;
}

/*
 *
 */
function sumaPonderada(arr: Array<number>, ponderaciones: Array<number>): number {
	let ret = 0.0;

	arr.forEach((element, index) => {
		const ponderacion = (ponderaciones && ponderaciones.find((_, i) => i === index)) || 0;
		ret = ret + element * ponderacion;
	});

	return ret;
}

/*
 *
 */
export function cloneFromAnotherGrid(
	original: GridApClusters,
	from: GridApClusters,
	clusters: Array<number>,
	ponderaciones: Array<number>
	// calcTotalRow: (arr: Array<number>) => number
): GridApClusters {
	const indexes = clusters.map((elem) => 1 + (elem === 0 ? from.clusters.length : from.clusters.indexOf(elem)));

	const rows: Array<Array<number>> = [];

	for (const from_row of from.rows) {
		const new_row: Array<number> = [];
		new_row.push(from_row[0]);

		for (let i = 0; i < clusters.length; i++) {
			const valueCell = from_row[indexes[i]];
			new_row.push(valueCell);
		}

		// reserva el espacio para el total
		new_row.push(NaN);

		rows.push(new_row);
	}

	const ret = new GridApClusters(
		original.clusters,
		original.aps_end,
		rows,
		original.min_value, // TODO
		original.max_value, // TODO
		original.calcTotal
	);

	calcTotalRowGripApCluster(ret, ponderaciones);

	return ret;
}

export function calcTotalRowGripApCluster(data: GridApClusters, ponderaciones: Array<number>) {
	for (const data_row of data.rows) {
		const input_calc_total = data_row.slice(1, data.clusters.length + 1);

		const total = sumaPonderada(input_calc_total, ponderaciones);
		data_row[data.clusters.length + 1] = total;
	}
}

/*
 * Hace una copia de la grilla agregando o sacando columnas.
 * Usado para probar la UI.
 */

export function cloneGridApClustersPruebas(grid: GridApClusters, num_cols: number): GridApClusters {
	if (num_cols < 1) {
		throw new Error('#clusters < grid.clusters.length');
	}

	let new_grid: GridApClusters;

	if (num_cols === grid.clusters.length) {
		new_grid = grid;
	} else if (num_cols < grid.clusters.length) {
		const new_clusters: Array<number> = [];

		for (let i = 0; i < num_cols; i++) {
			new_clusters.push(grid.clusters[i]);
		}

		const new_rows: Array<Array<number>> = [];

		for (const orig_row of grid.rows) {
			const new_row: Array<number> = [];

			new_row.push(orig_row[0]);

			for (let i = 0; i < num_cols; i++) {
				new_row.push(orig_row[1 + num_cols]);
			}

			new_row.push(orig_row[orig_row.length - 1]);

			new_rows.push(new_row);
		}

		new_grid = new GridApClusters(new_clusters, grid.aps_end, new_rows, grid.min_value, grid.max_value, grid.calcTotal);
	} else {
		const orig_len = grid.clusters.length;
		const cnt_new_cols = num_cols - orig_len;
		const max_id_cluster = grid.clusters[grid.clusters.length - 1];

		const new_clusters: Array<number> = [];

		for (const cluster of grid.clusters) {
			new_clusters.push(cluster);
		}
		for (let i = 0; i < cnt_new_cols; i++) {
			new_clusters.push(max_id_cluster + 1 + i);
		}

		const new_rows: Array<Array<number>> = [];

		for (const orig_row of grid.rows) {
			const new_row: Array<number> = [];

			// ap + clusters
			for (let i = 0; i < orig_row.length - 1; i++) {
				new_row.push(orig_row[i]);
			}
			for (let i = 0; i < cnt_new_cols; i++) {
				const copied_value = orig_row[1 + (i % orig_len)];
				new_row.push(copied_value);
			}
			// total
			new_row.push(orig_row[orig_row.length - 1]);

			new_rows.push(new_row);
		}

		new_grid = new GridApClusters(new_clusters, grid.aps_end, new_rows, grid.min_value, grid.max_value, grid.calcTotal);
	}

	return new_grid;
}

/*
 *
 */
export function calculateDelta(tc: GridApClusters, other: GridApClusters): GridApClusters {
	if (typeof tc === 'undefined' || typeof other === 'undefined') {
		return undefined;
	}

	let index = 0;
	const tempArray = JSON.parse(JSON.stringify(tc.rows));
	const result = new GridApClusters(tc.clusters, tc.aps_end, tempArray, tc.min_value, tc.max_value, tc.calcTotal);
	result.rows.forEach((element) => {
		for (let indexCluster = 1; indexCluster < element.length; indexCluster++) {
			element[indexCluster] = element[indexCluster] - other.getValue(index, indexCluster);
		}
		index++;
	});
	return result;
}

/*
 *
 */
export function back2percentTargetCurves(data_grid: GridApClusters): GridApClusters {
	const min_value = data_grid.min_value / 100.0;
	const max_value = data_grid.max_value / 100.0;

	const rows: Array<Array<number>> = [];

	for (const old_row of data_grid.rows) {
		const new_row: Array<number> = [];
		for (const value of old_row) {
			new_row.push(value / 100.0);
		}
		rows.push(new_row);
	}

	return new GridApClusters(data_grid.clusters, data_grid.aps_end, rows, min_value, max_value, data_grid.calcTotal);
}

/*
 *
 */
export function copyDataFromGrid(
	to: GridApClusters,
	from: GridApClusters,
	row_start: number,
	row_end: number,
	col_start: number,
	col_end: number
): void {
	console.log('copyDataFromGrid', row_start, row_end, col_start, col_end);
	const rows_from = from.rows;
	const rows_to = to.rows;

	for (let row_num = row_start; row_num <= row_end; row_num++) {
		const row_from = rows_from[row_num];
		const row_to = rows_to[row_num];

		for (let col_num = col_start; col_num <= col_end; col_num++) {
			row_to[col_num] = row_from[col_num];
		}
	}
}
