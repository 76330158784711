<div class="menu-bar" [ngClass]="{ 'menu-bar-sticky': stickyMenu }">
	<div class="btns">
		<button class="btn-bar" (click)="linkHome()">
			<span>Home</span>
		</button>
		<button class="btn-bar" [disabled]="disabledAll" (click)="linkSeasons()">
			<mat-icon class="icon-btn">navigate_next</mat-icon>
			<span [ngClass]="{ 'text-btn': seccion === 'seasons' }">Seasons</span>
		</button>
		<button class="btn-bar" [disabled]="disabledAll" (click)="linkClusters()">
			<mat-icon class="icon-btn">navigate_next</mat-icon>
			<span [ngClass]="{ 'text-btn': seccion === 'clusters' }">Clusters</span>
		</button>
		<button class="btn-bar" [disabled]="disabledAll" (click)="linkTarget()">
			<mat-icon class="icon-btn">navigate_next</mat-icon>
			<span [ngClass]="{ 'text-btn': seccion === 'target' }">Target Curves</span>
		</button>
		<button class="btn-bar" [disabled]="disabledAll" (click)="linkBuckets()">
			<mat-icon class="icon-btn">navigate_next</mat-icon>
			<span [ngClass]="{ 'text-btn': seccion === 'buckets' }">Buckets</span>
		</button>
		<button class="btn-bar" (click)="linkSummary()">
			<mat-icon class="icon-btn">navigate_next</mat-icon>
			<span [ngClass]="{ 'text-btn': seccion === 'summary' }">Summary</span>
		</button>
	</div>
	<div class="infoSession">
		<span> {{ elemento }} - {{ cabina }} </span>
	</div>
	<button mat-icon-button>
		<mat-icon
			[matBadge]="badgeNumber"
			matBadgeColor="warn"
			matBadgeSize="small"
			[matBadgeHidden]="badgeHidden"
			(click)="toggleMenu()"
			>notifications</mat-icon
		>
	</button>
</div>
