import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClustersService, SeasonByParams } from '@services/clusters/clusters.service';
import {
	DataClusterModelOutput,
	DataClusters,
	IndicatorClustersService,
	MenuesSaveBffIn,
	TodBffIn
} from '@services/clusters/indicator-cluster.service';
import { DialogService } from '@services/dialog.service';
import { OneTimeServices } from '@services/one-time.service';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { convertTods, TodStartEnd } from 'src/app/models/clusters/GridTod';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { RESTRICTED_YEARS } from '../../one-time.constants';
import { BaselineInput, DATE_FORMAT_MENU_CLUSTERS, ModelInput } from './menu-clusters.constant';
import { BaselineValues, ModelOutputCluster, ModelValues } from './menu-clusters.interface';

export interface editorOutput {
	level: string;
	element: string;
	cabin: string;
	baselineYear: string;
	editorYear: string;
}

@Component({
	selector: 'app-menu-clusters',
	templateUrl: './menu-clusters.component.html',
	styleUrls: ['./menu-clusters.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT_MENU_CLUSTERS }
	]
})
export class MenuClustersComponent implements OnInit, OnChanges {
	// FormControlName
	baselineControl = BaselineInput;
	modelControl = ModelInput;

	// Formularios
	baselineForm: UntypedFormGroup;
	modelForm: UntypedFormGroup;

	// Opciones de Select
	levelOptions = [];
	elementOptions = [];
	cabinOptions = [];
	seasonsOptions = [];
	sendSnackbarActive;

	tods: Array<TodBffIn>;

	numberClustersOptions = Array.from(Array(20)).map((value, index) => index + 1);
	shortOptions = Array.from(Array(350)).map((value, index) => index + 1);
	modelOptions = [
		{
			display: 'international connections',
			value: 'international connections'
		},
		{ display: 'domestic connections', value: 'domestic connections' },
		{ display: 'short ap pax', value: 'short ap pax' }
	];

	// Event
	@Output() baselineClick = new EventEmitter<BaselineValues>();
	@Output() calculateClick = new EventEmitter<DataClusterModelOutput>();
	@Output() baselineChanges = new EventEmitter<BaselineValues>();
	@Output() baselineLoad = new EventEmitter<BaselineValues>();

	@Output() modelChanges = new EventEmitter<ModelValues>();

	// Autocomplete
	filteredElementOptions: Observable<any[]>;

	// Multi selection
	allSelectedModel = false;
	@ViewChild('selectModel', { static: true }) modelSel: MatSelect;

	allSelectedSeasons = false;
	@ViewChild('selectSeasons', { static: true }) seasonsSel: MatSelect;

	@Input() dataClusters: DataClusters;
	@Input() selectedSeason: string;

	bbbInSelectedSeason: string;

	wrongDataYears = [];

	startRangeYear: number;

	menues: MenuesSaveBffIn = null;

	constructor(
		private fb: UntypedFormBuilder,
		private oneTimeService: OneTimeServices,
		private clustersService: ClustersService,
		private dialog: DialogService,
		private indicatorsService: IndicatorClustersService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.getMenu();
		this.getLevelList();
		this.getCabinList();
		this.builderForms();
	}

	ngOnChanges(): void {
		const dataClustersTemp = this.dataClusters;
		if (dataClustersTemp !== undefined) {
			this.formatTodToolsClusters(dataClustersTemp);
		}
		if (this.selectedSeason !== undefined) {
			this.bbbInSelectedSeason = this.selectedSeason;
		}
	}

	builderForms() {
		this.startRangeYear = this.getFirstValidYearRange();

		const startDate = moment().startOf('month').year(this.startRangeYear).format('YYYY-MM-DD');
		const endDate = moment()
			.endOf('month')
			.year(this.startRangeYear + 1)
			.add(-1, 'month')
			.format('YYYY-MM-DD');

		// Form Baseline
		this.baselineForm = this.fb.group({
			[BaselineInput.level]: [''],
			[BaselineInput.elementCode]: [''],
			[BaselineInput.cabinCode]: [''],
			[BaselineInput.startDate]: [startDate],
			[BaselineInput.endDate]: [endDate],
			[BaselineInput.seasons]: [''],
			[BaselineInput.shortAP]: [30]
		});

		// Form Model
		this.modelForm = this.fb.group({
			[ModelInput.numberClusters]: [0],
			[ModelInput.modelInputs]: ['']
		});

		this.baselineForm.get(BaselineInput.level).valueChanges.subscribe((value) => {
			this.elementOptions = value.elements;
			OneTimeContext.setBaselineLevel(value.level);

			this.baselineForm.patchValue({
				[BaselineInput.elementCode]: ''
			});
		});

		this.baselineForm.get(BaselineInput.elementCode).valueChanges.subscribe((value) => {
			// console.log('elementCode', value, this.baselineForm.getRawValue());
			OneTimeContext.setBaselineElement(value.elementCode);
			this.getSeasonsMenu();
		});

		this.baselineForm.get(BaselineInput.cabinCode).valueChanges.subscribe((value) => {
			this.getSeasonsMenu();
		});

		this.baselineForm.valueChanges.subscribe((values: BaselineValues) => {
			const valid =
				values &&
				values.cabinCode &&
				values.elementCode &&
				values.level &&
				values.seasons &&
				values.startDate &&
				values.endDate &&
				values.shortAP;
			if (valid) {
				this.baselineChanges.emit(values);
			}
		});

		this.modelForm.valueChanges.subscribe((values: ModelValues) => {
			const valid = values && values.numberClusters; // && values.modelInputs ;

			if (valid) {
				this.modelChanges.emit(values);
			}
		});

		const baselineValueChanges = this.baselineForm.valueChanges.subscribe((values: BaselineValues) => {
			const valid =
				values &&
				values.cabinCode &&
				values.elementCode &&
				values.level &&
				values.seasons &&
				values.startDate &&
				values.endDate;
			if (valid) {
				this.baselineLoad.emit(values);
				baselineValueChanges.unsubscribe();
			}
		});

		this.modelForm.patchValue({
			[ModelInput.numberClusters]: 4
		});

		// this.getLevelList();
	}

	getFirstValidYearRange() {
		let startYear: number = parseInt(moment().subtract(1, 'years').format('YYYY'), 10);
		let endYear: number = parseInt(moment().format('YYYY'), 10);

		this.wrongDataYears.sort((a, b) => a - b);
		let isValidYear = false;
		while (!isValidYear) {
			if (this.wrongDataYears.includes(startYear) || this.wrongDataYears.includes(endYear)) {
				startYear = startYear - 1;
				endYear = endYear - 1;
			} else {
				isValidYear = true;
			}
		}
		return startYear;
	}

	updateBaseline() {
		const valuesBaseline: BaselineValues = this.baselineForm.getRawValue();

		const startYear = moment(valuesBaseline.startDate).year();
		const endYear = moment(valuesBaseline.endDate).year();

		if (this.wrongDataYears.includes(startYear) || this.wrongDataYears.includes(endYear)) {
			this.dialog
				.confirmDialog({
					title: 'You are selecting ' + startYear + ' and ' + endYear + ' as your baseline range',
					message: 'Do you want to continue?',
					confirmCaption: 'Continue',
					cancelCaption: 'Go Back',
					typeDialog: 'warning'
				})
				.subscribe((confirm) => {
					if (confirm) {
						this.baselineClick.emit(valuesBaseline);
					}
				});
		} else {
			this.baselineClick.emit(valuesBaseline);
		}
	}

	// data combobox
	getLevelList() {
		this.oneTimeService.getLevelsAndElements().subscribe(
			(data) => {
				this.levelOptions = data;
				if (this.levelOptions) {
					this.elementOptions = this.levelOptions.find((dato) => dato.level === OneTimeContext.getLevel()).elements;
					this.optionFilter();

					const level = this.levelOptions.find((option) => option.level === OneTimeContext.getLevel());
					const element = this.elementOptions.find((option) => option.elementCode === OneTimeContext.getElementCode());

					OneTimeContext.setBaselineLevel(level);
					OneTimeContext.setBaselineElement(element.elementCode);

					let menuLevel = null;
					let menuElement = null;
					if (this.menues) {
						const menuLevelSave = this.menues && this.menues.menu_baseline.level;
						menuLevel = this.levelOptions.find((option) => option.level === menuLevelSave);

						const menuElementSave = this.menues && this.menues.menu_baseline.elementCode;
						menuElement = this.elementOptions.find((option) => option.elementCode === menuElementSave);

						OneTimeContext.setBaselineLevel(menuLevel);
						OneTimeContext.setBaselineElement(menuElement);
					}

					this.baselineForm.patchValue({
						[BaselineInput.level]: menuLevel || level,
						[BaselineInput.elementCode]: menuElement || element
					});
				}
				// this.getCabinList();
			},
			(err) => {
				console.log(err);
			}
		);
	}

	getCabinList() {
		// this.builderForms();
		this.oneTimeService.getCabins().subscribe(
			(data) => {
				this.cabinOptions = data;
				if (this.cabinOptions) {
					const cabin = this.cabinOptions.find((option) => option.code === OneTimeContext.getCabinCode());
					OneTimeContext.setBaselineCabinCode(cabin.code);

					let menuCabin = null;
					if (this.menues) {
						const menuCabinSave = this.menues && this.menues.menu_baseline.cabinCode;
						menuCabin = this.cabinOptions.find((option) => option.code === menuCabinSave);
						OneTimeContext.setBaselineCabinCode(menuCabin);
					}

					this.baselineForm.patchValue({
						[BaselineInput.cabinCode]: menuCabin || cabin
					});
				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	getMenu() {
		const idSession = OneTimeContext.getSessionID();
		// Formularios
		this.baselineForm = this.fb.group({
			[BaselineInput.level]: [''],
			[BaselineInput.elementCode]: [''],
			[BaselineInput.cabinCode]: [''],
			[BaselineInput.startDate]: [''],
			[BaselineInput.endDate]: [''],
			[BaselineInput.seasons]: [''],
			[BaselineInput.shortAP]: [30]
		});
		this.modelForm = this.fb.group({
			[ModelInput.numberClusters]: [0],
			[ModelInput.modelInputs]: ['']
		});
		this.wrongDataYears = RESTRICTED_YEARS;

		this.oneTimeService.getLastMenu('cluster', idSession, this.selectedSeason).subscribe(
			(res) => {
				let menuClusters = 4;
				let menuInput: string | any = '';
				if (res !== null) {
					const menuesSave: MenuesSaveBffIn = res;
					this.menues = menuesSave;
					if (this.menues) {
						const menuClustersSave = this.menues && this.menues.menu_model.numberClusters;
						menuClusters = this.numberClustersOptions.find((v) => v === menuClustersSave);
						const menuInputSave =
							this.menues && this.menues.menu_model.modelInputs ? this.menues.menu_model.modelInputs : [];
						menuInput = this.modelOptions.filter((v) => menuInputSave.some((s) => s === v.value)).map((m) => m.value);
					}
				}

				this.modelForm.patchValue({
					[ModelInput.numberClusters]: menuClusters,
					[ModelInput.modelInputs]: menuInput
				});
				// this.getLevelList();
				// this.builderForms();
			},
			(err) => {
				console.log(err);
			}
		);
	}

	// autocomplete
	optionFilter() {
		this.filteredElementOptions = this.baselineForm.get(BaselineInput.elementCode).valueChanges.pipe(
			startWith(''),
			map((value) => (typeof value === 'string' ? value : value.elementCode)),
			map((elementCode) => (elementCode ? this._filter(elementCode) : this.elementOptions.slice()))
		);
	}

	private _filter(nombre: string) {
		const filterValue = this._normalizeValue(nombre);
		return this.elementOptions.filter((option) => this._normalizeValue(option.elementCode).includes(filterValue));
	}

	private _normalizeValue(value: string): string {
		return value.toLowerCase().replace(/\s/g, '');
	}

	// Display autocomplete
	changeElement(value) {
		const display = value ? value.elementCode : '';
		if (display) {
			OneTimeContext.setBaselineElement(display);
		}

		return display;
	}

	// Multi selection
	toggleAllSelectionModel() {
		if (this.allSelectedModel) {
			this.modelSel.options.forEach((item: MatOption) => item.select());
		} else {
			this.modelSel.options.forEach((item: MatOption) => {
				item.deselect();
			});
		}
	}

	optionModelClick() {
		let newStatus = true;
		this.modelSel.options.forEach((item: MatOption) => {
			if (!item.selected) {
				newStatus = false;
			}
		});
		this.allSelectedModel = newStatus;
	}

	toggleAllSelectionSeasons() {
		if (this.allSelectedSeasons) {
			this.seasonsSel.options.forEach((item: MatOption) => item.select());
		} else {
			this.seasonsSel.options.forEach((item: MatOption) => {
				item.deselect();
			});
		}
	}

	optionSeasonsClick() {
		let newStatus = true;
		this.seasonsSel.options.forEach((itemSeason: MatOption) => {
			if (!itemSeason.selected) {
				newStatus = false;
			}
		});
		this.allSelectedSeasons = newStatus;
	}

	// Seasons
	getSeasonsMenu() {
		const formValue: BaselineValues = this.baselineForm.getRawValue();
		const validForm =
			formValue.elementCode.elementCode && formValue.cabinCode.code && formValue.startDate && formValue.endDate;
		if (validForm) {
			const params: SeasonByParams = {
				elementCode: formValue.elementCode.elementCode,
				cabinCode: formValue.cabinCode.code,
				initDate: moment('2000-01-01').format('YYYY-MM-DD'),
				endDate: moment('3000-01-01').format('YYYY-MM-DD'),
				sessionId: OneTimeContext.getSessionID()
			};
			this.clustersService.getSeasonsByParams(params).subscribe((response) => {
				this.seasonsOptions = [...response];

				const selectedOption = this.seasonsOptions.find((v, i) => v === this.selectedSeason);
				const selected = selectedOption ? [selectedOption] : this.seasonsOptions.filter((v, i) => i === 0);

				let menuSeasons = null;
				let menuShortAP = null;
				let startDate = moment().startOf('month').year(this.startRangeYear).format('YYYY-MM-DD');
				let endDate = moment()
					.endOf('month')
					.year(this.startRangeYear + 1)
					.add(-1, 'month')
					.format('YYYY-MM-DD');
				if (this.menues) {
					const menuSeasonsSave = this.menues && this.menues.menu_baseline.seasons;

					menuSeasons = this.seasonsOptions.filter((season) => menuSeasonsSave.some((s) => s === season));

					const menuShortAPSave = this.menues && this.menues.menu_baseline.shortAP;

					menuShortAP = this.shortOptions.find((v) => v === menuShortAPSave);

					const menuStartSave = this.menues && this.menues.menu_baseline.startDate;
					startDate = menuStartSave;
					const menuEndSave = this.menues && this.menues.menu_baseline.endDate;
					endDate = menuEndSave;
				}

				this.baselineForm.patchValue({
					[BaselineInput.seasons]: menuSeasons || selected,
					[BaselineInput.shortAP]: menuShortAP || 30,
					[BaselineInput.startDate]: startDate,
					[BaselineInput.endDate]: endDate
				});
			});
		}
	}

	formatTodToolsClusters(data) {
		const todsOutbound = convertTods(data.outbound.franjas).map((tod) =>
			this.convToBffIndicatorsInTod('outbound', tod)
		);
		const todsInbound = convertTods(data.inbound.franjas).map((tod) => this.convToBffIndicatorsInTod('inbound', tod));

		this.tods = todsOutbound.concat(todsInbound);
	}

	convToBffIndicatorsInTod(boundParams: string, tod: TodStartEnd): TodBffIn {
		const ret: TodBffIn = {
			bound: boundParams,
			todStart: tod.start + ':00',
			todEnd: tod.end + ':00'
		};
		return ret;
	}

	calculate() {
		const valuesBaseline: BaselineValues = this.baselineForm.getRawValue();
		const valuesModel: ModelValues = this.modelForm.getRawValue();
		// this.calculateClick.emit(valuesModel);

		let modelInputsArr: Array<string> = [];

		if (Array.isArray(valuesModel.modelInputs) && valuesModel.modelInputs.length > 0) {
			modelInputsArr = valuesModel.modelInputs;
		}

		const params: ModelOutputCluster = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			sessionID: OneTimeContext.getSessionID(),
			season: this.bbbInSelectedSeason,
			numberClusters: valuesModel.numberClusters,
			shortAP: valuesBaseline.shortAP,
			modelInputs: modelInputsArr,
			tod: this.tods,
			level: OneTimeContext.getLevel(),
			elementCodeBaseline: valuesBaseline.elementCode.elementCode,
			cabinCodeBaseline: valuesBaseline.cabinCode.code,
			seasonBaseline: valuesBaseline.seasons,
			startDate: moment(valuesBaseline.startDate).format('YYYY-MM-DD'),
			endDate: moment(valuesBaseline.endDate).format('YYYY-MM-DD')
		};

		this.indicatorsService.getModelOutputCluster(params).subscribe(
			(response) => {
				this.openSnackBarCorrect('Clusters have been calculated successfully and are available in Model output');
				this.calculateClick.emit(response);
			},
			(err) => {
				this.openSnackBarIncorrect('Unable to calculate Clusters');
			}
		);
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackIncorrect']
		});
	}
}
