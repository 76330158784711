import { AfterViewInit, Component, ElementRef, OnDestroy } from '@angular/core';
import { DataFilter } from 'src/app/models/one-time-home/DataFilter';
import { ElementSessionList, SessionElement } from 'src/app/shared/table-old-sessions/table-old-sessions.component';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnDestroy {
	disableTablas = false;
	stateSession = false;
	seEnvia: DataFilter;
	valor = false;
	dataStorage: SessionElement;
	listSessions: ElementSessionList[];

	constructor(private elementRef: ElementRef) {}

	ngAfterViewInit(): void {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'scroll';
	}

	ngOnDestroy() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'hidden';
	}

	onLoad(recibe: DataFilter) {
		this.seEnvia = recibe;
		this.disableTablas = true;
	}

	onRestSession(data: SessionElement) {
		this.dataStorage = data;
	}

	changeStateSession(rest: boolean) {
		this.stateSession = rest;
	}

	updateListSessions(list: ElementSessionList[]) {
		this.listSessions = list;

		if (this.stateSession) {
			this.disableTablas = false;
		}
	}
}
