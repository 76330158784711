import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { D3TargetCurveRenderer } from './D3-target-curve-scatterplot/D3TargetCurveRenderer';

let nextId = 0;

const colors = [
	'#0000ff',
	'#808080',
	'#800000',
	'#808000',
	'#fa8072',
	'#ff00ff',
	'#ff0000',
	'#008080',
	'#ffa500',
	'#8a2be2',
	'#a52a2a',
	'#5f9ea0',
	'#800080',
	'#008000',
	'#000080',
	'#d2691e',
	'#ff7f50',
	'#6495ed',
	'#dc143c',
	'#bc8f8f',
	'#4169e1',
	'#8b4513',
	'#f4a460',
	'#2e8b57',
	'#00008b',
	'#008b8b',
	'#b8860b',
	'#006400',
	'#8b008b',
	'#556b2f',
	'#ff8c00',
	'#9932cc',
	'#8b0000',
	'#e9967a',
	'#483d8b',
	'#2f4f4f',
	'#9400d3',
	'#ff1493',
	'#00bfff',
	'#696969',
	'#1e90ff',
	'#b22222',
	'#228b22',
	'#daa520',
	'#adff2f',
	'#808080',
	'#f0fff0',
	'#ff69b4',
	'#cd5c5c',
	'#4b0082',
	'#f0e68c',
	'#7cfc00',
	'#f08080',
	'#90ee90',
	'#d3d3d3',
	'#ffb6c1',
	'#ffa07a',
	'#20b2aa',
	'#778899',
	'#32cd32',
	'#66cdaa',
	'#0000cd',
	'#ba55d3',
	'#9370db',
	'#3cb371',
	'#7b68ee',
	'#00fa9a',
	'#48d1cc',
	'#c71585',
	'#191970',
	'#6b8e23',
	'#ff4500',
	'#da70d6',
	'#98fb98',
	'#afeeee',
	'#db7093',
	'#cd853f',
	'#ffc0cb',
	'#dda0dd',
	'#a0522d',
	'#87ceeb',
	'#6a5acd',
	'#708090',
	'#00ff7f',
	'#4682b4',
	'#d2b48c',
	'#d8bfd8',
	'#ff6347',
	'#40e0d0',
	'#ee82ee',
	'#f5deb3',
	'#9acd32',
	'#663399'
];

interface Legend {
	name: string;
	color: string;
}

const TargetCurveScatterplotInputs = {
	data: 'data'
};

@Component({
	selector: 'app-target-curve-scatterplot',
	templateUrl: './target-curve-scatterplot.component.html',
	styleUrls: ['./target-curve-scatterplot.component.scss']
})
export class TargetCurveScatterplotComponent implements OnInit, OnChanges {
	// @Input() data: Array <Array <D3TargetCurveRenderer.TargetCurve>> ;

	data: Array<D3TargetCurveRenderer.GraphPoints>;
	@Input(TargetCurveScatterplotInputs.data) set setCurves(value: Array<D3TargetCurveRenderer.GraphPoints>) {
		this.data = value;
	}

	ph_id = `ph_d3_target_curve_${nextId++}`;

	initialized = false;

	legends: Array<Legend> = [];

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.data) {
			this.plot();
		}
	}

	ngOnInit() {
		this.plot();

		this.initialized = true;
	}

	plot() {
		const unicos = [];

		for (var i = 0; i < this.data.length; i++) {
			const elemento = this.data[i].nameLine;
			if (!unicos.includes(this.data[i].nameLine)) {
				unicos.push(elemento);
			}
		}

		if (this.data) {
			D3TargetCurveRenderer.plot(this.data, this.ph_id, colors, 490, 350, unicos);
		}

		this.legends = [];

		unicos.forEach((value, index) => {
			this.legends = [...this.legends, { name: value, color: colors[index] }];
		});
	}
}
