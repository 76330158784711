<div class="content-tod-tools">
	<mat-accordion class="example-headers-align" multi>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title class="title-tod"> TOD Tools </mat-panel-title>
			</mat-expansion-panel-header>
			<app-tod-tools-clusters
				(validezRefreshClusters)="changeRefreshClusters($event)"
				[titleTodTools]="outboundTitle"
				[dataTodTolls]="outboundTimeZone"
			>
			</app-tod-tools-clusters>
			<app-tod-tools-clusters
				(validezRefreshClusters)="changeRefreshClusters($event)"
				[titleTodTools]="inboundTitle"
				[dataTodTolls]="inboundTimeZone"
			>
			</app-tod-tools-clusters>

			<div class="container-buttons-tod-tools select-rem">
				<div class="container-actions select-white">
					<mat-form-field>
						<mat-select placeholder="Import TOD" (valueChange)="importTOD($event)">
							<mat-option [value]="optionSeason" *ngFor="let optionSeason of seasons">{{ optionSeason }}</mat-option>
						</mat-select>
					</mat-form-field>
					<button
						class="btn-copy"
						[class.disabled]="validateRefreshClusters"
						[disabled]="validateRefreshClusters"
						(click)="refreshClusters()"
					>
						Update TOD
					</button>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>
