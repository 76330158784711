<ng-container class="table-clusters">
	<div>
		<hot-table
			[settings]="tableSettings"
			[columns]="columns"
			[cell]="cells"
			[data]="formated_data_ask_indicators"
			readOnly="false"
			stretchH="all"
			[hotId]="id"
			class="hot handsontable"
		>
		</hot-table>
	</div>
</ng-container>
