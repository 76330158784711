import { D3TargetCurveRenderer } from '../../components/one-time/scatterplots/target-curve-scatterplot/D3-target-curve-scatterplot/D3TargetCurveRenderer';

export interface CurveClusters {}

export function cloneFromAnotherCurves(
	source: D3TargetCurveRenderer.TargetCurve[],
	original: D3TargetCurveRenderer.TargetCurve[],
	clusters: Array<number>
): D3TargetCurveRenderer.TargetCurve[] {
	const cloned = clusters.map((cluster, i) => {
		const finded = getClusterCurve(cluster, source);

		return new D3TargetCurveRenderer.TargetCurve(
			original[i].type_id,
			original[i].cluster,
			original[i].name,
			finded.points
		);
	});

	// TODO: revisar esta logica, es probable que haya que recalcular la columna total
	cloned.push(getClusterCurve(0, source));

	return cloned;
}

function getClusterCurve(cluster: number, sourceInput: D3TargetCurveRenderer.TargetCurve[]) {
	let finded: D3TargetCurveRenderer.TargetCurve;
	if (cluster === 0) {
		finded = sourceInput.find((i) => i.name.endsWith('total'));
	} else {
		finded = sourceInput.find((i) => i.name.endsWith('c' + cluster));
	}
	return finded;
}
