export const BaselineInput = {
	level: 'level',
	elementCode: 'elementCode',
	cabinCode: 'cabinCode',
	startDate: 'startDate',
	endDate: 'endDate',
	seasons: 'seasons',
	shortAP: 'shortAP'
};

export const ModelInput = {
	numberClusters: 'numberClusters',
	modelInputs: 'modelInputs'
};

export const DATE_FORMAT_MENU_CLUSTERS = {
	parse: {
		dateInput: ['YYYY-MM-DD', 'DD-MM-YYYY']
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	}
};
