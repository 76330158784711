<ng-container>
	<hot-table
		[settings]="tableSettings"
		[columns]="columns"
		[data]="calendarData"
		[readOnly]="readOnly"
		[hotId]="id"
		[afterInit]="onAfterInit"
		[stretchH]="stretchH"
		class="hot handsontable htColumnHeaders"
	>
	</hot-table>
</ng-container>
