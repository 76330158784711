<div [id]="ph_id" class="target-curve-ph"></div>

<div class="legends-container">
	<div *ngFor="let legend of legends">
		<div [style.color]="legend.color" class="legend">
			<span class="material-icons icono"> fiber_manual_record </span>
			<span class="text">{{ legend.name }}</span>
		</div>
	</div>
</div>
