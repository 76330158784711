import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit
} from '@angular/core';
import { ClustersService, SeasonByParams } from '@services/clusters/clusters.service';
import { DialogService } from '@services/dialog.service';
import { SnackBarService } from '@services/snack-bar.service';
import {
	ApplyLoadFactorOutInput,
	AskIndicatorsParams,
	BASELINE_CURVE_T,
	DataSaveBffIn,
	DataTargetCurves,
	MenuesSaveBffIn,
	ModelSettingOut,
	SmoothCurveOutput,
	TargetCurveProgressInput,
	TargetCurveSessionProgressInput,
	TargetCurvesModelOutputParams,
	TargetCurvesParams,
	TargetOut,
	TARGET_CURVE_T,
	TCAskIndicators,
	TCClusterValue,
	TCCurvePoint,
	TClusterCurveTable,
	TCTableRow
} from '@services/target-curves/target-curves.interface';
import { IndicatorTargetCurveService } from '@services/target-curves/target-curves.service';
import moment from 'moment';
import { GridKpiGeneric } from 'src/app/models/GridKpiGeneric';
import { DataModalImport, ResponseDataModal } from 'src/app/models/ModalImport';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { cloneFromAnotherCurves } from 'src/app/models/target-curves/curve-clusters';
import {
	back2percentTargetCurves,
	calculateDelta,
	cloneFromAnotherGrid,
	cloneGridApClusters,
	GridApClusters
} from 'src/app/models/target-curves/GridApClusters';
import { GridModelSetting } from 'src/app/models/target-curves/GridModelSetting';
import { BaselineInput, FormMenu, ModelInput } from 'src/app/shared/menu-lateral-inputs/menu-lateral-inputs.constant';
import {
	BaselineValues,
	ModelValues,
	ViewComponentMenu
} from 'src/app/shared/menu-lateral-inputs/menu-lateral-inputs.interface';
import { D3TargetCurveRenderer } from '../scatterplots/target-curve-scatterplot/D3-target-curve-scatterplot/D3TargetCurveRenderer';

@Component({
	selector: 'app-target-curves',
	templateUrl: './target-curves.component.html',
	styleUrls: ['./target-curves.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TargetCurvesComponent implements OnInit, OnDestroy, AfterViewInit {
	viewForm: ViewComponentMenu[] = [
		{ name: FormMenu.baseline, view: true },
		{ name: FormMenu.model, view: true }
	];

	viewInputs: ViewComponentMenu[] = [
		{ name: BaselineInput.cabin, view: true },
		{ name: BaselineInput.element, view: true },
		{ name: BaselineInput.level, view: true },
		{ name: BaselineInput.endDate, view: true },
		{ name: BaselineInput.startDate, view: true },
		{ name: BaselineInput.seasons, view: true },
		{ name: BaselineInput.monthly, view: true },
		{ name: BaselineInput.endDateAsk, view: true },
		{ name: BaselineInput.startDateAsk, view: true },
		{ name: ModelInput.activate, view: true },
		{ name: ModelInput.model, view: true },
		{ name: ModelInput.endDateAct, view: true },
		{ name: ModelInput.startDateAct, view: true }
	];

	selectedSeason: string;
	seasons: string[] = []; // seasons de la session
	seasonsReady = false;
	seasonBaseline: string[];

	ponderaciones_ask: Array<number> | undefined = undefined;

	menu_baseline: BaselineValues;
	menu_model: ModelValues;

	selectedSmoothCurveMethod: string;

	source_type = '';

	data_tc_ready = false;

	disableSave = true;

	sendSnackbarActive;

	MODAL_TITLE = 'Import Target Curves';

	//
	clusters_graph: Array<number> = [];

	// las grillas
	data_grid_target_curves: GridApClusters | undefined = undefined;

	data_grid_target_curves_pax: GridApClusters | undefined = undefined;

	// baseline bookings
	data_grid_baseline_pp: GridApClusters | undefined = undefined;
	data_grid_baseline_pax: GridApClusters | undefined = undefined;
	data_grid_baseline_delta_pp: GridApClusters | undefined = undefined;
	data_grid_baseline_delta_pax: GridApClusters | undefined = undefined;

	// baseline target curves: no hay delta pq podria tener una lista de clusters distinta
	data_grid_baseline_tc_pp: GridApClusters | undefined = undefined;
	data_grid_baseline_tc_pax: GridApClusters | undefined = undefined;

	data_grid_productive_pp: GridApClusters | undefined = undefined;
	data_grid_productive_pax: GridApClusters | undefined = undefined;
	data_grid_productive_delta_pp: GridApClusters | undefined = undefined;
	data_grid_productive_delta_pax: GridApClusters | undefined = undefined;

	data_grid_model_output_pp: GridApClusters | undefined = undefined;
	data_grid_model_output_pax: GridApClusters | undefined = undefined;
	data_grid_model_output_delta_pp: GridApClusters | undefined = undefined;
	data_grid_model_output_delta_pax: GridApClusters | undefined = undefined;

	data_grid_model_trend: GridApClusters | undefined = undefined;

	data_grid_model_posted_flight: GridApClusters | undefined = undefined;

	// la grilla de model settings (ajustes modelo en figma)
	data_grid_model_settings: GridModelSetting | undefined = undefined;

	// se crea haciendo la union de tc + bl + prod
	data_all_scatterplot: Array<Array<D3TargetCurveRenderer.TargetCurve>> = [];
	data_graf: Array<D3TargetCurveRenderer.GraphPoints> = [];

	// data ask indicators
	data_ask_indicators: TCAskIndicators;

	// guarda el retorno de cada uno de los servicios tc / bl / prod
	data_scatterplot_tc: Array<D3TargetCurveRenderer.TargetCurve> = [];
	data_scatterplot_bl: Array<D3TargetCurveRenderer.TargetCurve> = [];
	data_scatterplot_bl_tc: Array<D3TargetCurveRenderer.TargetCurve> = [];
	data_scatterplot_prod: Array<D3TargetCurveRenderer.TargetCurve> = [];
	data_scatterplot_model_output: Array<D3TargetCurveRenderer.TargetCurve> = [];

	// los KPI's para los efectos
	trend_kpis: Array<GridKpiGeneric>;
	posted_flights_kpis: Array<GridKpiGeneric>;

	dataModal: DataModalImport;
	data_target: DataTargetCurves;
	data_target_out;

	// Variable para mostrar seccion view en menu lateral
	showViewSection: boolean;

	constructor(
		private clustersServices: ClustersService,
		private indicatorServices: IndicatorTargetCurveService,
		private elementRef: ElementRef,
		private snack: SnackBarService,
		private changeDetectorRef: ChangeDetectorRef,
		private dialog: DialogService
	) {
		OneTimeContext.setCurrentlyInBuckets(false);
	}

	ngOnInit() {
		this.getSeasonsMenu();
	}

	ngOnDestroy() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'hidden';
	}

	ngAfterViewInit() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'scroll';
	}

	dataTargetOutTargetlf(param) {
		this.data_target_out = param;
	}

	changeSelectedSeason(event: string) {
		if (typeof event !== 'undefined') {
			this.source_type = '';
			const oldSelectedSeason = this.selectedSeason;
			this.selectedSeason = event;

			// vacia la UI
			this.data_grid_target_curves = undefined;
			this.data_grid_target_curves_pax = undefined;
			this.data_grid_baseline_pp = undefined;
			this.data_grid_baseline_pax = undefined;
			this.data_grid_baseline_delta_pp = undefined;
			this.data_grid_baseline_delta_pax = undefined;

			this.data_grid_baseline_tc_pp = undefined;
			this.data_grid_baseline_tc_pax = undefined;

			this.data_grid_productive_pp = undefined;
			this.data_grid_productive_pax = undefined;
			this.data_grid_productive_delta_pp = undefined;
			this.data_grid_productive_delta_pax = undefined;

			this.clearModelOutputGrids();

			this.refreshUI();

			// si es la primera vez que se carga la pagina, hay que esperar que se cargue el menu lateral para cargar las grillass
			// si no es la primera vez, se puede recargar las grillas al tiro
			if (typeof oldSelectedSeason !== 'undefined') {
				// carga las curvas y la grilla target curves
				this.getTargetCurves();
				this.getProductive();
				if (typeof this.seasonBaseline !== 'undefined') {
					this.getAskIndicators(true);
				}

				this.getBaseline();
				this.getBaselineTargetCurves();
			}
		}
	}

	getSeasonsMenu() {
		const dateStart = moment().startOf('month').add(-6, 'month').format('YYYY-MM-DD');
		const dateEnd = moment().endOf('month').add(200, 'year').format('YYYY-MM-DD');

		const params: SeasonByParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			initDate: dateStart,
			endDate: dateEnd,
			sessionId: OneTimeContext.getSessionID()
		};

		this.clustersServices.getSeasonsByParams(params).subscribe(
			(response) => {
				this.seasons = response;
				this.seasonsReady = true;

				this.refreshUI();
			},
			(error) => {
				console.log('getSeasonsMenu', error);
			}
		);
	}

	getTargetCurves() {
		const params: TargetCurvesParams = {
			cabinCode: OneTimeContext.getCabinCode(),
			elementCode: OneTimeContext.getElementCode(),
			level: OneTimeContext.getLevelId(),
			// Obtener desde menú
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			startDateAsk: OneTimeContext.getStartDateAsk(),
			endDateAsk: OneTimeContext.getEndDateAsk()
		};
		if (this.selectedSeason) {
			this.indicatorServices.getDataTargetCurves(params, TARGET_CURVE_T).subscribe(
				(response) => {
					this.data_grid_target_curves = response.grid_pp;
					this.data_grid_target_curves_pax = response.grid_pax;
					this.data_scatterplot_tc = response.curve;
					this.data_tc_ready = true;

					this.unionDataScatterplots();
					this.getDeltas();

					this.refreshUI();
				},
				(error) => {
					console.log('getTargetCurves', error);

					this.data_grid_target_curves = undefined;
					this.data_grid_target_curves_pax = undefined;
					this.data_scatterplot_tc = [];
					this.data_tc_ready = false;

					this.unionDataScatterplots();
					this.getDeltas();

					this.refreshUI();
				}
			);
		}
	}

	getBaseline() {
		const params: TargetCurvesParams = {
			cabinCode: OneTimeContext.getCabinCode(),
			elementCode: OneTimeContext.getElementCode(),
			level: OneTimeContext.getLevelId(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			levelBaseLine: OneTimeContext.getBaselineLevel(),
			elementCodeBaseLine: OneTimeContext.getBaselineElement(),
			cabinCodeBaseLine: OneTimeContext.getBaselineCabinCode(),
			seasonBaseLine: this.seasonBaseline,
			startDateBaseLine: OneTimeContext.getBaselineStartDate(),
			endDateBaseLine: OneTimeContext.getBaselineEndDate(),
			startDateAsk: OneTimeContext.getStartDateAsk(),
			endDateAsk: OneTimeContext.getEndDateAsk()
		};

		if (this.selectedSeason) {
			this.indicatorServices.getDataBaseline(params).subscribe(
				(response) => {
					this.data_grid_baseline_pp = response.grid_pp;
					this.data_grid_baseline_pax = response.grid_pax;
					this.data_scatterplot_bl = response.curve;

					this.unionDataScatterplots();
					this.getDeltas();
					this.snack.success('Baseline data has been updated successfully');
					this.refreshUI();
				},
				(error) => {
					console.log('getBaseline', error);

					this.data_grid_baseline_pp = undefined;
					this.data_grid_baseline_pax = undefined;
					this.data_scatterplot_bl = [];

					this.unionDataScatterplots();
					this.getDeltas();

					this.snack.error('Unable to get Baseline data');
					this.refreshUI();
				}
			);
		}
	}

	//
	getBaselineTargetCurves() {
		this.data_grid_baseline_tc_pp = undefined;
		this.data_grid_baseline_tc_pax = undefined;
		this.data_scatterplot_bl_tc = [];

		if (this.seasonBaseline.length === 1 && this.selectedSeason) {
			const params: TargetCurvesParams = {
				cabinCode: OneTimeContext.getBaselineCabinCode(),
				elementCode: OneTimeContext.getBaselineElement(),
				level: parseInt(OneTimeContext.getBaselineLevel(), 10),
				// Obtener desde menú
				season: this.seasonBaseline[0],
				sessionId: OneTimeContext.getSessionID(),
				startDateAsk: OneTimeContext.getStartDateAsk(),
				endDateAsk: OneTimeContext.getEndDateAsk()
			};

			this.indicatorServices.getDataTargetCurves(params, BASELINE_CURVE_T).subscribe(
				(response) => {
					this.data_target = response;
					this.data_grid_baseline_tc_pp = response.grid_pp;
					this.data_grid_baseline_tc_pax = response.grid_pax;
					this.data_scatterplot_bl_tc = response.curve;

					this.unionDataScatterplots();
					this.refreshUI();
				},
				(error) => {
					console.log('getBaselineTargetCurves', error);

					this.unionDataScatterplots();
					this.refreshUI();
				}
			);
		}
	}

	getAskIndicators(monthlyBasis: boolean) {
		const params: AskIndicatorsParams = {
			cabinCode: OneTimeContext.getCabinCode(),
			elementCode: OneTimeContext.getElementCode(),
			level: OneTimeContext.getLevelId(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			levelBaseLine: OneTimeContext.getBaselineLevel(),
			elementCodeBaseLine: OneTimeContext.getBaselineElement(),
			cabinCodeBaseLine: OneTimeContext.getBaselineCabinCode(),
			seasonsBaseLine: this.seasonBaseline,
			startDateBaseLine: OneTimeContext.getBaselineStartDate(),
			endDateBaseLine: OneTimeContext.getBaselineEndDate(),
			startDateAsk: OneTimeContext.getStartDateAsk(),
			endDateAsk: OneTimeContext.getEndDateAsk(),
			monthlyBasis: monthlyBasis
		};
		if (this.selectedSeason) {
			this.indicatorServices.getAskIndicators(params).subscribe(
				(response) => {
					this.data_ask_indicators = response;
					this.calcPonderacionesAsk(response.askAy);
					this.refreshUI();
				},
				(error) => {
					console.log('getAskIndicators', error);

					this.data_ask_indicators = {
						askrBl: [],
						askAy: [],
						askBl: []
					};
					this.refreshUI();
				}
			);
		}
	}

	getProductive() {
		const params: TargetCurvesParams = {
			cabinCode: OneTimeContext.getCabinCode(),
			elementCode: OneTimeContext.getElementCode(),
			level: OneTimeContext.getLevelId(),
			// Obtener desde menú
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			startDateAsk: OneTimeContext.getStartDateAsk(),
			endDateAsk: OneTimeContext.getEndDateAsk()
		};
		if (this.selectedSeason) {
			this.indicatorServices.getProductive(params).subscribe(
				(response) => {
					this.data_grid_productive_pp = response.grid_pp;
					this.data_grid_productive_pax = response.grid_pax;
					this.data_scatterplot_prod = response.curve;

					this.unionDataScatterplots();
					this.getDeltas();

					this.refreshUI();
				},
				(error) => {
					console.log('getProductive', error);

					this.data_grid_productive_pp = undefined;
					this.data_grid_productive_pax = undefined;
					this.data_scatterplot_prod = [];

					this.unionDataScatterplots();
					this.getDeltas();

					this.refreshUI();
				}
			);
		}
	}

	getApplyLoadFactorOut() {
		this.refresh('ApplyLoadFactorOut');
	}

	executeApplyLoadFactorOut() {
		this.data_all_scatterplot = [];
		const applyDataInput = this.transformDataLoadFactorOut(
			this.data_grid_target_curves,
			this.data_grid_target_curves_pax,
			this.data_scatterplot_tc
		);

		const targetOutData: TargetOut = {
			clusters: this.formatDataClusterTargetOutData(this.data_target_out, this.data_grid_target_curves.clusters),
			clusterValues: this.formatDataClusterValuesTargetOutData(this.data_target_out)
		};

		applyDataInput.targetOut = targetOutData;

		this.indicatorServices.getApplyLoadFactorOut(applyDataInput).subscribe(
			(response) => {
				this.data_grid_target_curves = response.grid_pp;
				this.data_grid_target_curves_pax = response.grid_pax;
				this.data_scatterplot_tc = response.curve;
				this.unionDataScatterplots();
				this.getDeltas();
				this.refreshUI();
				this.snack.success('Target load factor out updated successfully');
			},
			(error) => {
				console.log('executeApplyLoadFactorOut', error);
				this.snack.error('Unable to update Target load factor out');
			}
		);
	}

	formatDataClusterTargetOutData(param, cluster_list) {
		const clusters = [];
		for (let i = 0; i < cluster_list.length; i++) {
			clusters.push(cluster_list[i].toString());
		}
		clusters.push('Total');
		return clusters;
	}

	formatDataClusterValuesTargetOutData(param) {
		const clustersValues = [];
		for (let i = 0; i < param.length; i++) {
			if (param[i + 1] === '' || param[i + 1] === null) {
				clustersValues.push(null);
			} else {
				clustersValues.push(param[i + 1] / 100);
			}
		}
		return clustersValues;
	}

	callApplyLoadFactorOut() {
		this.getApplyLoadFactorOut();
	}

	copyModelOutput() {
		this.data_grid_target_curves = cloneGridApClusters(this.data_grid_model_output_pp);
		// this.data_grid_target_curves_pax = cloneGridApClusters(this.data_grid_model_output_pax);

		this.data_grid_model_output_delta_pp = calculateDelta(this.data_grid_target_curves, this.data_grid_model_output_pp);

		this.data_scatterplot_model_output.map((c) => {
			const cluster = c.cluster;
			const index = this.data_scatterplot_tc.findIndex((x) => x.cluster === c.cluster);
			this.data_scatterplot_tc[index].points = c.points;
		});

		this.refresh('ImportEvent');

		/*
    this.data_grid_model_output_delta_pax = calculateDelta(
      this.data_grid_target_curves_pax,
      this.data_grid_model_output_pax
    );
    */

		this.disableSave = true;
		this.source_type = 'model';
	}

	importTargetCurves(seasonEvent: string) {
		// TODO: conectar la data de indicators ask
		this.source_type = 'other season';
		const ponderaciones = this.ponderaciones_ask;
		const params: TargetCurvesParams = {
			cabinCode: OneTimeContext.getCabinCode(),
			elementCode: OneTimeContext.getElementCode(),
			level: OneTimeContext.getLevelId(),
			// Obtener desde menú
			season: seasonEvent,
			sessionId: OneTimeContext.getSessionID(),
			startDateAsk: OneTimeContext.getStartDateAsk(),
			endDateAsk: OneTimeContext.getEndDateAsk()
		};

		this.indicatorServices.getDataTargetCurves(params, TARGET_CURVE_T).subscribe(
			(response) => {
				const data_grid_season = response.grid_pp;
				const clusters_season = arrayClusterInt2String(data_grid_season.clusters);

				this.dataModal = {
					title: this.MODAL_TITLE,
					targetSeason: this.selectedSeason,
					targetClusters: this.data_grid_target_curves.clusters,
					sourceElement: OneTimeContext.getElementCode(),
					sourceCabinCode: OneTimeContext.getCabinCode(),
					sourceCabinName: OneTimeContext.getCabinName(),
					sourceSeason: seasonEvent,
					clusters: clusters_season
				};

				this.disableSave = true;

				this.dialog.copyTargetCurveDialog(this.dataModal).subscribe((confirm) => {
					if (typeof confirm !== 'boolean') {
						const responseOK = confirm as ResponseDataModal;
						const clusters = arrayClusterString2Int(responseOK.clusters);
						const new_grid_import = cloneFromAnotherGrid(
							this.data_grid_target_curves,
							data_grid_season,
							clusters,
							ponderaciones
						);

						//this.data_scatterplot_tc = response.curve;
						this.data_scatterplot_tc = cloneFromAnotherCurves(response.curve, this.data_scatterplot_tc, clusters);
						this.data_grid_target_curves = new_grid_import;

						this.unionDataScatterplots();
						this.refresh('ImportEvent');
						this.refreshUI();

						this.snack.success('Target Curves were imported successfully');
					}
				});
			},
			(error) => {
				console.error('importTargetCurves', error);

				this.snack.error('Unable to import Target Curves');
			}
		);
	}

	importBaselineCurves() {
		this.source_type = 'baseline';
		const clusters_baseline = arrayClusterInt2String(this.data_grid_baseline_tc_pp.clusters);

		const ponderaciones = this.ponderaciones_ask;

		this.dataModal = {
			title: this.MODAL_TITLE,
			targetSeason: this.selectedSeason,
			targetClusters: this.data_grid_target_curves.clusters,
			sourceElement: OneTimeContext.getBaselineElement(),
			sourceCabinCode: OneTimeContext.getBaselineCabinCode(),
			sourceCabinName: OneTimeContext.getBaselineCabinName(),
			sourceSeason: this.seasonBaseline[0],
			clusters: clusters_baseline
		};

		this.dialog.copyTargetCurveDialog(this.dataModal).subscribe(
			(confirm) => {
				if (typeof confirm !== 'boolean') {
					const responseOK = confirm as ResponseDataModal;
					const clusters = arrayClusterString2Int(responseOK.clusters);

					const new_grid = cloneFromAnotherGrid(
						this.data_grid_target_curves,
						this.data_grid_baseline_tc_pp,
						clusters,
						ponderaciones
					);

					this.data_grid_target_curves = new_grid;

					this.data_scatterplot_tc = cloneFromAnotherCurves(
						this.data_scatterplot_bl_tc,
						this.data_scatterplot_tc,
						clusters
					);

					this.unionDataScatterplots();

					this.disableSave = true;

					this.refresh('ImportEvent');

					this.refreshUI();

					this.snack.success('Target Curves were imported successfully');
				}
			},
			(error) => {
				console.log('importBaselineCurves', error);
			}
		);
	}

	// vacia las grillas relacionadas con model output
	clearModelOutputGrids() {
		this.data_grid_model_output_pp = undefined;
		this.data_grid_model_output_pax = undefined;

		this.data_grid_model_trend = undefined;

		this.trend_kpis = [];
		this.data_grid_model_posted_flight = undefined;

		this.posted_flights_kpis = [];

		this.data_grid_model_output_delta_pp = undefined;
		this.data_grid_model_output_delta_pax = undefined;

		this.data_grid_model_settings = undefined;

		this.data_scatterplot_model_output = [];
	}

	// callback boton calculate
	buttonModelOutput(event: [BaselineValues, ModelValues]) {
		const [baseline, model] = event;

		const is_trendy = Array.isArray(model.activate) && model.activate.includes('activate');
		const is_monthly = Array.isArray(baseline.monthly) && baseline.monthly.includes('basis');

		const params: TargetCurvesModelOutputParams = {
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			season: this.selectedSeason,
			sessionId: OneTimeContext.getSessionID(),
			elementCodeBaseLine: baseline.element.elementCode,
			cabinCodeBaseLine: baseline.cabin.code,
			seasonsBaseLine: baseline.seasons,
			startDateBaseLine: baseline.startDate,
			endDateBaseLine: baseline.endDate,
			model: model.model,
			monthlyBasis: is_monthly,
			startDateAsk: baseline.startDateAsk,
			endDateAsk: baseline.endDateAsk,
			trend: is_trendy,
			startDateTrend: model.startDateAct,
			endDateTrend: model.endDateAct
		};

		this.indicatorServices.getModelOutput(params).subscribe(
			(response) => {
				this.data_grid_model_output_pp = response.grid_pp;
				this.data_grid_model_output_pax = response.grid_pax;

				this.data_grid_model_trend = response.grid_trend[0];

				if (this.data_grid_model_trend !== undefined && this.data_ask_indicators !== undefined) {
					this.calcTotalRows(this.data_grid_model_trend, this.data_ask_indicators);
				}

				this.trend_kpis = response.grid_trend_kpis;
				this.data_grid_model_posted_flight = response.grid_posted_flight[0];

				if (this.data_grid_model_posted_flight !== undefined && this.data_ask_indicators !== undefined) {
					this.calcTotalRows(this.data_grid_model_posted_flight, this.data_ask_indicators);
				}

				this.posted_flights_kpis = response.grid_posted_flight_kpis;

				this.data_grid_model_output_delta_pp = calculateDelta(
					this.data_grid_target_curves,
					this.data_grid_model_output_pp
				);
				this.data_grid_model_output_delta_pax = calculateDelta(
					this.data_grid_target_curves_pax,
					this.data_grid_model_output_pax
				);

				this.data_grid_model_settings = response.model_settings;

				// los graficos
				this.data_scatterplot_model_output = response.curve;
				this.unionDataScatterplots();

				this.snack.success('Target curves were calculated successfully');
				this.refreshUI();
			},
			(error) => {
				console.log('buttonModelOutput', error);
				this.snack.error('Unable to calculate Target curves');
				this.clearModelOutputGrids();
				this.unionDataScatterplots();
			}
		);
	}

	unionDataScatterplots() {
		// nota: agregar las curvas en el mismo orden del selector de curvas
		this.data_all_scatterplot = [];
		this.data_all_scatterplot.push(this.data_scatterplot_tc);
		this.data_all_scatterplot.push(this.data_scatterplot_model_output);
		this.data_all_scatterplot.push(this.data_scatterplot_prod);
		this.data_all_scatterplot.push(this.data_scatterplot_bl);
		this.data_all_scatterplot.push(this.data_scatterplot_bl_tc);

		this.data_graf = this.dataGraph(this.data_all_scatterplot);

		this.refreshClustersGraph();
	}

	//
	refreshClustersGraph() {
		let arr: Array<number> = [];

		if (typeof this.data_grid_target_curves !== 'undefined') {
			arr = arr.concat(this.data_grid_target_curves.clusters);
		}

		if (typeof this.data_grid_baseline_tc_pp !== 'undefined') {
			arr = arr.concat(this.data_grid_baseline_tc_pp.clusters);
		}

		this.clusters_graph = arr.filter((value, index) => arr.indexOf(value) === index).sort();
	}

	getDeltas() {
		this.data_grid_baseline_delta_pp = calculateDelta(this.data_grid_target_curves, this.data_grid_baseline_pp);
		this.data_grid_baseline_delta_pax = calculateDelta(this.data_grid_target_curves_pax, this.data_grid_baseline_pax);
		this.data_grid_productive_delta_pp = calculateDelta(this.data_grid_target_curves, this.data_grid_productive_pp);
		this.data_grid_productive_delta_pax = calculateDelta(
			this.data_grid_target_curves_pax,
			this.data_grid_productive_pax
		);
	}

	// callback on click and on load
	buttonBaselineMenu(values: BaselineValues, esOnLoad: boolean) {
		this.baselineMenuChanges(values);
		let executedOnLoad: boolean = false;
		if (esOnLoad) {
			this.getTargetCurves();
			this.getProductive();
			if (typeof this.seasonBaseline !== 'undefined') {
				this.getAskIndicators(true);
				executedOnLoad = true;
			}
		}

		this.getBaseline();
		this.getBaselineTargetCurves();
		const is_monthly = Array.isArray(values.monthly) && values.monthly.includes('basis');
		if (!executedOnLoad || (executedOnLoad && !is_monthly)) {
			this.getAskIndicators(is_monthly);
		}
	}

	baselineMenuChanges(values: BaselineValues) {
		this.menu_baseline = values;

		OneTimeContext.setBaselineCabinName(values.cabin.description);
		OneTimeContext.setBaselineCabinCode(values.cabin.code);
		OneTimeContext.setBaselineElement(values.element.elementCode);
		OneTimeContext.setBaselineLevel(values.level.levelId?.toString());
		OneTimeContext.setBaselineStartDate(values.startDate);
		OneTimeContext.setBaselineEndDate(values.endDate);
		OneTimeContext.setStartDateAsk(values.startDateAsk);
		OneTimeContext.setEndDateAsk(values.endDateAsk);
		OneTimeContext.setBaselineSeason(values.seasons);

		this.seasonBaseline = values.seasons;
	}

	modelMenuChanges(values: ModelValues) {
		this.menu_model = values;
	}

	modelMenuLoad(values: ModelValues) {
		this.menu_model = values;
	}

	// callback cuando se selecciona otro metodo de suavizado
	smoothCurveSelection(method: string) {
		this.refresh(method);
	}

	smoothCurves(method: string) {
		this.selectedSmoothCurveMethod = method;
		const applyDataInput = this.transformDataLoadFactorOut(
			this.data_grid_target_curves,
			this.data_grid_target_curves_pax,
			this.data_scatterplot_tc
		);
		const smoothCurveOutput: SmoothCurveOutput = {
			targetCurve: applyDataInput.targetCurve,
			method: this.selectedSmoothCurveMethod
		};
		this.indicatorServices.smoothCurves(smoothCurveOutput).subscribe(
			(response) => {
				this.data_grid_target_curves = response.grid_pp;
				this.data_grid_target_curves_pax = response.grid_pax;
				this.data_scatterplot_tc = response.curve;
				this.data_tc_ready = true;
				this.unionDataScatterplots();
				this.getDeltas();
				this.snack.success('Curves smoothed successfully');
				this.changeDetectorRef.detectChanges();
			},
			(error) => {
				console.log('smoothCurves', error);
				this.snack.error('Unable to smooth curves');
				// dejamos las curvas como eran antes
			}
		);
	}

	transformDataLoadFactorOut(
		grid_pp: GridApClusters,
		grid_pax: GridApClusters,
		curve: Array<D3TargetCurveRenderer.TargetCurve>
	): ApplyLoadFactorOutInput {
		const loadFactorCurvesOutArray = this.transformDataLoadFactorOut1(grid_pp, grid_pax, curve);

		const applyLoadFactorOutInput: ApplyLoadFactorOutInput = {
			targetCurve: loadFactorCurvesOutArray,
			targetOut: null
		};
		return applyLoadFactorOutInput;
	}

	transformDataModelSettings(data: GridModelSetting): ModelSettingOut[] {
		const dataClustes: Array<string> = [...data.clusters.map((m) => m.toString()), 'Total'];
		const dataResult: ModelSettingOut[] = data.rows.map((row) => ({
			name: row[1],
			hasCheckbox: typeof row[0] === 'boolean' ? true : false,
			checked: typeof row[0] === 'boolean' ? row[0] : false,
			clusters: dataClustes,
			clustersValues: row
				.slice(2, row.length)
				.map((value) => value.replace('%', ''))
				.map((value) => parseInt(value, 10) / 100)
		}));

		return dataResult;
	}

	transformDataLoadFactorOut1(
		grid_pp: GridApClusters,
		grid_pax: GridApClusters,
		curve: Array<D3TargetCurveRenderer.TargetCurve>
	): Array<TClusterCurveTable> {
		let loadFactorCurvesOutArray: Array<TClusterCurveTable> = [];
		// nota: cluster 0 es el total
		for (const id_cluster of grid_pp.clusters.concat([0])) {
			const tables = this.transformTablesLoadFactorOut(id_cluster, grid_pp, grid_pax);
			const loadFactorCurvesOut: TClusterCurveTable = {
				cluster: id_cluster === 0 ? 'Total' : id_cluster.toString(),
				table: tables,
				curve: this.transformCurvesLoadFactorOut(id_cluster, curve)
			};
			loadFactorCurvesOutArray = [...loadFactorCurvesOutArray, loadFactorCurvesOut];
		}

		return loadFactorCurvesOutArray;
	}

	transformTablesLoadFactorOut(
		id_cluster: number,
		grid_pp: GridApClusters,
		grid_pax: GridApClusters
	): Array<TCTableRow> {
		let tables: Array<TCTableRow> = [];

		const pos_cluster = 1 + (id_cluster > 0 ? grid_pp.clusters.indexOf(id_cluster) : grid_pp.clusters.length);

		for (let i = 0; i < grid_pp.rows.length - 1; i++) {
			const aps = grid_pp.getValue(i, 0).toString().split('-');
			const gridLoadFactorPaxOut: TCTableRow = {
				apEnd: +aps[1],
				apStart: +aps[0],
				loadFactor: grid_pp.getValue(i, pos_cluster) / 100.0,
				pax: grid_pax.getValue(i, pos_cluster)
			};
			tables = [...tables, gridLoadFactorPaxOut];
		}

		return tables;
	}

	transformCurvesRefreshIn(
		clusters: Array<number>,
		curves: Array<D3TargetCurveRenderer.TargetCurve>
	): Array<TCCurvePoint[]> {
		const out: Array<TCCurvePoint[]> = [];

		// nota: cluster 0 es el total
		for (const id_cluster of clusters.concat([0])) {
			const curve = this.transformCurvesLoadFactorOut(id_cluster, curves);

			out.push(curve);
		}

		return out;
	}

	transformCurvesLoadFactorOut(
		num_cluster: number,
		curve: Array<D3TargetCurveRenderer.TargetCurve>
	): Array<TCCurvePoint> {
		const tc_cluster = curve.find((tc) => tc.cluster === num_cluster);

		if (num_cluster === 0 && typeof tc_cluster === 'undefined') {
			return [];
		} else {
			return tc_cluster.points.map((s) => {
				const point: TCCurvePoint = {
					ap: s.ap,
					loadFactor: s.porc / 100.0
				};
				return point;
			});
		}
	}

	dataGraph(value: D3TargetCurveRenderer.TargetCurve[][]) {
		let punto: D3TargetCurveRenderer.GraphPoints;
		let graf: Array<D3TargetCurveRenderer.GraphPoints> = [];

		for (let typeCurve = 0; typeCurve < value.length; typeCurve++) {
			const typeOftargetCurves = value[typeCurve];
			for (let clusterTarget = 0; clusterTarget < typeOftargetCurves.length; clusterTarget++) {
				const targetCurve = typeOftargetCurves[clusterTarget];
				for (const point of targetCurve.points) {
					punto = {
						type_id: targetCurve.type_id,
						cluster: targetCurve.cluster,
						nameLine: targetCurve.name,
						ap: point.ap,
						porc: point.porc
					};

					graf = [...graf, punto];
				}
			}
		}
		return graf;
	}

	save() {
		const data_save_scatterplot: Array<Array<D3TargetCurveRenderer.TargetCurve>> = [];
		data_save_scatterplot.push(this.data_scatterplot_tc);
		const save_data_graph = this.dataGraph(data_save_scatterplot);

		const menuesJson: MenuesSaveBffIn = {
			menu_baseline: this.menu_baseline,
			menu_model: this.menu_model
		};

		delete menuesJson.menu_baseline.level.elements;

		const additionalDataJson: DataSaveBffIn = {
			data_grid_target_curves:
				this.data_grid_target_curves !== undefined ? back2percentTargetCurves(this.data_grid_target_curves) : null,
			data_grid_model_output_pp:
				this.data_grid_model_output_pp !== undefined ? back2percentTargetCurves(this.data_grid_model_output_pp) : null,
			data_grid_model_trend:
				this.data_grid_model_trend !== undefined ? back2percentTargetCurves(this.data_grid_model_trend) : null,
			data_grid_model_posted_flight:
				this.data_grid_model_posted_flight !== undefined
					? back2percentTargetCurves(this.data_grid_model_posted_flight)
					: null
		};

		const details: Array<TargetCurveProgressInput> = this.getTargetCurveDetails(save_data_graph);

		const serviceOutput: TargetCurveSessionProgressInput = {
			sessionId: OneTimeContext.getSessionID(),
			elementCode: OneTimeContext.getElementCode(),
			cabinCode: OneTimeContext.getCabinCode(),
			season: this.selectedSeason,
			sourceType: this.source_type,
			menues: menuesJson,
			additionalDataJson: additionalDataJson,
			targetCurveDetails: details
		};

		this.indicatorServices.save(serviceOutput).subscribe(
			(out) => {
				this.snack.success('Target curves have been successfully saved');
			},
			(error) => {
				console.log('save', error);
				this.snack.error('Unable to save Target curves');
			}
		);
	}

	remove_total_curves(data_graph: Array<D3TargetCurveRenderer.GraphPoints>): Array<D3TargetCurveRenderer.GraphPoints> {
		return data_graph.filter((p) => !p.nameLine.endsWith('total'));
	}

	getTargetCurveDetails(data_graph: Array<D3TargetCurveRenderer.GraphPoints>): Array<TargetCurveProgressInput> {
		let result: Array<TargetCurveProgressInput> = [];
		const elementCode = OneTimeContext.getElementCode();
		const cabinCode = OneTimeContext.getCabinCode();
		const season = this.selectedSeason;
		for (const point of data_graph) {
			const input: TargetCurveProgressInput = {
				tcpSessionId: 0,
				elementCode: elementCode,
				cabinCode: cabinCode,
				season: season,
				cluster: point.cluster.toString(),
				ap: point.ap,
				target: point.porc / 100,
				targetRaw: point.porc / 100
			};
			result.push(input);
		}
		return result;
	}

	// parche: llamar cuando Angular no refresca la pagina
	refreshUI() {
		this.changeDetectorRef.detectChanges();
	}

	refreshButton() {
		this.refresh('');
	}

	refresh(smoothCurveMethod: string) {
		if (!this.isValidTargetCurves()) {
			this.snack.error('Unable to refresh Target Curves: Total for each cluster must be equal to or less than 100%');
			return;
		}
		const refreshTargetCurvesPayload = this.transformDataLoadFactorOut1(
			this.data_grid_target_curves,
			this.data_grid_target_curves_pax,
			this.data_scatterplot_tc
		);
		this.disableSave = false;

		this.indicatorServices.getRefreshTargetCurves(refreshTargetCurvesPayload).subscribe(
			(response) => {
				this.data_grid_target_curves = response.grid_pp;
				this.data_grid_target_curves_pax = response.grid_pax;
				this.data_scatterplot_tc = response.curve;

				this.unionDataScatterplots();
				this.getDeltas();

				this.refreshUI();

				if (
					smoothCurveMethod !== '' &&
					smoothCurveMethod !== 'ApplyLoadFactorOut' &&
					smoothCurveMethod !== 'ImportEvent'
				) {
					this.smoothCurves(smoothCurveMethod);
				} else if (smoothCurveMethod === 'ApplyLoadFactorOut') {
					this.executeApplyLoadFactorOut();
				} else if (smoothCurveMethod === '' && this.data_grid_model_output_pax !== undefined) {
					this.refreshModel();
				}
			},
			(error) => {
				console.log('refresh', error);

				this.snack.error('Unable to Refresh');
			}
		);
	}

	refreshModel() {
		const targetCurvesData = this.transformDataLoadFactorOut1(
			this.data_grid_target_curves,
			this.data_grid_target_curves_pax,
			this.data_scatterplot_tc
		);

		const modelOutputData = this.transformDataLoadFactorOut1(
			this.data_grid_model_output_pp,
			this.data_grid_model_output_pax,
			this.data_scatterplot_model_output
		);

		const trend = this.transformDataLoadFactorOut1(
			this.data_grid_model_trend,
			this.data_grid_model_trend,
			this.data_scatterplot_model_output
		);

		const postedFlight = this.transformDataLoadFactorOut1(
			this.data_grid_model_posted_flight,
			this.data_grid_model_posted_flight,
			this.data_scatterplot_model_output
		);

		const baselineBookings = this.transformDataLoadFactorOut1(
			this.data_grid_baseline_pp,
			this.data_grid_baseline_pax,
			this.data_scatterplot_bl
		);

		const refreshModelPayload = {
			modelOutput: modelOutputData,
			trend: {
				effectOverride: trend
			},
			postedFlights: {
				effectOverride: postedFlight
			},
			baselineBookings: baselineBookings,
			targetCurves: targetCurvesData,
			modelSetting: this.transformDataModelSettings(this.data_grid_model_settings)
		};

		this.indicatorServices.getRefreshModel(refreshModelPayload).subscribe(
			(response) => {
				this.data_grid_model_output_pp = response.grid_pp;
				this.data_grid_model_output_pax = response.grid_pax;

				this.getDeltasModelOutput();

				this.data_grid_model_settings = response.model_settings;

				// los graficos
				this.data_scatterplot_model_output = response.curve;
				this.snack.success('Refreshed successfully');
				this.unionDataScatterplots();

				this.refreshUI();
			},
			(error) => {
				console.log('refreshModel', error);
			}
		);
	}

	calcPonderacionesAsk(askYs: Array<TCClusterValue>) {
		// ojo que el arreglo askYs contiene el valor total
		const asYSinTotal = askYs.slice(0, askYs.length - 1);

		let suma = 0.0;

		for (const asky of asYSinTotal) {
			suma = suma + asky.value;
		}

		if (suma != 0.0) {
			this.ponderaciones_ask = asYSinTotal.map((elem) => elem.value / suma);
		} else {
			this.ponderaciones_ask = undefined;
		}
	}

	onTargetCurvesEdited(id: string) {
		this.disableSave = true;

		this.getDeltas();
		this.getDeltasModelOutput();
	}

	onTrendEdited() {
		if (this.data_grid_model_trend !== undefined && this.data_ask_indicators !== undefined) {
			this.calcTotalRows(this.data_grid_model_trend, this.data_ask_indicators);
		}
	}

	onPostedEdited() {
		if (this.data_grid_model_posted_flight !== undefined && this.data_ask_indicators !== undefined) {
			this.calcTotalRows(this.data_grid_model_posted_flight, this.data_ask_indicators);
		}
	}

	calcTotalRows(data: GridApClusters, ask: TCAskIndicators) {
		const total = ask.askAy.find((row) => row.cluster === 'Total');
		data.rows.forEach((row, indexRow) => {
			const rowsLength = data.rows.length - 1;
			const totalRow = row.reduce((acc, value, index) => {
				const endRow = row.length - 1;
				let suma = 0;
				if (0 < index && endRow > index) {
					const askValue = ask.askAy[index - 1].value;
					suma = askValue * value;
					return acc + suma;
				}
				if (endRow === index) {
					return acc / total.value;
				}
				return acc;
			}, 0);

			if (rowsLength !== indexRow) {
				row[row.length - 1] = totalRow;
			}
		});
	}

	getDeltasModelOutput() {
		this.data_grid_model_output_delta_pp = calculateDelta(this.data_grid_target_curves, this.data_grid_model_output_pp);
		this.data_grid_model_output_delta_pax = calculateDelta(
			this.data_grid_target_curves_pax,
			this.data_grid_model_output_pax
		);
	}

	isValidTargetCurves(): boolean {
		const totals = this.data_grid_target_curves.rows[this.data_grid_target_curves.rows.length - 1];
		if (totals.length > 0 && totals[0].toString().toLowerCase() == 'total') {
			for (let i = 1; i < totals.length; i++) {
				if (Number(totals[i].toFixed(2)) > 100.0) {
					return false;
				}
			}
		}
		return true;
	}
}

// Nota: <arr> no debe incluir el cluster 0 como sinonimo de Total
function arrayClusterInt2String(arr: Array<number>): Array<string> {
	const ret = arr.map((num) => num.toString());

	ret.push('Total');

	return ret;
}

// al reves de la funcion anterior, se accepta la palabra "Total" dentro de <arr>
function arrayClusterString2Int(arr: Array<string>): Array<number> {
	const ret = arr.map((sz) => (sz.toLowerCase() === 'total' ? 0 : parseInt(sz, 10)));

	return ret;
}

// suma simple para probar el calculo del total
function sum(arr: Array<number>): number {
	let ret = 0.0;

	for (const elem of arr) {
		ret = ret + elem;
	}

	return ret;
}
