import { Duration, LocalDate, LocalDateTime, LocalTime } from '@js-joda/core';
import * as d3 from 'd3';

export module D3DaySeasonValueRenderer {
	export class SeasonPoint {
		constructor(
			public year: number,
			public month: number,
			public day: number,
			public value: number,
			public season: string
		) {}
	}

	class SeasonPointDt {
		constructor(public datetime: LocalDateTime, public value: number, public season: string) {}
	}

	class Margin {
		constructor(public top: number, public right: number, public bottom: number, public left: number) {}
	}

	class PointDatum {
		constructor(
			public date: Date,
			public value: number,
			public color: string,
			public x: number,
			public y: number,
			public bound: string
		) {}
	}

	export function plot(
		out_in: number,
		all_outbound_points: Array<SeasonPoint>,
		all_inbound_points: Array<SeasonPoint>,
		year_from: number,
		month_from: number,
		year_to: number,
		month_to: number,
		colors: Map<string, string>,
		placeholder: string,
		divWidth: number,
		divHeight: number
	) {
		//console.log ("plot " + placeholder) ;
		const div = d3.select('#' + placeholder);

		if (out_in == 2) all_inbound_points = [];
		else if (out_in == 3) all_outbound_points = [];

		const first_date = LocalDate.of(year_from, month_from, 1);
		const last_date_tmp = LocalDate.of(year_to, month_to, 1);
		const last_date = LocalDate.of(year_to, month_to, last_date_tmp.lengthOfMonth());

		const outbound_points = filterPoints(all_outbound_points, first_date, last_date);
		const inbound_points = filterPoints(all_inbound_points, first_date, last_date);

		//
		const all_points = outbound_points.concat(inbound_points);

		if (all_points.length === 0) {
			//console.log ("There are no points to draw") ;
			div.select('svg').remove();
			return;
		}

		const noon = LocalTime.of(12, 0);
		const domainMinDate = convert(first_date.plusDays(-1.0).atTime(noon));
		const domainMaxDate = convert(last_date.atTime(noon));

		//console.log ("min-date: " + domainMinDate) ;
		//console.log ("msx-date: " + domainMaxDate) ;

		//
		const margin = new Margin(20, 30, 30, 60);
		const width = divWidth - margin.left - margin.right;
		const height = divHeight - margin.top - margin.bottom;

		// renove any graph previously drawn
		div.select('svg').remove();

		const svgtop = div
			.append('svg')
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom);

		svgtop
			.append('rect')
			.attr('x', 0)
			.attr('width', divWidth)
			.attr('y', 0)
			.attr('height', divHeight)
			.attr('fill', 'none')
			.attr('stroke', 'black');

		const svg = svgtop.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		// eje X
		const x = d3.scaleTime().domain([domainMinDate, domainMaxDate]).range([0, width]);

		svg
			.append('g')
			.attr('transform', 'translate(0,' + height + ')')
			.call(d3.axisBottom(x));

		// eje Y
		const domainY = minMaxY(all_points);

		const y = d3.scaleLinear().domain(domainY).range([height, 0]);

		svg.append('g').call(d3.axisLeft(y));

		// lines
		if (outbound_points.length > 0) appendLines(svg, outbound_points, colors, x, y);

		if (inbound_points.length > 0) appendLines(svg, inbound_points, colors, x, y);

		// points
		const tooltip = div
			.append('div')
			.style('opacity', '0')
			.attr('class', 'tooltip')
			.style('background-color', 'white')
			.style('border', 'solid')
			.style('border-width', '2px')
			.style('border-radius', '5px')
			.style('padding', '5px');

		const num_X = Math.max(outbound_points.length, inbound_points.length);

		var radius = 2;
		if (num_X <= 31) radius = 5;
		else if (num_X <= 365 / 2) radius = 4;

		appendPoints(svg, outbound_points, true, radius, colors, 'white', x, y, tooltip);
		appendPoints(svg, inbound_points, false, radius, colors, 'white', x, y, tooltip);
	}

	/*
    function minMaxDates (
        points: Array <SeasonPoint>,
        first_date: LocalDate, 
        last_date: LocalDate
      ): [Date, Date] {
        const noon = LocalTime.of (12, 0) ;

        const dates = points.map (function (sp) {return LocalDate.of (sp.year, sp.month, sp.day) ;}) ;

        let minDate = first_date ;
        let maxDate = last_date ;

        dates.forEach ( (date) => {
            // console.log ("date: " + date) ;
            // console.log ("minDate: " + minDate) ;
            // console.log ("maxDate: " + maxDate) ;

            if (date.isBefore (minDate)) {
                minDate = date ;
            }
            if (date.isAfter (maxDate)) {
                maxDate = date ;
            }
        }) 

        const minDateTime = minDate.plusDays (-1.0).atTime (noon) ;
        const maxDateTime = maxDate.atTime (noon) ;

        return [convert (minDateTime), convert (maxDateTime)] ;
    }
    */

	function minMaxY(points: SeasonPoint[]): [number, number] {
		const values = points.map(function (sp) {
			return sp.value;
		});

		let minY = Number.MAX_VALUE;
		let maxY = Number.MIN_VALUE;

		values.forEach((value) => {
			if (value < minY) {
				minY = value;
			}
			if (value > maxY) {
				maxY = value;
			}
		});

		const diff = maxY - minY;

		const tick = diff / 10.0;

		return [minY - tick, maxY + tick];
	}

	// function convert (localDate: LocalDate): Date {
	//    return new Date (localDate.year (), localDate.monthValue () - 1, localDate.dayOfMonth()) ;
	// }

	function convert(localDateTime: LocalDateTime): Date {
		return new Date(
			localDateTime.year(),
			localDateTime.monthValue() - 1,
			localDateTime.dayOfMonth(),
			localDateTime.hour(),
			localDateTime.minute(),
			localDateTime.second()
		);
	}

	function datetimeBetween(first: LocalDateTime, last: LocalDateTime): LocalDateTime {
		const half_duration = Duration.between(first, last).dividedBy(2.0);

		return first.plus(half_duration);
	}

	function last(array: Array<SeasonPointDt>): SeasonPointDt {
		return array[array.length - 1];
	}

	function appendLines(
		svg: d3.Selection,
		points: Array<SeasonPoint>,
		colors: Map<string, string>,
		x: d3.TimeScale,
		y: d3.LinearScale
	) {
		const lines = sameSeason(points);

		// debugLines (lines) ;

		for (var line of lines) {
			const points_d3 = line.map((point) => [x(convert(point.datetime)), y(point.value)]);

			const d3line = d3.line()(points_d3);

			const color = colors.get(line[0].season);

			svg.append('path').attr('fill', 'none').attr('stroke', color).attr('stroke-width', 1.5).attr('d', d3line);
		}
	}

	/*
	 *
	 */
	function debugLines(lines: Array<Array<SeasonPointDt>>) {
		//console.log ("<<<") ;
		lines.forEach((line) => {
			//console.log ("season:" + last (line).season) ;

			line.forEach((point) => {
				//console.log ("point: " + point.datetime + ", " + point.value + ", " + point.season) ;
			});
		});
		//console.log (">>>") ;
	}

	/*
	 *
	 */
	function sameSeason(points: Array<SeasonPoint>): Array<Array<SeasonPointDt>> {
		let list: Array<Array<SeasonPointDt>> = [];

		let currentList: Array<SeasonPointDt> = [];

		var currentSeason = points[0].season;

		for (var point of points) {
			const point_date = LocalDate.of(point.year, point.month, point.day);
			const point_datetime = point_date.atTime(0, 0, 0);
			const point_dt = new SeasonPointDt(point_datetime, point.value, point.season);

			if (point.season != currentSeason) {
				// add a fictional point to separate lines
				const previousPoint = last(currentList);
				const fictional_datetime = datetimeBetween(previousPoint.datetime, point_datetime);
				const fictional_value = (point.value + previousPoint.value) / 2.0;

				// previous curve
				const end_fictional_point_dt = new SeasonPointDt(fictional_datetime, fictional_value, previousPoint.season);

				currentList.push(end_fictional_point_dt);

				list.push(currentList);

				// new curve
				const first_fictional_point_dt = new SeasonPointDt(fictional_datetime, fictional_value, point.season);

				currentList = [first_fictional_point_dt];
				currentList.push(point_dt);

				currentSeason = point.season;
			} else {
				currentList.push(point_dt);
			}
		}

		list.push(currentList);

		return list;
	}

	function filterPoints(
		data: Array<D3DaySeasonValueRenderer.SeasonPoint>,
		first_date: LocalDate,
		last_date: LocalDate
	): Array<D3DaySeasonValueRenderer.SeasonPoint> {
		const points = data.filter((point) => {
			const date = LocalDate.of(point.year, point.month, point.day);
			// between first_date and last_date inclusive
			return !date.isBefore(first_date) && !date.isAfter(last_date);
		});

		return points;
	}

	/*
	 *
	 */
	function appendPoints(
		svg: d3.Selection,
		points: Array<SeasonPoint>,
		esOutbound: Boolean,
		radius: number,
		colors: Map<string, string>,
		color: string,
		x: d3.TimeScale,
		y: d3.LinearScale,
		tooltip: d3.Selection
	) {
		var fcolor = (d: PointDatum) => d.color;

		// tooltip functions
		var fmouseover = (d: PointDatum) => {
			tooltip.style('opacity', '1').style('visibility', 'visible');
		};
		var fmouseleave = (d: PointDatum) => {
			tooltip.style('opacity', '0').style('visibility', 'hidden');
		};
		var fmousemove = (d: PointDatum) => {
			tooltip
				.html(
					d.bound +
						': ' +
						d.date.getFullYear() +
						'/' +
						(d.date.getMonth() + 1) +
						'/' +
						d.date.getDate() +
						', ' +
						d.value
				)
				.style('left', d.x + 80 + 'px')
				.style('top', d.y + 'px');
		};

		const datum = points.map((point) => {
			const date_js = new Date(point.year, point.month - 1, point.day);
			const valueFix = parseFloat(point.value.toFixed(3));

			var _x = 0;
			var _y = 0;
			var bound = '';

			if (esOutbound) {
				_x = x(date_js);
				_y = y(valueFix);
				bound = 'outbound';
			} else {
				_x = x(date_js) - radius;
				_y = y(valueFix) - radius;
				bound = 'inbound';
			}

			return new PointDatum(date_js, valueFix, (color = colors.get(point.season)), _x, _y, bound);
		});

		var fx = (d: PointDatum) => d.x;
		var fy = (d: PointDatum) => d.y;

		if (esOutbound) {
			svg
				.append('g')
				.selectAll('dot_out')
				.data(datum)
				.enter()
				.append('circle')
				.attr('class', 'my_point')
				.attr('cx', fx)
				.attr('cy', fy)
				.attr('r', radius) //
				.attr('fill', fcolor) // fcolor
				.on('mouseover', fmouseover)
				.on('mouseleave', fmouseleave)
				.on('mousemove', fmousemove);
		} else {
			svg
				.append('g')
				.selectAll('dot_in')
				.data(datum)
				.enter()
				.append('rect')
				.attr('class', 'my_point')
				.attr('x', fx)
				.attr('y', fy)
				.attr('width', 1 + 2 * radius) //
				.attr('height', 1 + 2 * radius) //
				.attr('fill', fcolor) // fcolor
				.on('mouseover', fmouseover)
				.on('mouseleave', fmouseleave)
				.on('mousemove', fmousemove);
		}
	}
}
