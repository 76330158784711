<ng-container>
	<mat-card appearance="outlined" class="mat-card">
		<mat-card-content>
			<app-grid-clusters-indicators
				(clusterChanged)="onClusterChanged()"
				[element_code]="element_code"
				[data_grid_clusters]="data_grid_clusters"
				[data_grid_clusters_raw]="data_grid_clusters_raw"
				[data_grid_rask]="data_grid_rask"
				[data_grid_rask_selected]="data_grid_rask_selected"
				[data_grid_yield]="data_grid_yield"
				[data_grid_yield_selected]="data_grid_yield_selected"
				[data_grid_load_factor]="data_grid_load_factor"
				[data_grid_load_factor_selected]="data_grid_load_factor_selected"
				[data_grid_short_ap_pax]="data_grid_short_ap_pax"
				[data_grid_short_ap_pax_selected]="data_grid_short_ap_pax_selected"
				[data_grid_international_connecting]="data_grid_international_connecting"
				[data_grid_international_connecting_selected]="data_grid_international_connecting_selected"
				[data_grid_domestic_connecting]="data_grid_domestic_connecting"
				[data_grid_domestic_connecting_selected]="data_grid_domestic_connecting_selected"
				[data_grid_baseline_ask]="data_grid_baseline_ask"
				[data_grid_baseline_ask_selected]="data_grid_baseline_ask_selected"
				[data_grid_low_mix]="data_grid_low_mix"
				[data_grid_low_mix_selected]="data_grid_low_mix_selected"
				[data_grid_posted_flights]="data_grid_posted_flights"
				[data_grid_posted_flights_selected]="data_grid_posted_flights_selected"
				[data_grid_target_ask]="data_grid_target_ask"
				[data_grid_target_ask_selected]="data_grid_target_ask_selected"
				[data_grid_model_output]="data_grid_model_output"
				[data_grid_model_output_selected]="data_grid_model_output_selected"
				[data_grid_baseline_clusters]="data_grid_baseline_clusters"
				[data_grid_baseline_clusters_selected]="data_grid_baseline_clusters_selected"
				[baseline_clusters_visible]="baseline_clusters_visible"
			>
			</app-grid-clusters-indicators>
		</mat-card-content>

		<mat-card-actions class="select-rem">
			<div class="select-white">
				<mat-form-field class="ml-22">
					<mat-select [formControl]="selectSeasonImportCluster" placeholder="Import Clusters">
						<mat-option [value]="optionSeason" *ngFor="let optionSeason of seasons">{{ optionSeason }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<button class="btn-copy ml-22" (click)="applyModel()" [disabled]="isBtnApplyModelDisabled()">
				Copy Model Output
			</button>
			<button class="btn-copy ml-22" (click)="onUpdateKpisClicked()" [disabled]="disableBtnKpi">Update KPIs</button>
			<mat-form-field appearance="outline" class="ml-22">
				<mat-select multiple [formControl]="selectIndicadores" placeholder="Select KPIs">
					<mat-option
						[value]="indicator"
						*ngFor="let indicator of optionIndicators"
						[disabled]="maxOptionDisabled(indicator)"
					>
						{{ indicator }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<button class="btn-save ml-22 mr-30" (click)="save()" [disabled]="!disableBtnKpi">Save</button>
		</mat-card-actions>
	</mat-card>
</ng-container>
