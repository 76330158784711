<div class="ml-3">
	<div class="title-type">{{ title }}</div>
	<div class="container-table">
		<app-grid-tod-dow
			[data_grid]="data_grid_outbound"
			[data_grid_raw]="data_grid_raw_outbound"
			[rendererGrid]="rendererGrid"
			[afterChange]="afterChange"
			[readOnly]="readOnly"
			[title]="getTitleOutbound"
			[keyDownCell]="keyDownCellOutbound"
			(hotInstanceTable)="updateInstanceOutbound($event)"
		></app-grid-tod-dow>
	</div>
	<div class="container-table ml-1">
		<app-grid-tod-dow
			[data_grid]="data_grid_inbound"
			[data_grid_raw]="data_grid_raw_inbound"
			[rendererGrid]="rendererGrid"
			[afterChange]="afterChange"
			[readOnly]="readOnly"
			[title]="getTitleInbound"
			[keyDownCell]="keyDownCellInbound"
			(hotInstanceTable)="updateInstanceInbound($event)"
		></app-grid-tod-dow>
	</div>
	<div style="clear: both"></div>
</div>
