import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataClusters, IndicatorClustersService } from '@services/clusters/indicator-cluster.service';
import { convertTods, TodStartEnd } from 'src/app/models/clusters/GridTod';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';

export interface TemporalTimeZone {
	temporalOutbound: Array<TodStartEnd>;
	temporalInbound: Array<TodStartEnd>;
}

@Component({
	selector: 'app-tod-tools-container',
	templateUrl: './tod-tools-container.component.html',
	styleUrls: ['./tod-tools-container.component.scss']
})
export class TodToolsContainerComponent implements OnChanges {
	@Input() seasons: string[] = [];
	@Input() inbound_leg: string;
	@Input() outbound_leg: string;
	@Input() outbound_franjas: Array<number> = [];
	@Input() inbound_franjas: Array<number> = [];
	@Output() todToolsChanged = new EventEmitter<TemporalTimeZone>();

	outboundTitle: string;
	inboundTitle: string;

	data_clusters: DataClusters;
	sendSnackbarActive;

	outboundTimeZone: Array<TodStartEnd> = [];
	inboundTimeZone: Array<TodStartEnd> = [];

	validateRefreshClusters = false;

	constructor(private indicatorClusterServices: IndicatorClustersService, private snackBar: MatSnackBar) {}

	ngOnChanges() {
		if (typeof this.outbound_leg !== 'undefined' && typeof this.inbound_leg !== 'undefined') {
			this.outboundTitle = 'Outbound -' + this.outbound_leg;
			this.inboundTitle = 'Inbound -' + this.inbound_leg;

			this.outboundTimeZone = convertTods(this.outbound_franjas);
			this.inboundTimeZone = convertTods(this.inbound_franjas);
		}
	}

	changeRefreshClusters(event) {
		this.validateRefreshClusters = event;
	}

	importTOD(selectedSeason: string): void {
		const cabinCode = OneTimeContext.getCabinCode();
		const elementCode = OneTimeContext.getElementCode();
		const season = selectedSeason;
		const sessionId = OneTimeContext.getSessionID();

		this.indicatorClusterServices.getDataClusters(cabinCode, elementCode, season, sessionId).subscribe(
			(dataClusters) => {
				this.data_clusters = dataClusters;
				this.outboundTitle = 'Outbound -' + this.data_clusters.outbound.leg;
				this.inboundTitle = 'Inbound -' + this.data_clusters.inbound.leg;
				this.outboundTimeZone = convertTods(this.data_clusters.outbound.franjas);
				this.inboundTimeZone = convertTods(this.data_clusters.inbound.franjas);
				this.openSnackBarCorrect('Time of day imported successfully');
			},
			(err) => {
				console.log(err);
				this.openSnackBarIncorrect('Unable to import Time of day');
			}
		);
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackIncorrect']
		});
	}

	refreshClusters() {
		let temporalTimeZone: TemporalTimeZone = {
			temporalOutbound: this.outboundTimeZone,
			temporalInbound: this.inboundTimeZone
		};
		this.todToolsChanged.emit(temporalTimeZone);
	}
}
