import { ClusterApTable, ClusterAPTableRow } from '@services/buckets/buckets.interface';

export interface ApValue<T> {
	ap: string;
	value: T;
}

/*
 *
 */
function createRow<T>(inputRow: ClusterAPTableRow, emptyT: T): Array<any> {
	const row: Array<any> = [];

	// ap_start - ap_end  o "Total"
	row.push(inputRow.name);

	// los clusters
	for (const value of inputRow.clustersValues) {
		row.push(value * 100.0);
	}

	return row;
}

/*
 *
 */
export function createGridBucketApClustersNumbers(table: ClusterApTable): GridBucketApClusters<number> {
	function sumNumber(t1: number, t2: number): number {
		if (Number.isNaN(t1)) {
			return t2;
		}

		if (Number.isNaN(t2)) {
			return t1;
		}

		if (Number.isNaN(t1) && Number.isNaN(t2)) {
			return 0;
		}

		return t1 + t2;
	}

	return createGridBucketApClusters(table, Number.NaN, sumNumber);
}

/*
 *
 */
export function createGridBucketApClusters<T>(
	apTable: ClusterApTable,
	emptyT: T,
	calcTotal: (t1: T, t2: T) => T
): GridBucketApClusters<T> {
	const rows: Array<Array<any>> = [];

	let title = '';
	let ap_start = 1;

	let clusters = apTable.table[0].clusters;

	for (const row of apTable.table) {
		const new_row = createRow(row, emptyT);
		rows.push(new_row);
	}

	return new GridBucketApClusters(emptyT, title, clusters, rows, Number.MAX_VALUE, Number.MIN_VALUE, calcTotal);
}

/*
 *
 */
export class GridBucketApClusters<T> {
	constructor(
		public emptyT: T,
		public title: string,
		public clusters: Array<string>,
		public rows: Array<Array<T>>,
		public min_value: number,
		public max_value: number,
		public calcTotal: (t1: T, t2: T) => T
	) {}

	getValue(row: number, col: number): T {
		const rowFilter = this.rows[row] || [];
		return rowFilter.find((_, index) => index === col);
	}
}
