import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const _moment = _rollupMoment || moment;

export const MY_FORMATS = {
	parse: {
		dateInput: 'MM/YYYY'
	},
	display: {
		dateInput: 'MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	}
};

export class OutInFromTo {
	out_in: number;

	year_from: number;
	month_from: number;

	year_to: number;
	month_to: number;

	constructor(
		out_in: number,

		year_from: number,
		month_from: number,

		year_to: number,
		month_to: number
	) {
		this.out_in = out_in;
		this.year_from = year_from;
		this.month_from = month_from;
		this.year_to = year_to;
		this.month_to = month_to;
	}
}

export enum SelectionOutboundInbound {
	both_out_in = 1,
	only_out = 2,
	only_in = 3
}

@Component({
	selector: 'app-out-in-from-to',
	templateUrl: './out-in-from-to.component.html',
	styleUrls: ['./out-in-from-to.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},

		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
	]
})
export class OutInFromToComponent implements OnChanges {
	@Output() messageEvent = new EventEmitter<OutInFromTo>();

	@Input() year_from_min: number;
	@Input() month_from_min: number;
	@Input() year_to_max: number;
	@Input() month_to_max: number;

	dateIni: string = '';
	dateFin: string = '';
	rangoIni;
	rangoEnd;

	year_from = new UntypedFormControl('');
	month_from = new UntypedFormControl('');

	year_to = new UntypedFormControl('');
	month_to = new UntypedFormControl('');

	outbound = new UntypedFormControl(true);
	inbound = new UntypedFormControl(true);

	date = new UntypedFormControl(_moment());
	date2 = new UntypedFormControl(_moment());

	// ngOnInit() {
	// 	this.cargaDateLim();
	// }

	ngOnChanges() {
		this.year_from.setValue(String(this.year_from_min));
		this.month_from.setValue(String(this.month_from_min));
		this.year_to.setValue(String(this.year_to_max));
		this.month_to.setValue(String(this.month_to_max));
		// this.apply();
		this.cargaDateLim();
	}

	apply() {
		var sel_out_in = 0;

		if (this.outbound.value == true) {
			if (this.inbound.value == true) sel_out_in = SelectionOutboundInbound.both_out_in;
			else sel_out_in = SelectionOutboundInbound.only_out;
		} else if (this.inbound.value == true) sel_out_in = SelectionOutboundInbound.only_in;

		if (sel_out_in != 0) {
			const event = new OutInFromTo(
				sel_out_in,
				parseInt(this.year_from.value),
				parseInt(this.month_from.value),
				parseInt(this.year_to.value),
				parseInt(this.month_to.value)
			);

			this.messageEvent.emit(event);
		} else console.log('ERROR: select bound(s)');
	}

	// Calendario Anio y Mes
	chosenYearHandler(normalizedYear: Moment) {
		const ctrlValue = this.date.value;
		ctrlValue.year(normalizedYear.year());
		this.date.setValue(ctrlValue);
		this.year_from.setValue(this.date.value.year());
	}

	chosenYearHandler2(normalizedYear: Moment) {
		const ctrlValue = this.date2.value;
		ctrlValue.year(normalizedYear.year());
		this.date2.setValue(ctrlValue);
		this.year_to.setValue(this.date2.value.year());
	}

	chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
		const ctrlValue = this.date.value;
		ctrlValue.month(normalizedMonth.month());
		this.date.setValue(ctrlValue);
		datepicker.close();
		this.year_from.setValue(this.date.value.year());
		this.month_from.setValue(this.date.value.month() + 1);
	}

	chosenMonthHandler2(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
		const ctrlValue = this.date2.value;
		ctrlValue.month(normalizedMonth.month());
		this.date2.setValue(ctrlValue);
		datepicker.close();
		this.year_to.setValue(this.date2.value.year());
		this.month_to.setValue(this.date2.value.month() + 1);
	}

	cargaDateLim() {
		this.dateIni = this.year_from_min + '-' + this.month_from_min;
		this.dateFin = this.year_to_max + '-' + this.month_to_max;
		this.rangoIni = new Date(this.dateIni);
		this.rangoEnd = new Date(this.dateFin);
		this.date = new UntypedFormControl(_moment(this.dateIni, 'YYYY-MM'));
		this.date2 = new UntypedFormControl(_moment(this.dateFin, 'YYYY-MM'));
		console.log('CAMBIO');
	}
}
