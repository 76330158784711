import { Component, Input } from '@angular/core';
import Handsontable from 'handsontable';
import { GridModelSetting } from 'src/app/models/target-curves/GridModelSetting';
import { ConfigService } from '../../../../../config/config.service';

// TODO: ver si esta interface es necesaria
interface HTColumn {
	title: string;
	readOnly: boolean;
	type: string;
}

interface HTCell {
	row: number;
	col: number;
	readOnly: boolean;
	type: string;
}

const column_checkbox: HTColumn = {
	title: ' ',
	readOnly: false,
	type: 'checkbox'
};
const column_name: HTColumn = {
	title: ' ',
	readOnly: true,
	type: 'text'
};
const column_total: HTColumn = {
	title: 'Total',
	readOnly: true,
	type: 'numeric'
};

const ModelSettingsInputs = {
	gridModelSettings: 'grid_model_settings'
};
@Component({
	selector: 'app-model-settings',
	templateUrl: './model-settings.component.html',
	styleUrls: ['./model-settings.component.scss']
})
export class ModelSettingsComponent {
	grid_model_settings: GridModelSetting | undefined;
	grid_model_settings_ready = false;
	columns: Array<HTColumn> = [];
	cells: Array<HTCell> = [];
	@Input(ModelSettingsInputs.gridModelSettings)
	set setGridModelSetting(data: GridModelSetting) {
		if (typeof data !== 'undefined') {
			this.grid_model_settings = data;
			this.grid_model_settings_ready = true;

			// configura las columnas
			this.columns = [];
			// por defecto la primera columna muestra un checkbox
			this.columns.push(column_checkbox);
			this.columns.push(column_name);

			for (const cluster of data.clusters) {
				const column: HTColumn = {
					title: 'C' + cluster,
					readOnly: true,
					type: 'numeric'
				};
				this.columns.push(column);
			}

			this.columns.push(column_total);

			// configura las celdas que no tienen checkbox en la primera columna
			this.cells = [];
			for (const row_offset of data.rowsWithoutCb) {
				const cell_props: HTCell = {
					row: row_offset,
					col: 0,
					readOnly: true,
					type: 'text'
				};
				this.cells.push(cell_props);
			}
		} else {
			this.grid_model_settings = undefined;
			this.grid_model_settings_ready = false;
			this.columns = [];
			this.cells = [];
		}
	}

	id = 'ht_model_settings';
	title = 'Model Settings';

	tableSettings: Handsontable.GridSettings = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		contextMenu: false,
		dropdownMenu: false,
		language: 'es-MX',
		className: 'htCenter',
		filters: false,
		columnSorting: false,
		allowInsertRow: false,
		viewportColumnRenderingOffset: 1000,
		// colWidths: this.columns_width,
		colWidths(index) {
			if (index === 0) {
				return 20;
			}
			if (index === 1) {
				return 70;
			} else {
				return 35;
			}
		}
	};

	constructor(private configService: ConfigService) {}
}

/*
 * intento de crear renderer propio: OK
 * intento de crear editor propio: pausado luego de leer la doc de handsontable
 * --> intetar setear config celda x celda
 * 
function rendererOptionalCheckbox(
  hotInstance: Handsontable,
  td: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: Handsontable.CellValue,
  cellProperties: Handsontable.CellProperties
) {
  const cb = value as OptionalCheckbox;

  if (cb.is_checkbox) {
    Handsontable.renderers.CheckboxRenderer.apply(this, [
      hotInstance,
      td,
      row,
      col,
      prop,
      cb.value_checkbox,
      cellProperties,
    ]);
  } else {
    Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, col, prop, '', cellProperties]);
  }
}
*/
