import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
	selector: 'app-menu-lateral',
	templateUrl: './menu-lateral.component.html',
	styleUrls: ['./menu-lateral.component.scss']
})
export class MenuLateralComponent {
	public menuHeight = 600;
	public btnShow = true;
	stickyMenu: boolean = false;
	@ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

	constructor() {
		this.getScreenSize();
	}

	@HostListener('document:keyup.esc')
	onkeyup() {
		this.drawer.toggle();
		this.changeBtnShow();
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event?: Event) {
		this.menuHeight = window.innerHeight - 200;
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(e: Event) {
		const scrollTop = window.scrollY;
		if (scrollTop > 80) {
			this.stickyMenu = true;
		} else {
			this.stickyMenu = false;
		}
	}

	changeBtnShow() {
		this.btnShow = !this.btnShow;
	}
}
