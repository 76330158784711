<ng-container class="table-clusters">
	<div class="title-type">{{ title }}</div>
	<div *ngIf="isReady()">
		<hot-table
			[settings]="tableSettings()"
			[columns]="columns"
			[data]="data_grid.rows"
			[readOnly]="readOnly"
			[stretchH]="stretchH()"
			[hotId]="id"
			class="hot handsontable htColumnHeaders"
		>
		</hot-table>
	</div>
</ng-container>
