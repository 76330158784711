import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryComponent } from './summary.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SectionCompletitudComponent } from './section-completitud/section-completitud.component';

@NgModule({
	declarations: [SummaryComponent, SectionCompletitudComponent],
	imports: [CommonModule, SharedModule]
})
export class SummaryModule {}
