<div class="parameters">
	<div *ngIf="activeBlock1" class="first">
		<h2>Parameters</h2>

		<div class="search">
			<mat-form-field *ngIf="activeBlock1" class="w-100">
				<mat-icon matPrefix>search</mat-icon>
				<mat-label>Search Parameters</mat-label>
				<input matInput name="filter" autocomplete="off" (keyup)="applyFilter1($event)" />
			</mat-form-field>
		</div>
		<div class="content-table-side">
			<mat-table [dataSource]="dataSource" matSort>
				<ng-container matColumnDef="select" sticky>
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<mat-radio-button [(value)]="valueRadius">
							<div class="d-flex align-items-center justify-content-between content-table">
								<div class="block-one">
									<p class="pname" id="{{ element.id }}">{{ element.parameterName }}</p>
									<p class="puser">{{ element.lastUpdateUser }}</p>
								</div>
								<div>
									<div class="d-flex justify-content-end mb-1">
										<mat-icon
											*ngIf="element.associatedTotalLinks === 0"
											class="iconDelete"
											matPrefix
											(click)="deleteParameter(element)"
											>delete</mat-icon
										>
										<p class="butonlist">{{ element.associatedTotalLinks }} links</p>
									</div>
									<p class="pdate">{{ element.lastUpdateDate | date: 'd MMM, y HH:mm' }}</p>
								</div>
							</div>
						</mat-radio-button>
					</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumnsservicio; sticky: true"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumnsservicio"
					[ngClass]="{ 'element-row': true, highlight: idSelectedRow === row.parameterId }"
					(click)="getRecord(row)"
				></tr>
				<tr mat-footer-row *matFooterRowDef="displayedColumnsservicio; sticky: true"></tr>
			</mat-table>
		</div>
		<div class="btn-new">
			<button mat-raised-button (click)="buttonNew()">New Parameter</button>
		</div>
	</div>

	<div class="second w-100">
		<form (ngSubmit)="function(business, product.id, null)" name="loadform" #loadTableP="ngForm">
			<div class="header-principal">
				<div class="row">
					<div class="col-md-2">
						<mat-form-field>
							<mat-label>Product Category</mat-label>
							<mat-select [(value)]="category">
								<mat-option
									[value]="option.id"
									*ngFor="let option of comboListCategory"
									(click)="locateProduct(option.id)"
									(valueChange)="changeParameters()"
								>
									{{ option.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field>
							<mat-label>Product</mat-label>
							<mat-select [(value)]="product" (valueChange)="changeParameters()">
								<mat-option [value]="option" *ngFor="let option of comboListProduct">{{ option.name }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2">
						<mat-form-field>
							<mat-label>Business Unit</mat-label>
							<mat-select [(value)]="business" (valueChange)="changeParameters()">
								<mat-option [value]="option.id" *ngFor="let option of comboListBusiness">
									{{ option.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-2 align-content-center">
						<button type="submit" class="btn-search" [disabled]="!product || !category || !business">Search</button>
					</div>
				</div>
			</div>

			<div *ngIf="displayWelcome">
				<app-welcome-parameters></app-welcome-parameters>
			</div>

			<div *ngIf="displayLoad">
				<app-load-main></app-load-main>
			</div>

			<div class="render-new" *ngIf="displayNew">
				<app-new-parameter
					[productId]="product.id"
					[tableDataParameter]="loadtable"
					[bussinesId]="business"
					[productName]="product.name"
				></app-new-parameter>
			</div>

			<div class="render-edit" *ngIf="displayEdit">
				<app-edit-parameter
					[parameter]="selectedRow"
					[tableDataParameter]="loadtable"
					[productId]="product.id"
					[bussinesId]="business"
					[productName]="product.name"
				></app-edit-parameter>
			</div>

			<!--<div class="content-principal">
                <router-outlet></router-outlet>
            </div>-->
		</form>
	</div>
</div>
