import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fcalcColor } from '@services/calendars/calendar-color.service';
import Handsontable from 'handsontable';
import { Bounds } from 'src/app/models/Bounds';
import { colors_clusters } from 'src/app/models/clusters/ColorsClusters';
import { GridTod } from 'src/app/models/clusters/GridTod';
import { RowTod } from 'src/app/models/clusters/RowTod';

const GridClustersIndicatorsInputs = {
	data_grid_clusters: 'data_grid_clusters',
	data_grid_rask: 'data_grid_rask',
	data_grid_yield: 'data_grid_yield',
	data_grid_load_factor: 'data_grid_load_factor',
	data_grid_short_ap_pax: 'data_grid_short_ap_pax',
	data_grid_international_connecting: 'data_grid_international_connecting',
	data_grid_domestic_connecting: 'data_grid_domestic_connecting',
	data_grid_baseline_ask: 'data_grid_baseline_ask',
	data_grid_low_mix: 'data_grid_low_mix',
	data_grid_posted_flights: 'data_grid_posted_flights',
	data_grid_target_ask: 'data_grid_target_ask',
	data_grid_model_output: 'data_grid_model_output',
	data_grid_baseline_clusters: 'data_grid_baseline_clusters'
};

@Component({
	selector: 'app-grid-clusters-indicators',
	templateUrl: './grid-clusters-indicators.component.html',
	styleUrls: ['./grid-clusters-indicators.component.scss']
})
export class GridClustersIndicatorsComponent {
	@Output() clusterChanged = new EventEmitter<boolean>();

	@Input() element_code: Bounds<string>;

	//
	data_grid_clusters_ready = false;
	data_grid_clusters: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_clusters) set setDataGridClusters(data: Bounds<GridTod<number>>) {
		this.data_grid_clusters = data;

		if (typeof data !== 'undefined') this.data_grid_clusters_ready = true;
		else this.data_grid_clusters_ready = false;
	}

	@Input() data_grid_clusters_raw: Bounds<GridTod<number>>;

	//
	data_grid_rask_visible(): boolean {
		return this.data_grid_rask_ready && this.data_grid_rask_selected;
	}
	@Input() data_grid_rask_selected;
	data_grid_rask_ready = false;
	minRask = NaN;
	maxRask = NaN;

	data_grid_rask: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_rask) set setRask(data: Bounds<GridTod<number>>) {
		this.data_grid_rask = data;

		if (typeof data !== 'undefined') {
			this.data_grid_rask_ready = true;

			const par = this.minMax(this.data_grid_rask);
			this.minRask = par[0];
			this.maxRask = par[1];

			console.log('min/max rask: ' + this.minRask + ' / ' + this.maxRask);
		} else this.data_grid_rask_ready = false;
	}

	//
	data_grid_yield_visible(): boolean {
		return this.data_grid_yield_ready && this.data_grid_yield_selected;
	}
	@Input() data_grid_yield_selected;
	data_grid_yield_ready = false;
	minYield = NaN;
	maxYield = NaN;

	data_grid_yield: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_yield) set setYield(data: Bounds<GridTod<number>>) {
		this.data_grid_yield = data;

		if (typeof data !== 'undefined') {
			this.data_grid_yield_ready = true;

			const par = this.minMax(this.data_grid_yield);
			this.minYield = par[0];
			this.maxYield = par[1];

			console.log('min/max yield: ' + this.minYield + ' / ' + this.maxYield);
		} else this.data_grid_yield_ready = false;
	}

	//
	data_grid_load_factor_visible(): boolean {
		return this.data_grid_load_factor_ready && this.data_grid_load_factor_selected;
	}
	@Input() data_grid_load_factor_selected;
	data_grid_load_factor_ready = false;
	min_load_factor = NaN;
	max_load_factor = NaN;

	data_grid_load_factor: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_load_factor) set setLoadFactor(data: Bounds<GridTod<number>>) {
		this.data_grid_load_factor = data;

		if (typeof data !== 'undefined') {
			this.data_grid_load_factor_ready = true;

			const par = this.minMax(this.data_grid_load_factor);
			this.min_load_factor = par[0];
			this.max_load_factor = par[1];

			console.log('min/max load_factor: ' + this.min_load_factor + ' / ' + this.max_load_factor);
		} else this.data_grid_load_factor_ready = false;
	}

	//
	data_grid_short_ap_pax_visible(): boolean {
		return this.data_grid_short_ap_pax_ready && this.data_grid_short_ap_pax_selected;
	}
	@Input() data_grid_short_ap_pax_selected;
	data_grid_short_ap_pax_ready = false;
	min_short_ap_pax = NaN;
	max_short_ap_pax = NaN;

	data_grid_short_ap_pax: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_short_ap_pax) set setShortApPax(data: Bounds<GridTod<number>>) {
		this.data_grid_short_ap_pax = data;

		if (typeof data !== 'undefined') {
			this.data_grid_short_ap_pax_ready = true;

			const par = this.minMax(this.data_grid_short_ap_pax);
			this.min_short_ap_pax = par[0];
			this.max_short_ap_pax = par[1];

			console.log('min/max short_ap_pax: ' + this.min_short_ap_pax + ' / ' + this.max_short_ap_pax);
		} else this.data_grid_short_ap_pax_ready = false;
	}

	//
	data_grid_international_connecting_visible(): boolean {
		return this.data_grid_international_connecting_ready && this.data_grid_international_connecting_selected;
	}
	@Input() data_grid_international_connecting_selected;
	data_grid_international_connecting_ready = false;
	min_international_connecting = NaN;
	max_international_connecting = NaN;

	data_grid_international_connecting: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_international_connecting) set setInternationalConnecting(
		data: Bounds<GridTod<number>>
	) {
		this.data_grid_international_connecting = data;

		if (typeof data !== 'undefined') {
			this.data_grid_international_connecting_ready = true;

			const par = this.minMax(this.data_grid_international_connecting);
			this.min_international_connecting = par[0];
			this.max_international_connecting = par[1];

			console.log(
				'min/max international_connecting: ' +
					this.min_international_connecting +
					' / ' +
					this.max_international_connecting
			);
		} else this.data_grid_international_connecting_ready = false;
	}

	//
	data_grid_domestic_connecting_visible(): boolean {
		return this.data_grid_domestic_connecting_ready && this.data_grid_domestic_connecting_selected;
	}
	@Input() data_grid_domestic_connecting_selected;
	data_grid_domestic_connecting_ready = false;
	min_domestic_connecting = NaN;
	max_domestic_connecting = NaN;

	data_grid_domestic_connecting: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_domestic_connecting) set setDomesticConnecting(
		data: Bounds<GridTod<number>>
	) {
		this.data_grid_domestic_connecting = data;

		if (typeof data !== 'undefined') {
			this.data_grid_domestic_connecting_ready = true;

			const par = this.minMax(this.data_grid_domestic_connecting);
			this.min_domestic_connecting = par[0];
			this.max_domestic_connecting = par[1];

			console.log(
				'min/max domestic_connecting: ' + this.min_domestic_connecting + ' / ' + this.max_domestic_connecting
			);
		} else this.data_grid_domestic_connecting_ready = false;
	}

	//
	data_grid_baseline_ask_visible(): boolean {
		return this.data_grid_baseline_ask_ready && this.data_grid_baseline_ask_selected;
	}
	@Input() data_grid_baseline_ask_selected;
	data_grid_baseline_ask_ready = false;
	min_baseline_ask = NaN;
	max_baseline_ask = NaN;

	data_grid_baseline_ask: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_baseline_ask) set setBaselineAsk(data: Bounds<GridTod<number>>) {
		this.data_grid_baseline_ask = data;

		if (typeof data !== 'undefined') {
			this.data_grid_baseline_ask_ready = true;

			const par = this.minMax(this.data_grid_baseline_ask);
			this.min_baseline_ask = par[0];
			this.max_baseline_ask = par[1];

			console.log('min/max baseline_ask: ' + this.min_baseline_ask + ' / ' + this.max_baseline_ask);
		} else this.data_grid_baseline_ask_ready = false;
	}

	//
	data_grid_low_mix_visible(): boolean {
		return this.data_grid_low_mix_ready && this.data_grid_low_mix_selected;
	}
	@Input() data_grid_low_mix_selected;
	data_grid_low_mix_ready = false;
	min_low_mix = NaN;
	max_low_mix = NaN;

	data_grid_low_mix: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_low_mix) set setLowMix(data: Bounds<GridTod<number>>) {
		this.data_grid_low_mix = data;

		if (typeof data !== 'undefined') {
			this.data_grid_low_mix_ready = true;

			const par = this.minMax(this.data_grid_low_mix);
			this.min_low_mix = par[0];
			this.max_low_mix = par[1];

			console.log('min/max low_mix: ' + this.min_low_mix + ' / ' + this.max_low_mix);
		} else this.data_grid_low_mix_ready = false;
	}

	//
	data_grid_posted_flights_visible(): boolean {
		return this.data_grid_posted_flights_ready && this.data_grid_posted_flights_selected;
	}
	@Input() data_grid_posted_flights_selected;
	data_grid_posted_flights_ready = false;
	min_posted_flights = NaN;
	max_posted_flights = NaN;

	data_grid_posted_flights: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_posted_flights) set setPostedFlights(data: Bounds<GridTod<number>>) {
		this.data_grid_posted_flights = data;

		if (typeof data !== 'undefined') {
			this.data_grid_posted_flights_ready = true;

			const par = this.minMax(this.data_grid_posted_flights);
			this.min_posted_flights = par[0];
			this.max_posted_flights = par[1];

			console.log('min/max posted_flights: ' + this.min_posted_flights + ' / ' + this.max_posted_flights);
		} else this.data_grid_posted_flights_ready = false;
	}

	//
	data_grid_target_ask_visible(): boolean {
		return this.data_grid_target_ask_ready && this.data_grid_target_ask_selected;
	}
	@Input() data_grid_target_ask_selected;
	data_grid_target_ask_ready = false;
	min_target_ask = NaN;
	max_target_ask = NaN;

	data_grid_target_ask: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_target_ask) set setTargetAsk(data: Bounds<GridTod<number>>) {
		this.data_grid_target_ask = data;

		if (typeof data !== 'undefined') {
			this.data_grid_target_ask_ready = true;

			const par = this.minMax(this.data_grid_target_ask);
			this.min_target_ask = par[0];
			this.max_target_ask = par[1];

			console.log('min/max target_ask: ' + this.min_target_ask + ' / ' + this.max_target_ask);
		} else this.data_grid_target_ask_ready = false;
	}

	//
	data_grid_model_output_visible(): boolean {
		return this.data_grid_model_output_ready && this.data_grid_model_output_selected;
	}
	@Input() data_grid_model_output_selected;
	data_grid_model_output_ready = false;

	data_grid_model_output: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_model_output) set setModelOutput(data: Bounds<GridTod<number>>) {
		this.data_grid_model_output = data;
		// console.log (data) ;

		if (typeof data !== 'undefined') {
			this.data_grid_model_output_ready = true;
		} else this.data_grid_model_output_ready = false;
	}

	//
	@Input() baseline_clusters_visible = false;
	data_grid_baseline_clusters_visible(): boolean {
		return (
			this.data_grid_posted_flights_ready && this.data_grid_baseline_clusters_selected && this.baseline_clusters_visible
		);
	}
	@Input() data_grid_baseline_clusters_selected;
	data_grid_baseline_clusters_ready = false;
	min_baseline_clusters = NaN;
	max_baseline_clusters = NaN;

	data_grid_baseline_clusters: Bounds<GridTod<number>>;
	@Input(GridClustersIndicatorsInputs.data_grid_baseline_clusters) set setBaselineClusters(
		data: Bounds<GridTod<number>>
	) {
		this.data_grid_baseline_clusters = data;

		if (typeof data !== 'undefined') {
			this.data_grid_baseline_clusters_ready = true;

			const par = this.minMax(this.data_grid_baseline_clusters);
			this.min_baseline_clusters = par[0];
			this.max_baseline_clusters = par[1];

			console.log('min/max baseline_clusters: ' + this.min_baseline_clusters + ' / ' + this.max_baseline_clusters);
		} else this.data_grid_baseline_clusters_ready = false;
	}

	//
	constructor() {}

	minMax(data: Bounds<GridTod<number>>): [number, number] {
		const [minRaskOutbound, maxRaskOutbound] = data.outbound.minMax();
		const [minRaskInbound, maxRaskInbound] = data.inbound.minMax();

		return [
			minRaskOutbound < minRaskInbound ? minRaskOutbound : minRaskInbound,
			maxRaskOutbound > maxRaskInbound ? maxRaskOutbound : maxRaskInbound
		];
	}

	/*
	 * funciones callback (tienen que estar definidas como arrow function)
	 */

	afterChangeClusters = (
		data_grid: GridTod<string | number>,
		data_grid_raw: GridTod<string | number>,
		changes: Handsontable.CellChange[],
		source: Handsontable.ChangeSource
	) => {
		let hayUnCambio = false;

		if (source === 'edit' || source === 'CopyPaste.paste' || source === 'Autofill.fill') {
			changes?.forEach((c) => {
				const row = c[0];
				const field = c[1];
				const oldValue = c[2];
				const _newValue = c[3];

				if (oldValue !== _newValue) hayUnCambio = true;

				var newValue: number;

				if (typeof _newValue === 'string') {
					newValue = Number(_newValue);
				} else {
					newValue = _newValue;
				}

				const cellColor = colors_clusters.get(newValue);

				if ((null === newValue && oldValue !== null) || cellColor === undefined) {
					data_grid.updateValue(row, String(field), Number(oldValue));
				} else if (null !== newValue) {
					data_grid.updateValue(row, String(field), newValue);
				}
			});
		}

		if (hayUnCambio) {
			this.clusterChanged.emit(true);
		}
	};

	/*
	 *
	 */
	rendererGridClusters = (
		hotInstance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridTod<string | number>,
		data_grid_raw: GridTod<string | number> | undefined
	) => {
		Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, col, prop, value, cellProperties]);

		if (col <= 1) {
			// console.log (data_grid) ;
			return;
		}

		const emptyCellColor = '#F3F2F'; // #ffffff

		if (Number.isNaN(value)) {
			td.style.backgroundColor = emptyCellColor;
			td.innerHTML = '';
		} else {
			let hay_cambio = false;

			if (typeof data_grid_raw !== 'undefined') {
				// esta logica asume que afterChange actualiza la data de la estructura antes
				const valueGrid = data_grid.getValue(row, String(prop));
				const valueGridRaw = data_grid_raw.getValue(row, String(prop));

				hay_cambio = valueGrid !== valueGridRaw;
			}

			const color = colors_clusters.get(value);

			td.style.color = '#ffffff';
			td.style.backgroundColor = color;

			if (hay_cambio) {
				td.style.borderRadius = '50px';
				td.style.borderColor = '#6C757D';
			}
		}
	};

	simpleFormatNumeric(value: number): string {
		return value.toString();
	}

	// de la historia
	// valor minimo = 1000 (o sea > 0)
	// 100 se convierte en 1.0e2
	// 95.452.230 se convierte en 9.5e7
	// 9.130.000.000 se convierte en 9.1e9

	// 1.2e7
	askFormatNumeric(value: number): string {
		const exp = value.toExponential(1).replace('+', '');
		const ret = exp[0] + '<span style = "font-size: 9px" ;>' + exp.substring(1, 4) + '</span>' + exp.substring(4);

		return ret;
	}

	//
	twoDecimalFormatNumeric(value: number): string {
		return value.toFixed(2);
	}

	percentFormatNumeric(value: number): string {
		const percent = value * 100.0;
		return percent.toFixed(1) + '%';
	}

	//
	rendererGridYield = (hotInstance, td, row, col, prop, value, cellProperties, data_grid, data_grid_raw) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.minYield,
			this.maxYield,
			this.twoDecimalFormatNumeric
		);
	};

	rendererGridRask = (hotInstance, td, row, col, prop, value, cellProperties, data_grid, data_grid_raw) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.minRask,
			this.maxRask,
			this.twoDecimalFormatNumeric
		);
	};

	rendererGridLoadFactor = (hotInstance, td, row, col, prop, value, cellProperties, data_grid, data_grid_raw) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.min_load_factor,
			this.max_load_factor,
			this.percentFormatNumeric
		);
	};

	rendererGridShortApPax = (hotInstance, td, row, col, prop, value, cellProperties, data_grid, data_grid_raw) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.min_short_ap_pax,
			this.max_short_ap_pax,
			this.percentFormatNumeric
		);
	};

	rendererGridInternationalConnecting = (
		hotInstance,
		td,
		row,
		col,
		prop,
		value,
		cellProperties,
		data_grid,
		data_grid_raw
	) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.min_international_connecting,
			this.max_international_connecting,
			this.percentFormatNumeric
		);
	};

	rendererGridDomesticConnecting = (
		hotInstance,
		td,
		row,
		col,
		prop,
		value,
		cellProperties,
		data_grid,
		data_grid_raw
	) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.min_domestic_connecting,
			this.max_domestic_connecting,
			this.percentFormatNumeric
		);
	};

	rendererGridBaselineAsk = (hotInstance, td, row, col, prop, value, cellProperties, data_grid, data_grid_raw) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.min_baseline_ask,
			this.max_baseline_ask,
			this.percentFormatNumeric
		);
	};

	rendererGridLowMix = (hotInstance, td, row, col, prop, value, cellProperties, data_grid, data_grid_raw) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.min_low_mix,
			this.max_low_mix,
			this.percentFormatNumeric
		);
	};

	rendererGridPostedFlights = (hotInstance, td, row, col, prop, value, cellProperties, data_grid, data_grid_raw) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.min_posted_flights,
			this.max_posted_flights,
			this.percentFormatNumeric
		);
	};

	rendererGridTargetAsk = (hotInstance, td, row, col, prop, value, cellProperties, data_grid, data_grid_raw) => {
		this.rendererNumeric(
			hotInstance,
			td,
			row,
			col,
			prop,
			value,
			cellProperties,
			this.min_target_ask,
			this.max_target_ask,
			this.percentFormatNumeric
		);
	};

	/*
	 *
	 */
	rendererNumeric = (
		hotInstance,
		td,
		row,
		col,
		prop,
		value,
		cellProperties,
		minValue: number,
		maxValue: number,
		formatNumeric: (number) => string
	) => {
		Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, col, prop, value, cellProperties]);

		if (col <= 1) return;

		const emptyCellColor = ' #F3F2F6'; // #ffffff

		if (Number.isNaN(value)) {
			td.style.backgroundColor = emptyCellColor;
			td.innerHTML = '';
		} else {
			const valueColor = fcalcColor(value, minValue, maxValue, false);

			const hexColor = valueColor.toHex();

			if (hexColor.length === 7) {
				td.style.backgroundColor = hexColor;
			}

			const szValue = formatNumeric(value);

			td.innerHTML = szValue;
		}
	};

	/**
	 *
	 */

	private hotInstanceOut: Handsontable;
	private hotInstanceIn: Handsontable;

	getInstanceOut(instance: Handsontable): void {
		this.hotInstanceOut = instance;
	}

	getInstanceIn(instance: Handsontable): void {
		this.hotInstanceIn = instance;
	}

	rangeArray = (start: number, stop: number, step: number): number[] =>
		Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

	beforeKeyDownOutbound = (event: KeyboardEvent): void => {
		if (event.key === 'Delete') {
			this.cellDataSetRaw(this.data_grid_clusters_raw.outbound.rows, this.hotInstanceOut);
		}
	};

	beforeKeyDownInbound = (event: KeyboardEvent): void => {
		if (event.key === 'Delete') {
			this.cellDataSetRaw(this.data_grid_clusters_raw.inbound.rows, this.hotInstanceIn);
		}
	};

	cellDataSetRaw(data: RowTod<number>[], instance: Handsontable): void {
		const rangeSelected: Handsontable.CellRange[] = instance.getSelectedRange();

		rangeSelected.forEach((element) => {
			const rowStart = element.from.row < 0 ? 0 : element.from.row;
			const colStart = element.from.col <= 1 ? 2 : element.from.col;
			const rowsRange = this.rangeArray(rowStart, element.to.row, 1);
			const colsRange = this.rangeArray(colStart, element.to.col, 1);

			rowsRange.forEach((row: number) => {
				colsRange.forEach((col: number) => {
					const rowPotition: number = row;
					const colPosition: number = col;

					const dataPull = instance.getSourceData(rowPotition, colPosition, rowPotition, colPosition);
					const keyUpdate = Object.keys(dataPull.find((_, index) => index === 0)).find((_, index) => index === 0);
					const getRawKey = data.find((_, index) => index === rowPotition)[keyUpdate];
					const validatorSource = (getRawKey && 'edit') || 'myUpdateData';

					instance.setDataAtCell(rowPotition, colPosition, getRawKey, validatorSource);
				});
			});
		});
	}
}
