<div class="principal">
	<div class="contengo">
		<mat-form-field class="combo-links">
			<mat-select placeholder="{{ nameLink }}" #singleSelect disabled>
				<mat-option>
					<ngx-mat-select-search></ngx-mat-select-search>
				</mat-option>
				<mat-option [value]="nameLink">{{ nameLink }}</mat-option>
			</mat-select>
		</mat-form-field>
		<div class="icon-buton">
			<button type="button" class="btn-select-all" (click)="selectAllCells()">
				<img src="../../../../assets/icons/LATAM Icons/vector-cuadrilla.svg" alt="" />
			</button>
			<button type="button" class="btn-download" (click)="downloadCsv()">
				<img src="../../../../assets/icons/LATAM Icons/vector.svg" alt="" />
			</button>
			<button type="button" (click)="validateCellSave()" class="btn-search" [disabled]="!isDisableSave">Save</button>
		</div>
	</div>
	<div class="headersLinks">
		<hot-table [hotId]="id" [settings]="tableSettings" [data]="tableData" class="handsontable-custom"> </hot-table>
	</div>
</div>
