import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalDate } from '@js-joda/core';
import { IndicatorCalendarService } from '@services/calendars/indicator-calendars.service';
import { OutputModelCalendarService } from '@services/calendars/output-model-calendar.service';
import { RemCalendarService } from '@services/calendars/rem-calendar.service';
import { SeasonCalendarService } from '@services/calendars/season-calendar.service';
import { OneTimeServices } from '@services/one-time.service';
import moment from 'moment';
import { OutInFromTo } from 'src/app/components/one-time/seasons/FormFilterOutInFromTo/out-in-from-to.component';
import { CellSeason } from 'src/app/models/calendars/CellSeason';
import { createCalendars, PeriodCalendarStructure } from 'src/app/models/calendars/PeriodCalendarStructure';
import { RowCalendar } from 'src/app/models/calendars/RowCalendar';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { AsksPorSeasonKpi } from 'src/app/models/seasons/AsksPorSeasonKpi';
import { D3DaySeasonValueRenderer } from '../scatterplots/dia-season-valor-scatterplot/D3-day-season-value-scatterplot/D3DaySeasonValueRenderer';
import { editorOutput, filterBaseline } from './menu-seasons/menu-seasons.component';

/*
 *
 */
interface UICalendar {
	calendar: Array<RowCalendar<CellSeason> | RowCalendar<string> | RowCalendar<number>>;
	minValue: number;
	maxValue: number;
}

interface UICalendars1234 {
	month: string;
	year: number;
	outboundSeasonCalendar: UICalendar;
	outboundIndicatorCalendar: UICalendar;
	inboundSeasonCalendar: UICalendar;
	inboundIndicatorCalendar: UICalendar;
}

const SeasonsInputs = {
	filter: 'filter'
};

/*
 *
 */
@Component({
	selector: 'app-seasons',
	templateUrl: './seasons.component.html',
	styleUrls: ['./seasons.component.scss']
})
export class SeasonsComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() disableView: boolean;
	dataFilter: any = {};
	@Input(SeasonsInputs.filter)
	set setFilter(data: any) {
		console.log(this.dataFilter);
		this.dataFilter = data;
		this.generateAll(true, true);
	}

	//
	//
	// Flag de snack bar activo
	sendSnackbarActive;

	// Flag para boton Copy All
	copyDisabled = true;

	// Flag de mostrar carga de editorYear
	chargedEditorYear = true;

	// Flag de mostrar actualizacion baseline
	updatedBaseline = false;

	elementCode;
	inboundElementCode;
	outboundElementCode;
	cabinCode;

	respaldoMenu: editorOutput;

	sourceType = '';

	//
	// data entrada para los calendarios
	//

	columns: any = [];
	calendars: Array<UICalendars1234> = [];

	//
	inboundSeasonCalendars: PeriodCalendarStructure<CellSeason>;
	outboundSeasonCalendars: PeriodCalendarStructure<CellSeason>;

	inboundRaskCalendars: PeriodCalendarStructure<number> | undefined = undefined;
	inboundYieldCalendars: PeriodCalendarStructure<number>;
	inboundLoadFactorCalendars: PeriodCalendarStructure<number>;
	inboundPostedFlightsCalendars: PeriodCalendarStructure<number>;
	inboundBaselineSeasonsCalendars: PeriodCalendarStructure<string>;
	inboundModelOutputCalendars: PeriodCalendarStructure<string>;

	outboundRaskCalendars: PeriodCalendarStructure<number> | undefined = undefined;
	outboundYieldCalendars: PeriodCalendarStructure<number>;
	outboundLoadFactorCalendars: PeriodCalendarStructure<number>;
	outboundPostedFlightsCalendars: PeriodCalendarStructure<number>;
	outboundBaselineSeasonsCalendars: PeriodCalendarStructure<string>;
	outboundModelOutputCalendars: PeriodCalendarStructure<string>;

	// el indicador desplegado en los calendarios 2 y 4
	selectIndicadores;

	monthsNumber = 12;

	firstCalendarType = 'seasons';
	calendarType;

	initDate;
	endDate;

	year;
	editorYear;
	level;
	baseLineLevel;
	baseLineElementCode;
	baseLineCabinCode;
	baseLineYear;

	//
	// data entrada para los KPI's ask por season
	//

	asksPorSeasonKpis: Array<AsksPorSeasonKpi> = [];

	//
	// data entrada para los graficos
	//

	outbound_data_rask: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];
	inbound_data_rask: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];
	outbound_data_yield: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];
	inbound_data_yield: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];
	outbound_data_load_factor: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];
	inbound_data_load_factor: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];
	outbound_data_posted_flights: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];
	inbound_data_posted_flights: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];
	outbound_data_baseline_seasons: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];
	inbound_data_baseline_seasons: Array<D3DaySeasonValueRenderer.SeasonPoint> = [];

	// TODO: obtener estos valores automaticamente
	// rangos min/max que puede usar el formulario
	year_from_min: number;
	month_from_min: number;
	year_to_max: number;
	month_to_max: number;

	currentYear: number;
	currentMonth: number;

	out_in = 1; // outbound e inbound por defecto

	// valores seteados por formulario
	year_from = 2020;
	month_from = 6;
	year_to = 2020;
	month_to = 7;

	// cambio de nombre boton copyAll
	copyAllName: string;

	// boton update Kpi
	disabledUpdate = true;

	/*
	 *
	 */
	constructor(
		private oneTimeServices: OneTimeServices,
		private elementRef: ElementRef,
		private seasonCalendarService: SeasonCalendarService,
		private indicatorCalendarService: IndicatorCalendarService,
		private remCalendarService: RemCalendarService,
		private modelOutputCalendarService: OutputModelCalendarService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.chargedEditorYear = false;
		this.updatedBaseline = false;
		this.currentYear = moment().year();
		this.currentMonth = moment().month() + 1;
		this.putFechasLim(this.currentYear, this.currentMonth);

		this.generateAll(true, true);
	}

	ngAfterViewInit() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'scroll';
	}

	ngOnDestroy() {
		this.elementRef.nativeElement.ownerDocument.body.style.overflowY = 'hidden';
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}

	/*
	 * boton "Update KPIs"
	 */
	updateKPIs() {
		const currentDate = LocalDate.now();
		const year = this.chargedEditorYear ? parseInt(OneTimeContext.getEditorYear(), 10) : currentDate.year();

		this.generateKpisAsksPorSeason(year, false);

		this.putFechasLim(year, this.currentMonth);
		this.generatesDataScatterPlots();
		this.disabledUpdate = true;
	}

	copyAll() {
		if (this.selectIndicadores === 'model_output') {
			this.sourceType = 'model';

			copyCalendars(this.outboundSeasonCalendars, this.outboundModelOutputCalendars);
			copyCalendars(this.inboundSeasonCalendars, this.inboundModelOutputCalendars);

			this.calendarType = 'string';
			this.calendars = this.buildCalendars(
				this.outboundSeasonCalendars,
				this.outboundModelOutputCalendars,
				this.inboundSeasonCalendars,
				this.inboundModelOutputCalendars,
				this.monthsNumber
			);
			this.disabledUpdate = false;
			this.openSnackBarCorrect('Seasons were copied successfully');
		} else if (this.selectIndicadores === 'baseline_seasons') {
			this.sourceType = 'baseline';

			copyCalendars(this.outboundSeasonCalendars, this.outboundBaselineSeasonsCalendars);
			copyCalendars(this.inboundSeasonCalendars, this.inboundBaselineSeasonsCalendars);

			this.calendarType = 'string';
			this.calendars = this.buildCalendars(
				this.outboundSeasonCalendars,
				this.outboundBaselineSeasonsCalendars,
				this.inboundSeasonCalendars,
				this.inboundBaselineSeasonsCalendars,
				this.monthsNumber
			);
			this.disabledUpdate = false;
			this.openSnackBarCorrect('Seasons were copied successfully');
		} else {
			this.openSnackBarIncorrect("Unable to save season's");
			console.log('copyAll not supported for this selection');
		}
	}

	/*
	 * boton "Save"
	 */
	saveSeasons() {
		console.log('saveSeasons');

		const sessionId = OneTimeContext.getSessionID();

		this.seasonCalendarService
			.saveSeasons(
				sessionId,
				this.elementCode,
				this.cabinCode,
				this.sourceType,
				this.baseLineElementCode,
				this.baseLineCabinCode,
				this.baseLineYear,
				this.outboundSeasonCalendars,
				this.inboundSeasonCalendars,
				this.respaldoMenu
			)
			.subscribe(
				(ret) => {
					console.log(ret);
					this.openSnackBarCorrect("Season's have been successfully saved");
				},
				(error) => {
					console.log('saveSeasons', error);
					this.openSnackBarIncorrect("Unable to save season's");
				}
			);
	}

	/*
	 * pobla toda la data de los componentes cuando se carga la pagina
	 */
	generateAll(callSeasons, callIndicators: boolean): void {
		this.calendarType = 'numeric';
		this.selectIndicadores = 'rask';

		this.cabinCode = OneTimeContext.getCabinCode();
		this.elementCode = OneTimeContext.getElementCode();
		this.inboundElementCode = OneTimeContext.getInbound();
		this.outboundElementCode = OneTimeContext.getOutbound();

		const currentDate = LocalDate.now();

		this.year = !!OneTimeContext.getEditorYear() ? parseInt(OneTimeContext.getEditorYear(), 10) : currentDate.year();

		this.initDate = LocalDate.of(this.year, currentDate.monthValue(), 1);
		this.endDate = this.initDate.plusMonths(this.monthsNumber);

		const sessionId = OneTimeContext.getSessionID();

		this.level = OneTimeContext.getLevel();
		this.baseLineLevel = OneTimeContext.getBaselineLevel() !== null ? OneTimeContext.getBaselineLevel() : this.level;
		this.baseLineElementCode =
			OneTimeContext.getBaselineElement() !== null ? OneTimeContext.getBaselineElement() : this.elementCode;
		this.baseLineCabinCode =
			OneTimeContext.getBaselineCabinCode() !== null ? OneTimeContext.getBaselineCabinCode() : this.cabinCode;
		this.baseLineYear =
			OneTimeContext.getBaselineYear() !== null ? parseInt(OneTimeContext.getBaselineYear(), 10) : this.year - 1;

		this.columns = this.remCalendarService.getCalendarColumns();
		this.disableView = false;

		if (callIndicators) {
			this.inboundModelOutputCalendars = createCalendars(this.year, LocalDate.now().monthValue(), 12, '');
			this.outboundModelOutputCalendars = createCalendars(this.year, LocalDate.now().monthValue(), 12, '');
			this.createIndicatorCalendars(
				this.initDate,
				this.endDate,
				this.cabinCode,
				this.elementCode,
				this.year,
				this.level,
				this.baseLineLevel,
				this.baseLineElementCode,
				this.baseLineCabinCode,
				this.baseLineYear,
				this.monthsNumber
			);
		}

		if (callSeasons) {
			this.createSeasonCalendars(
				this.cabinCode,
				this.elementCode,
				this.initDate,
				this.endDate,
				this.monthsNumber,
				sessionId
			);
		}
	}

	createSeasonCalendars(cabinCode, elementCode, initDate, endDate, monthsNumber, sessionId) {
		this.seasonCalendarService
			.generateSeasonCalendars(cabinCode, elementCode, initDate, endDate, monthsNumber, sessionId)
			.subscribe(
				(seasonCalendars) => {
					console.log('createSeasonCalendars', seasonCalendars);
					this.outboundSeasonCalendars = seasonCalendars.outbound;
					this.inboundSeasonCalendars = seasonCalendars.inbound;

					if (typeof this.outboundRaskCalendars !== 'undefined' && typeof this.inboundRaskCalendars !== 'undefined') {
						this.calendars = this.buildCalendars(
							this.outboundSeasonCalendars,
							this.outboundRaskCalendars,
							this.inboundSeasonCalendars,
							this.inboundRaskCalendars,
							this.monthsNumber
						);
					} else {
						console.log('createSeasonCalendars', 'rask outbound/inbound undefined');
					}
				},
				(error) => {
					console.log('createSeasonCalendars', error);
					if (this.chargedEditorYear) {
						this.openSnackBarIncorrect('Unable to charge seasons');
					}
				}
			);
	}

	createIndicatorCalendars(
		initDate,
		endDate,
		cabinCode,
		elementCode,
		year,
		level,
		baseLineLevel,
		baseLineElementCode,
		baseLineCabinCode,
		baseLineYear,
		monthsNumber
	) {
		this.indicatorCalendarService
			.generateIndicatorCalendars(
				initDate,
				endDate,
				cabinCode,
				elementCode,
				year,
				level,
				baseLineLevel,
				baseLineElementCode,
				baseLineCabinCode,
				baseLineYear,
				monthsNumber
			)
			.subscribe(
				(indicatorCalendars) => {
					console.log('createIndicatorCalendars', indicatorCalendars);

					this.inboundRaskCalendars = indicatorCalendars.rask.inbound;
					this.inboundYieldCalendars = indicatorCalendars.yield.inbound;
					this.inboundLoadFactorCalendars = indicatorCalendars.loadFactor.inbound;
					this.inboundPostedFlightsCalendars = indicatorCalendars.postedFlights.inbound;
					this.inboundBaselineSeasonsCalendars = indicatorCalendars.baselineSeasons.inbound;

					this.outboundRaskCalendars = indicatorCalendars.rask.outbound;
					this.outboundYieldCalendars = indicatorCalendars.yield.outbound;
					this.outboundLoadFactorCalendars = indicatorCalendars.loadFactor.outbound;
					this.outboundPostedFlightsCalendars = indicatorCalendars.postedFlights.outbound;
					this.outboundBaselineSeasonsCalendars = indicatorCalendars.baselineSeasons.outbound;

					this.calendars = this.buildCalendars(
						this.outboundSeasonCalendars,
						this.outboundRaskCalendars,
						this.inboundSeasonCalendars,
						this.inboundRaskCalendars,
						this.monthsNumber
					);
					if (this.chargedEditorYear) {
						this.openSnackBarCorrect('Changed year successfully');
					} else if (this.updatedBaseline) {
						this.openSnackBarCorrect('Baseline data has been updated successfully');
					}

					// cuando tenemos toda la data de todos los calendarios, estamos listos para actualizar los KPI's....
					this.generateKpisAsksPorSeason(this.year, true);

					// ...y los graficos
					this.generatesDataScatterPlots();
				},
				(error) => {
					console.log('createIndicatorCalendars', error);
					if (this.chargedEditorYear) {
						this.openSnackBarIncorrect('Unable to change year');
					} else if (this.updatedBaseline) {
						this.openSnackBarIncorrect('Unable to get Baseline data');
					}
				}
			);
	}

	generateKpisAsksPorSeason(year: number, pageLoad: boolean): void {
		console.log("Actualizando kpi's ASK por season");

		this.seasonCalendarService
			.generateKpisAsksPorSeason(
				year,
				this.elementCode,
				this.cabinCode,
				this.outboundSeasonCalendars,
				this.inboundSeasonCalendars
			)
			.subscribe(
				(kpis) => {
					console.log(kpis);
					this.asksPorSeasonKpis = kpis;
					if (!pageLoad) {
						this.openSnackBarCorrect("KPI's have been updated successfully");
					}
				},
				(error) => {
					console.log('generateKpisAsksPorSeason', error);
					this.openSnackBarIncorrect("Unable to update KPI's");
				}
			);
	}

	generatesDataScatterPlots(): void {
		function join(
			seasons: PeriodCalendarStructure<CellSeason>,
			indicators: PeriodCalendarStructure<number>
		): Array<D3DaySeasonValueRenderer.SeasonPoint> {
			const arr_tupla3 = seasons.zip(indicators);

			return arr_tupla3.map((tupla3) => {
				const dt = tupla3[0];
				const season = tupla3[1];
				const indicator = tupla3[2];

				return new D3DaySeasonValueRenderer.SeasonPoint(
					dt.year(),
					dt.monthValue(),
					dt.dayOfMonth(),
					indicator,
					season.season
				);
			});
		}

		console.log('Actualizando data graficos');

		this.outbound_data_rask = join(this.outboundSeasonCalendars, this.outboundRaskCalendars);
		this.inbound_data_rask = join(this.inboundSeasonCalendars, this.inboundRaskCalendars);
		this.outbound_data_yield = join(this.outboundSeasonCalendars, this.outboundYieldCalendars);
		this.inbound_data_yield = join(this.inboundSeasonCalendars, this.inboundYieldCalendars);
		this.outbound_data_load_factor = join(this.outboundSeasonCalendars, this.outboundLoadFactorCalendars);
		this.inbound_data_load_factor = join(this.inboundSeasonCalendars, this.inboundLoadFactorCalendars);
		this.outbound_data_posted_flights = join(this.outboundSeasonCalendars, this.outboundPostedFlightsCalendars);
		this.inbound_data_posted_flights = join(this.inboundSeasonCalendars, this.inboundPostedFlightsCalendars);

		// console.log('outbound_data_rask', this.outbound_data_rask);
		// console.log('inbound_data_rask', this.inbound_data_rask);

		/* this.year_from_min = this.outboundSeasonCalendars.dt_init.year () ;
    this.month_from_min = this.outboundSeasonCalendars.dt_init.monthValue () ;

    this.year_to_max = this.outboundSeasonCalendars.dt_end.year () ;
    this.month_to_max = this.outboundSeasonCalendars.dt_end.monthValue () ; */

		this.year_from = this.year_from_min;
		this.month_from = this.month_from_min;

		this.year_to = this.year_to_max;
		this.month_to = this.month_to_max;
	}

	/*
	 * cambia de indicador en los calendarios 2 y 4
	 */
	desplegarCalendario() {
		switch (this.selectIndicadores) {
			case 'model_output':
				this.copyAllName = 'Copy Model Output';
				this.calendarType = 'string';
				this.copyDisabled = false;
				this.calendars = this.buildCalendars(
					this.outboundSeasonCalendars,
					this.outboundModelOutputCalendars,
					this.inboundSeasonCalendars,
					this.inboundModelOutputCalendars,
					this.monthsNumber
				);
				break;
			case 'rask':
				this.calendarType = 'numeric';
				this.copyDisabled = true;
				this.calendars = this.buildCalendars(
					this.outboundSeasonCalendars,
					this.outboundRaskCalendars,
					this.inboundSeasonCalendars,
					this.inboundRaskCalendars,
					this.monthsNumber
				);
				break;
			case 'yield':
				this.calendarType = 'numeric';
				this.copyDisabled = true;
				this.calendars = this.buildCalendars(
					this.outboundSeasonCalendars,
					this.outboundYieldCalendars,
					this.inboundSeasonCalendars,
					this.inboundYieldCalendars,
					this.monthsNumber
				);
				break;
			case 'load_factor':
				this.calendarType = 'numeric';
				this.copyDisabled = true;
				this.calendars = this.buildCalendars(
					this.outboundSeasonCalendars,
					this.outboundLoadFactorCalendars,
					this.inboundSeasonCalendars,
					this.inboundLoadFactorCalendars,
					this.monthsNumber
				);
				break;
			case 'posted_flights':
				this.calendarType = 'numeric';
				this.copyDisabled = true;
				this.calendars = this.buildCalendars(
					this.outboundSeasonCalendars,
					this.outboundPostedFlightsCalendars,
					this.inboundSeasonCalendars,
					this.inboundPostedFlightsCalendars,
					this.monthsNumber
				);
				break;
			case 'baseline_seasons':
				this.copyAllName = 'Copy Baseline Seasons';
				this.calendarType = 'string';
				this.copyDisabled = false;
				this.calendars = this.buildCalendars(
					this.outboundSeasonCalendars,
					this.outboundBaselineSeasonsCalendars,
					this.inboundSeasonCalendars,
					this.inboundBaselineSeasonsCalendars,
					this.monthsNumber
				);
				break;
			default:
				this.calendarType = 'string';
				this.copyDisabled = false;
				this.calendars = this.buildCalendars(
					this.outboundSeasonCalendars,
					this.outboundModelOutputCalendars,
					this.inboundSeasonCalendars,
					this.inboundModelOutputCalendars,
					this.monthsNumber
				);
				break;
		}
	}

	getBoundElementCode(elementCode): string {
		const prefix = elementCode.substring(0, 3);
		const suffix = elementCode.substring(3, 6);
		return suffix + prefix;
	}

	buildCalendars(
		outboundSeasonCalendars: PeriodCalendarStructure<CellSeason>,
		outboundIndicatorCalendars: PeriodCalendarStructure<number> | PeriodCalendarStructure<string>,
		inboundSeasonCalendars: PeriodCalendarStructure<CellSeason>,
		inboundIndicatorCalendars: PeriodCalendarStructure<number> | PeriodCalendarStructure<string>,
		monthsNumber: number
	): Array<UICalendars1234> {
		const calendars: Array<UICalendars1234> = [];

		console.log('buildCalendars-outboundSeasonCalendars', outboundSeasonCalendars);
		console.log('buildCalendars-outboundIndicatorCalendars', outboundIndicatorCalendars);
		console.log('buildCalendars-inboundSeasonCalendars', inboundSeasonCalendars);
		console.log('buildCalendars-inboundIndicatorCalendars', inboundIndicatorCalendars);

		for (let i = 0; i < monthsNumber; i++) {
			calendars.push({
				month: inboundSeasonCalendars.calendars[i].month,
				year: inboundSeasonCalendars.calendars[i].year,
				outboundSeasonCalendar: {
					calendar: outboundSeasonCalendars.calendars[i].rows,
					minValue: outboundSeasonCalendars.minValue,
					maxValue: outboundSeasonCalendars.maxValue
				},
				outboundIndicatorCalendar: {
					calendar: outboundIndicatorCalendars.calendars[i].rows,
					minValue: outboundIndicatorCalendars.minValue,
					maxValue: outboundIndicatorCalendars.maxValue
				},
				inboundSeasonCalendar: {
					calendar: inboundSeasonCalendars.calendars[i].rows,
					minValue: inboundSeasonCalendars.minValue,
					maxValue: inboundSeasonCalendars.maxValue
				},
				inboundIndicatorCalendar: {
					calendar: inboundIndicatorCalendars.calendars[i].rows,
					minValue: inboundIndicatorCalendars.minValue,
					maxValue: inboundIndicatorCalendars.maxValue
				}
			});
		}

		return calendars;
	}

	/*
	 * cambio en los fitros de los graficos
	 */
	receiveMessage(event: OutInFromTo) {
		console.log(event);

		this.year_from = event.year_from;
		this.month_from = event.month_from;
		this.year_to = event.year_to;
		this.month_to = event.month_to;
		this.out_in = event.out_in;
	}

	// recharge
	calculateModelOutputs(cabinBaseline, elementBaseline, levelBaseLine, editorYear, inputBaselineYear) {
		this.modelOutputCalendarService
			.generateIndicatorCalendars(
				this.initDate,
				this.endDate,
				this.cabinCode,
				this.elementCode,
				editorYear,
				this.level,
				levelBaseLine,
				elementBaseline,
				cabinBaseline,
				inputBaselineYear,
				this.monthsNumber
			)
			.subscribe(
				(model) => {
					this.inboundModelOutputCalendars = model.inbound;
					this.outboundModelOutputCalendars = model.outbound;
					this.openSnackBarCorrect('Seasons have been calculated successfully and are available in Model output');
					this.selectIndicadores = 'model_output';
					this.calendarType = 'string';
					this.copyDisabled = false;
					this.copyAllName = 'Copy Model Output';
					this.calendars = this.buildCalendars(
						this.outboundSeasonCalendars,
						this.outboundModelOutputCalendars,
						this.inboundSeasonCalendars,
						this.inboundModelOutputCalendars,
						this.monthsNumber
					);
				},
				(error) => {
					console.log('calculateModelOutputs', error);
					this.openSnackBarIncorrect('Unable to calculate Seasons');
				}
			);
	}

	updateEditor(editorOutput: editorOutput) {
		OneTimeContext.setEditorYear(editorOutput.editorYear);
		this.chargedEditorYear = true;
		this.updatedBaseline = false;
		this.putFechasLim(Number(editorOutput.editorYear), this.currentMonth);
		OneTimeContext.setBaselineLevel(editorOutput.level);
		OneTimeContext.setBaselineElement(editorOutput.element);
		OneTimeContext.setBaselineCabinCode(editorOutput.cabin);
		OneTimeContext.setBaselineYear(editorOutput.baselineYear);
		this.generateAll(true, true);
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackIncorrect']
		});
	}

	getUpdateBaseline(baselineData: filterBaseline) {
		OneTimeContext.setBaselineLevel(baselineData.level);
		OneTimeContext.setBaselineCabinCode(baselineData.cabin);
		OneTimeContext.setBaselineYear(baselineData.year);
		this.chargedEditorYear = false;
		this.updatedBaseline = true;
		this.generateAll(false, true);
		this.disabledUpdate = false;
	}

	putFechasLim(anio: number, mes: number) {
		this.year_from_min = anio;
		this.month_from_min = mes;
		if (mes === 1) {
			this.year_to_max = anio;
			this.month_to_max = 12;
		} else {
			this.year_to_max = anio + 1;
			this.month_to_max = mes - 1;
		}
	}

	editCampo(valor: boolean) {
		if (valor) {
			this.disabledUpdate = false;
		}
	}

	updateMenu(menu: editorOutput) {
		this.respaldoMenu = menu;
	}
}

/*
 *
 */
function copyCalendars(
	seasonCalendars: PeriodCalendarStructure<CellSeason>,
	indicatorCalendars: PeriodCalendarStructure<string>
): void {
	function copySeasonRow(_seasonRow: RowCalendar<CellSeason>, _modelRow: RowCalendar<string>) {
		if (_seasonRow.mon_data !== null) {
			_seasonRow.mon_data = { season: _modelRow.mon_data, season_raw: _modelRow.mon_data };
		}

		if (_seasonRow.tue_data !== null) {
			_seasonRow.tue_data = { season: _modelRow.tue_data, season_raw: _modelRow.tue_data };
		}

		if (_seasonRow.wed_data !== null) {
			_seasonRow.wed_data = { season: _modelRow.wed_data, season_raw: _modelRow.wed_data };
		}

		if (_seasonRow.thu_data !== null) {
			_seasonRow.thu_data = { season: _modelRow.thu_data, season_raw: _modelRow.thu_data };
		}

		if (_seasonRow.fri_data !== null) {
			_seasonRow.fri_data = { season: _modelRow.fri_data, season_raw: _modelRow.fri_data };
		}

		if (_seasonRow.sat_data !== null) {
			_seasonRow.sat_data = { season: _modelRow.sat_data, season_raw: _modelRow.sat_data };
		}

		if (_seasonRow.sun_data !== null) {
			_seasonRow.sun_data = { season: _modelRow.sun_data, season_raw: _modelRow.sun_data };
		}
	}

	seasonCalendars.calendars.forEach((seasonCal) => {
		const modelCalendar = indicatorCalendars.calendars.filter((c) => c.month === seasonCal.month)[0];

		seasonCal.rows.forEach((seasonRow) => {
			const baselineRow = modelCalendar.rows.filter((r) => r.week === seasonRow.week)[0];
			copySeasonRow(seasonRow, baselineRow);
		});
	});
}
