<ng-container>
	<mat-card appearance="outlined" class="mat-card">
		<mat-card-content class="mat-card-content">
			<div class="row" *ngIf="data_grid_info_table_ready">
				<div class="col-md-12 item-box">
					<app-grid-informative-table
						[readOnly]="false"
						title="Average Bid price by anticipation"
						[data_grid]="data_grid_info_table_grid_average_bp"
						[show_ap]="true"
						[rendererGrid]="rendererGridAverageBp"
					></app-grid-informative-table>
				</div>
				<div class="col-md-12 item-box">
					<app-grid-informative-table
						[readOnly]="false"
						title="LF Composition by AP"
						[data_grid]="data_grid_info_table_grid_lf_composition"
						[show_ap]="true"
						[rendererGrid]="rendererGridLfComposition"
					></app-grid-informative-table>
				</div>
			</div>
			<div class="row" *ngIf="data_grid_info_table_ready">
				<div class="col-md-12 item-box">
					<app-grid-informative-table
						[readOnly]="false"
						title="Δ Average Bid-Price vs Baseline"
						[data_grid]="data_grid_info_table_av_bp_bsln"
						[show_ap]="true"
						[rendererGrid]="rendererGridAverageBpVsBaseline"
					></app-grid-informative-table>
				</div>
				<div class="col-md-12 item-box">
					<app-grid-informative-table
						[readOnly]="true"
						title="Δ PAX vs Baseline"
						[data_grid]="data_grid_info_table_pax_bsln"
						[show_ap]="true"
						[rendererGrid]="rendererGridPaxVsBaseline"
					></app-grid-informative-table>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</ng-container>
