import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HotTableRegisterer } from '@handsontable/angular';
import { ParameterServices } from '@services/parameter.service';
import Handsontable from 'handsontable';
import 'handsontable/languages/es-MX';
import moment from 'moment';
import { SessionStorage } from 'src/app/core/storages/session.storage';
import { HttpSpinnerService } from 'src/app/http-spinner/http-spinner.service';
import Swal from 'sweetalert2';
import { Parameter } from '../../../models/Parameter';
import { ParameterDetail } from '../../../models/ParameterDetail';
import { Validate } from '../../parameter/validate';
import { ParametersComponent } from '../parameters/parameters.component';
import { SnackbarParameter } from '../SnackbarParameter/SnackbarParameter.component';
import { ConfigService } from '../../../config/config.service';
@Component({
	selector: 'app-new-parameter',
	templateUrl: './new-parameter.component.html',
	styleUrls: ['./new-parameter.component.scss'],
	providers: [Validate]
})
export class NewParameterComponent implements OnInit, OnDestroy {
	buttonSave: boolean;
	@Input() productName: string;
	@Input() productId: number;
	@Input() bussinesId: any;
	@Input() tableDataParameter;
	param: Parameter;
	paramDetail: ParameterDetail;
	validatingList: boolean;
	public clipboardCell: any;
	clipboardList: any = [];
	validate: Validate;
	validations: any;
	userMail;
	private hotRegisterer = new HotTableRegisterer();
	id = 'hotInstance';
	isDisableSave: boolean;
	tableDetail: any;
	tableData = [];
	tableColumns = [];
	tableNameColumns = [];
	newData = [];
	tableColumnsNew = [];
	date: moment.Moment = moment();
	parameterName: string;
	validationList: any[] = [];
	sendSnackbarActive;

	tableSettings: any = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		viewportColumnRenderingOffset: 26,
		viewportRowRenderingOffset: 'auto',
		stretchH: 'all',
		manualRowResize: true,
		manualColumnResize: true,
		columns: this.tableColumns,
		colHeaders: this.tableNameColumns,
		manualRowMove: true,
		manualColumnMove: true,
		contextMenu: true,
		minRows: 1,
		filters: true,
		dropdownMenu: false,
		className: 'htCenter',
		autoInsertRow: true,
		language: 'es-MX',
		minSpareRows: 1,
		colWidths: [70, 70, 100, 90, 120, 120, 120, 130, 100, 140, 160, 140, 140, 170],

		afterGetColHeader: (column, th) => {
			th.style.color = 'black';
			console.log('TABLE DETAIL HEADER', this.tableColumns);
			if (this.tableColumns[column].key) {
				th.style.backgroundColor = '#FAA0A0';
			}
		},

		afterRemoveRow: () => {
			console.log('despues de remover fila');
			this.hotRegisterer.getInstance(this.id).loadData(this.tableData);
			const rowCount = this.hotRegisterer.getInstance(this.id).countRows();
			if (rowCount === 1) {
				Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'question');
				return;
			}

			if (rowCount > 1) {
				var rowValids = [];
				for (var i = 0; i < rowCount; i++) {
					rowValids.push(i);
				}
			}

			this.hotRegisterer.getInstance(this.id).validateRows(rowValids, (valid) => {
				if (valid) {
					this.isDisableSave = true;
					console.log('ok');
				} else {
					this.isDisableSave = false;
					console.log('fallo validacion');
				}
			});
		},
		beforeChange(changes, source) {
			console.log('BEFORE CHANGE');
			changes?.forEach((element) => {
				console.log('CAMBIO : ', element);

				if (
					element[1] === 'competitors' ||
					element[1] === 'pos' ||
					element[1] === 'min_class' ||
					element[1] === 'max_class'
				) {
					let valueGet = element[3];

					const typeData = typeof valueGet;

					if (typeData === 'string') {
						element[3] = valueGet.toUpperCase();
					}

					//
				}

				if (element[1] === 'competitor') {
					let valueGet = element[3];

					const typeData = typeof valueGet;

					if (valueGet === 'default' || typeData === 'number') {
					} else {
						element[3] = valueGet.toUpperCase();
					}
				}

				/*
            if (element[1] === 'distance_to_target_curve_start' || element[1] === 'distance_to_target_curve_end') {

                let valueGet =element[3];

                const typeData = typeof valueGet;
                console.log('VALUE GET DISTANCE : ', valueGet);
                console.log('TYPE GET DISTANCE : ', typeData);

                if (typeData === 'string'){
                    element[3] = parseFloat(valueGet) ;

                }
            }
*/

				if (
					element[1] === 'time_of_day_start' ||
					element[1] === 'time_of_day_end' ||
					element[1] === 'competitors_time_of_day_start' ||
					element[1] === 'competitors_time_of_day_end'
				) {
					let valueGet = element[3];

					const regex = new RegExp('^-?[0-9][0-9]?:?[0-9][0-9]$');

					if (regex.test(valueGet)) {
						let esNegativo = false;
						let minutos = '';
						let final = '';

						if (valueGet !== '') {
							if (valueGet.includes('-')) {
								esNegativo = true;
							}

							valueGet = valueGet.replace('-', '');
							valueGet = valueGet.replace(':', '');

							minutos = valueGet.substr(-2);

							valueGet = valueGet.slice(0, -2);

							if (esNegativo === true) {
								final = '-';
							}

							if (valueGet.length === 1) {
								final = final + '0' + valueGet;
							} else {
								final = final + valueGet;
							}

							final = final + ':';
							final = final + minutos;

							return (element[3] = final);
						}
					}
				}
			});
			console.log('this.tableData DESPUES DEL CHANGE : ', this.tableData);
			console.log('this.tableDetail DESPUES DEL CHANGE : ', this.tableDetail);
			console.log('this.paramDetail DESPUES DEL CHANGE : ', this.paramDetail);
		},

		afterInit: () => {
			this.isDisableSave = false;
		},

		afterChange: (changes, row) => {
			if (row !== null) {
				this.isDisableSave = true;
			}
		}
	};

	constructor(
		private ListService: ParameterServices,
		private snackBar: MatSnackBar,
		private comp: Validate,
		private parameterComp: ParametersComponent,
		private httpSpinnerService: HttpSpinnerService,
		private sessionData: SessionStorage,
		private configService: ConfigService
	) {}

	validateCellSave() {
		this.isDisableSave = false;
		this.httpSpinnerService.nextState$(true);
		this.tableColumnsNew = [];
		console.log('this.tableData ANTES 0 : ', this.tableData);

		let indice = this.tableDataParameter.findIndex((table) => table.parameterName === this.parameterName);
		console.log(indice);
		if (indice > 0) {
			Swal.fire('Unable to save', 'the parameter name already exists', 'warning');
			this.httpSpinnerService.nextState$(false);
			return;
		}
		const rowCount = this.hotRegisterer.getInstance(this.id).countRows();
		if (rowCount === 1) {
			Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'warning');
			this.httpSpinnerService.nextState$(false);
			return;
		}
		console.log('NUMERO DE FILAS : ', rowCount);
		if (rowCount > 1) {
			var rowValids = [];
			for (var i = 0; i < rowCount - 1; i++) {
				rowValids.push(i);
			}
		}
		console.log('ROW VALIDS : ', rowValids);
		console.log('HANDSONTABLE ANTES DE GUARDAR', this.hotRegisterer.getInstance(this.id).getData());

		this.hotRegisterer.getInstance(this.id).validateRows(rowValids, (valid) => {
			console.log('IS VALID : ', valid);
			if (valid) {
				console.log('ULTIMA FILA', JSON.stringify(this.tableData[rowCount - 1]));
				if (JSON.stringify(this.tableData[rowCount - 1]) === '{}') {
					console.log('ELIMINAR ULTIMA FILA');

					this.tableData.length = this.tableData.length - 1;
				}

				this.paramDetail = new ParameterDetail();
				this.param = new Parameter();
				for (const value of this.tableColumns) {
					this.tableColumnsNew.push(value.data);
				}

				for (const value of this.tableData) {
					if (value.min_bid_price === '') {
						value.min_bid_price = null;
					}

					if (value.max_bid_price === '') {
						value.max_bid_price = null;
					}

					if (value.min_class === '') {
						value.min_class = null;
					}

					if (value.max_class === '') {
						value.max_class = null;
					}

					if (value.dilution_method === 'true') {
						value.dilution_method = true;
					}

					if (value.dilution_method === 'false') {
						value.dilution_method = false;
					}
				}
				console.log('this.tableData', this.tableData);
				console.log('this.this.tableDetail', this.tableDetail);

				this.paramDetail.data = Object.assign([], this.tableData);
				console.log('this.paramDetail.data', this.paramDetail.data);
				this.paramDetail.columns = this.tableColumnsNew;
				this.param.parameterId = this.tableDetail.parameterId;
				this.param.businessUnitId = this.bussinesId;
				this.param.productId = this.productId;
				this.param.parameterName = this.parameterName;
				this.param.productName = this.productName;
				this.param.parameterDetail = this.paramDetail;
				this.param.lastUpdateDate = this.date.toDate();
				this.param.lastUpdateUser = this.userMail;
				console.log('PARAMETRO ANTES DE GUARDAR', this.param);
				this.postNewParameter(this.param);
			} else {
				Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'warning');
				this.httpSpinnerService.nextState$(false);
				return;
			}
		});
	}

	ngOnInit() {
		this.userMail = this.sessionData.userEmail;
		this.isDisableSave = true;
		this.getNewDetail();
		Handsontable.validators.registerValidator('validatorGeneral', this.comp.validatorGeneral);
	}

	//Obtener nueva data
	getNewDetail() {
		console.log('NUEVO DETALLE');
		this.ListService.getNewTable(this.productName).subscribe(
			(res) => {
				this.tableDetail = res;
				if (this.productName === 'Competition Match') {
					console.log('modificar columns size...');
					this.tableSettings.colWidths = [100, 70, 70, 100, 120, 120, 130, 130, 140, 140, 140, 140, 140, 140, 140, 180];
				}
				for (const value of this.tableDetail.columns.values()) {
					if (value.data === 'min_class' || value.data === 'max_class') {
						continue;
					}
					value.validator = 'validatorGeneral';
					console.log('PASÓ VALIDACION');
					if (value.title.length > 13) {
						const cutList = value.title.split(' ');
						if (cutList.length >= 3) {
							if (cutList.length === 4) {
								if (cutList[0].length + cutList[1].length >= 10) {
									value.title = cutList[0] + ' ' + cutList[1] + '<br>' + cutList[2] + ' ' + cutList[3];
								} else {
									value.title = cutList[0] + ' ' + cutList[1] + ' ' + cutList[2] + '<br>' + cutList[3];
								}
							} else if (cutList.length === 3) {
								value.title = cutList[0] + ' ' + cutList[1] + '<br>' + cutList[2];
							} else if (cutList.length === 5) {
								value.title = cutList[0] + ' ' + cutList[1] + '<br>' + cutList[2] + ' ' + cutList[3] + ' ' + cutList[4];
							}
							console.log(value.title);
						}

						if (value.data === 'competitors_level') {
							value.title = 'Competitors' + '<br>' + 'Level';
						}

						if (value.data === 'competitors_routing') {
							value.title = 'Competitors' + '<br>' + 'Routing';
						}
					}

					if (
						value.data === 'time_of_day_start' ||
						value.data === 'time_of_day_end' ||
						value.data === 'competitors_time_of_day_start' ||
						value.data === 'competitors_time_of_day_end' ||
						value.data === 'dow'
					) {
						value.type = 'text';
					}
					this.tableNameColumns.push(value.title);
					this.tableColumns.push(value);
					this.validationList.push(value.validations);
				}
				for (const value of this.tableDetail.data.values()) {
					this.tableData.push(value);
				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	//Guardar nuevo parámetro
	postNewParameter(parameter: Parameter) {
		//this.tableDetail = [];
		console.log('PARAMETER TO SAVE', parameter);
		this.ListService.postNewParameter(parameter).subscribe(
			(res) => {
				this.openSnackBarCorrect('Parameter has been successfully saved!');
				this.parameterName = null;
				this.tableDetail = [];
				this.parameterComp.refreshTableNew(res);
				this.httpSpinnerService.nextState$(false);
			},
			(err) => {
				if (err.status === 400) {
					this.sendSnackbar(err);
					this.httpSpinnerService.nextState$(false);
					console.log('DATA AL FALLAR VALIDACION: ', this.tableData);
					console.log('HANDSONTABLE DESPUES DE GUARDAR', this.hotRegisterer.getInstance(this.id).getData());

					this.tableDetail = this.tableData;
					console.error(err);
				} else {
					this.openSnackBarIncorrect('Error! Unable to save parameter');
					this.parameterName = null;
					this.tableDetail = [];
					console.error(err);
					this.httpSpinnerService.nextState$(false);
				}
			}
		);
	}

	sendSnackbar(errMensaje) {
		this.sendSnackbarActive = true;
		this.snackBar.openFromComponent(SnackbarParameter, {
			data: errMensaje,
			panelClass: ['snackValidation']
		});
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackIncorrect']
		});
	}

	selectAllCells() {
		const hotInstance = this.hotRegisterer.getInstance(this.id);
		hotInstance.selectCells([[0, 0, hotInstance.countRows() - 1, hotInstance.countCols() - 1]], true, true);
	}

	downloadCsv() {
		const hot = this.hotRegisterer.getInstance(this.id).getPlugin('exportFile');
		hot.downloadFile('csv', {
			bom: true,
			columnDelimiter: ',',
			columnHeaders: true,
			exportHiddenColumns: true,
			exportHiddenRows: true,
			fileExtension: 'csv',
			filename: 'Strategies_[YYYY]-[MM]-[DD]',
			mimeType: 'text/csv',
			rowDelimiter: '\r\n',
			rowHeaders: true
		});
	}

	ngOnDestroy() {
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}
}
