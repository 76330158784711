import { createRowTod, RowTod } from './RowTod';

export class TodStartEnd {
	constructor(public start: string, public end: string) {}
}

export function copyGridTod<T>(grid: GridTod<T>): GridTod<T> {
	const rows_copy: Array<RowTod<T>> = [];

	for (let row of grid.rows) {
		const row_copy = new RowTod(
			row.tod_start,
			row.tod_end,
			row.mon_data,
			row.tue_data,
			row.wed_data,
			row.thu_data,
			row.fri_data,
			row.sat_data,
			row.sun_data
		);

		rows_copy.push(row_copy);
	}

	return new GridTod(grid.emptyT, rows_copy);
}

/*
 * junta 2 grillas.
 */
export function zip2GridTod<T1, T2, T3>(
	grid1: GridTod<T1>,
	grid2: GridTod<T2>,
	f: (start: string, end: string, dow: number, value1: T1, value2: T2) => T3
): Array<T3> {
	function validate() {
		const msg = 'Incompatible ranges: ';

		const num_rows = grid1.rows.length;

		if (num_rows != grid2.rows.length) throw Error(msg + 'lenght1 vs lenght2');

		for (let i = 0; i < num_rows; i++) {
			const start1 = grid1.rows[i].tod_start;
			const start2 = grid2.rows[i].tod_start;

			if (start1 !== start2) {
				throw Error(msg + start1 + ' vs ' + start2);
			}
		}
	}

	function appendValue(arr: Array<T3>, tod_start: string, tod_end: string, dow: number, value1: T1, value2: T2): void {
		if (value1 !== grid1.emptyT && value2 !== grid2.emptyT) {
			arr.push(f(tod_start, tod_end, dow, value1, value2));
		}
	}

	function appendRow(arr: Array<T3>, row1: RowTod<T1>, row2: RowTod<T2>): void {
		appendValue(arr, row1.tod_start, row1.tod_end, 1, row1.mon_data, row2.mon_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 2, row1.tue_data, row2.tue_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 3, row1.wed_data, row2.wed_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 4, row1.thu_data, row2.thu_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 5, row1.fri_data, row2.fri_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 6, row1.sat_data, row2.sat_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 7, row1.sun_data, row2.sun_data);
	}

	validate();

	const num_rows = grid1.rows.length;
	const arr: Array<T3> = [];

	for (let i = 0; i < num_rows; i++) {
		appendRow(arr, grid1.rows[i], grid2.rows[i]);
	}

	return arr;
}

/*
 * junta 3 grillas.
 *
 * la grilla de cluster deberia ser la primera y luego 2 grilas de indicadores
 */
export function zipGridTod<T1, T2, T3, T4>(
	grid1: GridTod<T1>,
	grid2: GridTod<T2>,
	grid3: GridTod<T3>,
	f: (start: string, end: string, dow: number, value1: T1, value2: T2, value3: T3) => T4
): Array<T4> {
	function validate() {
		const msg = 'Incompatible ranges: ';

		const num_rows = grid1.rows.length;

		if (num_rows != grid2.rows.length) throw Error(msg + 'lenght1 vs lenght2');

		if (num_rows != grid3.rows.length) throw Error(msg + 'lenght1 vs lenght3');

		for (let i = 0; i < num_rows; i++) {
			const start1 = grid1.rows[i].tod_start;
			const start2 = grid2.rows[i].tod_start;
			const start3 = grid3.rows[i].tod_start;

			if (start1 !== start2) {
				throw Error(msg + start1 + ' vs ' + start2);
			}

			if (start1 !== start3) {
				throw Error(msg + start1 + ' vs ' + start3);
			}
		}
	}

	function appendValue(
		arr: Array<T4>,
		tod_start: string,
		tod_end: string,
		dow: number,
		value1: T1,
		value2: T2,
		value3: T3
	): void {
		if (value1 !== grid1.emptyT && value2 !== grid2.emptyT && value3 !== grid3.emptyT) {
			arr.push(f(tod_start, tod_end, dow, value1, value2, value3));
		}
	}

	function appendRow(arr: Array<T4>, row1: RowTod<T1>, row2: RowTod<T2>, row3: RowTod<T3>): void {
		appendValue(arr, row1.tod_start, row1.tod_end, 1, row1.mon_data, row2.mon_data, row3.mon_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 2, row1.tue_data, row2.tue_data, row3.tue_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 3, row1.wed_data, row2.wed_data, row3.wed_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 4, row1.thu_data, row2.thu_data, row3.thu_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 5, row1.fri_data, row2.fri_data, row3.fri_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 6, row1.sat_data, row2.sat_data, row3.sat_data);
		appendValue(arr, row1.tod_start, row1.tod_end, 7, row1.sun_data, row2.sun_data, row3.sun_data);
	}

	validate();

	const num_rows = grid1.rows.length;
	const arr: Array<T4> = [];

	for (let i = 0; i < num_rows; i++) {
		appendRow(arr, grid1.rows[i], grid2.rows[i], grid3.rows[i]);
	}

	return arr;
}

/*
 * tods: contiene los cortes como number [1000, 1400, 1800]
 * emptyT: es el valor NA (Not Available).  Por ej, NaN para un number, "" para un string (no usar NULL o undefined)
 */
export function createGridTod<T>(tods: Array<number>, emptyT: T): GridTod<T> {
	const tods_start_end = convertTods(tods);

	return createGridTodFromStartEnd(tods_start_end, emptyT);
}

/*
 * 1234 -> 12:34
 */
export function formatEdge(n: number): string {
	const sz = '0000' + n.toString();
	const hours = sz.substring(sz.length - 4, sz.length - 2);
	const minutes = sz.substring(sz.length - 2, sz.length);

	return hours + ':' + minutes;
}

export function oneSecondLess(n): number {
	const minutes = n % 100;
	const hours = Math.floor(n / 100);

	return minutes == 0 ? (hours - 1) * 100 + 59 : hours * 100 + minutes - 1;
}

/*
 * [1000, 1400, 1800] -> [ (00:00 - 09:59), (10:00 - 13:59), (14:00 - 17-59), (18:00 - 23:59) ]
 */
export function convertTods(tods: Array<number>): Array<TodStartEnd> {
	const rows: Array<TodStartEnd> = [];

	const starts = Array('00:00').concat(tods.map((n) => formatEdge(n)));
	const ends = tods.map((n) => formatEdge(oneSecondLess(n))).concat(Array('23:59'));

	const ret = starts.map((start, i) => new TodStartEnd(start, ends[i]));

	return ret;
}

/*
 *
 */
export function createGridTodFromStartEnd<T>(tods: Array<TodStartEnd>, emptyT: T): GridTod<T> {
	const rows: Array<RowTod<T>> = [];

	for (let tod of tods) {
		const row = createRowTod(tod.start, tod.end, emptyT);
		rows.push(row);
	}

	const grid = new GridTod<T>(emptyT, rows);

	return grid;
}

export class GridTod<T> {
	constructor(public emptyT: T, public rows: Array<RowTod<T>>) {}

	/*
	 * para ser llamado desde HandsomeTable (HT), dow_attr es el nombre de la columna dentro de la grilla HT
	 */
	updateValue(tod_offset: number, dow_attr: string, _value: T) {
		let value = _value;
		if (_value == null) {
			value = this.emptyT;
		}

		const row = this.rows[tod_offset];
		row.updateValue(dow_attr, value);
	}

	getValue(tod_offset: number, dow_attr: string): T {
		const row = this.rows[tod_offset];
		return row.getValue(dow_attr);
	}

	/*
	 *
	 */
	map<S>(f: (tod_start: string, tod_end: string, dow: number, t: T) => S): Array<S> {
		function isDefined(t: T, thisEmptyT: T): boolean {
			if (typeof t === 'undefined') return false;

			if (t === null) return false;

			if (Number.isNaN(thisEmptyT as unknown as number) && Number.isNaN(t as unknown as number)) {
				return false;
			}

			if (t === thisEmptyT) {
				return false;
			}

			return true;
		}

		function appendValue(
			arr: Array<S>,
			tod_start: string,
			tod_end: string,
			dow: number,
			value: T,
			thisEmptyT: T
		): void {
			if (isDefined(value, thisEmptyT)) {
				arr.push(f(tod_start, tod_end, dow, value));
			}
		}

		function appendRow(arr: Array<S>, row: RowTod<T>, thisEmptyT: T) {
			appendValue(arr, row.tod_start, row.tod_end, 1, row.mon_data, thisEmptyT);
			appendValue(arr, row.tod_start, row.tod_end, 2, row.tue_data, thisEmptyT);
			appendValue(arr, row.tod_start, row.tod_end, 3, row.wed_data, thisEmptyT);
			appendValue(arr, row.tod_start, row.tod_end, 4, row.thu_data, thisEmptyT);
			appendValue(arr, row.tod_start, row.tod_end, 5, row.fri_data, thisEmptyT);
			appendValue(arr, row.tod_start, row.tod_end, 6, row.sat_data, thisEmptyT);
			appendValue(arr, row.tod_start, row.tod_end, 7, row.sun_data, thisEmptyT);
		}

		const arr: Array<S> = [];

		const num_rows = this.rows.length;

		for (let i = 0; i < num_rows; i++) {
			appendRow(arr, this.rows[i], this.emptyT);
		}

		return arr;
	}

	/*
	 *
	 */
	toArray(): Array<T> {
		function valueT(tod_start: string, tod_end: string, dow: number, t: T): T {
			return t;
		}

		return this.map(valueT);
	}

	minMax(): [number, number] {
		if (typeof this.emptyT === 'number' && this.rows.length > 0) {
			let minValue = Number.MAX_VALUE;
			let maxValue = Number.MIN_VALUE;

			for (let row of this.rows) {
				let mon = row.mon_data as unknown as number;
				let tue = row.tue_data as unknown as number;
				let wed = row.wed_data as unknown as number;
				let thu = row.thu_data as unknown as number;
				let fri = row.fri_data as unknown as number;
				let sat = row.sat_data as unknown as number;
				let sun = row.sun_data as unknown as number;

				if (minValue > mon) minValue = mon;
				if (minValue > tue) minValue = tue;
				if (minValue > wed) minValue = wed;
				if (minValue > thu) minValue = thu;
				if (minValue > fri) minValue = fri;
				if (minValue > sat) minValue = sat;
				if (minValue > sun) minValue = sun;

				if (maxValue < mon) maxValue = mon;
				if (maxValue < tue) maxValue = tue;
				if (maxValue < wed) maxValue = wed;
				if (maxValue < thu) maxValue = thu;
				if (maxValue < fri) maxValue = fri;
				if (maxValue < sat) maxValue = sat;
				if (maxValue < sun) maxValue = sun;
			}

			return [minValue, maxValue];
		} else return [NaN, NaN];
	}
}
