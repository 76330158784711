import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { ConfigService } from '../../../../../config/config.service';

interface HTColumn {
	title: string;
	readOnly: boolean;
}

const column_load_lf: HTColumn = {
	title: ' ',
	readOnly: true
};

const TargetLfInputs = {
	clusters: 'clusters'
};

@Component({
	selector: 'app-target-lf',
	templateUrl: './target-lf.component.html',
	styleUrls: ['./target-lf.component.scss']
})
export class TargetLfComponent {
	id = 'hot-instance-target-lf';
	private hotRegisterer = new HotTableRegisterer();
	private hotInstance: Handsontable;

	@Input() columns_width: Array<number> = [];

	@Output() dataTargetOut = new EventEmitter();

	columns: Array<HTColumn> = [];

	data_lf: Array<Array<any>> = [];

	stretchH = 'none';

	clusters: Array<number> = [];
	@Input(TargetLfInputs.clusters) set setClusters(data: Array<number>) {
		this.clusters = data;
		this.columns = [];
		this.columns.push(column_load_lf);

		const data_row = [];
		data_row.push('Target out');
		if (Array.isArray(this.data_lf) && !this.data_lf.length) {
			for (const cluster of this.clusters) {
				this.columns.push({ title: 'C' + cluster, readOnly: false });
				data_row.push('');
			}
		} else {
			for (let i = 0; i < this.clusters.length; i++) {
				this.columns.push({ title: 'C' + this.clusters[i], readOnly: false });
				data_row.push(this.data_lf[0][i + 1]);
			}
		}
		this.data_lf = [];
		this.data_lf.push(data_row);
		this.dataTargetOut.emit(this.data_lf[0]);
	}

	tableSettings(): Handsontable.GridSettings {
		return {
			licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
			contextMenu: false,
			dropdownMenu: false,
			language: 'es-MX',
			className: 'htCenter',
			filters: false,
			columnSorting: false,
			allowInsertRow: false,
			viewportColumnRenderingOffset: 1000,
			colWidths: this.columns_width,
			afterChange: (changes, source) => {
				if (source === 'edit') {
					changes?.forEach(([row, prop, oldValue, newValue]) => {
						const newValueNumeric = parseFloat(newValue);
						prop = this.hotInstance.propToCol(prop as string);
						if (!isNaN(newValueNumeric)) {
							this.data_lf[0][prop] = newValueNumeric;
							this.dataTargetOut.emit(this.data_lf[0]);
						} else {
							this.data_lf[0][prop] = oldValue;
						}

						// this.hotInstance.loadData(this.data_lf);
						this.hotInstance.render();
					});
				}
			}
		};
	}

	constructor(private changeDetectorRef: ChangeDetectorRef, private configService: ConfigService) {}

	// callback de hot
	public onHotAfterInit = (): void => {
		this.hotInstance = this.hotRegisterer.getInstance(this.id);
	};

	isReady(): boolean {
		return this.clusters.length > 0 && this.columns_width.length > 0;
	}
}
