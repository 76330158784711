export const environment = {
	production: false
};

export const bff = {
	protocol: 'https://',
	host: '',
	port: '',
	getCategory: '/api/bff/filter/product-categories/',
	getBusiness: '/api/bff/filter/business-units/',
	getProduct: '/api/bff/filter/products/',
	getCabins: '/api/bff/filter/cabins',
	getLoadTable: '/api/bff/parameters/list/',
	getTable: '/api/bff/parameters/detail/',
	postNewParameter: '/api/bff/parameters/save',
	putEditParameter: '/api/bff/parameters/update',
	emailValidation: '',
	tokenValidation: '/api/bff/authorization',
	getLinksList: '/api/bff/links/list/',
	getEditLinks: '/api/bff/filter/routes/',
	getDataLinks: '/api/bff/links/detail/',
	getDataSource: '/api/bff/parameters/list-parameter-links/',
	putEditLinks: '/api/bff/links/update',
	postNewLinks: '/api/bff/links/save',
	dataClusters: '/api/bff/filter/clusters/',
	dataSeason: '/api/bff/filter/seasons/',
	deleteParameter: '/api/bff/parameters/delete/',

	getOneTimeCabis: '/one-time/bff/filter/cabins',
	getElementsAndLevels: '/one-time/bff/filter/levels-elements',
	getKPI: '/one-time/bff/kpis',
	postNewSession: '/one-time/bff/session/save',
	getSeasonsByParams: '/one-time/bff/season/find-by-params/',
	getBaselinesByParams: '/one-time/bff/baseline/find-by-params/',
	getKpiAskPorSeason: '/one-time/bff/season/find-ask',
	getModelOutputsByParams: '/one-time/bff/season-calculate/find-seasons',
	saveSeasons: '/one-time/bff/season/saveSeasonSessionProgress',
	getSeasonsMenu: '/one-time/bff/season/menu-find-by-params/',
	getCluster: '/one-time/bff/cluster/find-by-params/',
	getIndicators: '/one-time/bff/cluster/get-indicators',
	getModelOutputCluster: '/one-time/bff/cluster/calculate/',
	updateKpi: '/one-time/bff/cluster/updateKpi',
	getUserSessions: '/one-time/bff/session/user',
	getSaveClusters: '/one-time/bff/cluster/saveClusterProgress',
	deleteSessionWork: '/one-time/bff/session/delete/',
	getCommitSession: '/one-time/bff/session/commitSession',
	getMenuLateral: '/one-time/bff/sidebarparameter',

	// Target Curves Endpoints
	getTargetCurves: '/one-time/bff/targetcurve/target-curve',
	getTargetCurveBaseline: '/one-time/bff/targetcurve/baseline-booking',
	getTargetCurveProductive: '/one-time/bff/targetcurve/target-curve-productive',
	getTargetCurveModelOutput: '/one-time/bff/targetcurve/calculate',
	getApplyLoadFactorOut: '/one-time/bff/targetcurve/applyLoadFactorOut',
	getAskIndicators: '/one-time/bff/targetcurve/ask',
	smoothCurves: '/one-time/bff/targetcurve/smoothCurves',
	saveProgressTargetCurves: '/one-time/bff/targetcurve/saveTargetCurveSessionProgress',
	refreshTarget: '/one-time/bff/targetcurve/refresh',
	refreshTargetCurves: '/one-time/bff/targetcurve/refreshTargetCurves',
	// Buckets endpoints
	getBuckets: '/one-time/bff/bucket/get-buckets',
	getBucketSummary: '/one-time/bff/bucket/get-bucket-summary',
	getClusterPax: '/one-time/bff/bucket/get-cluster-pax',
	getBaselineBuckets: '/one-time/bff/bucket/get-bucket-baseline',
	getModelOutputBuckets: '/one-time/bff/bucket/get-bucket-calculate',
	getBucketKpi: '/one-time/bff/bucket/get-bucket-kpi',
	getGraphBuckets: '/one-time/bff/bucket/get-bucket-plots',
	saveBucketProgress: '/one-time/bff/bucket/saveBucketSessionProgress',
	updateBPandAF: '/one-time/bff/bucket/update-bp-average-fare',
	refreshBuckets: '/one-time/bff/bucket/refresh'
};

export const configProperties = {
	configPath: 'assets/config/app.config.json'
};
