import { Component } from '@angular/core';

@Component({
	template: `
		<div>
			<img src="assetsgifloading.gif" />
			<img src="../../../../assets/images/loading.svg" alt="Rem Latam" />
		</div>
	`
})
export class BlockUiTemplateComponent {}
