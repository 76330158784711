import { Component, EventEmitter, Input, Output } from '@angular/core';
import Handsontable from 'handsontable';

import { GridTod } from 'src/app/models/clusters/GridTod';

@Component({
	selector: 'app-grid-outbound-inbound',
	templateUrl: './grid-outbound-inbound.component.html',
	styleUrls: ['./grid-outbound-inbound.component.scss']
})
export class GridOutboundInboundComponent {
	@Input() title: string = '';
	@Input() element_outbound: string = '';
	@Input() element_inbound: string = '';
	@Input() data_grid_outbound: GridTod<string | number>;
	@Input() data_grid_inbound: GridTod<string | number>;

	// para las grillas editables (clusters)
	@Input() data_grid_raw_outbound: GridTod<string | number> | undefined;
	@Input() data_grid_raw_inbound: GridTod<string | number> | undefined;
	@Input() afterChange: (
		data_grid: GridTod<string | number>,
		data_grid_raw: GridTod<string | number>,
		changes: Handsontable.CellChange[],
		source: Handsontable.ChangeSource
	) => void;

	@Input() readOnly = true;

	@Input() rendererGrid: (
		instance: Handsontable,
		TD: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridTod<string | number>,
		data_grid_raw: GridTod<string | number> | undefined
	) => void;

	@Input() keyDownCellOutbound: (event: KeyboardEvent) => void;
	@Input() keyDownCellInbound: (event: KeyboardEvent) => void;

	@Output() hotInstanceTableOut = new EventEmitter<Handsontable>();
	@Output() hotInstanceTableIn = new EventEmitter<Handsontable>();

	get getTitleOutbound(): string {
		return 'Outbound - ' + this.element_outbound;
	}

	get getTitleInbound(): string {
		return 'Inbound - ' + this.element_inbound;
	}

	constructor() {}

	updateInstanceOutbound(instance: Handsontable): void {
		this.hotInstanceTableOut.emit(instance);
	}

	updateInstanceInbound(instance: Handsontable): void {
		this.hotInstanceTableIn.emit(instance);
	}
}
