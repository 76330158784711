import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { bff } from 'src/environments/environment';

/*
export type DataClusterModelOutput = Bounds <OneClusterModelOutput> ;

export interface OneClusterModelOutput {

} ;
*/

export interface SeasonByParams {
	elementCode: string;
	cabinCode: string;
	initDate: string;
	endDate: string;
	sessionId?: number;
}

@Injectable({
	providedIn: 'root'
})
export class ClustersService {
	private host = `${bff.host}`;

	private ENDPOINT_GET_SEASONS_PARAMS = `${this.host}${bff.getSeasonsMenu}`;

	constructor(private http: HttpClient) {}

	getSeasonsByParams(seasonsParams: SeasonByParams): Observable<any> {
		let queryParams: HttpParams;

		if (seasonsParams.sessionId) {
			queryParams = new HttpParams()
				.set('cabinCode', seasonsParams.cabinCode)
				.set('elementCode', seasonsParams.elementCode)
				.set('initDate', seasonsParams.initDate)
				.set('endDate', seasonsParams.endDate)
				.set('sessionId', seasonsParams.sessionId.toString());
		} else {
			queryParams = new HttpParams()
				.set('cabinCode', seasonsParams.cabinCode)
				.set('elementCode', seasonsParams.elementCode)
				.set('initDate', seasonsParams.initDate)
				.set('endDate', seasonsParams.endDate);
		}

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			}),
			params: queryParams
		};

		return this.http.get(this.ENDPOINT_GET_SEASONS_PARAMS, httpOptions);
	}
}
