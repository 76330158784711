<div class="parameters">
	<div class="second">
		<form (ngSubmit)="apply()" name="loadform" #loadTableP="ngForm">
			<div class="rowPropio">
				<div class="elementoFiltro">
					<mat-checkbox [formControl]="outbound">Outbound</mat-checkbox>
				</div>
				<div class="elementoFiltro">
					<mat-checkbox [formControl]="inbound">Inbound</mat-checkbox>
				</div>
				<div class="elementoFiltro">
					<mat-form-field appearance="fill">
						<mat-label>Start month</mat-label>
						<input matInput [matDatepicker]="dp" [formControl]="date" [min]="rangoIni" [max]="rangoEnd" />
						<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
						<mat-datepicker
							startView="multi-year"
							#dp
							(yearSelected)="chosenYearHandler($event)"
							(monthSelected)="chosenMonthHandler($event, dp)"
							panelClass="example-month-picker"
						></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="elementoFiltro">
					<mat-form-field appearance="fill">
						<mat-label>End month</mat-label>
						<input matInput [matDatepicker]="dp2" [formControl]="date2" [min]="rangoIni" [max]="rangoEnd" />
						<mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
						<mat-datepicker
							startView="multi-year"
							#dp2
							(yearSelected)="chosenYearHandler2($event)"
							(monthSelected)="chosenMonthHandler2($event, dp2)"
							panelClass="example-month-picker"
						></mat-datepicker>
					</mat-form-field>
				</div>

				<div class="elementoFiltro">
					<button type="submit" class="btn-search">Filter</button>
				</div>
			</div>
		</form>
	</div>
</div>
