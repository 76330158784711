import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { GridTod } from 'src/app/models/clusters/GridTod';
import { ConfigService } from '../../../../../config/config.service';

const columns_grid_tod_dow = [
	{
		data: 'tod_start',
		title: 'Start',
		readOnly: true
	},
	{
		data: 'tod_end',
		title: 'End',
		readOnly: true
	},
	{
		data: 'mon_data',
		title: '1'
	},
	{
		data: 'tue_data',
		title: '2'
	},
	{
		data: 'wed_data',
		title: '3'
	},
	{
		data: 'thu_data',
		title: '4'
	},
	{
		data: 'fri_data',
		title: '5'
	},
	{
		data: 'sat_data',
		title: '6'
	},
	{
		data: 'sun_data',
		title: '7'
	}
];

function addRenderer(data: string, title: string, readOnly: boolean, renderer: any) {
	return {
		data: data,
		title: title,
		readOnly: readOnly,
		renderer: renderer
	};
}

let id_tod_dow = 0;

@Component({
	selector: 'app-grid-tod-dow',
	templateUrl: './grid-tod-dow.component.html',
	styleUrls: ['./grid-tod-dow.component.scss']
})
export class GridTodDowComponent implements OnInit /* , AfterViewInit */ {
	@Input() title: string = '';

	@Input() data_grid: GridTod<string | number>;

	@Input() data_grid_raw: GridTod<string | number> | undefined;

	@Input() readOnly = true;

	@Input() stretchH = 'all';

	@Input() afterChange: (
		data_grid: GridTod<string | number>,
		data_grid_raw: GridTod<string | number>,
		changes: Handsontable.CellChange[],
		source: Handsontable.ChangeSource
	) => void;

	/*
	 * firma tomada desde los fuentes de Handsontable:
	 *
	 *   https://github.com/handsontable/handsontable/blob/b0a09bde9a9d4164bee4f28cd047fa843f42a7a9/handsontable.d.ts#L1510
	 *
	 * a la funcion de handsontable se agrega 2 parametros:
	 * - data_grid: la data de la grilla que se desplega,
	 * - data_grid_raw: la data de la grilla "original", para saber si el usuario hizo un cambio
	 */

	@Input() rendererGrid: (
		instance: Handsontable,
		TD: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties,
		data_grid: GridTod<string | number>,
		data_grid_raw: GridTod<string | number> | undefined
	) => void;

	@Input() keyDownCell: (event: KeyboardEvent) => void;
	@Output() hotInstanceTable = new EventEmitter<Handsontable>();

	columns = columns_grid_tod_dow;

	private hotRegisterer = new HotTableRegisterer();
	id = 'hot-instance-' + id_tod_dow++;
	private hotInstance: Handsontable;

	public onAfterInit = (): void => {
		this.hotInstance = this.hotRegisterer.getInstance(this.id);
		this.hotInstanceTable.emit(this.hotInstance);
	};

	// Configuraciones Handsontable
	tableSettings: Handsontable.GridSettings = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		contextMenu: false,
		dropdownMenu: false,
		language: 'es-MX',
		className: 'htCenter',
		filters: false,
		columnSorting: false,
		allowInsertRow: false,
		viewportColumnRenderingOffset: 1000,
		colWidths: [34, 34, 33, 33, 33, 33, 33, 33, 33, 33]
		// colWidths: [26,26,18,18,18,18,18,18,18,18]
	};

	constructor(private configService: ConfigService) {}

	ngOnInit() {
		this.reconfigureRenderer();

		if (this.readOnly === false) {
			if (typeof this.data_grid_raw === 'undefined') {
				throw Error('For editable grids, data_grid_raw should be defined');
			}

			this.tableSettings.afterChange = (changes: Handsontable.CellChange[], source: Handsontable.ChangeSource) => {
				if (typeof this.afterChange !== 'undefined') {
					this.afterChange(this.data_grid, this.data_grid_raw, changes, source);
					this.reconfigureRenderer();
				}
			};
		}
	}

	reconfigureRenderer() {
		this.columns = columns_grid_tod_dow.map((col) =>
			addRenderer(
				col.data,
				col.title,
				col.readOnly === true ? true : this.readOnly,
				// this.rendererGrid
				this.callRendererGrid
			)
		);
	}

	callRendererGrid = (
		instance: Handsontable,
		td: HTMLTableCellElement,
		row: number,
		col: number,
		prop: string | number,
		value: Handsontable.CellValue,
		cellProperties: Handsontable.CellProperties
	) => {
		if (typeof this.rendererGrid !== 'undefined')
			this.rendererGrid(instance, td, row, col, prop, value, cellProperties, this.data_grid, this.data_grid_raw);
		else Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
	};
}
