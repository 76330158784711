import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuSeasonsComponent } from './menu-seasons/menu-seasons.component';
import { AsksPorSeasonComponent } from './asks-por-season/asks-por-season.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotTableModule } from '@handsontable/angular';
import { registerLanguageDictionary, esMX } from 'handsontable/i18n';

registerLanguageDictionary(esMX);

@NgModule({
	declarations: [MenuSeasonsComponent, AsksPorSeasonComponent],
	imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, HotTableModule.forRoot()],
	exports: [MenuSeasonsComponent, AsksPorSeasonComponent]
})
export class SeasonsModule {}
