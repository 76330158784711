<div>
	<div class="title-indicadores border-card">
		<div class="indicador" *ngFor="let cluster_color of clusters_colors">
			<div class="cluster-name">{{ cluster_color.cluster }}</div>
			<div class="material-icons" [style.color]="cluster_color.color">radio_button_checked</div>
		</div>
	</div>

	<div class="plots-grid">
		<div *ngFor="let arrayPoints of bucketsDataGraph; index as i" class="plot-container border-card">
			<h3 class="title-plot">{{ titlePlot[i].name }}</h3>
			<!-- [grafType]="i" -->
			<app-buckets-scatterplot
				[data]="arrayPoints"
				[clusterList]="clustersUnicos"
				[titles]="titlePlot[i]"
			></app-buckets-scatterplot>
		</div>
	</div>
</div>
