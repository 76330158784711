import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotTableModule } from '@handsontable/angular';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CargaTargetCurvesComponent } from './carga-target-curves.component';
import { GridApClustersComponent } from './grid-ap-clusters/grid-ap-clusters.component';
import { TargetLfComponent } from './target-lf/target-lf.component';

@NgModule({
	declarations: [CargaTargetCurvesComponent, GridApClustersComponent, TargetLfComponent],
	imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, MaterialModule, HotTableModule.forRoot()],
	exports: [CargaTargetCurvesComponent, GridApClustersComponent]
})
export class CargaTargetCurvesModule {}
