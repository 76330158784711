import * as d3 from 'd3';

export module D3ClusterElbowSilhouetteRenderer {
	export class ElbowSilhouettePoint {
		constructor(public cluster: string, public elbow: number, public silhouette: number) {}
	}

	class Margin {
		constructor(public top: number, public right: number, public bottom: number, public left: number) {}
	}

	// TODO: transformar en una rutina utilitaria para todos los scatterplot
	function minMax(values: Array<number>): [number, number] {
		let min = Number.MAX_VALUE;
		let max = Number.MIN_VALUE;

		values.forEach((value) => {
			if (value < min) {
				min = value;
			}
			if (value > max) {
				max = value;
			}
		});

		const diff = max - min;

		const tick = diff / 10.0;

		return [min - tick, max + tick];
	}

	export function plot(points: Array<ElbowSilhouettePoint>, placeholder: string, divWidth: number, divHeight: number) {
		console.log('Elbow / silhouette plot');

		const div = d3.select('#' + placeholder);

		const margin = new Margin(40, 50, 50, 50);
		const width = divWidth - margin.left - margin.right;
		const height = divHeight - margin.top - margin.bottom;

		div.select('svg').remove();

		const svgtop = div
			.append('svg')
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom);

		svgtop
			.append('rect')
			.attr('x', 0)
			.attr('width', divWidth)
			.attr('y', 0)
			.attr('height', divHeight)
			.attr('fill', 'none')
			.attr('stroke', 'none');

		const svg = svgtop.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		// eje X
		const valuesX = points.map(function (point) {
			return parseInt(point.cluster);
		});
		const domainX = minMax(valuesX);

		const x = d3.scaleLinear().domain(domainX).range([0, width]);

		svg
			.append('g')
			.attr('transform', 'translate(0,' + height + ')')
			.call(d3.axisBottom(x));

		// eje Y1
		const valuesY1 = points.map((point) => point.elbow);
		const domainY1 = minMax(valuesY1);

		const y1 = d3.scaleLinear().domain(domainY1).range([height, 0]);

		svg.append('g').call(d3.axisLeft(y1));

		// eje Y2
		const valuesY2 = points.map((point) => point.silhouette);
		const domainY2 = minMax(valuesY2);

		const y2 = d3.scaleLinear().domain(domainY2).range([height, 0]);

		svg
			.append('g')
			.attr('transform', 'translate(' + width + ', 0)')
			.call(d3.axisRight(y2));

		// elbow line
		const color_elbow = 'red';

		const points_elbow = points.map((point) => [x(point.cluster), y1(point.elbow)]);

		const line_elbow = d3.line()(points_elbow);

		svg.append('path').attr('fill', 'none').attr('stroke', color_elbow).attr('stroke-width', 1.5).attr('d', line_elbow);

		// silhouette line
		const color_silhouette = 'blue';

		const points_silhouette = points.map((point) => [x(point.cluster), y2(point.silhouette)]);

		const line_silhouette = d3.line()(points_silhouette);

		svg
			.append('path')
			.attr('fill', 'none')
			.attr('stroke', color_silhouette)
			.attr('stroke-width', 1.5)
			.attr('d', line_silhouette);

		// POINTS
		const radius = 4;
		const fx = (d) => x(d.cluster);
		const fy1 = (d) => y1(d.elbow);
		const fy2 = (d) => y2(d.silhouette);

		svg
			.append('g')
			.selectAll('dot')
			.data(points)
			.enter()
			.append('circle')
			.attr('class', 'my_point')
			.attr('cx', fx)
			.attr('cy', fy1)
			.attr('r', radius)
			.attr('fill', color_elbow); //

		svg
			.append('g')
			.selectAll('dot')
			.data(points)
			.enter()
			.append('circle')
			.attr('class', 'my_point')
			.attr('cx', fx)
			.attr('cy', fy2)
			.attr('r', radius)
			.attr('fill', color_silhouette); //

		// Y axis label:
		svg
			.append('text')
			.attr('text-anchor', 'end')
			.attr('transform', 'rotate(-90)')
			.attr('y', divWidth - margin.right - 5)
			.attr('x', -height / 2)
			.attr('stroke', color_silhouette)
			.text('Silhouette');

		svg
			.append('text')
			.attr('text-anchor', 'end')
			.attr('transform', 'rotate(-90)')
			.attr('y', -margin.left + 15)
			.attr('x', -height / 2)
			.attr('stroke', color_elbow)
			.text('Elbow');

		// Add X axis label:
		svg
			.append('text')
			.attr('text-anchor', 'end')
			.attr('x', width / 2)
			.attr('y', height + margin.top)
			.text('Clusters');
	}
}
