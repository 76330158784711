<div class="principal">
	<div class="contengo">
		<mat-form-field class="input-new">
			<mat-label>Parameter name</mat-label>
			<input
				[(ngModel)]="parameterName"
				matInput
				placeholder="New parameter"
				onkeypress="return event.keyCode!=13"
				autocomplete="off"
			/>
		</mat-form-field>
		<div class="icon-buton">
			<button type="button" class="btn-select-all" (click)="selectAllCells()">
				<img src="../../../assets/icons/LATAM Icons/vector-cuadrilla.svg" alt="" />
			</button>
			<button type="button" class="btn-download" (click)="downloadCsv()">
				<img src="../../../assets/icons/LATAM Icons/vector.svg" alt="" />
			</button>
			<button
				type="button"
				class="btn-search"
				(click)="validateCellSave()"
				[disabled]="!isDisableSave || !parameterName"
			>
				Save
			</button>
		</div>
	</div>
	<div *ngIf="tableDetail" class="headersParameters">
		<hot-table [hotId]="id" [settings]="tableSettings" [data]="tableData" class="handsontable-custom"> </hot-table>
	</div>
</div>
