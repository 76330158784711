import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { filter, share, debounceTime } from 'rxjs/operators';

/**
 * Gets an objects property based on its path.
 * @param path Path to the property
 * @param object Object to access
 */
const getProperty = (path, object) => path.reduce((obj, p) => !!obj ? obj[p] : null, object);
/**
 * Sets an objects property based on its path.
 * @param path Path to the property
 * @param value Value to set
 * @param object Object whose value to set
 * @param falsyTransformer optional transformer handling falsy values
 */
const setProperty = (path, value, object, falsyTransformer) => {
  const lastKeyIndex = path.length - 1;
  for (let i = 0; i < lastKeyIndex; ++i) {
    const key = path[i];
    if (!(key in object)) {
      object[key] = {};
    }
    object = object[key];
  }
  object[path[lastKeyIndex]] = (!value || typeof value === 'string' && value === 'false') && !!falsyTransformer ? falsyTransformer() : value;
};
/**
 * Constructs the storage key based on a prefix - if given - and the key itself
 */
const constructKey = (key, prefix, configuredPrefix, delimiter) => {
  const prefixToUse = prefix || configuredPrefix;
  if (prefixToUse) {
    return `${prefixToUse}${delimiter}${key}`;
  }
  return key;
};
/**
 * The librarys default config.
 */
const defaultConfig = {
  allowNull: true,
  storage: localStorage,
  delimiter: '_'
};
/**
 * StorageSerializer Guard
 */
const isSerializer = prefixOrSerializer => {
  return !!prefixOrSerializer && prefixOrSerializer.serialize !== undefined;
};

/**
 * Provides a Promise based service to access the localstorage.
 */
class PromisableService {
  /**
   * Creates a new instance
   */
  constructor(configuration, defaultSerializer) {
    this.configuration = configuration;
    this.defaultSerializer = defaultSerializer;
    this.storage = this.configuration.storage;
  }
  /**
   * Gets the number of entries in the applications local storage.
   */
  count() {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.storage.length);
      } catch (error) {
        reject(error);
      }
    });
  }
  /**
   * Returns the nth (defined by the index parameter) key in the storage.
   * The order of keys is user-agent defined, so you should not rely on it.
   * @param index   An integer representing the number of the key you want to get the name of. This is a zero-based index.
   */
  getKey(index) {
    return new Promise((resolve, reject) => {
      if (index < 0) {
        reject(new Error('index has to be 0 or greater'));
      }
      try {
        resolve(this.storage.key(index));
      } catch (error) {
        reject(error);
      }
    });
  }
  set(key, value, prefixOrSerializer, serializer) {
    return new Promise((resolve, reject) => {
      try {
        const prefix = typeof prefixOrSerializer === 'string' ? prefixOrSerializer : undefined;
        serializer = isSerializer(prefixOrSerializer) ? prefixOrSerializer : !!serializer ? serializer : this.defaultSerializer;
        if (this.configuration.allowNull || !this.configuration.allowNull && value !== 'null' && value !== null && value !== undefined) {
          this.storage.setItem(constructKey(key, prefix, this.configuration.prefix, this.configuration.delimiter), serializer.serialize(value));
        } else {
          return this.remove(key, prefix);
        }
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
  get(key, prefixOrSerializer, serializer) {
    return new Promise((resolve, reject) => {
      try {
        const prefix = typeof prefixOrSerializer === 'string' ? prefixOrSerializer : undefined;
        serializer = isSerializer(prefixOrSerializer) ? prefixOrSerializer : !!serializer ? serializer : this.defaultSerializer;
        resolve(serializer.deserialize(this.storage.getItem(constructKey(key, prefix, this.configuration.prefix, this.configuration.delimiter))));
      } catch (error) {
        reject(error);
      }
    });
  }
  /**
   * Removes the entry specified by the given key.
   * @param key     Key identifying the entry to remove.
   * @param prefix  Optional prefix to overwrite the configured one.
   */
  remove(key, prefix) {
    return new Promise((resolve, reject) => {
      try {
        this.storage.removeItem(constructKey(key, prefix, this.configuration.prefix, this.configuration.delimiter));
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
  /**
   * Clears all entries of the applications local storage.
   */
  clear() {
    return new Promise((resolve, reject) => {
      try {
        this.storage.clear();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
}

/**
 * Provides an injection token for the service configuration.
 */
const NGX_LOCAL_STORAGE_CONFIG = new InjectionToken('NgxLocalstorageConfiguration');

/**
 * Provides an injection token for the services serializer.
 */
const NGX_LOCAL_STORAGE_SERIALIZER = new InjectionToken('StorageSerializer');

/**
 * Provides a service to access the localstorage.
 */
class LocalStorageService {
  /**
   * Creates a new instance.
   */
  constructor(defaultSerializer, config) {
    this.defaultSerializer = defaultSerializer;
    this.config = config;
    this.config = {
      ...defaultConfig,
      ...config
    };
    this.storage = this.config.storage;
    this.promisable = new PromisableService(this.config, this.defaultSerializer);
  }
  /**
   * Returns a service variant based on Promises.
   */
  asPromisable() {
    return this.promisable;
  }
  /**
   * Gets the number of entries in the applications local storage.
   */
  count() {
    try {
      return this.storage.length;
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * Returns the nth (defined by the index parameter) key in the storage.
   * The order of keys is user-agent defined, so you should not rely on it.
   * @param index   An integer representing the number of the key you want to get the name of. This is a zero-based index.
   */
  getKey(index) {
    if (index < 0) {
      console.error(new Error('index has to be 0 or greater'));
    }
    try {
      return this.storage.key(index);
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * Adds the value with the given key or updates an existing entry.
   * @param key     Key to store.
   * @param value   Value to store.
   * @param prefixOrSerializer  Optional prefix or serializer to overwrite the configured one.
   * @param serializer  Optional serilizer.
   */
  set(key, value, prefixOrSerializer, serializer) {
    const prefix = typeof prefixOrSerializer === 'string' ? prefixOrSerializer : undefined;
    serializer = isSerializer(prefixOrSerializer) ? prefixOrSerializer : !!serializer ? serializer : this.defaultSerializer;
    if (this.config.allowNull || !this.config.allowNull && value !== 'null' && value !== null && value !== undefined) {
      this.storage.setItem(constructKey(key, prefix, this.config.prefix, this.config.delimiter), serializer.serialize(value));
    } else {
      this.remove(key, prefix);
    }
  }
  /**
   * Gets the entry specified by the given key or null.
   * @param key     Key identifying the wanted entry.
   * @param prefixOrSerializer  Optional prefix or serializer to overwrite the configured one.
   * @param serializer  Optional serilizer.
   */
  get(key, prefixOrSerializer, serializer) {
    const prefix = typeof prefixOrSerializer === 'string' ? prefixOrSerializer : undefined;
    serializer = isSerializer(prefixOrSerializer) ? prefixOrSerializer : !!serializer ? serializer : this.defaultSerializer;
    try {
      return serializer.deserialize(this.storage.getItem(constructKey(key, prefix, this.config.prefix, this.config.delimiter)));
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * Removes the entry specified by the given key.
   * @param key     Key identifying the entry to remove.
   * @param prefix  Optional prefix to overwrite the configured one.
   */
  remove(key, prefix) {
    try {
      this.storage.removeItem(constructKey(key, prefix, this.config.prefix, this.config.delimiter));
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * Clears all entries of the applications local storage.
   */
  clear() {
    try {
      this.storage.clear();
    } catch (error) {
      console.error(error);
    }
  }
}
LocalStorageService.ɵfac = function LocalStorageService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LocalStorageService)(i0.ɵɵinject(NGX_LOCAL_STORAGE_SERIALIZER), i0.ɵɵinject(NGX_LOCAL_STORAGE_CONFIG));
};
LocalStorageService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: LocalStorageService,
  factory: LocalStorageService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGX_LOCAL_STORAGE_SERIALIZER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGX_LOCAL_STORAGE_CONFIG]
      }]
    }];
  }, null);
})();

/**
 * Provides a service
 */
class StorageEventService {
  /**
   * Create e new instance.
   */
  constructor() {
    this._eventStream = new BehaviorSubject(null);
    this.subscriptions = new Subscription();
    this.subscriptions.add(fromEvent(window, 'storage').subscribe(ev => this._eventStream.next(ev)));
  }
  /**
   * Gets a stream of storage events.
   */
  get stream() {
    return this._eventStream.asObservable().pipe(filter(ev => !!ev), share());
  }
  /**
   * OnDestroy lifecycle hook. Clears the subscription.
   */
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
StorageEventService.ɵfac = function StorageEventService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StorageEventService)();
};
StorageEventService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: StorageEventService,
  factory: StorageEventService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StorageEventService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

/**
 * Provide a directive to directly interact with stored values.
 */
class LocalStorageDirective {
  /**
   * Creates a new instance.
   */
  constructor(er, lss, es) {
    this.er = er;
    this.lss = lss;
    this.es = es;
    /**
     * An optional debounce for storage write access after value changes.
     */
    this.lsDebounce = 0;
    /**
     * Flag if the bound elements value should be initialized from storage.
     */
    this.lsInitFromStorage = false;
    /**
     * Event which gets fired when a bound value got stored.
     */
    this.lsStoredValue = new EventEmitter();
    this._valuePath = [];
    this.es.stream.pipe(
    // TODO: filter should be more accurate
    filter(ev => ev.key && ev.key.indexOf(this.lsKey) >= 0)).subscribe(ev => {
      setProperty(this._valuePath.length ? this._valuePath : ['value'], ev.newValue, this.er.nativeElement, this.lsFalsyTransformer);
    });
  }
  /**
   * Provides a path to access the bound elements value property.
   */
  set lsValuePath(path) {
    if (path != null) {
      this._valuePath = Array.isArray(path) ? path : path.split(',');
    } else {
      this._valuePath = [];
    }
  }
  /**
   * AfterViewInit lifecycle hook.
   */
  ngAfterViewInit() {
    this._initKey();
    this._initFromStorage();
    this._hookEvent();
  }
  /**
   * Initalizes the from either the given value or the elements id or name property.
   */
  _initKey() {
    if (!this.lsKey) {
      if (!this.er.nativeElement.id && !this.er.nativeElement.name) {
        throw new Error('No key or element id or name supplied!');
      }
      this.lsKey = this.er.nativeElement.id || this.er.nativeElement.name;
    }
  }
  /**
   * Hooks onto the elements given event to perform storage write on value changes.
   */
  _hookEvent() {
    if (this.lsEvent) {
      this._eventSubscription = fromEvent(this.er.nativeElement, this.lsEvent).pipe(debounceTime(this.lsDebounce)).subscribe(() => {
        this.lss.asPromisable().set(this.lsKey, getProperty(this._valuePath.length ? this._valuePath : ['value'], this.er.nativeElement), this.lsPrefix).then(() => {
          this.lss.asPromisable().get(this.lsKey, this.lsPrefix).then(value => {
            this.lsStoredValue.emit(value);
          }).catch(err => console.error(err));
        }).catch(err => console.error(err));
      });
    }
  }
  /**
   * Initializes the elements value from storage.
   */
  _initFromStorage() {
    if (this.lsInitFromStorage) {
      this.lss.asPromisable().get(this.lsKey, this.lsPrefix).then(storedValue => {
        setProperty(this._valuePath.length ? this._valuePath : ['value'], storedValue, this.er.nativeElement, this.lsFalsyTransformer);
      }).catch(err => console.error(err));
    }
  }
  /**
   * Unsubscribe from event observable.
   */
  ngOnDestroy() {
    if (this._eventSubscription && !this._eventSubscription.closed) {
      this._eventSubscription.unsubscribe();
    }
  }
}
LocalStorageDirective.ɵfac = function LocalStorageDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LocalStorageDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(LocalStorageService), i0.ɵɵdirectiveInject(StorageEventService));
};
LocalStorageDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LocalStorageDirective,
  selectors: [["", "ngxLocalStorage", ""]],
  inputs: {
    lsKey: [0, "ngxLocalStorage", "lsKey"],
    lsPrefix: "lsPrefix",
    lsEvent: "lsEvent",
    lsDebounce: "lsDebounce",
    lsInitFromStorage: "lsInitFromStorage",
    lsFalsyTransformer: "lsFalsyTransformer",
    lsValuePath: "lsValuePath"
  },
  outputs: {
    lsStoredValue: "lsStoredValue"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxLocalStorage]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: LocalStorageService
    }, {
      type: StorageEventService
    }];
  }, {
    lsKey: [{
      type: Input,
      args: ['ngxLocalStorage']
    }],
    lsPrefix: [{
      type: Input
    }],
    lsEvent: [{
      type: Input
    }],
    lsDebounce: [{
      type: Input
    }],
    lsInitFromStorage: [{
      type: Input
    }],
    lsFalsyTransformer: [{
      type: Input
    }],
    lsValuePath: [{
      type: Input
    }],
    lsStoredValue: [{
      type: Output
    }]
  });
})();

/**
 * Provides a default serialization mechanism while
 */
class DefaultSerializer {
  /**
   * @inheritdoc
   */
  serialize(value) {
    return JSON.stringify(value);
  }
  /**
   * @inheritdoc
   */
  deserialize(storedValue) {
    return JSON.parse(storedValue);
  }
}
DefaultSerializer.ɵfac = function DefaultSerializer_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DefaultSerializer)();
};
DefaultSerializer.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DefaultSerializer,
  factory: DefaultSerializer.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultSerializer, [{
    type: Injectable
  }], null, null);
})();

/**
 * Provides the librarys module.
 */
class NgxLocalStorageModule {
  /**
   * Creates and configures a module with all the providers and directives.
   *
   * When registering the NgModule at the root, import as follows:
   *
   * ```
   * @NgModule({
   *   imports: [NgxLocalStorageModule.forRoot(config)]
   * })
   * class MyNgModule {}
   * ```
   *
   * @param config An `NgxLocalstorageConfiguration` configuration object that controls how accessing localstorage is performed.
   * @return The new `NgModule`.
   *
   */
  static forRoot(config) {
    return {
      ngModule: NgxLocalStorageModule,
      providers: [{
        provide: NGX_LOCAL_STORAGE_CONFIG,
        useValue: config
      }]
    };
  }
  /**
   * Creates and configures a module with all the providers and directives.
   * When registering for submodules and lazy-loaded submodules, create the NgModule as follows:
   *
   * ```
   * @NgModule({
   *   imports: [NgxLocalStorageModule.forChild()]
   * })
   * class MyNgModule {}
   * ```
   *
   * @return The new NgModule.
   *
   */
  static forChild() {
    return {
      ngModule: NgxLocalStorageModule
    };
  }
}
NgxLocalStorageModule.ɵfac = function NgxLocalStorageModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxLocalStorageModule)();
};
NgxLocalStorageModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxLocalStorageModule
});
NgxLocalStorageModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: NGX_LOCAL_STORAGE_SERIALIZER,
    useClass: DefaultSerializer
  }],
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxLocalStorageModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [LocalStorageDirective],
      exports: [LocalStorageDirective],
      providers: [{
        provide: NGX_LOCAL_STORAGE_SERIALIZER,
        useClass: DefaultSerializer
      }]
    }]
  }], null, null);
})();

/**
 * Provides a decoarator to bind a property directly to a storage value.
 * @param options configuration used for the decoarator
 */
function ngxLocalStorage(options) {
  return function (target, propertyDescription) {
    const key = !!options && !!options.key ? options.key : propertyDescription;
    const prefix = !!options && !!options.prefix ? options.prefix : null;
    const storage = options?.storage ?? localStorage;
    const service = new LocalStorageService(new DefaultSerializer(), {
      prefix: prefix,
      storage: storage
    });
    const eventService = new StorageEventService();
    eventService.stream.pipe(filter(ev => ev.key && ev.key.indexOf(constructKey(key, prefix)) >= 0)).subscribe(ev => {
      if (!!ev.newValue && typeof ev.newValue === 'string') {
        if (ev.newValue !== 'null') {
          target[propertyDescription] = ev.newValue;
        } else {
          target[propertyDescription] = !!options.nullTransformer ? options.nullTransformer() : null;
        }
      }
    });
    Object.defineProperty(target, propertyDescription, {
      get: function () {
        const storageValue = service.get(key, prefix);
        return storageValue == null && !!options.nullTransformer ? options.nullTransformer() : storageValue;
      },
      set: function (value) {
        service.set(key, value, prefix);
      }
    });
  };
}

/*
 * Public API Surface of ngx-localstorage
 */

/**
 * Generated bundle index. Do not edit.
 */

export { LocalStorageDirective, LocalStorageService, NGX_LOCAL_STORAGE_CONFIG, NGX_LOCAL_STORAGE_SERIALIZER, NgxLocalStorageModule, StorageEventService, ngxLocalStorage };
