/*
 * tipo de datos retorno de los servicios
 */

export const TARGET_CURVE_T = 0;
export const MODEL_OUTPUT_T = 1;
export const BASELINE_BOOKINGS_T = 2;
export const BASELINE_CURVE_T = 3;
export const PRODUCTIVE_T = 4;

// eslint-disable-next-line max-len
import { D3TargetCurveRenderer } from 'src/app/components/one-time/scatterplots/target-curve-scatterplot/D3-target-curve-scatterplot/D3TargetCurveRenderer';
import { GridKpiGeneric } from 'src/app/models/GridKpiGeneric';
import { GridApClusters } from 'src/app/models/target-curves/GridApClusters';
import { GridModelSetting } from 'src/app/models/target-curves/GridModelSetting';
import { BaselineValues, ModelValues } from 'src/app/shared/menu-lateral-inputs/menu-lateral-inputs.interface';

// comun
export interface TCCurvePoint {
	ap: number;
	loadFactor: number;
}

export interface TCTableRow {
	apStart: number;
	apEnd: number;
	loadFactor: number;
	pax?: number;
}

export interface TClusterCurveTable {
	cluster: string;
	curve: Array<TCCurvePoint>;
	table: Array<TCTableRow>;
}

export interface TargetCurvesParams {
	elementCode: string;
	cabinCode: string;
	level: number;
	season: string;
	sessionId: number;
	levelBaseLine?: string;
	elementCodeBaseLine?: string;
	cabinCodeBaseLine?: string;
	seasonBaseLine?: string[];
	startDateBaseLine?: string;
	endDateBaseLine?: string;
	startDateAsk?: string;
	endDateAsk?: string;
}

export interface TargetOut {
	clusters: Array<string>;
	clusterValues: Array<number>;
}

export interface ApplyLoadFactorOutInput {
	targetCurve: Array<TClusterCurveTable>;
	targetOut: TargetOut;
}

export interface SmoothCurveOutput {
	targetCurve: Array<TClusterCurveTable>;
	method: string;
}

export interface MenuesSaveBffIn {
	menu_baseline: BaselineValues;
	menu_model: ModelValues;
}

export interface DataSaveBffIn {
	data_grid_target_curves: GridApClusters;
	data_grid_model_output_pp: GridApClusters;
	data_grid_model_posted_flight: GridApClusters;
	data_grid_model_trend: GridApClusters;
}

export interface TargetCurveProgressInput {
	tcpSessionId: number;
	elementCode: string;
	cabinCode: string;
	season: string;
	cluster: string;
	ap: number;
	target: number;
	targetRaw: number;
}

export interface TargetCurveSessionProgressInput {
	sessionId: number;
	elementCode: string;
	cabinCode: string;
	season: string;
	sourceType: string;
	menues: MenuesSaveBffIn;
	additionalDataJson: DataSaveBffIn;
	targetCurveDetails: Array<TargetCurveProgressInput>;
}

/*
{
  "elementCode": "AQPLIM",
  "cabinCode": "Y",
  "season": "L",
  "sessionId": 50,
  "elementCodeBaseLine": "AQPLIM",
  "cabinCodeBaseLine": "Y",
  "seasonsBaseLine": [
    "L"
  ],
  "startDateBaseLine": "2020-12-01",
  "endDateBaseLine": "2021-11-30",
  "model": "Base model",
  "monthlyBasis": true,
  "startDateAsk": "2021-09-24",
  "endDateAsk": "2021-12-24",
  "trend": true,
  "startDateTrend": "2020-12-01",
  "endDateTrend": "2021-11-30"
}
*/

export interface TargetCurvesModelOutputParams {
	elementCode: string;
	cabinCode: string;
	season: string;
	sessionId: number;
	elementCodeBaseLine: string;
	cabinCodeBaseLine: string;
	seasonsBaseLine: Array<string>;
	startDateBaseLine: string;
	endDateBaseLine: string;
	model: string;
	monthlyBasis: boolean;
	startDateAsk: string;
	endDateAsk: string;
	trend: boolean;
	startDateTrend: string;
	endDateTrend: string;
}

export interface ModelSettingOut {
	name: string;
	hasCheckbox: boolean;
	clusters: Array<string>;
	clustersValues: Array<number>;
	checked: boolean;
}

export interface EffectOut {
	effectOutput: Array<TClusterCurveTable>;
	kpi: Array<GridKpiGeneric>;
}

//
export type TargetCurvesOut = Array<TClusterCurveTable>;
export type BaselineOut = Array<TClusterCurveTable>;
export type ProductiveOut = Array<TClusterCurveTable>;
export type TargetCurveApplyFactorOut = Array<TClusterCurveTable>;

export interface ModelOutputOut {
	modelOutput: Array<TClusterCurveTable>;
	modelSetting: Array<ModelSettingOut>;
	targetCurve: any; // TODO
	trend: EffectOut;
	postedFlights: EffectOut;
}

/*
 * tipos retornados por los servicios hacia la UI
 */
export interface DataTargetCurves {
	curve: Array<D3TargetCurveRenderer.TargetCurve>;
	grid_pp: GridApClusters;
	grid_pax: GridApClusters;
}

export interface DataBaseline {
	curve: Array<D3TargetCurveRenderer.TargetCurve>;
	grid_pp: GridApClusters;
	grid_pax: GridApClusters;
}

export interface DataProductive {
	curve: Array<D3TargetCurveRenderer.TargetCurve>;
	grid_pp: GridApClusters;
	grid_pax: GridApClusters;
}

export interface DataModelOutput {
	curve: Array<D3TargetCurveRenderer.TargetCurve>;
	grid_pp: GridApClusters;
	grid_pax: GridApClusters;
	model_settings: GridModelSetting;
	grid_trend: Array<GridApClusters>;
	grid_trend_kpis: Array<GridKpiGeneric>;
	grid_posted_flight: Array<GridApClusters>;
	grid_posted_flight_kpis: Array<GridKpiGeneric>;
}

export interface RefreshModelOut {
	modelOutput: Array<TClusterCurveTable>;
	modelSetting: Array<ModelSettingOut>;
}

export interface DataRefreshModel {
	curve: Array<D3TargetCurveRenderer.TargetCurve>;
	grid_pp: GridApClusters;
	grid_pax: GridApClusters;
	model_settings: GridModelSetting;
}

export interface RefreshModelPayload {
	modelOutput: ModelOutput[];
	trend: PostedFlights;
	postedFlights: PostedFlights;
	baselineBookings: ModelOutput[];
	targetCurves: ModelOutput[];
}

export interface ModelOutput {
	cluster: string;
	curve: TCCurvePoint[];
	table: TCTableRow[];
}

export interface PostedFlights {
	effectOverride: EffectOverride[];
}

export interface EffectOverride {
	cluster: string;
	table: TCTableRow[];
}

// ********* refresh 2 *********

export interface AskIndicatorsParams {
	elementCode: string;
	cabinCode: string;
	level: number;
	season: string;
	sessionId: number;
	levelBaseLine?: string;
	elementCodeBaseLine?: string;
	cabinCodeBaseLine?: string;
	seasonsBaseLine?: string[];
	startDateBaseLine?: string;
	endDateBaseLine?: string;
	startDateAsk?: string;
	endDateAsk?: string;
	monthlyBasis?: boolean;
}

export interface TCClusterValue {
	cluster: string;
	value: number;
}

export interface TCAskIndicators {
	askrBl: Array<TCClusterValue>;
	askAy: Array<TCClusterValue>;
	askBl: Array<TCClusterValue>;
}
