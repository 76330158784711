<mat-drawer-container>
	<mat-drawer
		#drawer
		mode="over"
		position="end"
		class="mi-drawer"
		[ngClass]="{ 'menu-notify-sticky': stickyMenu }"
		[ngStyle]="{ height: menuHeight + 'px' }"
		(closedStart)="closedStart()"
	>
		<mat-list>
			<div mat-subheader>Notifications</div>

			<mat-list-item *ngFor="let item of listNotify">
				<mat-icon mat-list-icon style="color: red">report</mat-icon>
				<span class="mat-list-text">
					<div mat-line><span class="text-item">Page: </span>&nbsp;{{ item.page }}</div>
					<div mat-line><span class="text-item-msg">Message: </span>&nbsp;{{ item.msg }}</div>
					<div mat-line><span class="text-item">Service: </span>&nbsp;{{ item.services }}</div>
					<div mat-line><span class="text-item">Date: </span>&nbsp;{{ item.dateError }}</div>
				</span>

				<button mat-icon-button (click)="openModalNotify(item)">
					<mat-icon class="mat-24">visibility</mat-icon>
				</button>
			</mat-list-item>
		</mat-list>

		<!--CONTENIDO-->
	</mat-drawer>
</mat-drawer-container>
