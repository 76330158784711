<ng-container>
	<mat-card appearance="outlined" class="mat-card">
		<mat-card-content class="mat-card-content">
			<div class="row" *ngIf="buckets_data_grid_au_ready">
				<!--Buckets AU-->
				<div class="col-md-6 item-box" *ngIf="buckets_data_grid_au_ready && view === 'AU'">
					<app-grid-bucket-clusters
						[readOnly]="false"
						title="Buckets"
						[data_grid]="buckets_data_grid_au"
						[data_grid_raw]="raw_buckets_data_grid_au"
						[show_bucket_col]="true"
						[cluster_pax_list]="cluster_pax_list"
						[rendererGrid]="rendererBucket"
						(buckets_edited)="onBucketsEdited($event)"
					>
					</app-grid-bucket-clusters>
				</div>
				<div class="col-md-6 item-box" *ngIf="view === 'AU'">
					<div *ngIf="indicatorSelect === 'Baseline' && view === 'AU'">
						<!-- Baseline AU: [readOnly]="true"  [baselineAFRow]="baselineAFRow" -->
						<app-grid-baseline-bucket-clusters
							title="Baseline"
							[data_grid]="baseline_buckets_data_grid_au"
							[show_bucket_col]="true"
							[cluster_pax_list]="cluster_pax_list"
							[rendererGrid]="rendererBucket"
						>
						</app-grid-baseline-bucket-clusters>
					</div>
					<div *ngIf="indicatorSelect === 'Model output' && view === 'AU'">
						<!-- Model Output AU: [readOnly]="true"  [baselineAFRow]="baselineAFRow" -->
						<app-grid-baseline-bucket-clusters
							title="Model Output"
							[data_grid]="model_output_buckets_data_grid_au"
							[show_bucket_col]="true"
							[cluster_pax_list]="cluster_pax_list"
							[rendererGrid]="rendererBucket"
						>
						</app-grid-baseline-bucket-clusters>
					</div>
				</div>

				<div class="col-md-6 item-box" *ngIf="view === 'AU'">
					<app-grid-bucket-summary [readOnly]="false" title="KPIs" [data_grid]="buckets_data_summary_grid">
					</app-grid-bucket-summary>
				</div>
			</div>
			<div class="row" *ngIf="buckets_data_grid_bp_ready">
				<!--Buckets BP-->
				<div class="col-md-6 item-box" *ngIf="buckets_data_grid_bp_ready && view === 'BP'">
					<app-grid-bucket-clusters
						[readOnly]="false"
						title="Buckets"
						[data_grid]="buckets_data_grid_bp"
						[data_grid_raw]="raw_buckets_data_grid_bp"
						[show_bucket_col]="true"
						[cluster_pax_list]="cluster_pax_list"
						[rendererGrid]="rendererBucket"
						(buckets_edited)="onBucketsEdited($event)"
					>
					</app-grid-bucket-clusters>
				</div>
				<div class="col-md-6 item-box" *ngIf="view === 'BP'">
					<div *ngIf="indicatorSelect === 'Baseline' && view === 'BP'">
						<!-- Baseline BP: [readOnly]="true"  [baselineAFRow]="baselineAFRow" -->
						<app-grid-baseline-bucket-clusters
							title="Baseline"
							[data_grid]="baseline_buckets_data_grid_bp"
							[show_bucket_col]="true"
							[cluster_pax_list]="cluster_pax_list"
							[rendererGrid]="rendererBucket"
						>
						</app-grid-baseline-bucket-clusters>
					</div>
					<div *ngIf="indicatorSelect === 'Model output' && view === 'BP'">
						<!-- Model Output BP: [readOnly]="true"  [baselineAFRow]="baselineAFRow" -->
						<app-grid-baseline-bucket-clusters
							title="Model Output"
							[data_grid]="model_output_buckets_data_grid_bp"
							[show_bucket_col]="true"
							[cluster_pax_list]="cluster_pax_list"
							[rendererGrid]="rendererBucket"
						>
						</app-grid-baseline-bucket-clusters>
					</div>
				</div>

				<div class="col-md-6 item-box" *ngIf="view === 'BP'">
					<app-grid-bucket-summary [readOnly]="false" title="KPIs" [data_grid]="buckets_data_summary_grid">
					</app-grid-bucket-summary>
				</div>
			</div>
		</mat-card-content>
		<mat-card-actions class="select-rem">
			<div class="contenedor-btn">
				<div class="btn-select">
					<button class="btn-update ml-22" (click)="updateBPandAF()">Update BP and Average Fare</button>
					<button class="btn-update ml-22" [disabled]="disableUndoUpdateBPandAF" (click)="rollbackUpdateBPandAF()">
						Undo
					</button>
				</div>
				<div class="btn-trio">
					<span class="select-white">
						<mat-form-field class="ml-22">
							<mat-select [formControl]="selectSeason" placeholder="Import Buckets">
								<mat-option [value]="season" *ngFor="let season of data_list_seasons">{{ season }}</mat-option>
							</mat-select>
						</mat-form-field>
					</span>

					<button class="btn-grey ml-22" (click)="copyModelOutput()" *ngIf="indicatorSelect === 'Model output'">
						Copy Model output
					</button>

					<button class="btn-refresh ml-22" (click)="refresh()">Refresh</button>
					<div class="btn-select ml-22">
						<mat-form-field appearance="outline">
							<mat-select [(value)]="indicatorSelect">
								<mat-option [value]="indicator" *ngFor="let indicator of optionsIndicator">
									{{ indicator }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<button class="btn-save ml-22" [disabled]="disableSave" (click)="save()">Save</button>
				</div>
			</div>
		</mat-card-actions>
	</mat-card>
</ng-container>
