import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { bff } from 'src/environments/environment';
import { Parameter } from '../models/Parameter';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	})
};

@Injectable({
	providedIn: 'root'
})
export class ParameterServices {
	private env = `${bff.host}`;

	headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

	constructor(private http: HttpClient) {}

	getCategoryList(): Observable<any> {
		return this.http.get(this.env + bff.getCategory);
	}

	getBusinessList(): Observable<any> {
		return this.http.get(this.env + bff.getBusiness);
	}

	getProductList(id): Observable<any> {
		return this.http.get(this.env + bff.getProduct + id);
	}

	getLoadTable(idBusiness: number, idProduct: number): Observable<any> {
		return this.http.get(this.env + bff.getLoadTable + idBusiness + '/' + idProduct);
	}

	getNewTable(nameProduct: string): Observable<any> {
		return this.http.get(this.env + bff.getTable + nameProduct + '/0');
	}
	getEditTable(nameProduct: string, idParameter: number): Observable<any> {
		return this.http.get(this.env + bff.getTable + nameProduct + '/' + idParameter);
	}

	postNewParameter(parameter: Parameter): Observable<any> {
		return this.http.post(this.env + bff.postNewParameter, parameter, httpOptions);
	}

	putEditParameter(parameter: Parameter): Observable<any> {
		return this.http.put(this.env + bff.putEditParameter, parameter, httpOptions);
	}

	deleteParameter(parameterId) {
		return this.http.delete(this.env + bff.deleteParameter + parameterId);
	}
}
