import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '@services/dialog.service';
import { OneTimeServices } from '@services/one-time.service';
import { SessionStorage } from 'src/app/core/storages/session.storage';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';

export interface FilterSession {
	level: string;
	element: string;
	cabin: string;
	cabinName: string;
}

export interface ElementSessionList {
	id: number;
	level?: string;
	elementId: string;
	cabinCode: string;
	creationDateTime: string;
	lastUpdateDateTime: string;
}

export interface SessionElement {
	sessionId: number;
	levelName: string;
	levelCode: string;
	elementCode: string;
	cabinName: string;
	cabinCode: string;
}

@Component({
	selector: 'app-table-old-sessions',
	templateUrl: './table-old-sessions.component.html',
	styleUrls: ['./table-old-sessions.component.scss']
})
export class TableOldSessionsComponent implements OnInit {
	@Output() restSession = new EventEmitter<SessionElement>();
	@Output() restSessionLoad = new EventEmitter<FilterSession>();
	@Output() newSession = new EventEmitter<boolean>();
	@Output() listSessions = new EventEmitter<ElementSessionList[]>();
	@Input() summarySelect = false;
	displayedColumns: string[] = [
		'id',
		'level',
		'elementId',
		'cabinCode',
		'creationDateTime',
		'lastUpdateDateTime',
		'delete',
		'load'
	];
	sessionId = OneTimeContext.getSessionID();
	dataSource: ElementSessionList[];
	comboListCabin;
	comboListLevel;

	// Flag de snack bar activo
	sendSnackbarActive;

	constructor(
		private oneTimeServices: OneTimeServices,
		private dialog: DialogService,
		private oneTimeService: OneTimeServices,
		private snackBar: MatSnackBar,
		private sessionData: SessionStorage
	) {}

	ngOnInit() {
		this.getCabinList();
		this.getLevelList();
		this.getSessions('load');
		this.viewOptionsSummary();
	}

	viewOptionsSummary(): void {
		if (this.summarySelect) {
			const displayedColumnsFilter = this.displayedColumns.filter((f) => f !== 'delete' && f !== 'load');
			this.displayedColumns = [...displayedColumnsFilter, 'view'];
		}
	}

	getSessions(event: string) {
		const user = this.sessionData.userEmail;

		this.oneTimeServices.getSessionList(user).subscribe(
			(resSession) => {
				this.dataSource = this.sortData(resSession);
				this.listSessions.emit(this.dataSource);

				if (event === 'load') {
					const sessionLoad: FilterSession = this.dataSource
						.map((m) => ({
							id: m.id,
							element: m.elementId,
							cabin: m.cabinCode,
							cabinName: this.normCabin(m.cabinCode),
							level: m.level
						}))
						.find((d) => d.id === this.sessionId);
					this.restSessionLoad.emit(sessionLoad);
				}
			},
			(err) => {
				console.error(err);
			}
		);
	}

	getLevelList() {
		this.oneTimeServices.getLevelsAndElements().subscribe(
			(res) => {
				this.comboListLevel = res;
			},
			(err) => {
				console.error(err);
			}
		);
	}

	getCabinList() {
		this.oneTimeServices.getCabins().subscribe(
			(res) => {
				this.comboListCabin = res;
			},
			(err) => {
				console.error(err);
			}
		);
	}

	normLevel(level) {
		if (this.comboListLevel) {
			return this.comboListLevel.find((c) => c.level === level).levelId;
		} else {
			return level;
		}
	}

	normCabin(cabin) {
		if (this.comboListCabin) {
			return this.comboListCabin.find((c) => c.code === cabin).description;
		} else {
			return cabin;
		}
	}

	chargeSession(sesion) {
		OneTimeContext.deleteSessionStorage();
		const sesionSelect: SessionElement = {
			sessionId: sesion.id,
			levelName: sesion.level,
			levelCode: this.normLevel(sesion.level),
			elementCode: sesion.elementId,
			cabinCode: sesion.cabinCode,
			cabinName: this.normCabin(sesion.cabinCode)
		};

		OneTimeContext.setSessionID(sesionSelect.sessionId);
		OneTimeContext.setLevel(sesionSelect.levelName);
		OneTimeContext.setLevelId(parseInt(sesionSelect.levelCode, 10));
		OneTimeContext.setCabinCode(sesionSelect.cabinCode);
		OneTimeContext.setCabinName(sesionSelect.cabinName);
		OneTimeContext.setElementCode(sesionSelect.elementCode);
		OneTimeContext.setBaselineElement(sesionSelect.elementCode);
		OneTimeContext.setBaselineLevel(sesionSelect.levelName);
		OneTimeContext.setBaselineCabinCode(sesionSelect.cabinCode);
		OneTimeContext.setBaselineCabinName(sesionSelect.cabinName);

		this.sessionId = sesionSelect.sessionId;

		this.newSession.emit(false);
		this.restSession.emit(sesionSelect);
		this.getSessions('click');
		this.openSnackBarCorrect('Session restored successfully');
	}

	deleteSession(element) {
		const sessionId = OneTimeContext.getSessionID();
		this.dialog
			.confirmDialog({
				title: 'Delete Session',
				message: 'Are you sure you want to permanently delete this session?',
				confirmCaption: 'Delete',
				cancelCaption: 'Go Back',
				typeDialog: 'danger'
			})
			.subscribe((confirm) => {
				if (confirm) {
					this.oneTimeService.deleteSession(element.id).subscribe(
						(res) => {
							this.getSessions('click');
							this.openSnackBarCorrect('Deleted Session successfully');
							if (sessionId === element.id) {
								this.newSession.emit(true);
							}
						},
						(err) => {
							console.error(err);
							this.openSnackBarIncorrect('Unable to delete session');
						}
					);
				}
			});
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 10000,
			panelClass: ['snackIncorrect']
		});
	}

	sortData(data: ElementSessionList[]) {
		return data.sort((a: ElementSessionList, b: ElementSessionList) => {
			let comparatorResult = 0;
			// Sorting logic here
			if (a.id != null && b.id != null) {
				if (a.id > b.id) {
					comparatorResult = 1;
				} else if (a.id < b.id) {
					comparatorResult = -1;
				}
			} else if (a.id != null) {
				comparatorResult = 1;
			} else if (b.id != null) {
				comparatorResult = -1;
			}

			if (a.id === this.sessionId) {
				comparatorResult = 1;
			} else if (b.id === this.sessionId) {
				comparatorResult = -1;
			}

			return comparatorResult * -1;
		});
	}
}
