import { Component, Input } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import { OneTimeServices } from '@services/one-time.service';
import Handsontable from 'handsontable';
import { OneTimeContext } from 'src/app/models/OneTimeGlobalContext';
import { ConfigService } from '../../../../config/config.service';

type RendererFunc = (
	instance: Handsontable,
	TD: HTMLTableCellElement,
	row: number,
	col: number,
	prop: number | string,
	value: number | string,
	cellProperties: any
) => void;
interface DataKPI {
	season: string;
	seasonCommited: boolean;
	ask: number;
	clusters: number;
	clustersCommited: boolean;
	targetCurves: number;
	targetCurvesCommited: boolean;
	buckets: number;
	bucketsCommited: boolean;
}

interface ColumnKPI {
	data: string;
	numericFormat?: { pattern?: string } | null;
	readOnly?: boolean;
	title: string;
	type: 'numeric' | 'text';
	placeholder?: string;
	renderer?: string | RendererFunc;
}

const KpiTableInputs = {
	objetoLlamada: 'objetoLlamada'
};

@Component({
	selector: 'app-kpi-table',
	templateUrl: './kpi-table.component.html',
	styleUrls: ['./kpi-table.component.scss']
})
export class KpiTableComponent {
	@Input() createSession: boolean = false;
	@Input(KpiTableInputs.objetoLlamada)
	set setFilter(objetoLlamada: any) {
		this.updateTable(objetoLlamada);
	}

	// Nombre Instancia Handsontable
	id = 'hotInstanceAsk';
	hotRegisterer = new HotTableRegisterer();
	hotInstance: Handsontable;

	// Settings Handsontable
	data: DataKPI[] = [];
	cols: ColumnKPI[] = [];

	tableSettings: any = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		contextMenu: true,
		stretchH: 'all',
		dropdownMenu: false,
		manualColumnMove: false,
		filters: false,
		language: 'es-MX',
		rowHeaders: false,
		columnSorting: false,
		allowInsertRow: false,
		copyPaste: false,
		readOnly: true,
		className: 'htCenter'
	};

	constructor(private oneTimeService: OneTimeServices, private configService: ConfigService) {}

	addLastRow() {
		this.hotInstance.alter('insert_row_below');
		const data: DataKPI[] = this.hotInstance.getSettings().data as DataKPI[];
		const indexRowTotal = data.length - 1;

		const valueRowTotal = data.map((m) => m.ask).reduce((acc, value) => acc + value, 0.0);

		this.hotInstance.setDataAtCell(indexRowTotal, 0, 'TOTAL');
		this.hotInstance.setDataAtCell(indexRowTotal, 1, valueRowTotal);
	}

	updateTable(filter: any) {
		const level = filter && filter.levelId;
		const elementCode = filter && filter.element.elementCode;
		const id = filter && filter.cabin.code;
		let sessionId = '-1';

		if (OneTimeContext.getSessionID().toString() !== 'NaN') {
			sessionId = OneTimeContext.getSessionID().toString();
		}

		this.oneTimeService.getSourceKPI(level, elementCode, id, sessionId).subscribe(
			(res) => {
				this.data = [...res.data];
				this.cols = [...res.columns.map((col) => ({ renderer: this.rendererColumnTable, ...col }))];
			},
			(err) => {
				this.data = [];
				this.cols = [];
				console.error(err);
			}
		);
	}

	// Funciones de Handsontable
	afterGetColHeader = (col: number, th: HTMLTableCellElement) => {
		th.style.backgroundColor = '#1b0088';
		th.style.color = 'white';
		th.style.fontWeight = 'regular';
	};

	afterChange = (changes: Array<any>, source: string) => {
		if ((source === 'loadData' || source === 'updateData') && this.data.length !== 0) {
			this.hotInstance = this.hotRegisterer.getInstance(this.id);
			this.addLastRow();
		}
	};

	rendererColumnTable: RendererFunc = (instance, TD, row, col, prop, value, cellProperties) => {
		const argumentsRender = [instance, TD, row, col, prop, value, cellProperties];
		const type = cellProperties && cellProperties.type;
		const cellData: any = instance.getSettings().data[row];
		const rowTotalNumber = instance.getSettings().data.length - 1;

		const colors = {
			yellow: '#FEE644',
			green: '#7FD698',
			red: '#F19F9A'
		};
		TD.className = 'htCenter';

		switch (type) {
			case 'numeric':
				Handsontable.renderers.NumericRenderer.apply(this, argumentsRender);
				break;
			case 'text':
				Handsontable.renderers.TextRenderer.apply(this, argumentsRender);
				break;
		}

		this.rendererSeasons(prop, cellData, TD, colors);
		this.rendererAsk(prop, cellData, TD, row, rowTotalNumber, colors);
		this.rendererClusters(prop, cellData, TD, row, rowTotalNumber, colors);
		this.rendererTargetCurves(prop, cellData, TD, row, rowTotalNumber, colors);
		this.rendererBuckets(prop, cellData, TD, row, rowTotalNumber, colors);
	};

	rendererSeasons(prop: string | number, cellData: any, TD: HTMLTableCellElement, colors: any): void {
		if (prop === 'season') {
			if (cellData.seasonCommited === false) {
				TD.style.backgroundColor = colors.yellow;
				TD.style.color = '#303030';
			}
		}
	}

	rendererAsk(
		prop: string | number,
		cellData: any,
		TD: HTMLTableCellElement,
		row: number,
		rowTotalNumber: number,
		colors: any
	): void {
		if (prop === 'ask') {
			if (rowTotalNumber === row) {
				if (cellData.ask > 0.9995) {
					TD.style.backgroundColor = colors.green;
					TD.style.color = '#303030';
				} else {
					TD.style.backgroundColor = colors.red;
					TD.style.color = '#303030';
				}
			}
		}
	}

	rendererClusters(
		prop: string | number,
		cellData: any,
		TD: HTMLTableCellElement,
		row: number,
		rowTotalNumber: number,
		colors: any
	): void {
		if (prop === 'clusters') {
			if (rowTotalNumber !== row) {
				if (cellData.clustersCommited === false) {
					TD.style.backgroundColor = colors.yellow;
					TD.style.color = '#303030';
				} else if (cellData.clusters === 0) {
					TD.style.backgroundColor = colors.red;
					TD.style.color = '#303030';
				}
			}
		}
	}

	rendererTargetCurves(
		prop: string | number,
		cellData: any,
		TD: HTMLTableCellElement,
		row: number,
		rowTotalNumber: number,
		colors: any
	): void {
		if (prop === 'targetCurves') {
			if (rowTotalNumber !== row) {
				if (cellData.targetCurvesCommited === false) {
					TD.style.backgroundColor = colors.yellow;
					TD.style.color = '#303030';
				} else if (cellData.targetCurves === 1.0) {
					TD.style.backgroundColor = colors.green;
					TD.style.color = '#303030';
				} else {
					TD.style.backgroundColor = colors.red;
					TD.style.color = '#303030';
				}
			}
		}
	}

	rendererBuckets(
		prop: string | number,
		cellData: any,
		TD: HTMLTableCellElement,
		row: number,
		rowTotalNumber: number,
		colors: any
	): void {
		if (prop === 'buckets') {
			if (rowTotalNumber !== row) {
				if (cellData.bucketsCommited === false) {
					TD.style.backgroundColor = colors.yellow;
					TD.style.color = '#303030';
				} else if (cellData.buckets === 1.0) {
					TD.style.backgroundColor = colors.green;
					TD.style.color = '#303030';
				} else {
					TD.style.backgroundColor = colors.red;
					TD.style.color = '#303030';
				}
			}
		}
	}
}
