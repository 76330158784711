import { ChronoUnit, LocalDate } from '@js-joda/core';
import { MonthCalendar } from './MonthCalendar';
import { RowCalendar } from './RowCalendar';

export function createCalendars<T>(
	year_init: number,
	month_init: number,
	num_months: number,
	emptyT: T
): PeriodCalendarStructure<T> {
	const dt_init = LocalDate.of(year_init, month_init, 1);

	const dt_last_month = dt_init.plusMonths(num_months - 1);
	const num_days_in_last_month = dt_last_month.lengthOfMonth();
	const dt_end = dt_last_month.withDayOfMonth(num_days_in_last_month);

	const calendars = createMonths(dt_init, num_months, emptyT);

	return new PeriodCalendarStructure<T>(dt_init, dt_end, undefined, undefined, emptyT, calendars);
}

export function logCalendars<T>(calendars: PeriodCalendarStructure<T>) {
	console.log('dt_init: ' + calendars.dt_init);
	console.log('dt_end: ' + calendars.dt_end);

	console.log('outbounds <<<');
	logMonthCalendarSeason(calendars.calendars);
	console.log('>>> outbounds');
}

export class PeriodCalendarStructure<T> {
	constructor(
		public dt_init: LocalDate,
		public dt_end: LocalDate,
		public minValue: number | undefined,
		public maxValue: number | undefined,
		public emptyT: T,
		public calendars: Array<MonthCalendar<T>>
	) {}

	updateOneCell(dt: LocalDate, cellData: T) {
		if (dt.isBefore(this.dt_init) || dt.isAfter(this.dt_end)) {
			return; // TODO ver como lanzar una exception
		}

		function isNumber(t): t is number {
			return typeof t === 'number';
		}

		// actualiza min/max solo cuando el tipo T es number
		if (isNumber(cellData)) {
			if (cellData < this.minValue || this.minValue === undefined) {
				this.minValue = cellData;
			}

			if (cellData > this.maxValue || this.maxValue === undefined) {
				this.maxValue = cellData;
			}
		}

		const month_offset = Math.floor(ChronoUnit.MONTHS.between(this.dt_init, dt));

		const month_cal = this.calendars[month_offset];

		const diff_days = Math.floor(ChronoUnit.DAYS.between(month_cal.first_day_first_row, dt));
		const week_offset = Math.floor(diff_days / 7);

		const week_row = month_cal.rows[week_offset];

		const dow = dt.dayOfWeek().value();

		if (dow == 1) week_row.mon_data = cellData;
		else if (dow == 2) week_row.tue_data = cellData;
		else if (dow == 3) week_row.wed_data = cellData;
		else if (dow == 4) week_row.thu_data = cellData;
		else if (dow == 5) week_row.fri_data = cellData;
		else if (dow == 6) week_row.sat_data = cellData;
		else if (dow == 7) week_row.sun_data = cellData;
		else console.log('Invalid dow ' + dow);
	}

	/*
	 *
	 */
	zip<U>(other: PeriodCalendarStructure<U>): Array<[LocalDate, T, U]> {
		const ret: Array<[LocalDate, T, U]> = [];

		const emptyU = other.emptyT;

		for (let i = 0; i < this.calendars.length; i++) {
			const thisCalendar = this.calendars[i];
			const otherCalendar = other.calendars[i];

			let offset = 0;

			for (let iWeek = 0; iWeek < thisCalendar.rows.length; iWeek++) {
				const first_day_of_week = thisCalendar.first_day_first_row.plusDays(offset);

				const thisWeek = thisCalendar.rows[iWeek];
				const otherWeek = otherCalendar.rows[iWeek];

				this.zipWeek(first_day_of_week, thisWeek, otherWeek, emptyU, ret);

				offset += 7;
			}
		}

		return ret;
	}

	private zipWeek<U>(
		first_day_of_week: LocalDate,
		thisWeek: RowCalendar<T>,
		otherWeek: RowCalendar<U>,
		emptyU: U,
		arr: Array<[LocalDate, T, U]>
	): void {
		this.zipDay(first_day_of_week, thisWeek.mon_data, otherWeek.mon_data, emptyU, arr);
		this.zipDay(first_day_of_week.plusDays(1.0), thisWeek.tue_data, otherWeek.tue_data, emptyU, arr);
		this.zipDay(first_day_of_week.plusDays(2.0), thisWeek.wed_data, otherWeek.wed_data, emptyU, arr);
		this.zipDay(first_day_of_week.plusDays(3.0), thisWeek.thu_data, otherWeek.thu_data, emptyU, arr);
		this.zipDay(first_day_of_week.plusDays(4.0), thisWeek.fri_data, otherWeek.fri_data, emptyU, arr);
		this.zipDay(first_day_of_week.plusDays(5.0), thisWeek.sat_data, otherWeek.sat_data, emptyU, arr);
		this.zipDay(first_day_of_week.plusDays(6.0), thisWeek.sun_data, otherWeek.sun_data, emptyU, arr);
	}

	/*
	 *
	 */
	private zipDay<U>(dt: LocalDate, thisCell: T, otherCell: U, emptyU: U, arr: Array<[LocalDate, T, U]>): void {
		if (
			typeof thisCell != 'undefined' &&
			thisCell !== null &&
			thisCell != this.emptyT &&
			typeof otherCell != 'undefined' &&
			otherCell !== null &&
			otherCell != emptyU
		) {
			arr.push([dt, thisCell, otherCell]);
		}
	}

	/*
	 *
	 */
	map<S>(f: (dt: LocalDate, cell: T) => S): Array<S> {
		const ret = [];

		for (let calendar of this.calendars) {
			let offset = 0;

			for (let week of calendar.rows) {
				const first_day_of_week = calendar.first_day_first_row.plusDays(offset);

				this.mapWeek(first_day_of_week, week, f, ret);

				offset += 7;
			}
		}

		return ret;
	}

	private mapWeek<S>(
		first_day_of_week: LocalDate,
		week: RowCalendar<T>,
		f: (dt: LocalDate, cell: T) => S,
		arr: Array<S>
	): void {
		this.mapDay(first_day_of_week, week.mon_data, f, arr);
		this.mapDay(first_day_of_week.plusDays(1.0), week.tue_data, f, arr);
		this.mapDay(first_day_of_week.plusDays(2.0), week.wed_data, f, arr);
		this.mapDay(first_day_of_week.plusDays(3.0), week.thu_data, f, arr);
		this.mapDay(first_day_of_week.plusDays(4.0), week.fri_data, f, arr);
		this.mapDay(first_day_of_week.plusDays(5.0), week.sat_data, f, arr);
		this.mapDay(first_day_of_week.plusDays(6.0), week.sun_data, f, arr);
	}

	private mapDay<S>(day: LocalDate, cell: T, f: (dt: LocalDate, cell: T) => S, arr: Array<S>): void {
		if (typeof cell != 'undefined' && cell !== null && cell != this.emptyT) {
			const value = f(day, cell);

			arr.push(value);
		}
	}
}

// ----------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------

function createMonths<T>(dt_init: LocalDate, num_months: number, emptyT: T): Array<MonthCalendar<T>> {
	const months = new Array<MonthCalendar<T>>(num_months);

	for (let i = 0; i < num_months; i++) {
		const dt = dt_init.plusMonths(i);

		months[i] = createMonth(dt, emptyT);
	}

	return months;
}

function createMonth<T>(first_day_in_month: LocalDate, emptyT: T): MonthCalendar<T> {
	const year = first_day_in_month.year();
	const month = first_day_in_month.month().name();

	const num_days_in_month = first_day_in_month.lengthOfMonth();

	const last_day_in_month = first_day_in_month.withDayOfMonth(num_days_in_month);

	const first_day_dow = first_day_in_month.dayOfWeek().value();
	const last_day_dow = last_day_in_month.dayOfWeek().value();

	const num_days_in_table = num_days_in_month + (first_day_dow - 1) + (7 - last_day_dow);

	const num_semanas = num_days_in_table / 7;

	// console.log ("num_semanas: " + num_semanas) ;

	const first_day_first_row = first_day_in_month.minusDays(first_day_dow - 1);

	const rows = new Array<RowCalendar<T>>(num_semanas);

	rows[0] = createFirstRow(first_day_in_month, emptyT);

	for (let i = 1; i < num_semanas - 1; i++) {
		const first_day_row = first_day_first_row.plusDays(7 * i);
		rows[i] = createRow(first_day_row, emptyT);
	}

	rows[num_semanas - 1] = createLastRow(last_day_in_month, emptyT);

	return new MonthCalendar(year, month, first_day_first_row, rows);
}

function createFirstRow<T>(first_day_in_month: LocalDate, emptyT: T): RowCalendar<T> {
	const dow = first_day_in_month.dayOfWeek().value();

	const num_day_eow = 7 - dow + 1;

	const days = '1-' + num_day_eow;

	const week = first_day_in_month.isoWeekOfWeekyear(); // TODO

	const mon_data = dow > 1 ? null : emptyT;
	const tue_data = dow > 2 ? null : emptyT;
	const wed_data = dow > 3 ? null : emptyT;
	const thu_data = dow > 4 ? null : emptyT;
	const fri_data = dow > 5 ? null : emptyT;
	const sat_data = dow > 6 ? null : emptyT;
	const sun_data = emptyT;

	return new RowCalendar<T>(days, week, mon_data, tue_data, wed_data, thu_data, fri_data, sat_data, sun_data);
}

function createRow<T>(first_day: LocalDate, emptyT: T): RowCalendar<T> {
	const last_day = first_day.plusDays(6.0);

	const num_day_bow = first_day.dayOfMonth();
	const num_day_eow = last_day.dayOfMonth();

	const days = '' + num_day_bow + '-' + num_day_eow;

	const week = first_day.isoWeekOfWeekyear(); // TODO

	return new RowCalendar<T>(days, week, emptyT, emptyT, emptyT, emptyT, emptyT, emptyT, emptyT);
}

function createLastRow<T>(last_day_in_month: LocalDate, emptyT: T): RowCalendar<T> {
	const dow = last_day_in_month.dayOfWeek().value();

	const num_day_eow = last_day_in_month.dayOfMonth();

	const num_day_bow = num_day_eow - dow + 1;

	const days = '' + num_day_bow + '-' + num_day_eow;

	const week = last_day_in_month.isoWeekOfWeekyear(); // TODO

	const mon_data = emptyT;
	const tue_data = dow >= 2 ? emptyT : null;
	const wed_data = dow >= 3 ? emptyT : null;
	const thu_data = dow >= 4 ? emptyT : null;
	const fri_data = dow >= 5 ? emptyT : null;
	const sat_data = dow >= 6 ? emptyT : null;
	const sun_data = dow >= 7 ? emptyT : null;

	return new RowCalendar<T>(days, week, mon_data, tue_data, wed_data, thu_data, fri_data, sat_data, sun_data);
}

function logMonthCalendarSeason<T>(months: Array<MonthCalendar<T>>) {
	for (var month of months) {
		console.log('' + month.month + ' / ' + month.year);
		console.log(month.first_day_first_row);

		for (var row of month.rows) {
			console.log(row);
		}
	}
}
