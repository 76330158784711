import { Injectable } from '@angular/core';

// import { ErrorDialogService } from '../error-dialog/errordialog.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SessionStorage } from '../core/storages/session.storage';
import { HttpSpinnerService } from '../http-spinner/http-spinner.service';
import { LoginService } from '../services/login.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
	constructor(
		// public errorDialogService: ErrorDialogService
		private httpSpinnerService: HttpSpinnerService,
		private loginService: LoginService,
		private router: Router,
		private translateService: TranslateService,
		private sessionData: SessionStorage
	) {}

	private exceptions: string[] = ['login'];

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.exceptions.every((term: string) => request.url.indexOf(term) === -1)) {
			return next.handle(request).pipe(
				tap(
					(response: any) => {},
					(error) => {}
				)
			);
		}
		const verificateUrlStorage = request.url.includes('https://storage.googleapis.com/');
		let token;

		if (verificateUrlStorage) {
			token = this.sessionData.accessToken;
		} else {
			token = this.sessionData.idToken;
		}

		const headers = {
			Authorization: 'Bearer ' + token,
			'X-Application-Name': 'LATAM'
		};

		this.httpSpinnerService.nextState$(true);

		const reqClone = request.clone({
			setHeaders: headers
		});

		return next.handle(reqClone).pipe(
			catchError((err: HttpErrorResponse) => {
				if ((err.status === 402 || err.status === 403 || err.status === 0) && this.router.url !== '/') {
					this.httpSpinnerService.nextState$(false);
					Swal.fire({
						icon: 'info',
						title: this.translateService.instant('errorInterceptor.title'),
						text: this.translateService.instant('errorInterceptor.message'),
						confirmButtonColor: '#3fc3ee'
					}).then(() => {
						localStorage.clear();
						this.sessionData.clearSession();
						this.loginService.setCurrentLoginState(false);
						this.router.navigateByUrl('');
						return throwError(err);
					});
				} else {
					return throwError(err);
				}
			}),
			finalize(() => {
				this.httpSpinnerService.nextState$(false);
			})
		);
	}
}
