import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { SessionStorage } from 'src/app/core/storages/session.storage';
import { LoginService } from 'src/app/services/login.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	isForbidden = false;
	errorMessage: string;

	constructor(
		private readonly authService: SocialAuthService,
		private readonly sessionService: SessionService,
		private readonly sessionData: SessionStorage,
		private readonly loginService: LoginService,
		private readonly router: Router
	) {}

	ngOnInit() {
		if (this.sessionData.userName && this.sessionData.idToken) {
			this.router.navigateByUrl('/dashboard/one-time');
		}
		this.login();
	}

	login() {
		this.isForbidden = false;
		this.authService.authState.subscribe(
			(user: SocialUser) => {
				this.sessionData.idToken = user.idToken;
				this.sessionData.accessToken = user.authToken;
				this.sessionService.getSessionInfo().subscribe(
					(res) => {
						if (res.isAuthorized) {
							this.sessionData.userName = user.name;
							this.sessionData.userEmail = user.email;
							this.sessionData.photo = user.photoUrl;
							this.loginService.setCurrentLoginState(true);
							this.router.navigateByUrl('/dashboard/one-time');
						} else {
							this.isForbidden = true;
							this.sessionData.clearSession();
							this.errorMessage = 'login.error400';
						}
					},
					(error: HttpErrorResponse) => {
						this.isForbidden = true;
						if (error.status === 401 || error.status === 403 || error.status === 404) {
							this.errorMessage = 'login.error400';
						} else if (error.status === 500) {
							this.errorMessage = 'login.error500';
						} else {
							this.errorMessage = 'login.error';
						}
					}
				);
			},
			(error) => {
				console.log('error', error);
				this.loginService.setCurrentLoginState(false);
				this.errorMessage = 'login.error400';
			}
		);
	}
}
