import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { HotTableRegisterer } from '@handsontable/angular';
import { TranslateService } from '@ngx-translate/core';
import { ParameterServices } from '@services/parameter.service';
import Handsontable from 'handsontable';
import 'handsontable/languages/es-MX';
import moment from 'moment';
import { SessionStorage } from 'src/app/core/storages/session.storage';
import { HttpSpinnerService } from 'src/app/http-spinner/http-spinner.service';
import Swal from 'sweetalert2';
import { Parameter } from '../../../models/Parameter';
import { ParameterDetail } from '../../../models/ParameterDetail';
import { Validate } from '../../parameter/validate';
import { ParametersComponent } from '../parameters/parameters.component';
import { SnackbarParameter } from '../SnackbarParameter/SnackbarParameter.component';
import { ConfigService } from '../../../config/config.service';

@Component({
	selector: 'app-edit-parameter',
	templateUrl: './edit-parameter.component.html',
	styleUrls: ['./edit-parameter.component.scss'],
	providers: [Validate]
})
export class EditParameterComponent implements OnInit, OnChanges, OnDestroy {
	isDisableSave: boolean;
	@Input() productId: number;
	@Input() productName: string;
	@Input() parameter: any;
	@Input() bussinesId: any;
	@Input() tableDataParameter;
	param: Parameter;
	paramDetail: ParameterDetail;
	@ViewChild(MatSort) sort: MatSort;

	validations;
	compareName;

	private hotRegisterer = new HotTableRegisterer();
	id = 'hotInstance';
	parameterDetail;
	tableData = [];
	tableColumns = [];
	tableNameColumns = [];
	tableColumnsNew = [];
	newData;
	validationList: any[] = [];
	parameterName: any;
	userMail;
	validate: Validate;
	sendSnackbarActive;
	date: moment.Moment = moment();
	changeBound;

	hotSettings: Handsontable.GridSettings = {
		licenseKey: this.configService.getSettings('hot')?.handsontable_key || 'non-commercial-and-evaluation',
		stretchH: 'all',
		columns: this.tableColumns,
		colHeaders: this.tableNameColumns,
		manualColumnMove: false,
		contextMenu: true,
		filters: true,
		dropdownMenu: false,
		language: 'es-MX',
		className: 'htCenter',
		rowHeaders: false,
		allowInsertRow: true,
		colWidths: [70, 70, 100, 90, 120, 120, 120, 130, 100, 140, 160, 140, 140, 170],

		afterGetColHeader: (column, th) => {
			th.style.color = 'black';
			if (this.parameterDetail.columns[column].key) {
				th.style.backgroundColor = '#FAA0A0';
			}
		},

		afterGetColumnHeaderRenderers: (renderers) => {
		},

		afterRemoveRow: () => {
			this.hotRegisterer.getInstance(this.id).loadData(this.parameterDetail.data);
			const rowCount = this.hotRegisterer.getInstance(this.id).countRows();

			if (rowCount === 1) {
				Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'question');
				return;
			}

			if (rowCount > 1) {
				var rowValids = [];
				for (var i = 0; i < rowCount - 1; i++) {
					rowValids.push(i);
				}
			}

			this.hotRegisterer.getInstance(this.id).validateRows(rowValids, (valid) => {
				if (valid) {
					this.isDisableSave = true;
					console.log('ok');
				} else {
					this.isDisableSave = false;
					console.log('fallo validacion');
				}
			});
		},

		beforeChange(changes, source) {
			changes?.forEach((element) => {
				if (
					element[1] === 'competitors' ||
					element[1] === 'pos' ||
					element[1] === 'min_class' ||
					element[1] === 'max_class'
				) {
					let valueGet = element[3];
					const typeData = typeof valueGet;
					if (typeData === 'string') {
						element[3] = valueGet.toUpperCase();
					}
				}

				if (element[1] === 'competitor') {
					let valueGet = element[3];
					const typeData = typeof valueGet;
					if (valueGet === 'default' || typeData === 'number') {
					} else {
						element[3] = valueGet.toUpperCase();
					}
				}

				if (
					element[1] === 'time_of_day_start' ||
					element[1] === 'time_of_day_end' ||
					element[1] === 'competitors_time_of_day_start' ||
					element[1] === 'competitors_time_of_day_end'
				) {
					let valueGet = element[3];
					const regex = new RegExp('^-?[0-9][0-9]?:?[0-9][0-9]$');
					if (regex.test(valueGet)) {
						let esNegativo = false;
						let minutos = '';
						let final = '';
						if (valueGet !== '') {
							if (valueGet.includes('-')) {
								esNegativo = true;
							}
							valueGet = valueGet.replace('-', '');
							valueGet = valueGet.replace(':', '');
							minutos = valueGet.substr(-2);
							valueGet = valueGet.slice(0, -2);
							if (esNegativo === true) {
								final = '-';
							}

							if (valueGet.length === 1) {
								final = final + '0' + valueGet;
							} else {
								final = final + valueGet;
							}
							final = final + ':';
							final = final + minutos;

							return (element[3] = final);
						}
					}
				}
			});
		},

		afterInit: () => {
			this.isDisableSave = false;
		},

		afterChange: (changes, source) => {
			if (source !== null) {
				this.isDisableSave = true;
			}
		}
	};

	constructor(
		private ListService: ParameterServices,
		private translateService: TranslateService,
		private snackBar: MatSnackBar,
		private comp: Validate,
		private parameterComp: ParametersComponent,
		private httpSpinnerService: HttpSpinnerService,
		private sessionData: SessionStorage,
		private configService: ConfigService
	) {}

	validateCellSave() {
		this.isDisableSave = false;
		this.tableColumnsNew = [];
		this.httpSpinnerService.nextState$(true);

		if (this.compareName !== this.parameterName) {
			let indice = this.tableDataParameter.findIndex((table) => table.parameterName === this.parameterName);
			if (indice > 0) {
				Swal.fire('Unable to save', 'the parameter name already exists', 'warning');
				this.httpSpinnerService.nextState$(false);
				return;
			}
		}
		this.newData = this.hotRegisterer.getInstance(this.id).getSourceData();
		const rowCount = this.hotRegisterer.getInstance(this.id).countRows();

		if (rowCount === 1) {
			Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'warning');
			this.httpSpinnerService.nextState$(false);
			return;
		}

		if (rowCount > 1) {
			var rowValids = [];
			for (var i = 0; i < rowCount - 1; i++) {
				rowValids.push(i);
			}
		}
		this.hotRegisterer.getInstance(this.id).validateRows(rowValids, (valid) => {
			if (valid) {
				console.log(valid);
				this.newData.length = this.newData.length - 1;
				this.paramDetail = new ParameterDetail();
				this.param = new Parameter();
				for (const value of this.tableColumns) {
					this.tableColumnsNew.push(value.data);
				}

				for (const value of this.newData) {
					value.parameter_id = this.parameter.parameterId;
					if (!value.id) {
						value.id = null;
					}
				}

				for (const value of this.newData) {
					if (value.min_bid_price === '') {
						value.min_bid_price = null;
					}

					if (value.max_bid_price === '') {
						value.max_bid_price = null;
					}

					if (value.min_class === '') {
						value.min_class = null;
					}

					if (value.max_class === '') {
						value.max_class = null;
					}
					if (value.dilution_method === 'true') {
						value.dilution_method = true;
					}

					if (value.dilution_method === 'false') {
						value.dilution_method = false;
					}
				}

				this.paramDetail.columns = this.tableColumnsNew;
				this.paramDetail.data = Object.assign([], this.newData);
				this.param.parameterId = this.parameter.parameterId;
				this.param.businessUnitId = this.bussinesId;
				this.param.productId = this.productId;
				this.param.parameterName = this.parameterName;
				this.param.productName = this.productName;
				this.param.parameterDetail = this.paramDetail;
				this.param.lastUpdateDate = this.date.toDate();
				this.param.lastUpdateUser = this.userMail;

				this.postEditParameter(this.param);
			} else {
				Swal.fire('Unable to save', 'All inputs must be valid. Correct highlighted cells and try again', 'warning');
				this.newData.length = this.newData.length - 1;
				this.httpSpinnerService.nextState$(false);
				return;
			}
		});
	}

	ngOnInit() {
		this.userMail = this.sessionData.userEmail;
		Handsontable.validators.registerValidator('ValidatorGeneric', this.comp.validatorGeneral);
		this.isDisableSave = false;
		this.getEditDetail();
	}

	ngOnChanges() {
		if (this.parameterDetail) {
			this.tableData.length = null;
			this.hotRegisterer.getInstance(this.id).destroyEditor();
			this.isDisableSave = false;
			this.refreshData();
		}
	}

	getEditDetail() {
		if (this.productName === 'Competition Match') {
			console.log('modificar columns size...');
			this.hotSettings.colWidths = [100, 70, 70, 100, 120, 120, 130, 130, 140, 140, 140, 140, 140, 140, 140, 180];
		}
		if (this.parameter === undefined) {
			this.parameter = '';
		} else {
			this.compareName = this.parameter.parameterName;
			this.ListService.getEditTable(this.productName, this.parameter.parameterId).subscribe(
				(res) => {
					this.parameterName = this.parameter.parameterName;
					this.parameterDetail = res;

					for (const value of this.parameterDetail.data.values()) {
						if (value.time_of_day_start) {
							value.time_of_day_start = value.time_of_day_start.substr(0, 5);
							value.time_of_day_end = value.time_of_day_end.substr(0, 5);
						}
					}

					for (const value of this.parameterDetail.columns.values()) {
						if (value.data === 'min_class' || value.data === 'max_class') {
							continue;
						}

						value.correctFormat = true;
						value.strict = true;

						if (value.title.length > 13) {
							const cutList = value.title.split(' ');
							if (cutList.length >= 3) {
								if (cutList.length === 4) {
									if (cutList[0].length + cutList[1].length >= 10) {
										value.title = cutList[0] + ' ' + cutList[1] + '<br>' + cutList[2] + ' ' + cutList[3];
									} else {
										value.title = cutList[0] + ' ' + cutList[1] + ' ' + cutList[2] + '<br>' + cutList[3];
									}
								} else if (cutList.length === 3) {
									value.title = cutList[0] + ' ' + cutList[1] + '<br>' + cutList[2];
								} else if (cutList.length === 5) {
									value.title =
										cutList[0] + ' ' + cutList[1] + '<br>' + cutList[2] + ' ' + cutList[3] + ' ' + cutList[4];
								}
							}

							if (value.data === 'competitors_level') {
								value.title = 'Competitors' + '<br>' + 'Level';
							}

							if (value.data === 'competitors_routing') {
								value.title = 'Competitors' + '<br>' + 'Routing';
							}
						}

						if (
							value.data === 'time_of_day_start' ||
							value.data === 'time_of_day_end' ||
							value.data === 'competitors_time_of_day_start' ||
							value.data === 'competitors_time_of_day_end' ||
							value.data === 'dow'
						) {
							value.type = 'text';
						}

						value.validator = 'ValidatorGeneric';
						console.log('VALUE', value);
						console.log('VALUE IS KEY', value.key);
						/*
            if (value.key === true) {
              value.title = '<div class=bgHeadersKeyTrue>' + value.title + '</div>';
            } else {
              value.title = '<div class=bgHeaderKeyFalse>' + value.title + '</div>';
            }*/
						this.tableNameColumns.push(value.title);
						this.tableColumns.push(value);
						this.validationList.push(value.validations);
					}
					this.hotRegisterer.getInstance(this.id).loadData(this.parameterDetail.data);
					if (this.changeBound) {
						this.hotRegisterer.getInstance(this.id).updateSettings({
							columns: this.tableColumns,
							minSpareRows: 1,
							colWidths: [100, 70, 70, 90, 120, 120, 120, 130, 100, 140, 160, 140, 140, 170]
						});
					} else {
						this.hotRegisterer.getInstance(this.id).updateSettings({ columns: this.tableColumns, minSpareRows: 1 });
					}
				},
				(err) => {
					console.log(err);
				}
			);
		}
	}

	refreshData() {
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
		this.compareName = this.parameter.parameterName;
		this.hotRegisterer.getInstance(this.id).updateSettings({ minSpareRows: 0 });
		this.parameterDetail = null;
		this.parameterName = this.parameter.parameterName;
		this.ListService.getEditTable(this.productName, this.parameter.parameterId).subscribe(
			(res) => {
				this.parameterDetail = res;
				for (const value of this.parameterDetail.data.values()) {
					if (value.time_of_day_start) {
						value.time_of_day_start = value.time_of_day_start.substr(0, 5);
						value.time_of_day_end = value.time_of_day_end.substr(0, 5);
					}
				}
				this.hotRegisterer.getInstance(this.id).loadData(this.parameterDetail.data);
				this.hotRegisterer.getInstance(this.id).updateSettings({ minSpareRows: 1 });
			},
			(err) => {
				console.log(err);
			}
		);
	}

	postEditParameter(parameter: Parameter) {
		this.ListService.putEditParameter(parameter).subscribe(
			(res) => {
				this.parameterComp.refreshTableEdit(res);
				this.hotRegisterer.getInstance(this.id).loadData(res.parameterDetail.data);
				this.compareName = res.parameterName;
				this.openSnackBarCorrect('Parameter has been successfully saved!');
				this.isDisableSave = false;
				this.httpSpinnerService.nextState$(false);
			},
			(err) => {
				if (err.status === 400) {
					this.sendSnackbar(err);
					this.httpSpinnerService.nextState$(false);
					console.error(err);
				} else {
					this.openSnackBarIncorrect('Error! Unable to save parameter');
					this.httpSpinnerService.nextState$(false);
					console.error(err);
				}
			}
		);
	}

	openSnackBarCorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackCorrect']
		});
	}

	openSnackBarIncorrect(message) {
		this.sendSnackbarActive = false;
		this.snackBar.open(message, '', {
			duration: 6000,
			panelClass: ['snackIncorrect']
		});
	}

	sendSnackbar(errMensaje) {
		this.sendSnackbarActive = true;
		this.snackBar.openFromComponent(SnackbarParameter, {
			data: errMensaje,
			panelClass: ['snackValidation']
		});
	}

	downloadCsv() {
		const hot = this.hotRegisterer.getInstance(this.id).getPlugin('exportFile');
		hot.downloadFile('csv', {
			bom: true,
			columnDelimiter: ',',
			columnHeaders: true,
			exportHiddenColumns: true,
			exportHiddenRows: true,
			fileExtension: 'csv',
			filename: 'Parameters[YYYY]-[MM]-[DD]',
			mimeType: 'text/csv',
			rowDelimiter: '\r\n',
			rowHeaders: true
		});
	}

	selectAllCells() {
		const hotInstance = this.hotRegisterer.getInstance(this.id);
		hotInstance.selectCells([[0, 0, hotInstance.countRows() - 1, hotInstance.countCols() - 1]], true, true);
	}

	ngOnDestroy() {
		this.parameter = null;
		this.productName = null;
		this.productId = null;
		this.parameterDetail = [null];
		this.tableData = [];
		this.tableColumns = [{}];
		this.tableNameColumns = [];
		if (this.sendSnackbarActive) {
			this.snackBar.dismiss();
		}
	}
}
