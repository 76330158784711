import { Component } from '@angular/core';

@Component({
	selector: 'app-load-main-links',
	templateUrl: './load-main-links.component.html',
	styleUrls: ['./load-main-links.component.scss']
})
export class LoadMainLinksComponent {
	constructor() {}
}
